import * as React from "react";
import { url } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import { PrintProvider, Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";
import lodash from "lodash";
import { HTTP } from "../../axios";
import AsyncSelect from "react-select/async/dist/react-select.esm";

export default () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [invoices, setInvoices] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [showInUSD, setShowInUSD] = React.useState(false);

  const [showPurchaseQty, setShowPurchaseQty] = React.useState(true);
  const [showSmallMeasurePurchaseQty, setShowSmallMeasurePurchaseQty] =
    React.useState(true);
  const [showSellQty, setShowSellQty] = React.useState(true);
  const [showSmallMeasureSellQty, setShowSmallMeasureSellQty] =
    React.useState(true);
  const [showAverageSellPrice, setShowAverageSellPrice] = React.useState(true);
  const [showAverageSellOnePrice, setShowAverageSellOnePrice] =
    React.useState(true);
  const [showTotalSellPrice, setShowTotalSellPrice] = React.useState(true);
  const [showTotalSellProfit, setShowTotalSellProfit] = React.useState(true);

  const [totalDiscountPrice, setTotalDiscountPrice] = React.useState([]);
  const [totalSellPrice, setTotalSellPrice] = React.useState([]);
  const [totalProfit, setTotalProfit] = React.useState([]);
  const [totalSellQty, setTotalSellQty] = React.useState(0);
  const [totalSellSmallQty, setTotalSellSmallQty] = React.useState(0);
  const [totalSellGiftQty, setTotalSellGiftQty] = React.useState(0);
  const [totalSellSmallGiftQty, setTotalSellSmallGiftQty] = React.useState(0);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let invoice = ``;
    if (invoices !== 0) {
      invoice = `${invoices}`;
    } else {
      invoice = "";
    }

    await HTTP.get(
      `${url}/AdminReports/GetInvoiceProfitLoseReport?_start=${start}&_end=${end}${invoice}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        setTotalDiscountPrice([
          response.headers["mctotaldiscountprice"],
          response.headers["sctotaldiscountprice"],
        ]);
        setTotalSellPrice([
          response.headers["mctotalsellprice"],
          response.headers["sctotalsellprice"],
        ]);
        setTotalProfit([
          response.headers["mctotalprofit"],
          response.headers["sctotalprofit"],
        ]);
        setTotalSellQty(response.headers["totalsellqty"]);
        setTotalSellSmallQty(response.headers["totalsellsmallqty"]);
        setTotalSellGiftQty(response.headers["totalsellgiftqty"]);
        setTotalSellSmallGiftQty(response.headers["totalsellsmallgiftqty"]);
        setData(
          lodash
            .chain(response.data)
            .groupBy("productId")
            .map((value, key) => {
              let mcAveragePurchaseOnePriceList = value;
              let mcAveragePurchasePriceList = value;
              let mcAverageSellOnePriceList = value;
              let mcAverageSellPriceList = value;
              let scAveragePurchaseOnePriceList = value;
              let scAveragePurchasePriceList = value;
              let scAverageSellOnePriceList = value;
              let scAverageSellPriceList = value;

              mcAveragePurchaseOnePriceList =
                mcAveragePurchaseOnePriceList.filter((o) => {
                  return o.type === "Purchase";
                });
              mcAveragePurchasePriceList = mcAveragePurchasePriceList.filter(
                (o) => {
                  return o.type === "Purchase";
                }
              );
              mcAverageSellOnePriceList = mcAverageSellOnePriceList.filter(
                (o) => {
                  return o.type === "Quick Sell" || o.type === "Sell";
                }
              );
              mcAverageSellPriceList = mcAverageSellPriceList.filter((o) => {
                return o.type === "Quick Sell" || o.type === "Sell";
              });
              scAveragePurchaseOnePriceList =
                scAveragePurchaseOnePriceList.filter((o) => {
                  return o.type === "Purchase";
                });
              scAveragePurchasePriceList = scAveragePurchasePriceList.filter(
                (o) => {
                  return o.type === "Purchase";
                }
              );
              scAverageSellOnePriceList = scAverageSellOnePriceList.filter(
                (o) => {
                  return o.type === "Quick Sell" || o.type === "Sell";
                }
              );
              scAverageSellPriceList = scAverageSellPriceList.filter((o) => {
                return o.type === "Quick Sell" || o.type === "Sell";
              });
              return {
                productId: key,
                productName: value[0].productName,
                productBarcode: value[0].productBarcode,
                smallMeasurePurchaseQty: lodash.sumBy(
                  value,
                  "smallMeasurePurchaseQty"
                ),
                purchaseQty: lodash.sumBy(value, "purchaseQty"),
                sellQty: lodash.sumBy(value, "sellQty"),
                smallMeasureSellQty: lodash.sumBy(value, "smallMeasureSellQty"),

                ///// type === purchase
                mcAveragePurchaseOnePrice: lodash.meanBy(
                  scAveragePurchaseOnePriceList,
                  "mcAveragePurchaseOnePrice"
                ),
                scAveragePurchaseOnePrice: lodash.meanBy(
                  mcAveragePurchaseOnePriceList,
                  "scAveragePurchaseOnePrice"
                ),

                mcAveragePurchasePrice: lodash.meanBy(
                  mcAveragePurchasePriceList,
                  "mcAveragePurchasePrice"
                ),
                scAveragePurchasePrice: lodash.meanBy(
                  scAveragePurchasePriceList,
                  "scAveragePurchasePrice"
                ),

                ///// type === quick sell or sell
                mcAverageSellOnePrice: lodash.meanBy(
                  mcAverageSellOnePriceList,
                  "mcAverageSellOnePrice"
                ),
                scAverageSellOnePrice: lodash.meanBy(
                  scAverageSellOnePriceList,
                  "scAverageSellOnePrice"
                ),

                mcAverageSellPrice: lodash.meanBy(
                  mcAverageSellPriceList,
                  "mcAverageSellPrice"
                ),
                scAverageSellPrice: lodash.meanBy(
                  scAverageSellPriceList,
                  "scAverageSellPrice"
                ),

                mcTotalSellPrice: lodash.sumBy(value, "mcTotalSellPrice"),
                scTotalSellPrice: lodash.sumBy(value, "scTotalSellPrice"),
                mcTotalPurchasePrice: lodash.sumBy(
                  value,
                  "mcTotalPurchasePrice"
                ),
                scTotalPurchasePrice: lodash.sumBy(
                  value,
                  "scTotalPurchasePrice"
                ),
                mcTotalSellProfit: lodash.sumBy(value, "mcTotalSellProfit"),
                scTotalSellProfit: lodash.sumBy(value, "scTotalSellProfit"),
                type: "-",
              };
            })
            .value()
        );
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(function () {
    setIsLoading(true);
  }, []);

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [invoices, start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/SellInvoices?_end=10&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((invoice) => {
        newArray.push({
          value: invoice.id,
          label: `${invoice.invoiceNumber} (${invoice.customerName})`,
          invoice: invoice,
        });
      });
      callback(newArray);
    }
  };

  return (
    <div>
      <PrintProvider>
        <Print single name={`expireReport`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.profitLoseReport")}
            </h1>
            <div className={`d-inline-block`} style={{ float: "inline-end" }}>
              <NoPrint>
                <Button
                  variant={"outline-primary"}
                  onClick={() => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "15px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}
                </Button>
                <div className={`clearfix`}> </div>
              </NoPrint>
            </div>
          </div>
          <NoPrint force>
            <Row>
              {isLoading && <LoadingScreen />}
              <Col xs={6}>
                <AsyncSelect
                  defaultOptions
                  loadOptions={loadOptions}
                  isMulti
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  onChange={(e) => {
                    let invoice = "";
                    if (e.length !== 0) {
                      e.map((x) => (invoice += "&id=" + x.value));
                      setInvoices(invoice);
                    } else {
                      setInvoices(``);
                    }
                  }}
                />
              </Col>
              <Col xs={1}>
                <div className="form-check">
                  <input
                    id={"showInUSD"}
                    type="checkbox"
                    onChange={(e) => setShowInUSD(e.target.checked)}
                    defaultChecked={showInUSD}
                  />
                  <label className="form-check-label mb-3" htmlFor="showInUSD">
                    {translate("resources.root.showInUSD")}
                  </label>
                </div>
              </Col>
              <Col xs={8} className={`m-1`}>
                <Button
                  id="export"
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  Export CSV
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <h5>
                  {translate("resources.root.totalDiscountPrice")}:&nbsp;
                  {showInUSD &&
                    totalDiscountPrice[0] &&
                    parseFloat(totalDiscountPrice[0])?.toLocaleString() +
                      " " +
                      translate("resources.root.usd")}
                  {!showInUSD &&
                    totalDiscountPrice[1] &&
                    parseFloat(totalDiscountPrice[1])?.toLocaleString() +
                      " " +
                      translate("resources.root.iqd")}
                </h5>
              </Col>
              <Col xs={3}>
                <h5>
                  {translate("resources.root.totalSellPrice")}:&nbsp;
                  {showInUSD &&
                    totalSellPrice[0] &&
                    parseFloat(totalSellPrice[0])?.toLocaleString() +
                      " " +
                      translate("resources.root.usd")}
                  {!showInUSD &&
                    totalSellPrice[1] &&
                    parseFloat(totalSellPrice[1])?.toLocaleString() +
                      " " +
                      translate("resources.root.iqd")}
                </h5>
              </Col>
              <Col xs={3}>
                <h5>
                  {translate("resources.root.totalProfit")}:&nbsp;
                  {showInUSD &&
                    totalProfit[0] &&
                    parseFloat(totalProfit[0])?.toLocaleString() +
                      " " +
                      translate("resources.root.usd")}
                  {!showInUSD &&
                    totalProfit[1] &&
                    parseFloat(totalProfit[1])?.toLocaleString() +
                      " " +
                      translate("resources.root.iqd")}
                </h5>
              </Col>
              <Col xs={3}>
                <h5>
                  {translate("resources.root.totalSellQty")}:&nbsp;
                  {totalSellQty && parseFloat(totalSellQty)?.toLocaleString()}
                  &nbsp;+&nbsp;
                  {totalSellSmallQty &&
                    parseFloat(totalSellSmallQty)?.toLocaleString()}
                </h5>
              </Col>
              <Col xs={3}>
                <h5>
                  {translate("resources.root.totalSellGiftQty")}:&nbsp;
                  {totalSellGiftQty &&
                    parseFloat(totalSellGiftQty)?.toLocaleString()}
                  &nbsp;+&nbsp;
                  {totalSellSmallGiftQty &&
                    parseFloat(totalSellSmallGiftQty)?.toLocaleString()}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col xs={1}>
                <Col>
                  <div className="form-check">
                    <input
                      id={"purchaseQty"}
                      type="checkbox"
                      onChange={(e) => setShowPurchaseQty(e.target.checked)}
                      defaultChecked={showPurchaseQty}
                    />
                    <label
                      className="form-check-label mb-3"
                      htmlFor="purchaseQty"
                    >
                      {translate("resources.root.purchaseQty")}
                    </label>
                  </div>
                </Col>
              </Col>
              <Col xs={1}>
                <Col>
                  <div className="form-check">
                    <input
                      id={"smallMeasurePurchaseQty"}
                      type="checkbox"
                      onChange={(e) =>
                        setShowSmallMeasurePurchaseQty(e.target.checked)
                      }
                      defaultChecked={showSmallMeasurePurchaseQty}
                    />
                    <label
                      className="form-check-label mb-3"
                      htmlFor="smallMeasurePurchaseQty"
                    >
                      {translate("resources.root.smallMeasurePurchaseQty")}
                    </label>
                  </div>
                </Col>
              </Col>
              <Col xs={1}>
                <Col>
                  <div className="form-check">
                    <input
                      id={"sellQty"}
                      type="checkbox"
                      onChange={(e) => setShowSellQty(e.target.checked)}
                      defaultChecked={showSellQty}
                    />
                    <label className="form-check-label mb-3" htmlFor="sellQty">
                      {translate("resources.root.sellQty")}
                    </label>
                  </div>
                </Col>
              </Col>
              <Col xs={1}>
                <Col>
                  <div className="form-check">
                    <input
                      id={"smallMeasureSellQty"}
                      type="checkbox"
                      onChange={(e) =>
                        setShowSmallMeasureSellQty(e.target.checked)
                      }
                      defaultChecked={showSmallMeasureSellQty}
                    />
                    <label
                      className="form-check-label mb-3"
                      htmlFor="smallMeasureSellQty"
                    >
                      {translate("resources.root.gift")}
                    </label>
                  </div>
                </Col>
              </Col>
              <Col xs={1}>
                <Col>
                  <div className="form-check">
                    <input
                      id={"averageSellPrice"}
                      type="checkbox"
                      onChange={(e) =>
                        setShowAverageSellPrice(e.target.checked)
                      }
                      defaultChecked={showAverageSellPrice}
                    />
                    <label
                      className="form-check-label mb-3"
                      htmlFor="averageSellPrice"
                    >
                      {translate("resources.root.averageSellPrice")}
                    </label>
                  </div>
                </Col>
              </Col>
              <Col xs={1}>
                <Col>
                  <div className="form-check">
                    <input
                      id={"averageSellOnePrice"}
                      type="checkbox"
                      onChange={(e) =>
                        setShowAverageSellOnePrice(e.target.checked)
                      }
                      defaultChecked={showAverageSellOnePrice}
                    />
                    <label
                      className="form-check-label mb-3"
                      htmlFor="averageSellOnePrice"
                    >
                      {translate("resources.root.averageSellOnePrice")}
                    </label>
                  </div>
                </Col>
              </Col>
              <Col xs={1}>
                <Col>
                  <div className="form-check">
                    <input
                      id={"totalSellPrice"}
                      type="checkbox"
                      onChange={(e) => setShowTotalSellPrice(e.target.checked)}
                      defaultChecked={showTotalSellPrice}
                    />
                    <label
                      className="form-check-label mb-3"
                      htmlFor="totalSellPrice"
                    >
                      {translate("resources.root.totalSellPrice")}
                    </label>
                  </div>
                </Col>
              </Col>
              <Col xs={1}>
                <Col>
                  <div className="form-check">
                    <input
                      id={"totalSellProfit"}
                      type="checkbox"
                      onChange={(e) => setShowTotalSellProfit(e.target.checked)}
                      defaultChecked={showTotalSellProfit}
                    />
                    <label
                      className="form-check-label mb-3"
                      htmlFor="totalSellProfit"
                    >
                      {translate("resources.root.totalSellProfit")}
                    </label>
                  </div>
                </Col>
              </Col>
            </Row>
          </NoPrint>
          <Row className={`mt-2`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.name")}</th>
                    <th>{translate("resources.root.barcode")}</th>
                    {showSellQty && (
                      <th>{translate("resources.root.sellQty")}</th>
                    )}
                    {showSmallMeasureSellQty && (
                      <th>{translate("resources.root.gift")}</th>
                    )}
                    {showAverageSellPrice && (
                      <th>{translate("resources.root.averageSellPrice")}</th>
                    )}
                    {showAverageSellOnePrice && (
                      <th>{translate("resources.root.averageSellOnePrice")}</th>
                    )}
                    {showTotalSellPrice && (
                      <th>{translate("resources.root.totalSellPrice")}</th>
                    )}
                    {showTotalSellProfit && (
                      <th>{translate("resources.root.totalSellProfit")}</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr
                          style={{
                            backgroundColor:
                              one.mcAveragePurchaseOnePrice >
                                one.mcAverageSellOnePrice &&
                              one.type !== "Purchase"
                                ? "tomato"
                                : "transparent",
                          }}
                        >
                          <td>{one.productId ?? "-"}</td>
                          <td>
                            {one.productName.length > 50
                              ? one.productName.substring(0, 50).concat("...")
                              : one.productName}
                          </td>
                          <td>{one.productBarcode ?? "-"}</td>
                          {showSellQty && (
                            <td>
                              {one.sellQty ?? "0"} +{" "}
                              {one.smallMeasureSellQty ?? "0"}
                            </td>
                          )}
                          {showSmallMeasureSellQty && (
                            <td>
                              {one.sellGiftQty ?? "0"} +{" "}
                              {one.smallMeasureSellGiftQty ?? "0"}
                            </td>
                          )}
                          {showAverageSellPrice && (
                            <td>
                              {showInUSD
                                ? one.mcAverageSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scAverageSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {showAverageSellOnePrice && (
                            <td>
                              {showInUSD
                                ? one.mcAverageSellOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scAverageSellOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {showTotalSellPrice && (
                            <td>
                              {showInUSD
                                ? one.mcTotalSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scTotalSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {showTotalSellProfit && (
                            <td>
                              {showInUSD
                                ? one.mcTotalSellProfit?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scTotalSellProfit?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
