import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  ReferenceField,
  DateField,
  required,
  FunctionField,
  NumberInput,
  minValue,
  DateInput,
  BooleanInput,
  AutocompleteInput,
  useTranslate,
  FormDataConsumer,
} from "react-admin";
import { ListActions } from "../templates/ListActions";
import { url } from "../../request";
import moment from "moment";
import { MyCustomPagination, SearchFilter } from "../../App";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import LinearProgress from "@mui/material/LinearProgress";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";

export const EmployeeAbsentsList = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const translate = useTranslate();

  return (
    <List
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions setCurrency={setCurrency} currency={currency} />}
    >
      <Datagrid bulkActionButtons={<BulkAction {...props} />}>
        <ReferenceField
          label="resources.root.employee"
          source="employeeId"
          reference="Employees"
        >
          <FunctionField
            label="resources.root.name"
            render={(record) => `${record.firstName} ${record.middleName}`}
          />
        </ReferenceField>
        {currency === "default" && (
          <FunctionField
            label="resources.root.subtractedSalary"
            sortBy={"mcSubtractedSalary"}
            render={(record) =>
              record.employee.salaryByMain
                ? `$${record.mcSubtractedSalary}`
                : `${record.scSubtractedSalary} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label="resources.root.subtractedSalary"
            sortBy={"mcSubtractedSalary"}
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcSubtractedSalary}`
                : `${record.scSubtractedSalary} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        <TextField source="reason" label="resources.root.reason" />
        <TextField source="note" label="resources.root.note" />
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
      </Datagrid>
    </List>
  );
};

export const EmployeeAbsentsCreate = (props) => {
  const translate = useTranslate();
  const [choices, setChoices] = React.useState([]);
  const [employeeId, setEmployeeId] = React.useState(0);
  const [selectedEmployee, setSelectedEmployee] = React.useState({});
  const [empData, setEmpData] = React.useState([]);
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const classes = useStyles();

  React.useEffect(() => {
    HTTP.get(`${url}/Employees?_end=1000`).then((res) => {
      setChoices(res.data);
    });
  }, []);

  React.useEffect(() => {
    if (employeeId === 0) return;
    HTTP.get(`${url}/Employees/${employeeId}`).then((res) => {
      setSelectedEmployee(res.data);
      setEmpData(res.data.employeeAbsents);
      setLoading(false);
    });
  }, [employeeId]);

  const optionRenderer = (choice) => `${choice.firstName} ${choice.middleName}`;
  return (
    <Create
      {...props}
      transform={(data) => ({
        ...data,
        accountant: localStorage.getItem("name"),
      })}
    >
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <AutocompleteInput
              source="employeeId"
              choices={choices}
              optionText={optionRenderer}
              optionValue="id"
              validate={[required()]}
              onChange={(val) => {
                setEmployeeId(val);
                setSeparatedNumber(0);
                setLoading(true);
              }}
              label={translate("resources.root.chooseEmployee")}
            />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  selectedEmployee &&
                  (formData.cashPaid ? (
                    formData.employee.salaryByMain ? (
                      <NumberInput
                        validate={[required(), minValue(0)]}
                        source="mcSubtractedSalary"
                        label={translate("resources.root.subtructedSalary")}
                        onChange={(e) =>
                          setSeparatedNumber(parseFloat(e.target.value) || 0)
                        }
                        min={0}
                      />
                    ) : (
                      <NumberInput
                        validate={[required(), minValue(0)]}
                        source="scSubtractedSalary"
                        onChange={(e) =>
                          setSeparatedNumber(parseFloat(e.target.value) || 0)
                        }
                        label={translate("resources.root.subtructedSalary")}
                        min={0}
                      />
                    )
                  ) : selectedEmployee.salaryByMain ? (
                    <NumberInput
                      validate={[required(), minValue(0)]}
                      source="mcSubtractedSalary"
                      defaultValue={0}
                      label={translate("resources.root.subtructedSalary")}
                      onChange={(e) =>
                        setSeparatedNumber(parseFloat(e.target.value) || 0)
                      }
                      min={0}
                    />
                  ) : (
                    <NumberInput
                      validate={[required(), minValue(0)]}
                      source="scSubtractedSalary"
                      defaultValue={0}
                      onChange={(e) =>
                        setSeparatedNumber(parseFloat(e.target.value) || 0)
                      }
                      label={translate("resources.root.subtructedSalary")}
                      min={0}
                    />
                  ))
                );
              }}
            </FormDataConsumer>
            <DateInput
              label={translate("resources.root.date")}
              source="date"
              defaultValue={moment().format("YYYY-MM-DD")}
            />
            <TextInput
              source="reason"
              multiline
              label={translate("resources.root.reason")}
            />
            <TextInput
              source="note"
              multiline
              label={translate("resources.root.note")}
            />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  selectedEmployee.id &&
                  formData.cashPaid && (
                    <BooleanInput
                      source="byMain"
                      defaultValue={selectedEmployee.cashPaid}
                      label={
                        formData.byMain
                          ? `${translate("resources.root.usd")}`
                          : ` ${translate("resources.root.iqd")}`
                      }
                    />
                  )
                );
              }}
            </FormDataConsumer>
          </div>
          <div className={classes.formCol}>
            {
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {translate("resources.root.totalAbsents")}
                  </Typography>
                  <Typography
                    className={classes.marginBottom}
                    variant="h5"
                    component="h2"
                  >
                    {selectedEmployee.salaryByMain
                      ? `$${empData
                          .map((el) => el.mcSubtractedSalary)
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}`
                      : `${empData
                          .map((el) => el.scSubtractedSalary)
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`}
                  </Typography>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {selectedEmployee.id && "Employee"}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {selectedEmployee.phone}
                    <br />
                    {selectedEmployee.email}
                    <br />
                    {translate("resources.root.address")} :{" "}
                    {selectedEmployee.address}
                    <br />
                    {translate("resources.root.salaryType")} :{" "}
                    {selectedEmployee.salaryType}
                  </Typography>
                </CardContent>
                {loading && <LinearProgress className={classes.progressBar} />}
              </Card>
            }
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "49%",
  },
  input: {
    width: "100%",
  },
  table: {
    minWidth: 500,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
  root: {
    minWidth: 275,
    marginTop: 6,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  marginBottom: {
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
    marginBottom: 0,
  },
  pos: {
    marginBottom: 12,
  },
  primary: {
    backgroundColor: "#1976d2",
    color: "#fff",
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  btnGroup: {
    marginRight: 10,
  },
});
