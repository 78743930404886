import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  EditButton,
  FunctionField,
  FormDataConsumer,
  BooleanInput,
  NumberInput,
  AutocompleteInput,
  required,
  TabbedForm,
  FormTab,
  maxValue,
  minValue,
  number,
  useTranslate,
  BooleanField,
  useNotify,
  useRefresh,
  Show,
  SimpleShowLayout,
  NumberField,
  ArrayField,
  DateField,
  ShowButton,
  useResourceDefinition,
  ImageField,
  ImageInput,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import { url } from "../../request";
import MaterialTextField from "@mui/material/TextField";
import Autocomplete from "@mui/lab/Autocomplete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import MaterialButton from "@mui/material/Button";
import swal from "sweetalert";
import { ListActions } from "../templates/ListActions";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";
import Input from "@mui/material/Input";
import { useParams } from "react-router-dom";
import { path } from "../../request";

export const SupplierList = (props) => {
  const translate = useTranslate();
  const [currency, setCurrency] = React.useState("default");
  return (
    <List
      {...props}
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions setCurrency={setCurrency} currency={currency} />}
    >
      <Datagrid bulkActionButtons={<BulkAction {...props} />}>
        <TextField source="id" label="resources.root.id" />
        <FunctionField
          sortBy={"supplierName"}
          label="resources.root.supplierName"
          render={(record) => (
            <a
              href={`#/Reports/SupplierStatement/${record.id}`}
              target={`_blank`}
            >
              {record.supplierName}
            </a>
          )}
        />
        <TextField source="owner" label="resources.root.owner" />
        <TextField source="phone" label="resources.root.phone" />
        <TextField source="address" label="resources.root.address" />
        {currency === "default" && (
          <FunctionField
            label="resources.root.currentLoan"
            sortBy={"mcCurrentLoan"}
            render={(record) =>
              (record.mcCurrentLoan || record.scCurrentLoan) &&
              (record.payLoanByMain
                ? `$${record.mcCurrentLoan?.toLocaleString()}`
                : `${record.scCurrentLoan?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`)
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label="resources.root.currentLoan"
            sortBy={"mcCurrentLoan"}
            render={(record) =>
              (record.mcCurrentLoan || record.scCurrentLoan) &&
              (currency === "dollar"
                ? `$${record.mcCurrentLoan?.toLocaleString()}`
                : `${record.scCurrentLoan?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`)
            }
          />
        )}
        <BooleanField source="payLoanByMain" label="resources.root.isDollar" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const SupplierCreate = (props) => {
  const [choices, setChoices] = React.useState([]);
  const [selectedChoices, setSelectedChoices] = React.useState([]);
  const [currency, setCurrency] = React.useState(true);

  const classes = useStyles();
  React.useEffect(() => {
    HTTP.get(`${url}/Stores`).then((res) => {
      setChoices(res.data);
    });
  }, []);

  const optionRenderer = (choice) => `${choice.title}`;
  return (
    <Create
      {...props}
      transform={(data) => {
        return {
          ...data,
          storeIds: [data.storeIds],
        };
      }}
      redirect="list"
    >
      <SimpleForm defaultValues={{ discountRate: 0 }}>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <AutocompleteInput
              source="storeIds"
              choices={choices}
              optionText={optionRenderer}
              onSelect={(val) => setSelectedChoices([...selectedChoices, val])}
              label="resources.root.chooseStore"
              className={classes.input}
            />
            <TextInput
              source="supplierName"
              label="resources.root.supplierName"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="owner"
              label="resources.root.owner"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="email"
              label="resources.root.email"
              className={classes.input}
            />
            <TextInput
              source="phone"
              label="resources.root.phone"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="phone2"
              label="resources.root.phone2"
              className={classes.input}
            />
            <NumberInput
              source="discountRate"
              min={0}
              max={100}
              step={1}
              label="resources.root.discountRate"
              className={classes.input}
            />
          </div>
          <div className={classes.formCol}>
            <TextInput
              source="country"
              label="resources.root.country"
              defaultValue="Iraq"
              className={classes.input}
            />
            <TextInput
              source="city"
              label="resources.root.city"
              defaultValue="Erbil"
              className={classes.input}
            />
            <TextInput
              source="address"
              label="resources.root.address"
              className={classes.input}
            />
            <TextInput
              source="note"
              label="resources.root.note"
              className={classes.input}
            />
            {currency ? (
              <NumberInput
                validate={[required(), minValue(0)]}
                source="mcRoofLoan"
                label="resources.root.mcRoofLoan"
                min={0}
                defaultValue={0}
                className={classes.input}
              />
            ) : (
              <NumberInput
                validate={[required(), minValue(0)]}
                source="scRoofLoan"
                label="resources.root.scRoofLoan"
                min={0}
                defaultValue={0}
                className={classes.input}
              />
            )}
            {currency ? (
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    <NumberInput
                      validate={[
                        required(),
                        maxValue(formData.mcRoofLoan ? formData.mcRoofLoan : 0),
                        minValue(0),
                      ]}
                      max={formData.mcRoofLoan ? formData.mcRoofLoan : 0}
                      min={0}
                      defaultValue={0}
                      source="mcOpenAccountLoan"
                      label="resources.root.currentLoan"
                      className={classes.input}
                    />
                  );
                }}
              </FormDataConsumer>
            ) : (
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    <NumberInput
                      validate={[
                        required(),
                        maxValue(formData.scRoofLoan ? formData.scRoofLoan : 0),
                        minValue(0),
                      ]}
                      max={formData.scRoofLoan ? formData.scRoofLoan : 0}
                      min={0}
                      source="scOpenAccountLoan"
                      defaultValue={0}
                      label="resources.root.currentLoan"
                      className={classes.input}
                    />
                  );
                }}
              </FormDataConsumer>
            )}
            <BooleanInput
              source="payLoanByMain"
              className={classes.input}
              label={currency ? "resources.root.usd" : "resources.root.iqd"}
              defaultValue={true}
              onChange={(e) => setCurrency(e.target.checked)}
            />
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

export const SupplierEdit = () => {
  const props = useResourceDefinition();
  const { id } = useParams();
  const [stores, setStores] = React.useState([]);
  const [storeSuppliers, setStoreSuppliers] = React.useState([]);
  const [storeId, setStoreId] = React.useState(0);
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const [supplier, setSupplier] = React.useState(0);
  const [currentLoan, setCurrentLoan] = React.useState(0);
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();

  function isInArray(value, array) {
    return array.indexOf(value) > -1;
  }

  React.useEffect(() => {
    HTTP.get(`${url}/Suppliers/${id}`).then((res) => {
      setSupplier(res.data);
      console.log(res.data, "lkjuhsfbvjklhsbjhs");
      res.data.storeSuppliers && setStoreSuppliers(res.data.storeSuppliers);
    });
  }, []);

  React.useEffect(() => {
    HTTP.get(`${url}/Stores?_end=10000`).then((res) => {
      setStores(
        res.data.filter(
          (el) =>
            !isInArray(
              el.id,
              storeSuppliers.map((el) => el.store.id)
            )
        )
      );
    });
  }, [storeSuppliers]);

  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="resources.root.supplier">
          <TextInput
            source="supplierName"
            label="resources.root.supplierName"
            className={classes.input}
            validate={[required()]}
          />
          <TextInput
            source="owner"
            label="resources.root.owner"
            className={classes.input}
            validate={[required()]}
          />
          <TextInput
            source="email"
            label="resources.root.email"
            className={classes.input}
          />
          <TextInput
            source="address"
            label="resources.root.address"
            className={classes.input}
          />
          <TextInput
            source="phone"
            label="resources.root.phone"
            className={classes.input}
            validate={[required()]}
          />
          <TextInput
            source="phone2"
            label="resources.root.phone2"
            className={classes.input}
          />
          <TextInput
            source="country"
            label="resources.root.country"
            className={classes.input}
          />
          <TextInput
            source="city"
            label="resources.root.city"
            className={classes.input}
          />
          <TextInput
            source="note"
            label="resources.root.note"
            className={classes.input}
          />
          <NumberInput
            source="discountRate"
            min={0}
            max={100}
            step={1}
            label="resources.root.discountRate"
            className={classes.input}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              setSeparatedNumber(
                formData.payLoanByMain
                  ? parseFloat(formData.mcRoofLoan)
                  : parseFloat(formData.scRoofLoan)
              );
              return formData.payLoanByMain ? (
                <NumberInput
                  validate={[
                    required(),
                    number(),
                    minValue(formData.mcCurrentLoan),
                  ]}
                  min={formData.mcCurrentLoan}
                  source="mcRoofLoan"
                  label="resources.root.roofLoan"
                  className={classes.input}
                  onChange={(e) =>
                    setSeparatedNumber(parseFloat(e.target.value) || 0)
                  }
                  helperText={
                    separatedNumber > 0
                      ? `$${separatedNumber?.toLocaleString()}`
                      : ""
                  }
                />
              ) : (
                <NumberInput
                  validate={[
                    required(),
                    number(),
                    minValue(formData.scCurrentLoan),
                  ]}
                  min={formData.scCurrentLoan}
                  source="scRoofLoan"
                  label="resources.root.roofLoan"
                  className={classes.input}
                  onChange={(e) =>
                    setSeparatedNumber(parseFloat(e.target.value) || 0)
                  }
                  helperText={
                    separatedNumber > 0
                      ? `${separatedNumber?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`
                      : ""
                  }
                />
              );
            }}
          </FormDataConsumer>

          <ImageInput
            source="attachment"
            label="resources.root.attachment"
            accept="image/*"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <FunctionField
            label="resources.root.attachment"
            render={(record) =>
              record.attachment !== null && (
                <div style={{ position: "relative" }}>
                  <button
                    type="button"
                    className="btn btn-danger p-2"
                    style={{ right: "0px", position: "absolute" }}
                    onClick={() => {
                      HTTP.delete(
                        `${url}/Customers/DeleteCustomerImage?id=${id}`
                      )
                        .then(() => {
                          notify("Image deleted successfully", {
                            type: "success",
                            undoable: true,
                          });
                          refresh();
                        })
                        .catch((err) =>
                          notify(err.response.data.message, {
                            type: "error",
                            undoable: true,
                          })
                        );
                    }}
                  >
                    <span>&times;</span>
                  </button>
                  <img
                    style={{
                      width: 200,
                      height: 200,
                      objectFit: "scale-down",
                      cursor: "pointer",
                    }}
                    src={`${path}${record.attachment}`}
                    alt="Attachment"
                    onClick={() =>
                      window.open(`${path}${record.attachment}`, "_blank")
                    }
                  />
                </div>
              )
            }
          />
        </FormTab>
        <FormTab label="resources.root.stores">
          <div className={classes.addStoreSection}>
            <Autocomplete
              id="combo-box-demo"
              options={stores}
              onChange={(e, val) => (val ? setStoreId(val.id) : setStoreId(0))}
              getOptionLabel={(option) => option.title}
              style={{ width: 300 }}
              renderInput={(params) => (
                <MaterialTextField
                  {...params}
                  label={translate("resources.root.store")}
                  variant="outlined"
                />
              )}
            />
            {supplier.payLoanByMain && (
              <Input
                type={"number"}
                label={"resources.root.currentLoan"}
                defaultValue={currentLoan}
                onChange={(e) => setCurrentLoan(e.target.value)}
              />
            )}
            {!supplier.payLoanByMain && (
              <Input
                type={"number"}
                label={"resources.root.currentLoan"}
                defaultValue={currentLoan}
                onChange={(e) => setCurrentLoan(e.target.value)}
              />
            )}
            <MaterialButton
              variant="outlined"
              disabled={!storeId}
              onClick={() => {
                HTTP.post(`${url}/StoreSuppliers`, {
                  supplierId: props.id,
                  storeId: storeId,
                  byMain: supplier.payLoanByMain,
                  mcOpenAccountLoan: currentLoan,
                  scOpenAccountLoan: currentLoan,
                })
                  .then((res) => {
                    HTTP.get(`${url}/Suppliers/${id}`).then((res) => {
                      res.data.storeSuppliers &&
                        setStoreSuppliers(res.data.storeSuppliers);
                    });
                  })
                  .catch((err) =>
                    notify(err.response.data.message, {
                      type: "error",
                      undoable: true,
                    })
                  );
                setStoreId(0);
              }}
            >
              {translate("resources.root.add")}
            </MaterialButton>
          </div>
          <TableContainer component={Paper} className={classes.table}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {translate("resources.root.supplierName")}
                  </TableCell>
                  <TableCell align="right">
                    {translate("resources.root.description")}
                  </TableCell>
                  <TableCell align="right">
                    {translate("resources.root.openAccountLoan")}
                  </TableCell>
                  <TableCell align="right">
                    {translate("resources.root.currentLoan")}
                  </TableCell>
                  <TableCell align="right">
                    {translate("resources.root.action")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {storeSuppliers &&
                  storeSuppliers.map((row) => (
                    <TableRow key={row.store.id}>
                      <TableCell>{row.store.title}</TableCell>
                      <TableCell align="right">
                        {row.store.description}
                      </TableCell>
                      <TableCell align="right">
                        {supplier.payLoanByMain
                          ? row.mcOpenAccountLoan?.toLocaleString()
                          : row.scOpenAccountLoan?.toLocaleString()}
                      </TableCell>
                      <TableCell align="right">
                        {supplier.payLoanByMain
                          ? row.mcCurrentLoan?.toLocaleString()
                          : row.scCurrentLoan?.toLocaleString()}
                      </TableCell>
                      <TableCell align="right">
                        <DeleteIcon
                          onClick={() => {
                            swal({
                              title: translate("resources.root.swalTitle"),
                              text: translate("resources.root.swalText"),
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then((willDelete) => {
                              if (willDelete) {
                                HTTP.delete(`${url}/StoreSuppliers/${row.id}`)
                                  .then((res) => {
                                    HTTP.get(`${url}/Suppliers/${id}`).then(
                                      (res) => {
                                        res.data.storeSuppliers &&
                                          setStoreSuppliers(
                                            res.data.storeSuppliers
                                          );
                                      }
                                    );
                                    swal(
                                      translate("resources.root.swalDeleted"),
                                      {
                                        icon: "success",
                                      }
                                    );
                                  })
                                  .catch((err) =>
                                    notify(err.response.data.message, {
                                      type: "error",
                                      undoable: true,
                                    })
                                  )
                                  .finally((res) => {
                                    HTTP.get(`${url}/Suppliers/${id}`).then(
                                      (res) => {
                                        res.data.storeSuppliers &&
                                          setStoreSuppliers(
                                            res.data.storeSuppliers
                                          );
                                      }
                                    );
                                  });
                              } else {
                                swal(
                                  translate(
                                    "resources.root.swalDeleteCancelled"
                                  )
                                );
                              }
                            });
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const SupplierShow = (props) => {
  const translate = useTranslate();
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="supplierName" label="resources.root.name" />
        <TextField source="owner" label="resources.root.owner" />
        <TextField source="email" label="resources.root.email" />
        <TextField source="phone" label="resources.root.phone" />
        <TextField source="phone2" label="resources.root.phone2" />
        <TextField source="country" label="resources.root.country" />
        <TextField source="city" label="resources.root.city" />
        <TextField source="address" label="resources.root.address" />
        <TextField source="note" label="resources.root.note" />
        <FunctionField
          label="resources.root.currentLoan"
          render={(record) =>
            record.payLoanByMain
              ? record.mcCurrentLoan?.toLocaleString() +
                " " +
                translate("resources.root.usd")
              : record.scCurrentLoan?.toLocaleString() +
                " " +
                translate("resources.root.iqd")
          }
        />
        <FunctionField
          label="resources.root.roofLoan"
          render={(record) =>
            record.payLoanByMain
              ? record.mcRoofLoan?.toLocaleString() +
                " " +
                translate("resources.root.usd")
              : record.scRoofLoan?.toLocaleString() +
                " " +
                translate("resources.root.iqd")
          }
        />
        <FunctionField
          label="resources.root.openAccountLoan"
          render={(record) =>
            record.payLoanByMain
              ? record.mcOpenAccountLoan?.toLocaleString() +
                " " +
                translate("resources.root.usd")
              : record.scOpenAccountLoan?.toLocaleString() +
                " " +
                translate("resources.root.iqd")
          }
        />

        <FunctionField
          label="resources.root.attachment"
          render={(record) =>
            record.attachment !== null && (
              <div style={{ position: "relative" }}>
                <img
                  style={{
                    width: 200,
                    height: 200,
                    objectFit: "scale-down",
                    cursor: "pointer",
                  }}
                  src={`${path}${record.attachment}`}
                  alt="Attachment"
                  onClick={() =>
                    window.open(`${path}${record.attachment}`, "_blank")
                  }
                />
              </div>
            )
          }
        />
        <ArrayField source="storeSuppliers" label="resources.root.stores">
          <Datagrid>
            <TextField source="store.title" label="resources.root.store" />
            <FunctionField
              label="resources.root.currentLoan"
              render={(record) =>
                record.byMain
                  ? record.mcCurrentLoan?.toLocaleString() +
                    " " +
                    translate("resources.root.usd")
                  : record.scCurrentLoan?.toLocaleString() +
                    " " +
                    translate("resources.root.iqd")
              }
            />
            <FunctionField
              label="resources.root.openAccountLoan"
              render={(record) =>
                record.byMain
                  ? record.mcOpenAccountLoan?.toLocaleString() +
                    " " +
                    translate("resources.root.usd")
                  : record.scOpenAccountLoan?.toLocaleString() +
                    " " +
                    translate("resources.root.iqd")
              }
            />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "49%",
  },
  input: {
    width: "100%",
  },
  table: {
    minWidth: 800,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 800,
  },
});
