import styled from 'styled-components';

export const Heading = styled.h1`
text-align: center;
color: green;
`;

export const Content = styled.div`
overflowY: scroll;
height: 2500px;
`;

export const Button = styled.div`
position: fixed;
width: 100%;
left: 50%;
bottom: 100px;
height: 80px;
font-size: 3rem;
z-index: 1000;
color: #007bff !important;
`
