import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  ReferenceField,
  FormDataConsumer,
  DateField,
  required,
  FunctionField,
  NumberInput,
  minValue,
  DateInput,
  BooleanInput,
  AutocompleteInput,
  BooleanField,
  useTranslate,
} from "react-admin";
import { ListActions } from "../templates/ListActions";
import { url } from "../../request";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";

export const EmployeeRewardsList = (props) => {
  const translate = useTranslate();
  const [currency, setCurrency] = React.useState("default");

  return (
    <List
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions setCurrency={setCurrency} currency={currency} />}
    >
      <Datagrid bulkActionButtons={<BulkAction {...props} />}>
        <ReferenceField
          label={translate("resources.root.employee")}
          source="employeeId"
          reference="Employees"
        >
          <FunctionField
            label={translate("resources.root.name")}
            render={(record) => `${record.firstName} ${record.middleName}`}
          />
        </ReferenceField>
        {currency === "default" && (
          <FunctionField
            label="resources.root.reward"
            sortBy={"mcPrice"}
            render={(record) =>
              record.byMain
                ? `$${record.mcPrice}`
                : `${record.scPrice} ${translate("resources.root.iqd")}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label={translate("resources.root.reward")}
            sortBy={"mcPrice"}
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcPrice}`
                : `${record.scPrice} ${translate("resources.root.iqd")}`
            }
          />
        )}
        <TextField
          source="rewardedBy"
          label={translate("resources.root.rewardedBy")}
        />
        <BooleanField
          source="cashPaid"
          label={translate("resources.root.cashPaid")}
        />
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
      </Datagrid>
    </List>
  );
};

export const EmployeeRewardsCreate = (props) => {
  const translate = useTranslate();
  const [choices, setChoices] = React.useState([]);
  const [employeeId, setEmployeeId] = React.useState(0);
  const [selectedEmployee, setSelectedEmployee] = React.useState({});
  const [rewards, setRewards] = React.useState([]);
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const [cash, setCash] = React.useState(false);
  const [salaryByMain, setSalaryByMain] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const classes = useStyles();

  React.useEffect(() => {
    HTTP.get(`${url}/Employees?_end=1000`).then((res) => {
      setChoices(res.data);
    });
  }, []);

  React.useEffect(() => {
    if (employeeId === 0) return;
    HTTP.get(`${url}/Employees/${employeeId}`).then((res) => {
      setSelectedEmployee(res.data);
      console.log(res.data);
      setSalaryByMain(res.data.salaryByMain);
      setRewards(res.data.employeeRewards);
      setLoading(false);
    });
  }, [employeeId]);
  const optionRenderer = (choice) => `${choice.firstName} ${choice.middleName}`;
  return (
    <Create
      {...props}
      transform={(data) => ({
        ...data,
        accountant: localStorage.getItem("name"),
      })}
    >
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <AutocompleteInput
              source="employeeId"
              perPage={1000}
              label={translate("resources.root.chooseEmployee")}
              choices={choices}
              optionText={optionRenderer}
              optionValue="id"
              validate={[required()]}
              onChange={(val) => {
                setEmployeeId(val);
                setSeparatedNumber(0);
                setLoading(true);
              }}
            />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return formData.cashPaid ? (
                  formData.byMain ? (
                    <NumberInput
                      validate={[required(), minValue(0)]}
                      source="mcPrice"
                      defaultValue={0}
                      label={translate("resources.root.price")}
                      onChange={(e) =>
                        setSeparatedNumber(parseFloat(e.target.value) || 0)
                      }
                      min={0}
                    />
                  ) : (
                    <NumberInput
                      validate={[required(), minValue(0)]}
                      source="scPrice"
                      defaultValue={0}
                      onChange={(e) =>
                        setSeparatedNumber(parseFloat(e.target.value) || 0)
                      }
                      label={translate("resources.root.price")}
                      min={0}
                    />
                  )
                ) : // When the cash paid is false
                selectedEmployee.salaryByMain ? (
                  <NumberInput
                    validate={[required(), minValue(0)]}
                    source="mcPrice"
                    defaultValue={0}
                    label={translate("resources.root.priceInUSD")}
                    onChange={(e) =>
                      setSeparatedNumber(parseFloat(e.target.value) || 0)
                    }
                    min={0}
                  />
                ) : (
                  <NumberInput
                    validate={[required(), minValue(0)]}
                    source="scPrice"
                    defaultValue={0}
                    onChange={(e) =>
                      setSeparatedNumber(parseFloat(e.target.value) || 0)
                    }
                    label={translate("resources.root.priceInIQD")}
                    min={0}
                  />
                );
              }}
            </FormDataConsumer>
            <DateInput
              label={translate("resources.root.date")}
              source="date"
              defaultValue={moment().format("YYYY-MM-DD")}
            />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return salaryByMain ? (
                  <BooleanInput
                    source="byMain"
                    defaultValue={true}
                    label={translate("resources.root.dollar")}
                  />
                ) : (
                  <BooleanInput
                    source="byMain"
                    defaultValue={false}
                    label={translate("resources.root.dinar")}
                  />
                );
              }}
            </FormDataConsumer>
            <TextInput
              source="rewardedBy"
              multiline
              label={translate("resources.root.rewardedBy")}
            />
            <TextInput
              source="reason"
              multiline
              label={translate("resources.root.reason")}
            />
            <TextInput
              source="note"
              multiline
              label={translate("resources.root.note")}
            />
            {process.env.REACT_APP_APP_NAME !== "Mediply" && (
              <BooleanInput
                source="cashPaid"
                label={translate("resources.root.cash")}
                onChange={(e) => setCash(e.target.checked)}
              />
            )}
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                console.log();
                return (
                  formData.cashPaid && (
                    <BooleanInput
                      source="byMain"
                      // defaultValue={selectedChoices.cashPaid}
                      label={
                        formData.byMain
                          ? `${translate("resources.root.usd")}`
                          : ` ${translate("resources.root.iqd")}`
                      }
                    />
                  )
                );
              }}
            </FormDataConsumer>
          </div>
          <div className={classes.formCol}>
            {
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {translate("resources.root.currentRewards")}
                  </Typography>
                  <Typography
                    className={classes.marginBottom}
                    variant="h5"
                    component="h2"
                  >
                    {salaryByMain
                      ? `$${rewards
                          .filter((el) => !el.cashPaid)
                          .map((el) => el.mcPrice)
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}`
                      : `${rewards
                          .filter((el) => !el.cashPaid)
                          .map((el) => el.scPrice)
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`}
                  </Typography>
                  {!cash && (
                    <Typography
                      className={classes.title}
                      style={{
                        color: salaryByMain
                          ? selectedEmployee.mcCurrentLoan - separatedNumber <
                              0 && "red"
                          : selectedEmployee.scCurrentLoan - separatedNumber <
                              0 && "red",
                      }}
                      color="textSecondary"
                      gutterBottom
                    >
                      {translate("resources.root.addedReward")}
                    </Typography>
                  )}
                  {!cash && (
                    <Typography
                      className={classes.marginBottom}
                      style={{
                        color: selectedEmployee.salaryByMain
                          ? selectedEmployee.mcCurrentLoan - separatedNumber <
                              0 && "red"
                          : selectedEmployee.scCurrentLoan - separatedNumber <
                              0 && "red",
                      }}
                      variant="h5"
                      component="h2"
                    >
                      {selectedEmployee.salaryByMain
                        ? `$${(
                            rewards
                              .filter((el) => !el.cashPaid)
                              .map((el) => el.mcPrice)
                              .reduce((a, b) => a + b, 0) + separatedNumber
                          )?.toLocaleString()}`
                        : `${(
                            rewards
                              .filter((el) => !el.cashPaid)
                              .map((el) => el.scPrice)
                              .reduce((a, b) => a + b, 0) + separatedNumber
                          )?.toLocaleString()} IQD`}
                    </Typography>
                  )}
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {selectedEmployee.id && "Employee"}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {selectedEmployee.phone}
                    <br />
                    {selectedEmployee.email}
                    {/* <br />
                    {translate("resources.root.gender")} :{" "}
                    {selectedEmployee.Male === true
                      ? translate("resources.root.male")
                      : selectedEmployee.Male === false
                      ? translate("resources.root.female")
                      : ""}*/}
                    <br />
                    {translate("resources.root.address")} :{" "}
                    {selectedEmployee.address}
                    <br />
                    {translate("resources.root.salaryType")} :{" "}
                    {selectedEmployee.salaryType}
                  </Typography>
                </CardContent>
                {loading && <LinearProgress className={classes.progressBar} />}
              </Card>
            }
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "49%",
  },
  input: {
    width: "100%",
  },
  table: {
    minWidth: 500,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
  root: {
    minWidth: 275,
    marginTop: 6,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  marginBottom: {
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
    marginBottom: 0,
  },
  pos: {
    marginBottom: 12,
  },
  primary: {
    backgroundColor: "#1976d2",
    color: "#fff",
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  btnGroup: {
    marginRight: 10,
  },
});
