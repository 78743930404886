import React from "react";
import { url } from "../../request";
import { useRedirect, useTranslate } from "react-admin";
import { Button, Col, Form, Row } from "react-bootstrap";
import { SketchPicker } from "react-color";
import Select from "react-select";
import Barcode from "react-barcode";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import logos from "../../assets/logos";
import { HTTP } from "../../axios";

export const ProductLabel = () => {
  const redirect = useRedirect();
  const translate = useTranslate();

  const [products, setProducts] = React.useState([]);
  const [selectProducts, setSelectProducts] = React.useState([]);
  const [numberOfLabels, setNumberOfLabels] = React.useState(1);
  const [labelWidth, setLabelWidth] = React.useState(30);
  const [labelHeight, setLabelHeight] = React.useState(150);
  const [boldName, setBoldName] = React.useState(false);
  const [boldPrice, setBoldPrice] = React.useState(false);
  const [nameColor, setNameColor] = React.useState(
    localStorage.getItem("nameColor") ?? "#000000"
  );
  const [priceColor, setPriceColor] = React.useState(
    localStorage.getItem("priceColor") ?? "#000000"
  );
  const [barcodeColor, setBarcodeColor] = React.useState(
    localStorage.getItem("barcodeColor") ?? "#000000"
  );
  const [showSmallPrice, setShowSmallPrice] = React.useState(false);
  const [barcodeWidth, setBarcodeWidth] = React.useState(
    localStorage.getItem("barcodeWidth") ?? 1.5
  );
  const [barcodeHeight, setBarcodeHeight] = React.useState(
    localStorage.getItem("barcodeHeight") ?? 25
  );
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(100);
  const [logoUrl, setLogoUrl] = React.useState(
    localStorage.getItem("logoUrl") ?? logos.logo
  );
  const [nameSize, setNameSize] = React.useState(
    localStorage.getItem("nameSize") ?? 20
  );
  const [priceSize, setPriceSize] = React.useState(
    localStorage.getItem("priceSize") ?? 32
  );

  function PrintElem() {
    let mywindow = window.open("", "PRINT", "height=1000,width=1800");

    mywindow.document.write(
      '<html lang="ku"><head><title>' + document.title + "</title>"
    );
    mywindow.document.write("</head><body >");
    mywindow.document.write("<h1>" + document.title + "</h1>");
    mywindow.document.write(document.getElementById("printableDiv").innerHTML);
    mywindow.document.write("</body></html>");

    mywindow.document.close();
    mywindow.focus();

    mywindow.print();
    mywindow.close();

    return true;
  }

  React.useEffect(
    function () {
      HTTP.get(`${url}/Products?_start=${start}&_end=${end}`)
        .then((response) => {
          let newArray = [];
          response.data.map((product) => {
            newArray.push({
              value: product.id,
              label: `${product.name} (${product.barcode})`,
              product: product,
            });
          });
          setSelectProducts(newArray);
          setProducts(newArray);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            redirect("/Login");
          }
          console.log(error.response.status);
        });
    },
    [start, end]
  );
  return (
    <div style={{ textAlign: "start" }}>
      <Button variant={"primary"} onClick={PrintElem}>
        {translate("resources.root.print")}
      </Button>
      <Accordion className={`m-2`}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ fontWeight: "bold" }}>
            {translate("resources.root.labelPrinter")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Row>
            <Col xs={6}>
              <Form.Group as={Row} controlId="labelWidth">
                <Form.Label column sm={6}>
                  {translate("resources.root.labelWidth")}
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    placeholder={translate("resources.root.labelWidth")}
                    onChange={(e) => {
                      localStorage.setItem("labelWidth", e.target.value);
                      setLabelWidth(e.target.value);
                    }}
                    defaultValue={localStorage.getItem("labelWidth") ?? 30}
                    onFocus={(e) => e.target.select()}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group as={Row} controlId="labelHeight">
                <Form.Label column sm={6}>
                  {translate("resources.root.labelHeight")}
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    placeholder={translate("resources.root.labelHeight")}
                    onChange={(e) => {
                      localStorage.setItem("labelHeight", e.target.value);
                      setLabelHeight(e.target.value);
                    }}
                    defaultValue={localStorage.getItem("labelHeight") ?? 10}
                    onFocus={(e) => e.target.select()}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group as={Row} controlId="nameSize">
                <Form.Label column sm={6}>
                  {translate("resources.root.nameSize")}
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    placeholder={translate("resources.root.nameSize")}
                    onChange={(e) => {
                      localStorage.setItem("nameSize", e.target.value);
                      setNameSize(e.target.value);
                    }}
                    defaultValue={localStorage.getItem("nameSize") ?? 20}
                    onFocus={(e) => e.target.select()}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group as={Row} controlId="priceSize">
                <Form.Label column sm={6}>
                  {translate("resources.root.priceSize")}
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    placeholder={translate("resources.root.priceSize")}
                    onChange={(e) => {
                      localStorage.setItem("priceSize", e.target.value);
                      setPriceSize(e.target.value);
                    }}
                    defaultValue={localStorage.getItem("priceSize") ?? 32}
                    onFocus={(e) => e.target.select()}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group as={Row} controlId="barcodeWidth">
                <Form.Label column sm={6}>
                  {translate("resources.root.barcodeWidth")}
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    placeholder={translate("resources.root.barcodeWidth")}
                    onChange={(e) => {
                      localStorage.setItem("barcodeWidth", e.target.value);
                      setBarcodeWidth(e.target.value);
                    }}
                    defaultValue={localStorage.getItem("barcodeWidth") ?? 1.5}
                    onFocus={(e) => e.target.select()}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group as={Row} controlId="barcodeHeight">
                <Form.Label column sm={6}>
                  {translate("resources.root.barcodeHeight")}
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    placeholder={translate("resources.root.barcodeHeight")}
                    onChange={(e) => {
                      localStorage.setItem("barcodeHeight", e.target.value);
                      setBarcodeHeight(e.target.value);
                    }}
                    defaultValue={localStorage.getItem("barcodeHeight") ?? 25}
                    onFocus={(e) => e.target.select()}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group controlId="boldName">
                <Form.Check
                  id={"boldName"}
                  type={"switch"}
                  label={translate("resources.root.boldName")}
                  defaultChecked={boldName}
                  onChange={(e) => {
                    localStorage.setItem("boldName", e.target.checked);
                    setBoldName(e.target.checked);
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group controlId="boldPrice">
                <Form.Check
                  id={"boldPrice"}
                  type={"switch"}
                  label={translate("resources.root.boldPrice")}
                  defaultChecked={boldPrice}
                  onChange={(e) => {
                    localStorage.setItem("boldPrice", e.target.checked);
                    setBoldPrice(e.target.checked);
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group as={Row} controlId="nameColor">
                <Form.Label column sm={6}>
                  {translate("resources.root.nameColor")}
                </Form.Label>
                <SketchPicker
                  color={localStorage.getItem("nameColor") ?? nameColor}
                  onChange={(e) => {
                    setNameColor(e.hex);
                    localStorage.setItem("nameColor", e.hex);
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group as={Row} controlId="priceColor">
                <Form.Label column sm={6}>
                  {translate("resources.root.priceColor")}
                </Form.Label>
                <SketchPicker
                  color={localStorage.getItem("priceColor") ?? priceColor}
                  onChange={(e) => {
                    setPriceColor(e.hex);
                    localStorage.setItem("priceColor", e.hex);
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group as={Row} controlId="barcodeColor">
                <Form.Label column sm={6}>
                  {translate("resources.root.barcodeColor")}
                </Form.Label>
                <SketchPicker
                  color={localStorage.getItem("barcodeColor") ?? barcodeColor}
                  onChange={(e) => {
                    setBarcodeColor(e.hex);
                    localStorage.setItem("barcodeColor", e.hex);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </AccordionDetails>
      </Accordion>
      <div className={`m-2`}>
        <Row>
          <Col xs={6}>
            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                {translate("resources.root.start")}
              </Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  placeholder={translate("resources.root.start")}
                  onChange={(e) => {
                    setStart(e.target.value);
                  }}
                  defaultValue={start}
                  onFocus={(e) => e.target.select()}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                {translate("resources.root.end")}
              </Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  placeholder={translate("resources.root.end")}
                  onChange={(e) => {
                    setEnd(e.target.value);
                  }}
                  defaultValue={end}
                  onFocus={(e) => e.target.select()}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                {translate("resources.root.numberOfLabels")}
              </Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  placeholder={translate("resources.root.numberOfLabels")}
                  onChange={(e) => {
                    setNumberOfLabels(e.target.value);
                  }}
                  defaultValue={numberOfLabels}
                  onFocus={(e) => e.target.select()}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group as={Row}>
              <Form.Label column xs={2}>
                {translate("resources.root.products")}
              </Form.Label>
              <Col>
                <Select
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  closeMenuOnSelect={false}
                  isMulti
                  name="colors"
                  isClearable
                  isSearchable
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={selectProducts}
                  onChange={(e) => {
                    let results = [];
                    for (let j = 0; j < e.length; j++) {
                      for (let i = 1; i <= numberOfLabels; i++) {
                        results.push(e[j]);
                      }
                    }
                    setProducts(results);
                  }}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col xs={4}>
            <Form.Group controlId="showSmallPrice">
              <Form.Check
                id={"showSmallPrice"}
                type={"switch"}
                label={translate("resources.root.showSmallPrice")}
                defaultChecked={showSmallPrice}
                onChange={(e) => {
                  localStorage.setItem("showSmallPrice", e.target.checked);
                  setShowSmallPrice(e.target.checked);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
      <div id={`printableDiv`}>
        {products &&
          products.map((product, index) => {
            return (
              <div
                style={{
                  width: labelWidth + "%" ?? "30%",
                  height: labelHeight + "px" ?? "120px",
                  display: "inline-block",
                  border: "1px solid #000",
                  position: "relative",
                  padding: "5px",
                  textAlign: "center",
                  margin: "0.5%",
                }}
              >
                <p
                  style={{
                    overflow: "hidden",
                    color: nameColor,
                    fontSize: nameSize + "px" ?? "20px",
                    fontWeight: boldName ? "bold" : "normal",
                    position: "absolute",
                  }}
                >
                  {product.product.preferName.slice(0, 30)}
                </p>
                <p
                  style={{
                    overflow: "hidden",
                    color: priceColor,
                    fontSize: priceSize + "px" ?? "32px",
                    fontWeight: boldPrice ? "bold" : "normal",
                    position: "absolute",
                    bottom: "25%",
                    right: "25%",
                  }}
                >
                  {showSmallPrice
                    ? product.product.scSellPrice?.toLocaleString()
                    : product.product.scSmallMeasureSellPrice?.toLocaleString() +
                      ` ${translate("resources.root.iqd")}`}
                </p>
                <div
                  style={{
                    overflow: "hidden",
                    color: barcodeColor,
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                  }}
                >
                  <Barcode
                    background={`transparent`}
                    height={barcodeHeight}
                    width={barcodeWidth}
                    value={product.product ? product.product.barcode : "-"}
                  />
                </div>
                <img
                  alt=""
                  src={logoUrl}
                  style={{
                    width: "90px",
                    height: "90px",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
