export const toCsv = function (table, fileName) {
  const rows = table.querySelectorAll("tr");
  const text = [].slice
    .call(rows)
    .map(function (row) {
      // Query all cells
      const cells = row.querySelectorAll("th,td");
      return [].slice
        .call(cells)
        .map(function (cell) {
          return cell.textContent.replace(",", "").replace(",", "");
        })
        .join(",");
    })      
    .join("\n");
  const link = document.createElement("a");
  link.setAttribute(
    "href",
    `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`
  );
  link.setAttribute("download", fileName);
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
