import React from "react";
import PrintProvider, { Print } from "react-easy-print";
import Page from "./SellReceipt/Page";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { useTranslate } from "react-admin";

export const PrintQuickSellInvoice = (props) => {
  const invoice = JSON.parse(props.match.params.json);
  const type = props.match.params.type;
  const translate = useTranslate();
  const classes = useStyles();

  React.useEffect(function () {
    let sidebar = document.getElementsByClassName("MuiDrawer-root");
    let appMenu = document.getElementsByClassName("MuiPaper-root");
    let appBar = document.getElementsByClassName("theRoot");
    let layOut2 = document.getElementsByClassName("RaLayout-appFrame-2");
    sidebar[0].style.display = "none";
    appMenu[0].style.display = "none";
    appBar[0].style.marginTop = "0px";
    layOut2[0].style.marginTop = "0px";
    window.print();
  }, []);

  return (
    <PrintProvider>
      <Button
        variant="contained"
        className={classes.botton}
        onClick={() => {
          let sidebar = document.getElementsByClassName("MuiDrawer-root");
          let appMenu = document.getElementsByClassName("MuiPaper-root");
          let appBar = document.getElementsByClassName("theRoot");
          let layOut2 = document.getElementsByClassName("RaLayout-appFrame-2");
          sidebar[0].style.display = "none";
          appMenu[0].style.display = "none";
          appBar[0].style.marginTop = "0px";
          layOut2[0].style.marginTop = "0px";
          window.print();
        }}
      >
        {translate("resources.root.print")}
      </Button>
      <Print single={true} name="foo">
        <div className={classes.printWrapper}>
          <Page
            invoice={invoice}
            order={
              invoice && type === "Return"
                ? invoice.quickSellReturnOrders
                : invoice.quickSellOrders
            }
            title={translate("resources.root." + type + "")}
            human={invoice && invoice.name}
          />
        </div>
      </Print>
    </PrintProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  addressInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  discount: {
    width: "10% !important",
    marginLeft: 5,
    marginTop: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  tableContainer: {
    marginTop: 20,
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  printWrapper: {
    width: "100%",
    height: "950",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
}));
