import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  required,
  FormDataConsumer,
  DateInput,
  FunctionField,
  AutocompleteInput,
  Show,
  DateField,
  SimpleShowLayout,
  useDataProvider,
  NumberInput,
  number,
  maxValue,
  minValue,
  BooleanInput,
  useTranslate,
} from "react-admin";
import Button from "@mui/material/Button";
import { url } from "../../request";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { ListActions } from "../templates/ListActions";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import Page from "../CustomPages/VoucherReceipt/Page";
import { HTTP } from "../../axios";

export const RepresentativePaymentsList = (props) => {
  const [currency, setCurrency] = React.useState("default");

  return (
    <List
      filters={<SearchFilter date={true} />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions setCurrency={setCurrency} currency={currency} />}
    >
      <Datagrid bulkActionButtons={<BulkAction {...props} />} rowClick={"show"}>
        <FunctionField
          label="resources.root.name"
          render={(record) =>
            record.representative &&
            `${record.representative.firstName} ${record.representative.middleName}`
          }
        />
        {currency === "default" && (
          <FunctionField
            label="resources.root.price"
            render={(record) =>
              record.byMain
                ? `$${record.mcPrice?.toLocaleString()}`
                : `${record.scPrice?.toLocaleString()} IQD`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label="resources.root.price"
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcPrice?.toLocaleString()}`
                : `${record.scPrice?.toLocaleString()} IQD`
            }
          />
        )}
        <TextField source="accountant" label="resources.root.accountant" />
        <TextField source="payedBy" label="resources.root.payedBy" />
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
      </Datagrid>
    </List>
  );
};

export const RepresentativePaymentsShow = (props) => {
  const translate = useTranslate();
  const [payment, setPayment] = React.useState([]);
  React.useEffect(() => {
    HTTP.get(`${url}/RepresentativePayments/${props.id}`).then((res) => {
      setPayment(res.data);
      console.log(res.data);
    });
  }, [props]);
  return (
    <div>
      <Page
        data={{
          invoiceNo: payment.invoiceNumber && payment.invoiceNumber,
          serialNo: payment.id && payment.id,
          dollar: payment.byMain && payment.byMain,
          isDeposit: true,
          date: payment.date && payment.date,
          payedTo: payment.accountant && payment.accountant,
          amount:
            payment.byMain && payment.byMain
              ? payment.mcPrice && payment.mcPrice?.toLocaleString()
              : payment.scPrice && payment.scPrice?.toLocaleString(),
          currentLoan:
            payment.representative.loanByMain &&
            payment.representative.loanByMain
              ? payment.representative.mcCurrentLoan &&
                payment.representative.mcCurrentLoan?.toLocaleString()
              : payment.representative.scCurrentLoan &&
                payment.representative.scCurrentLoan?.toLocaleString(),
          type: "RepresentativePayment",
          note: payment.note && payment.note,
        }}
      />
      <Show>
        <SimpleShowLayout>
          <FunctionField
            label="resources.root.name"
            render={(record) =>
              record.representative &&
              `${record.representative.firstName} ${record.representative.middleName} ${record.representative.lastName}`
            }
          />
          <FunctionField
            label="resources.root.price"
            render={(record) =>
              record.byMain
                ? `$${record.mcPrice}`
                : `${record.scPrice} ${translate("resources.root.iqd")}`
            }
          />
          <FunctionField
            label="resources.root.price"
            render={(record) =>
              record.byMain
                ? `$${record.mcForgivePrice}`
                : `${record.scForgivePrice} ${translate("resources.root.iqd")}`
            }
          />
          <TextField source="accountant" label="resources.root.accountant" />
          <TextField source="payedBy" label="resources.root.payedBy" />
          <DateField
            source="date"
            label="resources.root.date"
            locales={"en-GB"}
          />
          <TextField source="note" label="resources.root.note" />
          <Button
            class={`btn btn-primary`}
            onClick={() => {
              let appLayout = document.getElementsByClassName("theRoot");
              let sidebar = document.getElementsByClassName("MuiDrawer-root");
              let appMenu = document.getElementsByClassName("MuiPaper-root");
              let appBar = document.getElementsByClassName("theRoot");
              let showPage = document.getElementsByClassName("show-page");
              let printInvoice = document.getElementById("hidePage");
              printInvoice.classList.remove("d-none");
              appLayout[0].style.marginTop = "0";
              sidebar[0].style.display = "none";
              appMenu[0].style.display = "none";
              appBar[0].style.marginTop = "0px";
              showPage[0].style.display = "none";
              window.print();
              printInvoice.classList.add("d-none");
              appLayout[0].style.marginTop = "10px";
              sidebar[0].style.display = "block";
              appMenu[0].style.display = "block";
              appBar[0].style.marginTop = "40px";
              showPage[0].style.display = "block";
            }}
          >
            {translate("resources.root.print")}
          </Button>
        </SimpleShowLayout>
      </Show>
    </div>
  );
};

export const RepresentativePaymentsCreate = (props) => {
  const [currency, setCurrency] = React.useState(true);
  const [representativeId, setRepresentativeId] = React.useState(0);
  const [mcCurrentLoan, setMcCurrentLoan] = React.useState(0);
  const [scCurrentLoan, setScCurrentLoan] = React.useState(0);
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const [forgivePrice, setForgivePrice] = React.useState(0);
  const [representative, setRepresentative] = React.useState([]);
  const [choices, setChoices] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const dataProvider = useDataProvider();
  const classes = useStyles();
  const translate = useTranslate();

  React.useEffect(() => {
    const res = dataProvider
      .getList("Representatives", {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: "id", order: "ASC" },
        filter: {},
      })
      .then((response) => setChoices(response.data));
  }, []);

  React.useEffect(() => {
    HTTP.get(`${url}/Representatives/${representativeId}`).then((res) => {
      setMcCurrentLoan(res.data.mcCurrentLoan);
      setScCurrentLoan(res.data.scCurrentLoan);
      setCurrency(res.data.payLoanByMain);
      setRepresentative(res.data);
      setLoading(false);
    });
  }, [representativeId]);

  const optionRenderer = (choice) => `${choice.firstName} ${choice.middleName}`;

  return (
    <Create
      {...props}
      transform={(data) => ({
        ...data,
        accountant: localStorage.getItem("name"),
      })}
      redirect="show"
    >
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <AutocompleteInput
              source="representativeId"
              perPage={1000}
              label="resources.root.Representative"
              choices={choices}
              optionText={optionRenderer}
              optionValue="id"
              validate={[required()]}
              onChange={(e) => {
                setRepresentativeId(e);
                setSeparatedNumber(0);
                setLoading(true);
              }}
              className={classes.input}
            />
            <NumberInput
              source="refNo"
              label="resources.root.refNo"
              className={classes.input}
            />
            <DateInput
              source="date"
              label="resources.root.date"
              className={classes.input}
              defaultValue={new Date()}
              validate={[required()]}
            />
            <TextInput
              source="note"
              label="resources.root.note"
              className={classes.input}
              options={{ multiLine: true }}
            />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  representative.id &&
                  (representative.payLoanByMain ? (
                    <BooleanInput
                      source="byMain"
                      style={{ display: "none" }}
                      defaultValue={true}
                      label="resources.root.usd"
                    />
                  ) : (
                    <BooleanInput
                      source="byMain"
                      style={{ display: "none" }}
                      defaultValue={false}
                      label="resources.root.iqd"
                    />
                  ))
                );
              }}
            </FormDataConsumer>
          </div>
          <div className={classes.formCol}>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return currency ? (
                  <NumberInput
                    validate={[
                      required(),
                      number(),
                      maxValue(mcCurrentLoan - forgivePrice),
                      minValue(0.01),
                    ]}
                    min={0}
                    max={mcCurrentLoan - forgivePrice}
                    source="mcPrice"
                    label="resources.root.price"
                    onChange={(e) =>
                      setSeparatedNumber(parseFloat(e.target.value) || 0)
                    }
                    helperText={
                      separatedNumber > 0
                        ? `$${separatedNumber?.toLocaleString()}`
                        : ""
                    }
                    className={classes.input}
                  />
                ) : (
                  <NumberInput
                    validate={[
                      required(),
                      number(),
                      maxValue(scCurrentLoan - forgivePrice),
                      minValue(0.01),
                    ]}
                    min={0}
                    max={scCurrentLoan - forgivePrice}
                    source="scPrice"
                    label="resources.root.price"
                    onChange={(e) =>
                      setSeparatedNumber(parseFloat(e.target.value) || 0)
                    }
                    helperText={
                      separatedNumber > 0
                        ? `${separatedNumber?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`
                        : ""
                    }
                    className={classes.input}
                  />
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return currency ? (
                  <NumberInput
                    validate={[
                      required(),
                      number(),
                      maxValue(mcCurrentLoan - separatedNumber),
                      minValue(0),
                    ]}
                    min={0}
                    max={mcCurrentLoan - separatedNumber}
                    source="mcForgivePrice"
                    label="resources.root.forgivePrice"
                    defaultValue={0}
                    onChange={(e) =>
                      setForgivePrice(parseFloat(e.target.value) || 0)
                    }
                    helperText={
                      forgivePrice > 0
                        ? `$${forgivePrice?.toLocaleString()}`
                        : ""
                    }
                    className={classes.input}
                  />
                ) : (
                  <NumberInput
                    validate={[
                      required(),
                      number(),
                      maxValue(scCurrentLoan - separatedNumber),
                      minValue(0),
                    ]}
                    min={0}
                    max={scCurrentLoan - separatedNumber}
                    source="scForgivePrice"
                    label="resources.root.forgivePrice"
                    defaultValue={0}
                    onChange={(e) =>
                      setForgivePrice(parseFloat(e.target.value) || 0)
                    }
                    helperText={
                      forgivePrice > 0
                        ? `${forgivePrice?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`
                        : ""
                    }
                    className={classes.input}
                  />
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  representative.id && (
                    <TextInput
                      source="payedBy"
                      label="resources.root.payedBy"
                      className={classes.input}
                      defaultValue={`${representative.firstName} ${representative.middleName}`}
                      validate={[required()]}
                    />
                  )
                );
              }}
            </FormDataConsumer>
            {representative.id && (
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {translate("resources.root.currentLoan")}
                  </Typography>
                  <Typography
                    className={classes.marginBottom}
                    variant="h5"
                    component="h2"
                  >
                    {currency
                      ? `$${mcCurrentLoan?.toLocaleString()}`
                      : `${scCurrentLoan?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`}
                  </Typography>
                  <Typography
                    className={classes.title}
                    style={{
                      color: currency
                        ? mcCurrentLoan - (separatedNumber + forgivePrice) <
                            0 && "red"
                        : scCurrentLoan - (separatedNumber + forgivePrice) <
                            0 && "red",
                    }}
                    color="textSecondary"
                    gutterBottom
                  >
                    {translate("resources.root.remainder")}
                  </Typography>
                  <Typography
                    className={classes.marginBottom}
                    style={{
                      color: currency
                        ? mcCurrentLoan - (separatedNumber + forgivePrice) <
                            0 && "red"
                        : scCurrentLoan - (separatedNumber + forgivePrice) <
                            0 && "red",
                    }}
                    variant="h5"
                    component="h2"
                  >
                    {currency
                      ? `$${(
                          mcCurrentLoan -
                          (separatedNumber + forgivePrice)
                        )?.toLocaleString()}`
                      : `${(
                          scCurrentLoan -
                          (separatedNumber + forgivePrice)
                        )?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`}
                  </Typography>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {representative.id && translate("resources.root.owner")}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {`${representative.firstName} ${representative.middleName}`}
                    <br />
                    {representative.phone}
                    <br />
                    {representative.email}
                    <br />
                    {representative.address}
                  </Typography>
                </CardContent>
                {loading && <LinearProgress className={classes.progressBar} />}
              </Card>
            )}
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "49%",
  },
  input: {
    width: "100%",
  },
  table: {
    minWidth: 500,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
  root: {
    minWidth: 275,
    marginTop: 6,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  marginBottom: {
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
    marginBottom: 0,
  },
  pos: {
    marginBottom: 12,
  },
});
