import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  ReferenceField,
  FormDataConsumer,
  DateField,
  required,
  FunctionField,
  NumberInput,
  minValue,
  DateInput,
  BooleanInput,
  AutocompleteInput,
  useTranslate,
} from "react-admin";
import { ListActions } from "../templates/ListActions";
import { url } from "../../request";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";

export const EmployeePunishmentsList = (props) => {
  const translate = useTranslate();
  const [currency, setCurrency] = React.useState("default");

  return (
    <List
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions setCurrency={setCurrency} currency={currency} />}
    >
      <Datagrid bulkActionButtons={<BulkAction {...props} />}>
        <ReferenceField
          label={translate("resources.root.employee")}
          source="employeeId"
          reference="Employees"
        >
          <FunctionField
            label={translate("resources.root.name")}
            render={(record) => `${record.firstName} ${record.middleName}`}
          />
        </ReferenceField>
        {currency === "default" && (
          <FunctionField
            sortBy={"mcPrice"}
            label={translate("resources.root.punishment")}
            render={(record) =>
              record.byMain
                ? `$${record.mcPrice?.toLocaleString()}`
                : `${record.scPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            sortBy={"scPrice"}
            label={translate("resources.root.punishment")}
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcPrice?.toLocaleString()}`
                : `${record.scPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        <TextField
          source="punishedBy"
          label={translate("resources.root.punishedBy")}
        />
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
      </Datagrid>
    </List>
  );
};

export const EmployeePunishmentsCreate = (props) => {
  const [choices, setChoices] = React.useState([]);
  const [employeeId, setEmployeeId] = React.useState(0);
  const [selectedEmployee, setSelectedEmployee] = React.useState({});
  const [punishments, setPunishments] = React.useState([]);
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const [cash, setCash] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const classes = useStyles();

  React.useEffect(() => {
    HTTP.get(`${url}/Employees?_end=1000`).then((res) => {
      setChoices(res.data);
    });
  }, []);

  React.useEffect(() => {
    if (employeeId === 0) return;
    HTTP.get(`${url}/Employees/${employeeId}`).then((res) => {
      setSelectedEmployee(res.data);
      setPunishments(res.data.employeePunishments);
      setLoading(false);
    });
  }, [employeeId]);

  const optionRenderer = (choice) => `${choice.firstName} ${choice.middleName}`;
  const translate = useTranslate();
  return (
    <Create
      {...props}
      transform={(data) => ({
        ...data,
        accountant: localStorage.getItem("name"),
      })}
    >
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <AutocompleteInput
              source="employeeId"
              choices={choices}
              optionText={optionRenderer}
              optionValue="id"
              validate={[required()]}
              onChange={(val) => {
                setEmployeeId(val);
                setSeparatedNumber(0);
                setLoading(true);
              }}
              label={translate("resources.root.chooseEmployee")}
            />
            {selectedEmployee && selectedEmployee.salaryByMain ? (
              <NumberInput
                validate={[required(), minValue(0)]}
                source="mcPrice"
                defaultValue={0}
                label={translate("resources.root.price")}
                onChange={(e) =>
                  setSeparatedNumber(parseFloat(e.target.value) || 0)
                }
                min={0}
              />
            ) : (
              <NumberInput
                validate={[required(), minValue(0)]}
                source="scPrice"
                defaultValue={0}
                onChange={(e) =>
                  setSeparatedNumber(parseFloat(e.target.value) || 0)
                }
                label={translate("resources.root.price")}
                min={0}
              />
            )}
            <DateInput
              source="date"
              defaultValue={moment().format("YYYY-MM-DD")}
              label={translate("resources.root.date")}
            />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  selectedEmployee.id &&
                  (selectedEmployee.salaryByMain ? (
                    <BooleanInput
                      source="byMain"
                      defaultValue={true}
                      label="resources.root.dollar"
                    />
                  ) : (
                    <BooleanInput
                      source="byMain"
                      defaultValue={false}
                      label="resources.root.dinar"
                    />
                  ))
                );
              }}
            </FormDataConsumer>
            <TextInput
              source="punishedBy"
              multiline
              label={translate("resources.root.punishedBy")}
            />
            <TextInput
              source="reason"
              multiline
              label={translate("resources.root.reason")}
            />
            <TextInput
              source="note"
              multiline
              label={translate("resources.root.note")}
            />
          </div>
          <div className={classes.formCol}>
            {selectedEmployee && (
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {translate("resources.root.currentPunishments")}
                  </Typography>
                  <Typography
                    className={classes.marginBottom}
                    variant="h5"
                    component="h2"
                  >
                    {selectedEmployee.salaryByMain
                      ? `$${punishments
                          .map((el) => el.mcPrice)
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}`
                      : `${punishments
                          .map((el) => el.scPrice)
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`}
                  </Typography>
                  {!cash && (
                    <Typography
                      className={classes.title}
                      style={{
                        color: selectedEmployee.salaryByMain
                          ? selectedEmployee.mcCurrentLoan - separatedNumber <
                              0 && "red"
                          : selectedEmployee.scCurrentLoan - separatedNumber <
                              0 && "red",
                      }}
                      color="textSecondary"
                      gutterBottom
                    >
                      {translate("resources.root.addedPunishment")}
                    </Typography>
                  )}
                  {!cash && (
                    <Typography
                      className={classes.marginBottom}
                      style={{
                        color: selectedEmployee.salaryByMain
                          ? selectedEmployee.mcCurrentLoan - separatedNumber <
                              0 && "red"
                          : selectedEmployee.scCurrentLoan - separatedNumber <
                              0 && "red",
                      }}
                      variant="h5"
                      component="h2"
                    >
                      {selectedEmployee.salaryByMain
                        ? `$${(
                            punishments
                              .map((el) => el.mcPrice)
                              .reduce((a, b) => a + b, 0) + separatedNumber
                          )?.toLocaleString()}`
                        : `${(
                            punishments
                              .map((el) => el.scPrice)
                              .reduce((a, b) => a + b, 0) + separatedNumber
                          )?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`}
                    </Typography>
                  )}
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {selectedEmployee.id &&
                      `${translate("resources.root.employee")}`}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {selectedEmployee.phone}
                    <br />
                    {selectedEmployee.email}
                    <br />
                    {translate("resources.root.address")} :{" "}
                    {selectedEmployee.address}
                    <br />
                    {translate("resources.root.salaryType")} :{" "}
                    {selectedEmployee.salaryType}
                  </Typography>
                </CardContent>
                {loading && <LinearProgress className={classes.progressBar} />}
              </Card>
            )}
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "49%",
  },
  input: {
    width: "100%",
  },
  table: {
    minWidth: 500,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
  root: {
    minWidth: 275,
    marginTop: 6,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  marginBottom: {
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
    marginBottom: 0,
  },
  pos: {
    marginBottom: 12,
  },
  primary: {
    backgroundColor: "#1976d2",
    color: "#fff",
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  btnGroup: {
    marginRight: 10,
  },
});
