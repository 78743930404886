import * as React from "react";
import {List, Datagrid, TextField, DateField} from "react-admin";
import {MyCustomPagination, SearchFilter} from "../../App";

export const GetLogs = (props) => {
    return (
        <List
            {...props}
            pagination={<MyCustomPagination />}
            filters={<SearchFilter date={true} />}
            sort={{ field: "id", order: "DESC" }}
        >
            <Datagrid>
                <TextField source="id" label="resources.root.id" />
                <TextField source="fullName" label="resources.root.fullName" />
                <TextField source="userName" label="resources.root.userName" />
                <TextField source="action" label="resources.root.action" />
                <TextField source="controller" label="resources.root.controller" />
                <TextField source="ip" label="resources.root.ip" />
                <DateField source="date" label="resources.root.date" locales={'en-GB'} showTime />
                <DateField source="createdAt" label="resources.root.createdAt" locales={'en-GB'} showTime />
            </Datagrid>
        </List>
    );
};