import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  required,
  DateField,
  Show,
  ReferenceField,
  DateInput,
  NumberInput,
  FormDataConsumer,
  BooleanInput,
  ImageInput,
  ImageField,
  useDataProvider,
  SimpleShowLayout,
  FunctionField,
  AutocompleteInput,
  useTranslate,
  useResourceDefinition,
} from "react-admin";
import { path } from "../../request";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { url } from "../../request";
import Button from "@mui/material/Button";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import Page from "../CustomPages/VoucherReceipt/Page";
import { HTTP } from "../../axios";
import moment from "moment";

export const BankAccountTransfersList = (props) => {
  const translate = useTranslate();
  return (
    <List
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={<BulkAction {...props} />} rowClick="show">
        <FunctionField
          label={`resources.root.attachment`}
          render={(record) => (
            <img
              style={{
                width: 75,
                height: 75,
                objectFit: "scale-down",
                cursor: "pointer",
              }}
              src={
                record.attachment !== null
                  ? `${path}${record.attachment}`
                  : `https://via.placeholder.com/75`
              }
              alt={record.name}
              onClick={() =>
                window.open(
                  record.attachment !== null
                    ? `${path}${record.attachment}`
                    : `https://via.placeholder.com/75`,
                  "_blank"
                )
              }
            />
          )}
        />
        <ReferenceField
          label="resources.root.fromBankAccount"
          source="fromBankAccountId"
          reference="BankAccounts"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="resources.root.toBankAccount"
          source="toBankAccountId"
          reference="BankAccounts"
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label="resources.root.price"
          sortBy={"mcAmount"}
          render={(record) =>
            record.mcAmount > 0
              ? "$" + record.mcAmount?.toLocaleString()
              : record.scAmount?.toLocaleString() +
                ` ${translate("resources.root.iqd")}`
          }
        />
        <TextField source="note" label="resources.root.note" />
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
      </Datagrid>
    </List>
  );
};

export const BankAccountTransfersShow = (props) => {
  const translate = useTranslate();
  const [transfer, setTransfer] = React.useState({});
  React.useEffect(() => {
    HTTP.get(`${url}/BankAccountTransfers/${props.id}`).then((response) => {
      setTransfer(response.data);
    });
  }, [props]);
  return (
    <div>
      <Page
        data={{
          invoiceNo: transfer.invoiceNumber && transfer.invoiceNumber,
          serialNo: transfer.id && transfer.id,
          dollar: transfer.byMain && transfer.byMain,
          isDeposit: false,
          date: transfer.date && transfer.date,
          payedTo: transfer.fromBankAccount && transfer.fromBankAccount.name,
          amount:
            transfer.byMain && transfer.byMain
              ? transfer.mcAmount && transfer.mcAmount?.toLocaleString()
              : transfer.scAmount && transfer.scAmount?.toLocaleString(),
          type: "BankTransfer",
          note: transfer.note && transfer.note,
        }}
      />
      <Show>
        <SimpleShowLayout>
          <ReferenceField
            label="resources.root.fromBankAccount"
            source="fromBankAccountId"
            reference="BankAccounts"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="resources.root.toBankAccount"
            source="toBankAccountId"
            reference="BankAccounts"
          >
            <TextField source="name" />
          </ReferenceField>
          <FunctionField
            label="resources.root.price"
            render={(record) =>
              record.mcAmount > 0
                ? record.mcAmount?.toLocaleString()
                : record.scAmount?.toLocaleString()
            }
          />
          <TextField source="note" label="resources.root.note" />
          <DateField
            source="date"
            label="resources.root.date"
            locales={"en-GB"}
          />
          <FunctionField
            label="resources.root.attachment"
            render={(record) => (
              <img
                style={{
                  width: 200,
                  height: 200,
                  objectFit: "scale-down",
                  cursor: "pointer",
                }}
                src={`${path}${record.attachment}`}
                alt="Attachment"
                onClick={() =>
                  window.open(`${path}${record.attachment}`, "_blank")
                }
              />
            )}
          />
          <Button
            class={`btn btn-primary`}
            onClick={() => {
              let appLayout = document.getElementsByClassName("theRoot");
              let sidebar = document.getElementsByClassName("MuiDrawer-root");
              let appMenu = document.getElementsByClassName("MuiPaper-root");
              let appBar = document.getElementsByClassName("theRoot");
              let showPage = document.getElementsByClassName("show-page");
              let printInvoice = document.getElementById("hidePage");
              printInvoice.classList.remove("d-none");
              appLayout[0].style.marginTop = "0";
              sidebar[0].style.display = "none";
              appMenu[0].style.display = "none";
              appBar[0].style.marginTop = "0px";
              showPage[0].style.display = "none";
              window.print();
              printInvoice.classList.add("d-none");
              appLayout[0].style.marginTop = "10px";
              sidebar[0].style.display = "block";
              appMenu[0].style.display = "block";
              appBar[0].style.marginTop = "40px";
              showPage[0].style.display = "block";
            }}
          >
            {translate("resources.root.print")}
          </Button>
        </SimpleShowLayout>
      </Show>
    </div>
  );
};

export const BankAccountTransfersCreate = () => {
  const props = useResourceDefinition();
  const translate = useTranslate();
  const [currency, setCurrency] = React.useState(true);
  const [choices, setChoices] = React.useState([]);
  const [selectedChoices, setSelectedChoices] = React.useState([]);
  const [permissions, setPermissions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const dataProvider = useDataProvider();
  const classes = useStyles();

  React.useEffect(() => {
    const res = dataProvider
      .getList("BankAccounts", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "id", order: "DESC" },
        filter: {},
      })
      .then((response) => setChoices(response.data));
  }, []);

  React.useEffect(() => {
    HTTP.get(`${url}/BankAccounts/${selectedChoices.id}`).then((res) => {
      setPermissions(res.data);
      setLoading(false);
    });
  }, [selectedChoices]);

  const optionRenderer = (choice) => `${choice.name}`;
  return (
    <Create {...props} redirect="list">
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <AutocompleteInput
              source="fromBankAccountId"
              perPage={500}
              label="resources.root.fromBankAccount"
              choices={choices}
              optionText={optionRenderer}
              optionValue="id"
              onSelect={(val) => {
                setSelectedChoices(val);
                setLoading(true);
              }}
              validate={[required()]}
            />
            <AutocompleteInput
              source="toBankAccountId"
              perPage={500}
              label="resources.root.toBankAccount"
              choices={choices}
              optionText={optionRenderer}
              optionValue="id"
              validate={[required()]}
            />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                setCurrency(formData.byMain);
                return formData.byMain ? (
                  <NumberInput
                    source="mcAmount"
                    label="resources.root.dollar"
                    validate={[required()]}
                    defaultValue={0}
                  />
                ) : (
                  <NumberInput
                    source="scAmount"
                    label="resources.root.dinar"
                    validate={[required()]}
                    defaultValue={0}
                  />
                );
              }}
            </FormDataConsumer>
            <BooleanInput
              source="byMain"
              defaultValue={true}
              label={
                currency ? `resources.root.dollar` : `resources.root.dinar`
              }
            />
            <TextInput source="note" label="resources.root.note" multiline />
            <DateInput
              source="date"
              defaultValue={moment().format("YYYY-MM-DD")}
              label="resources.root.date"
            />
            <ImageInput
              source="attachment"
              label="resources.root.attachment"
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </div>
          <div className={classes.formCol}>
            {selectedChoices.id && (
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {translate("resources.root.currentCash")}
                  </Typography>
                  <Typography
                    className={classes.marginBottom}
                    variant="h5"
                    component="h2"
                  >
                    {`$${
                      permissions.mcCurrentBalance &&
                      permissions.mcCurrentBalance?.toLocaleString()
                    }, ${
                      permissions.scCurrentBalance &&
                      permissions.scCurrentBalance?.toLocaleString()
                    } ${translate("resources.root.iqd")}`}
                  </Typography>
                </CardContent>
                {loading && <LinearProgress className={classes.progressBar} />}
              </Card>
            )}
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "49%",
  },
  input: {
    width: "100%",
  },
  table: {
    minWidth: 500,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
  root: {
    minWidth: 275,
    marginTop: 6,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  marginBottom: {
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
    marginBottom: 0,
  },
  pos: {
    marginBottom: 12,
  },
  primary: {
    backgroundColor: "#1976d2",
    color: "#fff",
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  btnGroup: {
    marginRight: 10,
  },
});
