import React, { useEffect, useState } from "react";
import { url } from "../request";
import { useNotify, useTranslate } from "react-admin";
import { Col, Form, Row, Button, Card } from "react-bootstrap";
import Select from "react-select";
import { HTTP } from "../axios";

const Settings = () => {
  const [dollarPrice, setDollarPrice] = useState(
    localStorage.getItem("dollarPrice") || 1200
  );
  const [deactivated, setDeactivated] = useState(false);
  const [ignoreProductPriceType, setIgnoreProductPriceType] = useState(
    localStorage.getItem("ignoreProductPriceType") || false
  );
  const [useCustomPhoneNumbers, setUseCustomPhoneNumbers] = useState(
    localStorage.getItem("useCustomPhoneNumbers") || false
  );

  const [updateSellingPrice, setUpdateSellingPrice] = useState(
    localStorage.getItem("updateSellingPrice") || false
  );

  const [updatePurchasePrice, setUpdatePurchasePrice] = useState(
    localStorage.getItem("updatePurchasePrice") || false
  );

  const [defaultProducts, setDefaultProducts] = useState(
    localStorage.getItem("defaultProducts") || JSON.stringify([])
  );

  const translate = useTranslate();
  const notify = useNotify();

  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [commands, setCommands] = useState([]);
  const [stores, setStores] = useState([]);

  const [showCurrentLoan, setShowCurrentLoan] = useState(
    localStorage.getItem("showCurrentLoan") || false
  );
  const [showLastSellPrice, setShowLastSellPrice] = useState(
    localStorage.getItem("showLastSellPrice") || false
  );
  const [productPurchaseByUSD, setProductPurchaseByUSD] = useState(
    localStorage.getItem("productPurchaseByUSD") === "true" || false
  );
  const [productSellByUSD, setProductPurchaseByIQD] = useState(
    localStorage.getItem("productSellByUSD") === "true" || false
  );
  const [showSmallMeasure, setShowSmallMeasure] = useState(
    localStorage.getItem("showSmallMeasure") === "true" || false
  );
  const [showDiscountInvoice, setShowDiscountInvoice] = useState(
    localStorage.getItem("showDiscountInvoice") === "true" || false
  );
  const [colorizeTable, setColorizeTable] = useState(
    localStorage.getItem("colorizeTable") === "true" || false
  );
  const [sellMainCurrency, setSellMainCurrency] = useState(
    localStorage.getItem("sellMainCurrency") === "true" || false
  );
  const [purchaseMainCurrency, setPurchaseMainCurrency] = useState(
    localStorage.getItem("purchaseMainCurrency") === "true" || false
  );

  const [ignoreZeroDashboard, setIgnoreZeroDashboard] = useState(
    localStorage.getItem("ignoreZeroDashboard") === "true" || false
  );
  const [
    ignoreBelowZeroProductsDashboard,
    setIgnoreBelowZeroProductsDashboard,
  ] = useState(
    localStorage.getItem("ignoreBelowZeroProductsDashboard") === "true" || false
  );

  const [useOldSelect, setUseOldSelect] = useState(
    localStorage.getItem("useOldSelect") === "true" || false
  );
  const [showCreatedAt, setShowCreatedAt] = useState(
    localStorage.getItem("showCreatedAt") === "true" || false
  );
  const [showPurchasePriceInList, setShowPurchasePriceInList] = useState(
    localStorage.getItem("showPurchasePriceInList") === "true" || true
  );

  function handleQuickCommands(e, command) {
    let oldInLocal = JSON.parse(localStorage.getItem("quickCommands") || "[]");
    let elements = Object.values(oldInLocal) || [];
    if (e.target.checked) {
      elements.push(command);
    } else {
      let index = elements.findIndex((x) => x.route === command.route);
      elements.splice(index, 1);
    }
    localStorage.setItem("quickCommands", JSON.stringify(elements));
  }

  useEffect(() => {
    let commands = [
      {
        icon: "fa-plus",
        route: "Products",
        label: "products",
        group: "product",
        bgColor: "#0B5ED7",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Products/create",
        label: "createProducts",
        group: "product",
        bgColor: "#0B5ED7",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Damages/create",
        label: "createDamages",
        group: "product",
        bgColor: "#6C757D",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "StoreTransferInvoices/create",
        label: "createTransfers",
        group: "product",
        bgColor: "#0B5ED7",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "ProcessInvoices/create",
        label: "createProcess",
        group: "product",
        bgColor: "#0B5ED7",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Customers",
        label: "customers",
        group: "customer",
        bgColor: "#0B5ED7",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Customers/create",
        label: "createCustomer",
        group: "customer",
        bgColor: "#0B5ED7",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "CustomerPayments/create",
        label: "createCustomerPayment",
        group: "customer",
        bgColor: "#157347",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "SellInvoices",
        label: "sellInvoices",
        group: "sellInvoice",
        bgColor: "#157347",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "SellInvoices/create",
        label: "createSellInvoices",
        group: "sellInvoice",
        bgColor: "#157347",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "SellReturnInvoices/create",
        label: "createSellReturnInvoice",
        group: "sellInvoice",
        bgColor: "#6C757D",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Suppliers",
        label: "suppliers",
        group: "supplier",
        bgColor: "#0B5ED7",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Suppliers/create",
        label: "createSupplier",
        group: "supplier",
        bgColor: "#0B5ED7",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "SupplierPayments/create",
        label: "createSupplierPayment",
        group: "supplier",
        bgColor: "#6C757D",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "PurchaseInvoices",
        label: "purchaseInvoices",
        group: "purchaseInvoices",
        bgColor: "#6C757D",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "PurchaseInvoices/create",
        label: "createPurchaseInvoices",
        group: "purchaseInvoices",
        bgColor: "#6C757D",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "PurchaseReturnInvoices/create",
        label: "createPurchaseReturnInvoice",
        group: "purchaseInvoices",
        bgColor: "#157347",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Withdraws/create",
        label: "createWithdraws",
        group: "accountant",
        bgColor: "#6C757D",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Deposits/create",
        label: "createDeposits",
        group: "accountant",
        bgColor: "#157347",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Expenses",
        label: "expenses",
        group: "accountant",
        bgColor: "#6C757D",
        color: "#FFFFFF",
      },
      {
        icon: "fa-plus",
        route: "Expenses/create",
        label: "createExpenses",
        group: "accountant",
        bgColor: "#6C757D",
        color: "#FFFFFF",
      },
    ];
    let oldInLocal = JSON.parse(localStorage.getItem("quickCommands") || "[]");
    let elements = Object.values(oldInLocal) || [];
    commands.map((command) => {
      let index = elements.findIndex((x) => x.route === command.route);
      command.checked = index > -1;
    });
    setCommands(commands);

    HTTP.get(`${url}/Stores?_end=1000`)
      .then((response) => {
        setStores(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    HTTP.get(`${url}/Customers?_end=1000`)
      .then((response) => {
        let newArray = [];
        response.data.map((customer) => {
          newArray.push({
            value: customer.id,
            label: customer.firstName + " " + customer.middleName,
            customer: customer,
          });
        });
        setCustomers(newArray);
      })
      .catch((error) => {
        console.log(error);
      });
    HTTP.get(`${url}/Products?_end=5000`)
      .then((response) => {
        let newArray = [];
        response.data.map((product) => {
          newArray.push({
            value: product.id,
            label: product.name,
            product: product,
          });
        });
        setProducts(newArray);
      })
      .catch((error) => {
        console.log(error);
      });
    localStorage.setItem("ignoreProductPriceType", ignoreProductPriceType);
    localStorage.setItem("useCustomPhoneNumbers", useCustomPhoneNumbers);
    localStorage.setItem("defaultProducts", defaultProducts);

    HTTP.get(`${url}/ProductCategories?_end=10000`)
      .then((response) => {
        let newArray = [];
        response.data.map((category) => {
          newArray.push({
            value: category.id,
            label: category.name,
            category: category,
          });
        });
        setCategories(newArray);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [ignoreProductPriceType, useCustomPhoneNumbers]);
  return (
    <div style={{ textAlign: "start" }}>
      <Card border="dark">
        <Card.Header>{translate("resources.root.general")}</Card.Header>
        <Card.Body>
          <Row>
            <Col style={{ border: "1px solid", borderRadius: "10px" }} xs={4}>
              <Form.Group as={Row} className={`mt-3`}>
                <Form.Label column xs={3} htmlFor={`dollarPrice`}>
                  {translate("resources.root.dollarPrice")}
                </Form.Label>
                <Col xs={6}>
                  <Form.Control
                    id={`dollarPrice`}
                    label="Price"
                    value={dollarPrice}
                    onChange={(e) => {
                      setDollarPrice(e.target.value);
                      setDeactivated(false);
                    }}
                  />
                </Col>
                <Col xs={3}>
                  <Button
                    block
                    variant="primary"
                    onClick={() => {
                      setDeactivated(true);
                      HTTP.put(`${url}/DbPrefenses/1`, {
                        id: 1,
                        mcLabel: localStorage.getItem("mcLabel", "Dollar"),
                        scLabel: localStorage.getItem("scLabel", "Dinar"),
                        mcSymbol: localStorage.getItem("mcSymbol", "USD"),
                        scSymbol: localStorage.getItem("scSymbol", " IQD"),
                        mcToScPrice: dollarPrice,
                        storeId: localStorage.getItem("storeId") ?? 1,
                      })
                        .then(() => {
                          notify("resources.root.updated", "success");
                        })
                        .catch((err) =>
                          notify(err.response.data.message, {
                            type: "error",
                            undoable: true,
                          })
                        );
                      localStorage.setItem("dollarPrice", dollarPrice);
                    }}
                  >
                    {translate("ra.action.save")}
                  </Button>
                </Col>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={4}>
                  {translate("resources.root.vouchersFontSize")}
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      localStorage.setItem(
                        "depositVoucherFont",
                        e.target.value
                      );
                    }}
                    defaultValue={
                      localStorage.getItem("depositVoucherFont") ?? 14
                    }
                    onFocus={(e) => e.target.select()}
                  />
                </Col>
              </Form.Group>
              <Button
                className={`m-2`}
                onClick={() => window.open("#/SellReceipt", "_self")}
              >
                {translate("resources.root.invoiceMockup")}
              </Button>
              <Button
                className={`m-2`}
                onClick={() => window.open("#/VoucherReceipt", "_self")}
              >
                {translate("resources.root.voucherMockup")}
              </Button>
              <Button
                className={`m-2`}
                onClick={() => window.open("#/ProductLabel", "_self")}
              >
                {translate("resources.root.labelPrinter")}
              </Button>
              <Button
                className={`m-2`}
                onClick={() => window.open("#/ProductOffers", "_self")}
              >
                {translate("resources.root.offerPrint")}
              </Button>
              <Button
                className={`m-2`}
                onClick={() => window.open("#/ProductStick", "_self")}
              >
                {translate("resources.root.printStick")}
              </Button>
            </Col>
            <Col className={`mt-3`} xs={4}>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={4}>
                  {translate("resources.root.systemFontSize")}
                </Form.Label>
                <Col>
                  <Form.Control
                    as={"select"}
                    onChange={(e) => {
                      localStorage.setItem("systemFontSize", e.target.value);
                      window.location.reload();
                    }}
                    defaultValue={localStorage.getItem("systemFontSize") ?? 14}
                  >
                    <option value={`15`}>15</option>
                    <option value={`16`}>16</option>
                    <option value={`17`}>17</option>
                    <option value={`18`}>18</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
            <Col className={`mt-3`} xs={4}>
              <Form.Group as={Row} controlId="formHorizontalStoreId">
                <Form.Label column sm={4}>
                  {translate("resources.root.store")}
                </Form.Label>
                <Col>
                  <Form.Control
                    as={"select"}
                    onChange={(e) => {
                      localStorage.setItem("storeId", e.target.value);
                    }}
                    defaultValue={localStorage.getItem("storeId") ?? 1}
                  >
                    <option value={0}>
                      {translate("resources.root.allStores")}
                    </option>
                    {stores.map((store) => {
                      return (
                        <option
                          selected={
                            parseInt(localStorage.getItem("storeId")) ===
                            store.id
                          }
                          value={store.id}
                        >
                          {store.title}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card border="dark">
        <Card.Header>{translate("resources.root.sells")}</Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12}>
              <Form.Group as={Row}>
                <Form.Label column xs={2}>
                  {translate("resources.root.defaultProductsInPos")}
                </Form.Label>
                <Col>
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    closeMenuOnSelect={false}
                    isMulti
                    name="colors"
                    isClearable
                    isSearchable
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={products}
                    defaultValue={JSON.parse(
                      localStorage.getItem("defaultProducts")
                    )}
                    onChange={(e) => {
                      localStorage.setItem(
                        "defaultProducts",
                        JSON.stringify(e)
                      );
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <Form.Group as={Row}>
                <Form.Label column xs={5} htmlFor={`selectDefaultCustomer`}>
                  {translate("resources.root.selectDefaultCustomer")}
                  <span style={{ color: "#7ec97b" }}>
                    {" "}
                    (
                    {customers.find(
                      (customer) =>
                        customer.value ===
                        parseInt(localStorage.getItem("defaultCustomer"))
                    ) &&
                      customers.find(
                        (customer) =>
                          customer.value ===
                          parseInt(localStorage.getItem("defaultCustomer"))
                      ).label}
                    )
                  </span>
                </Form.Label>
                <Col>
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    id={`selectDefaultCustomer`}
                    isClearable
                    isSearchable
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={customers}
                    defaultValue={customers.find(
                      (customer) =>
                        customer.value ===
                        parseInt(localStorage.getItem("defaultCustomer"))
                    )}
                    onChange={(e) => {
                      localStorage.setItem("defaultCustomer", e.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group as={Row}>
                <Form.Label column xs={5} htmlFor={`selectDefaultCategory`}>
                  {translate("resources.root.selectDefaultCategory")}
                  <span style={{ color: "#7ec97b" }}>
                    {" "}
                    (
                    {categories.find(
                      (category) =>
                        category.value ===
                        parseInt(localStorage.getItem("defaultCategory"))
                    ) &&
                      categories.find(
                        (category) =>
                          category.value ===
                          parseInt(localStorage.getItem("defaultCategory"))
                      ).label}
                    )
                  </span>
                </Form.Label>
                <Col>
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    id={`selectDefaultCategory`}
                    isClearable
                    isSearchable
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={categories}
                    defaultValue={categories.find(
                      (category) =>
                        category.value ===
                        parseInt(localStorage.getItem("defaultCategory"))
                    )}
                    onChange={(e) => {
                      localStorage.setItem("defaultCategory", e.value);
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <div className="form-check">
                <input
                  id={"updateSellingPriceOnSale"}
                  type="checkbox"
                  onChange={(e) =>
                    localStorage.setItem("updateSellingPrice", e.target.checked)
                  }
                  defaultChecked={
                    localStorage.getItem("updateSellingPrice") ?? false
                  }
                />
                <label
                  className="form-check-label mb-3"
                  htmlFor="updateSellingPriceOnSale"
                >
                  {translate("resources.root.updateSellingPriceOnSale")}
                </label>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <div className="form-check">
                <input
                  id={"showCurrentLoan"}
                  type="checkbox"
                  onChange={(e) =>
                    localStorage.setItem("showCurrentLoan", e.target.checked)
                  }
                  defaultChecked={showCurrentLoan}
                />
                <label
                  className="form-check-label mb-3"
                  htmlFor="showCurrentLoan"
                >
                  {translate("resources.root.showCurrentLoan")}
                </label>
              </div>
            </Col>
            <Col xs={4}>
              <div className="form-check">
                <input
                  id={"getLastPriceForSelling"}
                  type="checkbox"
                  onChange={(e) =>
                    localStorage.setItem("showLastSellPrice", e.target.checked)
                  }
                  defaultChecked={showLastSellPrice}
                />
                <label
                  className="form-check-label mb-3"
                  htmlFor="getLastPriceForSelling"
                >
                  {translate("resources.root.getLastPriceForSelling")}
                </label>
              </div>
            </Col>
            <Col xs={4}>
              <div className="form-check">
                <input
                  id={"productPurchaseByUSD"}
                  type="checkbox"
                  onChange={(e) =>
                    localStorage.setItem(
                      "productPurchaseByUSD",
                      e.target.checked
                    )
                  }
                  defaultChecked={productPurchaseByUSD}
                />
                <label
                  className="form-check-label mb-3"
                  htmlFor="productPurchaseByUSD"
                >
                  {translate("resources.root.purchaseByUsd")}
                </label>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <div className="form-check">
                <input
                  id={"sellByUsd"}
                  type="checkbox"
                  onChange={(e) =>
                    localStorage.setItem("productSellByUSD", e.target.checked)
                  }
                  defaultChecked={productSellByUSD}
                />
                <label className="form-check-label mb-3" htmlFor="sellByUsd">
                  {translate("resources.root.sellByUsd")}
                </label>
              </div>
            </Col>
            <Col xs={4}>
              <div className="form-check">
                <input
                  id={"showSmallMeasure"}
                  type="checkbox"
                  onChange={(e) =>
                    localStorage.setItem("showSmallMeasure", e.target.checked)
                  }
                  defaultChecked={showSmallMeasure}
                />
                <label
                  className="form-check-label mb-3"
                  htmlFor="showSmallMeasure"
                >
                  {translate("resources.root.showSmallMeasure")}
                </label>
              </div>
            </Col>
            <Col xs={4}>
              <div className="form-check">
                <input
                  id={"showDiscountInvoice"}
                  type="checkbox"
                  onChange={(e) =>
                    localStorage.setItem(
                      "showDiscountInvoice",
                      e.target.checked
                    )
                  }
                  defaultChecked={showDiscountInvoice}
                />
                <label
                  className="form-check-label mb-3"
                  htmlFor="showDiscountInvoice"
                >
                  {translate("resources.root.showDiscountInvoice")}
                </label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <div className="form-check">
                <input
                  id={"colorizeTable"}
                  type="checkbox"
                  onChange={(e) =>
                    localStorage.setItem("colorizeTable", e.target.checked)
                  }
                  defaultChecked={colorizeTable}
                />
                <label
                  className="form-check-label mb-3"
                  htmlFor="colorizeTable"
                >
                  {translate("resources.root.colorizeTable")}
                </label>
              </div>
            </Col>
            <Col xs={4}>
              <div className="form-check">
                <input
                  id={"sellMainCurrency"}
                  type="checkbox"
                  onChange={(e) =>
                    localStorage.setItem("sellMainCurrency", e.target.checked)
                  }
                  defaultChecked={sellMainCurrency}
                />
                <label
                  className="form-check-label mb-3"
                  htmlFor="sellMainCurrency"
                >
                  {translate("resources.root.sellMainCurrency")}
                </label>
              </div>
            </Col>
            <Col xs={4}>
              <div className="form-check">
                <input
                  id={"purchaseMainCurrency"}
                  type="checkbox"
                  onChange={(e) =>
                    localStorage.setItem(
                      "purchaseMainCurrency",
                      e.target.checked
                    )
                  }
                  defaultChecked={purchaseMainCurrency}
                />
                <label
                  className="form-check-label mb-3"
                  htmlFor="purchaseMainCurrency"
                >
                  {translate("resources.root.purchaseMainCurrency")}
                </label>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={4}>
              <div className="form-check">
                <input
                  id={"updatePurchasePriceOnPurchase"}
                  type="checkbox"
                  onChange={(e) =>
                    localStorage.setItem(
                      "updatePurchasePrice",
                      e.target.checked
                    )
                  }
                  defaultChecked={
                    localStorage.getItem("updatePurchasePrice") ?? false
                  }
                />
                <label
                  className="form-check-label mb-3"
                  htmlFor="updatePurchasePriceOnPurchase"
                >
                  {translate("resources.root.updatePurchasePriceOnPurchase")}
                </label>
              </div>
            </Col>
            <Col xs={4}>
              <div className="form-check">
                <input
                  id={"useOldSelect"}
                  type="checkbox"
                  onChange={(e) => {
                    localStorage.setItem("useOldSelect", e.target.checked);
                    setUseOldSelect(e.target.checked);
                  }}
                  defaultChecked={useOldSelect}
                />
                <label className="form-check-label mb-3" htmlFor="useOldSelect">
                  {translate("resources.root.useOldSelect")}
                </label>
              </div>
            </Col>
            <Col xs={4}>
              <div className="form-check">
                <input
                  id={"showCreatedAt"}
                  type="checkbox"
                  onChange={(e) => {
                    localStorage.setItem("showCreatedAt", e.target.checked);
                    setShowCreatedAt(e.target.checked);
                  }}
                  defaultChecked={showCreatedAt}
                />
                <label
                  className="form-check-label mb-3"
                  htmlFor="showCreatedAt"
                >
                  {translate("resources.root.showCreatedAt")}
                </label>
              </div>
            </Col>

            <Col xs={4}>
              <div className="form-check">
                <input
                  id={"showPurchasePriceInList"}
                  type="checkbox"
                  onChange={(e) => {
                    localStorage.setItem(
                      "showPurchasePriceInList",
                      e.target.checked
                    );
                    setShowPurchasePriceInList(e.target.checked);
                  }}
                  defaultChecked={showPurchasePriceInList}
                />
                <label
                  className="form-check-label mb-3"
                  htmlFor="showPurchasePriceInList"
                >
                  {translate("resources.root.purchasePrice")}
                </label>
              </div>
            </Col>
          </Row>
          <br />
        </Card.Body>
      </Card>
      <Card border="dark">
        <Card.Header>{translate("resources.root.product")}</Card.Header>
        <Card.Body>
          <Row>
            <Col xs={3}>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={6}>
                  {translate("resources.root.productMeasure")}
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Product Measure"
                    onChange={(e) => {
                      localStorage.setItem("productMeasure", e.target.value);
                    }}
                    defaultValue={
                      localStorage.getItem("productMeasure") ?? "Kg"
                    }
                    onFocus={(e) => e.target.select()}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={6}>
                  {translate("resources.root.productOneContains")}
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder={translate("resources.root.productOneContains")}
                    onChange={(e) => {
                      localStorage.setItem(
                        "productOneContains",
                        e.target.value
                      );
                    }}
                    defaultValue={
                      localStorage.getItem("productOneContains") ?? 1
                    }
                    onFocus={(e) => e.target.select()}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={6}>
                  {translate("resources.root.productOneMeasure")}
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder={translate("resources.root.productOneMeasure")}
                    onChange={(e) => {
                      localStorage.setItem("productOneMeasure", e.target.value);
                    }}
                    defaultValue={
                      localStorage.getItem("productOneMeasure") ?? "Kg"
                    }
                    onFocus={(e) => e.target.select()}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={6}>
                  {translate("resources.root.productWeightInKg")}
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder={translate("resources.root.productWeightInKg")}
                    onChange={(e) => {
                      localStorage.setItem("productWeightInKg", e.target.value);
                    }}
                    defaultValue={
                      localStorage.getItem("productWeightInKg") ?? 1
                    }
                    onFocus={(e) => e.target.select()}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card border="dark">
        <Card.Header>{translate("resources.root.dashboard")}</Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12}>
              <h4>{translate("resources.root.quickCommands")}</h4>
            </Col>
            {commands.map((command) => {
              return (
                <Col xs={3}>
                  <div className="form-check">
                    <input
                      id={command.label}
                      type="checkbox"
                      onChange={(e) => handleQuickCommands(e, command)}
                      defaultChecked={command.checked}
                    />
                    <label
                      className="form-check-label mb-3"
                      htmlFor="showCurrentLoan"
                    >
                      {translate("resources.root." + command.label)}
                    </label>
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col xs={4}>
              <div className="form-check">
                <input
                  id={"ignoreZeroDashboard"}
                  type="checkbox"
                  onChange={(e) =>
                    localStorage.setItem(
                      "ignoreZeroDashboard",
                      e.target.checked
                    )
                  }
                  defaultChecked={
                    localStorage.getItem("ignoreZeroDashboard") ?? false
                  }
                />
                <label
                  className="form-check-label mb-3"
                  htmlFor="ignoreZeroDashboard"
                >
                  {translate("resources.root.ignoreZeroDashboard")}
                </label>
              </div>
            </Col>
            <Col xs={4}>
              <div className="form-check">
                <input
                  id={"ignoreBelowZeroProductsDashboard"}
                  type="checkbox"
                  onChange={(e) =>
                    localStorage.setItem(
                      "ignoreBelowZeroProductsDashboard",
                      e.target.checked
                    )
                  }
                  defaultChecked={
                    localStorage.getItem("ignoreBelowZeroProductsDashboard") ??
                    false
                  }
                />
                <label
                  className="form-check-label mb-3"
                  htmlFor="ignoreBelowZeroProductsDashboard"
                >
                  {translate("resources.root.ignoreBelowZeroProductsDashboard")}
                </label>
              </div>
            </Col>
            <Col xs={4}>
              <Form.Group as={Row} controlId="expireDaysDashboard">
                <Form.Label column sm={6}>
                  {translate("resources.root.expireDaysDashboard")}
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder={translate(
                      "resources.root.expireDaysDashboard"
                    )}
                    onChange={(e) => {
                      localStorage.setItem(
                        "expireDaysDashboard",
                        e.target.value
                      );
                    }}
                    defaultValue={
                      localStorage.getItem("expireDaysDashboard") ?? 30
                    }
                    onFocus={(e) => e.target.select()}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Settings;
