import * as React from "react";
import { url } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import AsyncSelect from "react-select/async";
import { PrintProvider, Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import { HTTP } from "../../axios";

export default () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10000);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [product, setProduct] = React.useState(0);
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [openAccountQty, setOpenAccountQty] = React.useState(0);
  const [previousBalance, setPreviousBalance] = React.useState(0);
  const [initialBalance, setInitialBalance] = React.useState(0);
  const [currentBalance, setCurrentBalance] = React.useState(0);
  const [currentSmallBalance, setCurrentSmallBalance] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showInUSD, setShowInUSD] = React.useState(false);
  const [headerData, setHeaderData] = React.useState({
    mcTotalDamage: 0,
    mcTotalPurchase: 0,
    mcTotalPurchaseReturn: 0,
    mcTotalQuickSell: 0,
    mcTotalQuickSellReturn: 0,
    mcTotalSell: 0,
    mcTotalSellReturn: 0,
    scTotalDamage: 0,
    scTotalPurchase: 0,
    scTotalPurchaseReturn: 0,
    scTotalQuickSell: 0,
    scTotalQuickSellReturn: 0,
    scTotalSell: 0,
    scTotalSellReturn: 0,

    totalDamageQty: "",
    totalPurchaseQty: "",
    totalPurchaseReturnQty: "",
    totalQuickSellQty: "",
    totalQuickSellReturnQty: "",
    totalSellQty: "",
    totalSellReturnQty: "",
  });

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;
    if (startDate && startDate.length > 0)
      sendDates += `&startDate=${startDate}`;
    if (endDate && endDate.length > 0) sendDates += `&endDate=${endDate}`;

    await HTTP.get(
      `${url}/Reports/ProductAccountStatement/${product}?_start=${start}&_end=${end}${sendDates}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        setCurrentBalance(response.headers.currentbalance ?? 0);
        setCurrentSmallBalance(
          Math.abs(response.headers.currentsmallbalance ?? 0)
        );
        setOpenAccountQty(response.headers.openaccountqty ?? 0);
        setPreviousBalance(response.headers.previousbalance ?? 0);
        setInitialBalance(response.headers.initialbalance ?? 0);
        if (response.data && response.data.length > 0) {
          let firstQty =
            parseFloat(response.headers.initialbalance ?? 0) +
            parseFloat(response.headers.previousbalance ?? 0);
          let newQty = response.data[0].isWithdraw
            ? parseFloat(firstQty - parseFloat(response.data[0].qte))
            : parseFloat(firstQty + parseFloat(response.data[0].qte));
          let newSmallQty = response.data[0].isWithdraw
            ? parseFloat(0 - parseFloat(response.data[0].smallMeasureQte))
            : parseFloat(parseFloat(response.data[0].smallMeasureQte));
          response.data[0].balance = newQty;
          response.data[0].smallBalance = newSmallQty;
          for (let i = 1; i < response.data.length; i++) {
            let temp = response.data[i].isWithdraw
              ? newQty - parseFloat(response.data[i].qte)
              : newQty + parseFloat(response.data[i].qte);
            let temp2 = response.data[i].isWithdraw
              ? newSmallQty - parseFloat(response.data[i].smallMeasureQte)
              : newSmallQty + parseFloat(response.data[i].smallMeasureQte);
            response.data[i].balance = parseFloat(temp);
            response.data[i].smallBalance = parseFloat(temp2);
            newQty = parseFloat(temp);
            newSmallQty = parseFloat(temp2);
          }
        }
        setHeaderData({
          mcTotalDamage: parseFloat(response.headers["mctotaldamage"]),
          mcTotalPurchase: parseFloat(response.headers["mctotalpurchase"]),
          mcTotalPurchaseReturn: parseFloat(
            response.headers["mctotalpurchasereturn"]
          ),
          mcTotalQuickSell: parseFloat(response.headers["mctotalquicksell"]),
          mcTotalQuickSellReturn: parseFloat(
            response.headers["mctotalquicksellreturn"]
          ),
          mcTotalSell: parseFloat(response.headers["mctotalsell"]),
          mcTotalSellReturn: parseFloat(response.headers["mctotalsellreturn"]),
          scTotalDamage: parseFloat(response.headers["sctotaldamage"]),
          scTotalPurchase: parseFloat(response.headers["sctotalpurchase"]),
          scTotalPurchaseReturn: parseFloat(
            response.headers["sctotalpurchasereturn"]
          ),
          scTotalQuickSell: parseFloat(response.headers["sctotalquicksell"]),
          scTotalQuickSellReturn: parseFloat(
            response.headers["sctotalquicksellreturn"]
          ),
          scTotalSell: parseFloat(response.headers["sctotalsell"]),
          scTotalSellReturn: parseFloat(response.headers["sctotalsellreturn"]),
          mcTotalExtraInventory: parseFloat(
            response.headers["mctotalextrainventory"]
          ),
          scTotalExtraInventory: parseFloat(
            response.headers["sctotalextrainventory"]
          ),

          totalDamageQty: response.headers["totaldamageqty"],
          totalPurchaseQty: response.headers["totalpurchaseqty"],
          totalPurchaseReturnQty: response.headers["totalpurchasereturnqty"],
          totalQuickSellQty: response.headers["totalquicksellqty"],
          totalQuickSellReturnQty: response.headers["totalquicksellreturnqty"],
          totalSellQty: response.headers["totalsellqty"],
          totalSellReturnQty: response.headers["totalsellreturnqty"],
        });
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [product, startDate, endDate, start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/Products?_end=100&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((product) => {
        newArray.push({
          value: product.id,
          label: product.name,
          product: product,
        });
      });
      callback(newArray);
    }
  };

  function getUrl(type, id) {
    if (type === "Sell Invoice") {
      return `#/SellInvoices/${id}/show`;
    } else if (type === "Cashier Sell") {
      return `#/Cashier/SellInfo/${id}/show`;
    } else if (type === "Return Invoice") {
      return `#/SellReturnInvoices/${id}/show`;
    } else if (type === "Cashier Return Invoice") {
      return `#/Cashier/SellInfo/${id}/show/return`;
    } else if (type === "Damage Invoice") {
      return `#/DamageInvoices/${id}/show`;
    } else if (type === "Purchase Invoice") {
      return `#/PurchaseInvoices/${id}/show`;
    } else if (type === "Purchase Return Invoice") {
      return `#/PurchaseReturnInvoices/${id}/show`;
    } else if (type === "Representative Sell") {
      return `#/RepresentativeInvoices/${id}/show`;
    } else if (type === "Representative Return") {
      return `#/RepresentativeReturnInvoices/${id}/show`;
    } else if (type === "Representative Customer Sell") {
      return `#/RepresentativeCustomerSellInvoices/${id}/show`;
    } else if (type === "Process Invoice" || type === "Produce Invoice") {
      return `#/ProcessInvoices/${id}/show`;
    } else {
      return "#/";
    }
  }

  return (
    <div>
      <PrintProvider>
        <Print single name={`expireReport`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.productStatementReport")}
            </h1>
            <div className={`d-inline-block`} style={{ float: "inline-end" }}>
              <NoPrint>
                <Button
                  variant={"outline-primary"}
                  onClick={(e) => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}
                </Button>
                <div className={`clearfix`}> </div>
              </NoPrint>
            </div>
          </div>
          <Row>
            {isLoading && <LoadingScreen />}
            <Col xs={1}>
              <label>{translate("resources.root.product")}</label>
            </Col>
            <Col xs={2}>
              <AsyncSelect
                defaultOptions
                loadOptions={loadOptions}
                onChange={(e) => setProduct(e ? e.value : 0)}
              />
            </Col>

            <Col xs={3}>
              <Form.Group as={Row} controlId="startDate">
                <Form.Label column sm={4}>
                  {translate("resources.root.startDate")}
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group as={Row} controlId="endDate">
                <Form.Label column sm={4}>
                  {translate("resources.root.endDate")}
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs={3}>
              <div className="form-check">
                <input
                  id={"showInUSD"}
                  type="checkbox"
                  onChange={(e) => setShowInUSD(e.target.checked)}
                  defaultChecked={showInUSD}
                />
                <label className="form-check-label mb-3" htmlFor="showInUSD">
                  {translate("resources.root.showInUSD")}
                </label>
              </div>
            </Col>
            <Col>
              <Accordion style={{ textAlign: "start" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{translate("resources.root.product")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.mcTotalDamage")}: $${
                        headerData.mcTotalDamage && headerData.mcTotalDamage
                      }`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.mcTotalPurchase")}: $${
                        headerData.mcTotalPurchase && headerData.mcTotalPurchase
                      }`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate(
                        "resources.root.mcTotalPurchaseReturn"
                      )}: $${
                        headerData.mcTotalPurchaseReturn &&
                        headerData.mcTotalPurchaseReturn
                      }`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.mcTotalQuickSell")}: $${
                        headerData.mcTotalQuickSell &&
                        headerData.mcTotalQuickSell
                      }`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate(
                        "resources.root.mcTotalQuickSellReturn"
                      )}: $${
                        headerData.mcTotalQuickSellReturn &&
                        headerData.mcTotalQuickSellReturn
                      }`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.mcTotalSell")}: $${
                        headerData.mcTotalSell && headerData.mcTotalSell
                      }`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.mcTotalSellReturn")}: $${
                        headerData.mcTotalSellReturn &&
                        headerData.mcTotalSellReturn
                      }`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.mcTotalExtraInventory")}: ${
                        headerData.mcTotalExtraInventory &&
                        headerData.mcTotalExtraInventory?.toLocaleString()
                      }`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.scTotalDamage")}: ${
                        headerData.scTotalDamage &&
                        headerData.scTotalDamage?.toLocaleString()
                      } ${translate("resources.root.iqd")}`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.scTotalPurchase")}: ${
                        headerData.scTotalPurchase &&
                        headerData.scTotalPurchase?.toLocaleString()
                      } ${translate("resources.root.iqd")}`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.scTotalPurchaseReturn")}: ${
                        headerData.scTotalPurchaseReturn &&
                        headerData.scTotalPurchaseReturn?.toLocaleString()
                      } ${translate("resources.root.iqd")}`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.scTotalQuickSell")}: ${
                        headerData.scTotalQuickSell &&
                        headerData.scTotalQuickSell?.toLocaleString()
                      } ${translate("resources.root.iqd")}`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate(
                        "resources.root.scTotalQuickSellReturn"
                      )}: ${
                        headerData.scTotalQuickSellReturn &&
                        headerData.scTotalQuickSellReturn?.toLocaleString()
                      } ${translate("resources.root.iqd")}`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.scTotalSell")}: ${
                        headerData.scTotalSell &&
                        headerData.scTotalSell?.toLocaleString()
                      } ${translate("resources.root.iqd")}`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.scTotalSellReturn")}: ${
                        headerData.scTotalSellReturn &&
                        headerData.scTotalSellReturn?.toLocaleString()
                      } ${translate("resources.root.iqd")}`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.mcTotalExtraInventory")}: ${
                        headerData.mcTotalExtraInventory &&
                        headerData.mcTotalExtraInventory?.toLocaleString()
                      }`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.scTotalExtraInventory")}: ${
                        headerData.scTotalExtraInventory &&
                        headerData.scTotalExtraInventory?.toLocaleString()
                      }`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.damageQty")}: ${
                        headerData.totalDamageQty && headerData.totalDamageQty
                      }`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.purchaseQty")}: ${
                        headerData.totalPurchaseQty &&
                        headerData.totalPurchaseQty
                      }`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.purchaseReturnQty")}: ${
                        headerData.totalPurchaseReturnQty &&
                        headerData.totalPurchaseReturnQty
                      }`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.quickSellQty")}: ${
                        headerData.totalQuickSellQty &&
                        headerData.totalQuickSellQty
                      }`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.quickSellReturnQty")}: ${
                        headerData.totalQuickSellReturnQty &&
                        headerData.totalQuickSellReturnQty
                      }`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.totalSellQty")}: ${
                        headerData.totalSellQty && headerData.totalSellQty
                      }`}
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      {`${translate("resources.root.totalSellReturnQty")}: ${
                        headerData.totalSellReturnQty &&
                        headerData.totalSellReturnQty
                      }`}
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>
            </Col>
            <Col>
              <h5 className={`text-center`}>
                <span>
                  {translate("resources.root.currentQuantity")}:{" "}
                  <b>
                    {currentBalance} + {currentSmallBalance}
                  </b>
                </span>{" "}
                -{" "}
                {startDate && startDate.length > 0 ? (
                  <span>
                    {translate("resources.root.previousQuantity")}:{" "}
                    <b>{previousBalance}</b>
                  </span>
                ) : (
                  <span>
                    {translate("resources.root.startQuantity")}:{" "}
                    <b>{initialBalance}</b>
                  </span>
                )}
              </h5>
            </Col>
            <Col xs={4}>
              <Button
                id="export"
                onClick={() => {
                  toCsv(
                    document.getElementById("exportableTable"),
                    "download.csv"
                  );
                }}
              >
                {translate("resources.root.exportCsv")}
              </Button>
            </Col>
          </Row>
          <Row className={`mt-2`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.invoiceNumber")}</th>
                    <th>{translate("resources.root.date")}</th>
                    <th>{translate("resources.root.description")}</th>
                    <th>{translate("resources.root.price")}</th>
                    <th>{translate("resources.root.smallPrice")}</th>
                    <th>{translate("resources.root.income")}</th>
                    <th>{translate("resources.root.outcome")}</th>
                    <th>{translate("resources.root.balance")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr
                          style={{
                            backgroundColor: one.isWithdraw
                              ? "#FCE8E9"
                              : "#BFEAA3",
                          }}
                        >
                          <td>{one?.id}</td>
                          <td>
                            <a
                              href={getUrl(one.description, one.id)}
                              target={"_blank"}
                            >
                              {one.invoiceNumber &&
                                one.invoiceNumber.toUpperCase()}
                            </a>
                          </td>
                          <td>
                            {moment(one.date).format("YYYY-MM-DD hh:mm:ss A")}
                          </td>
                          <td>{one.description}</td>
                          <td>
                            {showInUSD
                              ? one.mcPrice?.toLocaleString()
                              : one.scPrice?.toLocaleString()}
                          </td>
                          <td>
                            {showInUSD
                              ? one.mcSmallPrice?.toLocaleString()
                              : one.scSmallPrice?.toLocaleString()}
                          </td>
                          <td>
                            {one.isWithdraw
                              ? 0
                              : one.qte + " + " + one.smallMeasureQte}
                          </td>
                          <td>
                            {one.isWithdraw
                              ? one.qte + " + " + one.smallMeasureQte
                              : 0}
                          </td>
                          <td>{one.balance.toFixed(3)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                      <option value={1000}>1000</option>
                      <option value={2000}>2000</option>
                      <option value={10000}>10000</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
