import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
  Show,
  TabbedShowLayout,
  Tab,
  Create,
  useTranslate,
  DateField,
  FormTab,
  TabbedForm,
  useResourceDefinition,
} from "react-admin";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { url } from "../../request";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ListActions } from "../templates/ListActions";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { StoreInventoryInvoiceAdd } from "./StoreInventoryInvoiceAdd";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import Page from "../CustomPages/SellReceipt/Page";
import { HTTP } from "../../axios";
import { StoreInventoryInvoiceUpdate } from "./StoreInventoryInvoiceUpdate";
import { StoreInventoryInvoiceAddRealStock } from "./StoreInventoryInvoiceAddRealStock";
import { useParams } from "react-router-dom";

export const StoreInventoryInvoiceList = (props) => {
  const [currency, setCurrency] = React.useState("default");

  const translate = useTranslate();

  return (
    <div>
      <PrintProvider>
        <Print force name={"noName"}>
          <List
            filters={<SearchFilter driver={true} date={true} />}
            pagination={<MyCustomPagination />}
            {...props}
            sort={{ field: "id", order: "DESC" }}
            actions={
              <ListActions setCurrency={setCurrency} currency={currency} />
            }
          >
            <Datagrid
              bulkActionButtons={
                <BulkAction
                  {...props}
                  hasPrintOrders={"StoreInventoryInvoice"}
                />
              }
              rowClick={"show"}
            >
              <TextField source="id" label={translate("resources.root.id")} />
              <TextField
                source="invoiceNumber"
                label={translate("resources.root.invoiceNumber")}
              />
              <TextField
                source="inventoryType"
                label={translate("resources.root.inventoryType")}
              />
              <DateField
                source="date"
                label="resources.root.date"
                locales={"en-GB"}
              />
              <FunctionField
                sortBy={"totalQte"}
                label={translate("resources.root.finalQuantity")}
                render={(record) =>
                  `${record.totalQte + " + " + record.totalSmallMeasureQte}`
                }
              />
              <EditButton />
            </Datagrid>
          </List>
        </Print>
      </PrintProvider>
    </div>
  );
};

export const StoreInventoryInvoiceCreate = (props) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="resources.root.realStock">
        <StoreInventoryInvoiceAddRealStock />
      </FormTab>
      {process.env.REACT_APP_APP_NAME !== "Mediply" && (
        <FormTab label="resources.root.increaseDecrease">
          <StoreInventoryInvoiceAdd />
        </FormTab>
      )}
    </TabbedForm>
  </Create>
);

export const StoreInventoryInvoiceShow = () => {
  const props = useResourceDefinition();
  const { id } = useParams();
  const classes = useStyles();
  const [orders, setOrders] = React.useState([]);
  const [invoice, setInvoice] = React.useState([]);
  React.useEffect(() => {
    HTTP.get(`${url}/StoreInventoryInvoice/${id}`).then((res) => {
      setOrders(res.data.storeInventoryOrders);
      setInvoice(res.data);
    });
  }, [props]);

  const translate = useTranslate();

  return (
    <div>
      <PrintProvider>
        <Print single={true} name="foo">
          <div className={classes.printWrapper}>
            <Page
              invoice={invoice}
              title={translate("resources.root.storeInventory")}
              order={invoice.storeInventoryOrders}
              human={invoice.supplier}
            />
          </div>
        </Print>
        <NoPrint force>
          <Show>
            <TabbedShowLayout>
              <Tab label={translate("resources.root.invoice")}>
                <TextField
                  source="invoiceNumber"
                  label={translate("resources.root.invoiceNumber")}
                />
                <TextField
                  source="inventoryType"
                  label={translate("resources.root.inventoryType")}
                />
                <FunctionField
                  label={translate("resources.root.finalQuantity")}
                  render={(record) =>
                    `${record.totalQte + " + " + record.totalSmallMeasureQte}`
                  }
                />
                <DateField
                  source="date"
                  label="resources.root.date"
                  locales={"en-GB"}
                />
                <TextField
                  source="note"
                  label="resources.root.note"
                  locales={"en-GB"}
                />
              </Tab>
              <Tab label={translate("resources.root.orders")}>
                <TableContainer
                  component={Paper}
                  className={classes.tableContainer}
                >
                  <Table
                    stickyHeader
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {translate("resources.root.barcode")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.name")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.quantity")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.smallMeasureQty")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.scrollable}>
                      {orders &&
                        orders.map((order) => (
                          <TableRow key={order.product.barcode}>
                            <TableCell component="th" scope="row">
                              {order.product.barcode}
                            </TableCell>
                            <TableCell align="right">
                              <a href={`#/Products/${order.product.id}`}>
                                {order.product.name}
                              </a>
                            </TableCell>
                            <TableCell align="right">{order.qte}</TableCell>
                            <TableCell align="right">
                              {order.smallMeasureQte}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Tab>
            </TabbedShowLayout>
          </Show>
          <Button
            variant="contained"
            className={classes.botton}
            onClick={() => {
              let sidebar = document.getElementsByClassName("MuiDrawer-root");
              sidebar[0].style.display = "none";
              window.print();
              sidebar[0].style.display = "block";
            }}
          >
            {translate("resources.root.print")}
          </Button>
        </NoPrint>
      </PrintProvider>
    </div>
  );
};

export const StoreInventoryInvoiceEdit = (props) => {
  return <StoreInventoryInvoiceUpdate props={props} />;
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  invoiceNumberInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  discount: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {},
  tableContainer: {
    marginTop: 20,
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  printWrapper: {
    display: "none",
    width: "100%",
    height: "950",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "100%",
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
}));
