import * as React from "react";
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { CardContent, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { url } from "./request";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import moment from "moment";
// import Select from "@mui/material/Select";
// import InputLabel from "@mui/material/InputLabel";
// import FormControl from "@mui/material/FormControl";

// import { IoCalculator } from "react-icons/io5";
import { BsPersonSquare } from "react-icons/bs";
// import { CgDollar } from "react-icons/cg";
import { FaBox } from "react-icons/fa";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import SwipeableViews from "react-swipeable-views";
import Carousel from "react-material-ui-carousel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Tooltip } from "@mui/material";
import Fade from "@mui/material/Fade";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useRedirect, useTranslate } from "react-admin";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import AnimationButtons from "./components/CustomComponents/AnimationButtons";
import _ from "lodash";
ChartJS.register(...registerables);
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

const Dashboard = () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const role = localStorage.getItem("role") ?? "NoUser";
  const pluck = (arr, key) => arr.map((o) => o[key]);
  const [boxes, setBoxes] = React.useState([]);
  const [loggedInUsers, setLoggedInUsers] = React.useState([]);
  const [commands, setCommands] = React.useState([]);
  const [stores, setStores] = React.useState([]);

  const [sellChartData, setSellChartData] = React.useState([]);
  const [cashierSellChartData, setCashierSellChartData] = React.useState([]);
  const [purchaseChartData, setPurchaseChartData] = React.useState([]);
  const [representativesInvoiceChartData, setRepresentativesInvoiceChartData] =
    React.useState([]);
  const [
    representativesCustomerInvoiceChartData,
    setRepresentativesCustomerInvoiceChartData,
  ] = React.useState([]);
  const [expenseChartData, setExpenseChartData] = React.useState([]);
  const [paymentChartData, setPaymentChartData] = React.useState([]);
  const [detailsInfo, setDetailsInfo] = React.useState({
    count: 0,
    count2: 0,
    mcTotal: 0,
    mcTotal2: 0,
    scTotal: 0,
    scTotal2: 0,
  });

  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [activeTab, setActiveTab] = React.useState("sell");
  const [cashierAccounts, setCashierAccounts] = React.useState([]);

  const [showByMain, setShowByMain] = React.useState(false);
  const [showReturn, setShowReturn] = React.useState(false);
  const [hasExpires, setHasExpires] = React.useState(false);
  const [stockAlert, setStockAlert] = React.useState(false);

  const sellChart = {
    labels: pluck(
      sellChartData.map(({ date }) => {
        return { date: moment(date).format("YYYY-MM-DD") };
      }),
      "date"
    ),
    datasets: [
      {
        label: translate("resources.root.sells"),
        data: pluck(
          sellChartData.filter((x) => x.lost === false),
          showByMain ? "mcPrice" : "scPrice"
        ),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };
  const purchaseChart = {
    labels: pluck(
      purchaseChartData.map(({ date }) => {
        return { date: moment(date).format("YYYY-MM-DD") };
      }),
      "date"
    ),
    datasets: [
      {
        label: translate("resources.root.purchases"),
        data: pluck(
          purchaseChartData.filter((x) => x.lost === false),
          showByMain ? "mcPrice" : "scPrice"
        ),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };
  const representativesInvoiceChart = {
    labels: pluck(
      representativesInvoiceChartData.map(({ date }) => {
        return { date: moment(date).format("YYYY-MM-DD") };
      }),
      "date"
    ),
    datasets: [
      {
        label: translate("resources.root.sells"),
        data: pluck(
          representativesInvoiceChartData.filter((x) => x.lost === false),
          showByMain ? "mcPrice" : "scPrice"
        ),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };
  const representativesInvoiceCustomerChart = {
    labels: pluck(
      representativesCustomerInvoiceChartData.map(({ date }) => {
        return { date: moment(date).format("YYYY-MM-DD") };
      }),
      "date"
    ),
    datasets: [
      {
        label: translate("resources.root.sells"),
        data: pluck(
          representativesCustomerInvoiceChartData.filter(
            (x) => x.lost === false
          ),
          showByMain ? "mcPrice" : "scPrice"
        ),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };
  const expenseChart = {
    labels: pluck(
      expenseChartData.map(({ date }) => {
        return { date: moment(date).format("YYYY-MM-DD") };
      }),
      "date"
    ),
    datasets: [
      {
        label: translate("resources.root.expenses"),
        data: pluck(
          expenseChartData.filter((x) => x.lost === true),
          showByMain ? "mcPrice" : "scPrice"
        ),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };
  const paymentChart = {
    labels: pluck(
      paymentChartData.map(({ date }) => {
        return { date: moment(date).format("YYYY-MM-DD") };
      }),
      "date"
    ),
    datasets: [
      {
        label: translate("resources.root.customers"),
        data: pluck(
          paymentChartData.filter((x) => x.lost === false),
          showByMain ? "mcPrice" : "scPrice"
        ),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };
  const cashierSellsChart = {
    labels: pluck(
      cashierSellChartData.map(({ date }) => {
        return { date: moment(date).format("YYYY-MM-DD") };
      }),
      "date"
    ),
    datasets: [
      {
        label: translate("resources.root.sells"),
        data: pluck(
          cashierSellChartData.filter((x) => x.lost === false),
          showByMain ? "mcPrice" : "scPrice"
        ),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const options = { responsive: true };

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        setActiveTab("sell");
        getCharts("sell");
        break;
      case 1:
        setActiveTab("purchase");
        getCharts("purchase");
        break;
      case 2:
        setActiveTab("rep");
        getCharts("rep");
        break;
      case 3:
        setActiveTab("repcus");
        getCharts("repcus");
        break;
      case 4:
        setActiveTab("expense");
        getCharts("expense");
        break;
      case 5:
        setActiveTab("payment");
        getCharts("payment");
        break;
      case 6:
        setActiveTab("casher");
        getCharts("casher");
        break;
      default:
        setActiveTab("sell");
        getCharts("sell");
        break;
    }
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function getCharts(type = "sell") {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = {
      Accept: "application/json",
      storeid: localStorage.getItem("storeId") ?? 1,
      ratio: localStorage.getItem("dollarPrice"),
      Authorization: `Bearer ${token}`,
      language: localStorage.getItem("locale") === "kr" ? "Kurdish" : "English",
      platform: "Web",
    };
    axios
      .get(
        `${url}/Dashboard/Charts?invoiceType=${type}&startDate=${startDate}&endDate=${endDate}&ret=${showReturn}`
      )
      .then((response) => {
        let responseData = _.orderBy(response.data, "date", "asc");
        switch (type) {
          case "sell":
            setSellChartData(responseData);
            break;
          case "casher":
            setCashierSellChartData(responseData);
            break;
          case "purchase":
            setPurchaseChartData(responseData);
            break;
          case "rep":
            setRepresentativesInvoiceChartData(responseData);
            break;
          case "repcus":
            setRepresentativesCustomerInvoiceChartData(responseData);
            break;
          case "expense":
            setExpenseChartData(responseData);
            break;
          case "payment":
            setPaymentChartData(responseData);
            break;
          default:
            setSellChartData(responseData);
            break;
        }
        setDetailsInfo({
          count: parseFloat(response.headers["count"]),
          count2: parseFloat(response.headers["count2"]),
          mcTotal: parseFloat(response.headers["mctotal"]),
          mcTotal2: parseFloat(response.headers["mctotal2"]),
          scTotal: parseFloat(response.headers["sctotal"]),
          scTotal2: parseFloat(response.headers["sctotal2"]),
        });
      })
      .catch((error) => {
        console.log(error.response.status);
      });
  }

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = {
      Accept: "application/json",
      storeid: localStorage.getItem("storeId") ?? 1,
      ratio: localStorage.getItem("dollarPrice"),
      Authorization: `Bearer ${token}`,
      language: localStorage.getItem("locale") === "kr" ? "Kurdish" : "English",
      platform: "Web",
    };
    axios
      .get(`${url}/BankAccounts`)
      .then((response) => {
        setBoxes(response.data);
      })
      .catch((error) => {
        console.log(error.response.status);
      });

    axios
      .get(`${url}/Dashboard/LastLogins`)
      .then((response) => {
        setLoggedInUsers(response.data);
      })
      .catch((error) => {
        console.log(error.response.status);
      });

    let oldInLocal = JSON.parse(localStorage.getItem("quickCommands") || "[]");
    let elements = Object.values(oldInLocal) || [];
    setCommands(elements);
    getCharts("sell");

    axios
      .get(
        `${url}/Reports/ExpireReport?days=${
          localStorage.getItem("expireDaysDashboard") ?? 30
        }`
      )
      .then((response) => {
        setHasExpires(response.data.length > 0);
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    axios
      .get(
        `${url}/Reports/StockAlertReport?ignoreZero=${
          localStorage.getItem("ignoreZeroDashboard") ?? false
        }&ignoreBellowZeroProducts=${
          localStorage.getItem("ignoreBelowZeroProductsDashboard") ?? false
        }`
      )
      .then((response) => {
        setStockAlert(response.data.length > 0);
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    axios
      .get(`${url}/UserStoreBankAccounts`)
      .then((response) => {
        setCashierAccounts(response.data);
      })
      .catch((error) => {
        console.log(error.response.status);
      });

    if (role.toLowerCase() === "admin" || role.toLowerCase() === "superadmin") {
      axios
        .get(`${url}/Stores?_end=1000`)
        .then((response) => {
          setStores(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  React.useEffect(() => {
    getCharts(activeTab);
  }, [showReturn, startDate, endDate]);

  const tapData = [
    {
      index: 0,
      dataChart: sellChart,
      types: [
        {
          title: "resources.root.sellInvoice",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count,
          mcTotal: detailsInfo.mcTotal,
          scTotal: detailsInfo.scTotal,
        },
        {
          title: "resources.root.sellReturnInvoice",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count2,
          mcTotal: detailsInfo.mcTotal2,
          scTotal: detailsInfo.scTotal2,
        },
      ],
    },
    {
      index: 1,
      dataChart: purchaseChart,
      types: [
        {
          title: "resources.root.purchaseInvoice",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count,
          mcTotal: detailsInfo.mcTotal,
          scTotal: detailsInfo.scTotal,
        },
        {
          title: "resources.root.purchaseReturnInvoice",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count2,
          mcTotal: detailsInfo.mcTotal2,
          scTotal: detailsInfo.scTotal2,
        },
      ],
    },
    {
      index: 2,
      dataChart: representativesInvoiceChart,
      types: [
        {
          title: "resources.root.representativeInvoice",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count,
          mcTotal: detailsInfo.mcTotal,
          scTotal: detailsInfo.scTotal,
        },
        {
          title: "resources.root.representativeReturnInvoice",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count2,
          mcTotal: detailsInfo.mcTotal2,
          scTotal: detailsInfo.scTotal2,
        },
      ],
    },
    {
      index: 3,
      dataChart: representativesInvoiceCustomerChart,
      types: [
        {
          title: "resources.root.representativeCustomerInvoice",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count,
          mcTotal: detailsInfo.mcTotal,
          scTotal: detailsInfo.scTotal,
        },
        {
          title: "resources.root.representativeCustomerReturnInvoice",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count2,
          mcTotal: detailsInfo.mcTotal2,
          scTotal: detailsInfo.scTotal2,
        },
      ],
    },
    {
      index: 4,
      dataChart: expenseChart,
      types: [
        {
          title: "resources.root.expenses",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count,
          mcTotal: detailsInfo.mcTotal,
          scTotal: detailsInfo.scTotal,
        },
      ],
    },
    {
      index: 5,
      dataChart: paymentChart,
      types: [
        {
          title: "resources.root.customerPayments",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count,
          mcTotal: detailsInfo.mcTotal,
          scTotal: detailsInfo.scTotal,
        },
        {
          title: "resources.root.supplierPayments",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count2,
          mcTotal: detailsInfo.mcTotal2,
          scTotal: detailsInfo.scTotal2,
        },
      ],
    },
    {
      index: 6,
      dataChart: cashierSellsChart,
      types: [
        {
          title: "resources.root.cashierSells",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count,
          mcTotal: detailsInfo.mcTotal,
          scTotal: detailsInfo.scTotal,
        },
      ],
    },
  ];
  const tabChange = [
    { title: "Selling", index: 0 },
    { title: "Purchases", index: 1 },
    { title: "Representatives", index: 2 },
    { title: "RepresentativeCustomers", index: 3 },
    { title: "Expenses", index: 4 },
    { title: "Payments", index: 5 },
    { title: "cashierSells", index: 6 },
  ];

  const [backGroundTypeList, setBackGroundTypeList] = React.useState("Selling");

  if (role.toLowerCase() === "debtcollector") {
    return <div>Under Construction</div>;
  } else {
    return (
      <div className={`dashboard p-0`}>
        {/*   main them */}
        <Grid container justifyContent={"center"}>
          <Grid item className="col-12 col-lg-9">
            {/* Storees invoices*/}
            <Grid
              item
              className="pt-5 col-12 text-center startalign-items-center"
            >
              <h2 style={{ padding: "10px" }}>
                {translate("ra.navigation.Dashboard")}
              </h2>
              {(role.toLowerCase() === "admin" ||
                role.toLowerCase() === "superadmin") && (
                <Col className="pt-3 col-12 col-md-8 col-lg-6">
                  <Form.Group className="row" controlId="formHorizontalStoreId">
                    <Form.Label className="ms-3">
                      {translate("resources.root.store")}
                    </Form.Label>
                    <Col className="col-10 col-md-8 col-lg-6">
                      <Form.Control
                        className=" rounded newMenu-dashboard"
                        as={"select"}
                        onChange={(e) => {
                          localStorage.setItem("storeId", e.target.value);
                          window.location.reload();
                        }}
                        value={localStorage.getItem("storeId") ?? 1}
                      >
                        {stores.map((store, index) => {
                          return (
                            <option
                              key={index}
                              className="option-menu"
                              value={store.id}
                            >
                              {store.title}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>

                    {/* <FormControl className="col-10 col-md-8 col-lg-6" fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {translate("resources.root.store")}
                      </InputLabel>
                      <Select
                        className="col-10 col-md-8 col-lg-6"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        onChange={(e) => {
                          localStorage.setItem("storeId", e.target.value);
                          window.location.reload();
                        }}  
                        value={localStorage.getItem("storeId") ?? 1}
                      >
                        {stores.map((store, index) => {
                          return (
                            <option
                              key={index}
                              className="option-menu"
                              value={store.id}
                            >
                              {store.title}
                            </option>
                          );
                        })}
                      </Select>
                    </FormControl> */}
                  </Form.Group>
                </Col>
              )}
            </Grid>

            {/* 🤷‍♂️ */}
            <Grid className={`text-center row my-3`}>
              {commands &&
                commands.map((command, index) => {
                  return (
                    <Col className={`mt-1 col-6 col-md-4 col-lg-3`} key={index}>
                      <Button
                        block
                        onClick={() => redirect("/" + command.route)}
                        variant={"outline-secondary"}
                        style={{
                          height: 75,
                          width: "100%",
                          backgroundColor: command.bgColor,
                          color: command.color,
                          borderColor: command.bgColor,
                        }}
                      >
                        {translate("resources.root." + command.label)}
                      </Button>
                    </Col>
                  );
                })}
            </Grid>
            {/* Cards */}
            <Grid className=" row gx-5 justify-content-md-around " item>
              {role &&
                (role.toLowerCase() === "storeadmin" ||
                  role.toLowerCase() === "superadmin" ||
                  role.toLowerCase() === "admin" ||
                  role.toLowerCase() === "accountant") &&
                cashierAccounts &&
                cashierAccounts.map((one, index) => {
                  return (
                    <Grid
                      item
                      key={index}
                      className="col-12 col-md-6 col-lg-4 my-3"
                      // style={{ margin: "25px auto" }}
                    >
                      {/* <div class="card bg-newCard">
                                    <h5 class="card-header">
                                      <span className="d-flex justify-content-between h2 m-0">
                                        <p className="m-0 p-0">{`${one.firstName} ${one.middleName}`}</p>

                                        <p className="m-0 p-0">
                                          <BsPersonSquare />
                                        </p>
                                      </span>
                                    </h5>
                                    <div class="card-body text-center">
                                      <h5 class="card-title">
                                        {`$${
                                          one.mcCurrentCash &&
                                          one.mcCurrentCash?.toLocaleString()
                                        }`}
                                      </h5>
                                      <h5 class="card-title">
                                        {`${
                                          one.scCurrentCash &&
                                          one.scCurrentCash?.toLocaleString()
                                        }`}
                                        &nbsp; {translate("resources.root.iqd")}
                                      </h5>

                                      <a href="#" class="btn btn-primary">
                                        Go somewhere
                                      </a>
                                    </div>
                                  </div> */}
                      <Card
                        sx={{ minWidth: 275 }}
                        className="shadowed text-center"
                        variant="outlined"
                      >
                        <CardContent>
                          <Typography
                            style={{
                              fontSize: "25px",
                              fontWeight: "bold",
                            }}
                          >
                            <p className="m-0 p-0">
                              <BsPersonSquare /> &nbsp;
                            </p>
                            {`${one.firstName} ${one.middleName}`}
                          </Typography>
                          <Typography style={{ fontSize: "20px" }}>
                            <Tooltip
                              title="Current US money"
                              arrow
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 100 }}
                              placement="right-start"
                            >
                              <p
                                className={
                                  "btn-dollar text-white m-2 p-1 rounded"
                                }
                              >
                                {`$${
                                  one.mcCurrentCash &&
                                  one.mcCurrentCash?.toLocaleString()
                                }`}
                              </p>
                            </Tooltip>
                            <Tooltip
                              title="Current IQD money"
                              arrow
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 100 }}
                              placement="right-start"
                            >
                              <p
                                className={"btn-iqd text-white m-2 p-1 rounded"}
                              >
                                {`${
                                  one.scCurrentCash &&
                                  one.scCurrentCash?.toLocaleString()
                                }`}
                                {translate("resources.root.iqd")}
                              </p>
                            </Tooltip>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>

            {/* switch && date */}
            <Grid
              container
              className="col-12 mb-3 mb-lg-0 p-0 row justify-content-center"
            >
              {/* Switch */}
              <Grid
                className="col-12 col-lg-5 mb-4 mb-lg-0 text-center p-0 row justify-content-center"
                item
              >
                <Col className="col-6 p-0 ">
                  <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center mx-md-5 mx-lg-0  ">
                    <FormControlLabel
                      className="m-0 text-nowrap "
                      control={
                        <Switch
                          onChange={(e) => {
                            setShowByMain(e.target.checked);
                          }}
                          name="gilad"
                        />
                      }
                      label={translate("resources.root.showByMain")}
                    />
                  </InputGroup>
                </Col>
                <Col className="col-6 p-0">
                  <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center mx-md-5 mx-lg-0">
                    <FormControlLabel
                      className="m-0 text-nowrap"
                      control={
                        <Switch
                          onChange={(e) => {
                            setShowReturn(e.target.checked);
                          }}
                          name="gilad"
                        />
                      }
                      label={translate("resources.root.showReturn")}
                    />
                  </InputGroup>
                </Col>
              </Grid>
              {/* Date */}
              <Grid item className="col-12 col-lg-7">
                <Row>
                  <Col className="col-12 col-md-6 mb-3">
                    <Form.Group controlId="startDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2 text-nowrap">
                          {translate("resources.root.startDate")} :
                        </Form.Label>
                        <Form.Control
                          className=""
                          label="startDate"
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="col-12 col-md-6 mb-3">
                    <Form.Group controlId="endDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2  text-nowrap">
                          {translate("resources.root.endDate")} :
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Grid>
            </Grid>
            {/*Bar-dashbord*/}

            <AppBar position="static" color="default">
              <Tabs
                TabIndicatorProps={{ sx: { display: "none" } }}
                sx={{
                  "& .MuiTabs-flexContainer": {
                    flexWrap: "wrap",
                  },
                }}
                value={value}
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleChange}
                // indicatorColor="white"
                textColor="white"
                aria-label="scrollable auto tabs example"
                className="bar-dashbord rounded"
              >
                {tabChange &&
                  tabChange.map((tabItem) =>
                    process.env.REACT_APP_CASHIER_MODULE === "true" ? (
                      <Tab
                        onClick={() => {
                          setBackGroundTypeList(`${tabItem.title}`);
                        }}
                        className={`hoverd ${
                          backGroundTypeList === `${tabItem.title}`
                            ? "selected"
                            : ""
                        }`}
                        label={translate(`ra.navigation.${tabItem.title}`)}
                        {...a11yProps(tabItem.index)}
                      />
                    ) : tabItem.title === "cashierSells" ? null : (
                      <Tab
                        onClick={() => {
                          setBackGroundTypeList(`${tabItem.title}`);
                        }}
                        className={`hoverd ${
                          backGroundTypeList === `${tabItem.title}`
                            ? "selected"
                            : ""
                        }`}
                        label={translate(`ra.navigation.${tabItem.title}`)}
                        {...a11yProps(tabItem.index)}
                      />
                    )
                  )}
              </Tabs>
            </AppBar>
            {/*  sell & return card*/}
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
              className="noMargin "
            >
              {tapData &&
                tapData.map((tapD) => (
                  <TabPanel
                    value={value}
                    index={tapD.index}
                    dir={theme.direction}
                  >
                    <Grid
                      container
                      spacing={3}
                      style={{ padding: "0px !important" }}
                      mt={2}
                      justifyContent={"center"}
                    >
                      {tapD.types.map((typeItem) => (
                        <Grid item className="col-12  col-lg-6 ">
                          <Card
                            style={{ textAlign: "center" }}
                            className="noMargin"
                          >
                            <CardContent className="noMargin">
                              <Row className="newSellReturnBox-dashboard">
                                <Col xs={12} className="pt-2 h5 ">
                                  {translate(typeItem.title)}
                                </Col>
                                <Col
                                  className="blured py-3"
                                  style={{ fontSize: "1.2rem" }}
                                  xs={12}
                                >
                                  <Row className="px-4 ">
                                    <Col>
                                      <Typography className="text-nowrap h6 d-flex justify-content-around">
                                        {translate(typeItem.title_1)}
                                        :&nbsp;{typeItem.count}
                                      </Typography>
                                    </Col>
                                    <Col>
                                      <Typography className="text-nowrap h6">
                                        {translate(typeItem.title_2)}:
                                        {showByMain
                                          ? ` $ ${typeItem.mcTotal?.toLocaleString()}`
                                          : ` ${typeItem.scTotal?.toLocaleString()}   
                                            ${translate("resources.root.iqd")}`}
                                      </Typography>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <div style={{ width: "100%" }}>
                          <Line
                            data={tapD.dataChart}
                            options={options}
                            type={`line`}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </TabPanel>
                ))}
            </SwipeableViews>
          </Grid>

          {/* Boxes & Alerts & Login */}
          <Grid
            item
            className="boxes col-12 col-lg-3 m-md-0 mb-5 
                  p-md-3 pe-0 pb-md-0"
          >
            {role &&
              (role.toLowerCase() === "storeadmin" ||
                role.toLowerCase() === "superadmin" ||
                role.toLowerCase() === "admin" ||
                role.toLowerCase() === "accountant") && (
                <div>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <h5 style={{ padding: "10px" }}>
                      {translate("resources.root.boxes")}
                    </h5>
                  </Grid>
                  <Carousel
                    navButtonsProps={{
                      padding: "1px",
                    }}
                  >
                    {boxes &&
                      boxes.map((box, index) => {
                        return (
                          <Card style={{ textAlign: "center" }} key={index}>
                            <CardContent className="p-0">
                              <Row className="newSafeBox ">
                                <Col xs={12} className="py-3">
                                  <Row>
                                    <Col xs={8} style={{ fontSize: "1.2rem" }}>
                                      <Typography>{box.name}</Typography>
                                    </Col>
                                    <Col xs={4}>
                                      <FaBox />
                                    </Col>
                                  </Row>
                                </Col>

                                <Col
                                  className="blured py-3"
                                  style={{ fontSize: "1.2rem" }}
                                  xs={12}
                                >
                                  <Row className="px-4 ">
                                    <Col xs={12}>
                                      <Button className="btn-block btn-light mb-3">
                                        {box.mcCurrentBalance?.toLocaleString()}
                                        {translate("resources.root.usd")}
                                      </Button>
                                    </Col>
                                    <Col xs={12}>
                                      <Button className="btn-block btn-light">
                                        {box.scCurrentBalance?.toLocaleString()}
                                        {translate("resources.root.iqd")}
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>
                        );
                      })}
                  </Carousel>
                </div>
              )}
            {/* Expire */}
            {hasExpires && (
              <Grid
                item
                xs={12}
                style={{ textAlign: "center", marginBottom: "10px" }}
              >
                <AnimationButtons
                  text={translate("resources.root.expireReport")}
                  link={"#/Reports/ExpireReport"}
                />
              </Grid>
            )}
            {/* Stock Alert */}
            {stockAlert && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <AnimationButtons
                  text={translate("resources.root.stockReport")}
                  link={"#/Reports/StockReport"}
                />
              </Grid>
            )}
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <h3 style={{ padding: "10px" }}>
                {translate("resources.root.lastLogins")}
              </h3>
            </Grid>
            <Grid item xs={12} className={`lastLoggedInUsers`}>
              {loggedInUsers &&
                loggedInUsers.map((loggedInUser, index) => {
                  return (
                    <Accordion className="last-login-dashboard" key={index}>
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "white" }} />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ color: "white" }}
                      >
                        <Typography
                          style={{ fontWeight: "bold" }}
                          className={classes.heading}
                        >{`${loggedInUser.firstName} ${loggedInUser.middleName} ${loggedInUser.lastName}`}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          style={{ fontSize: "14px", color: "white" }}
                        >
                          {translate("resources.root.time")}:
                          {moment(loggedInUser.lastLoginDate).format(
                            "YYYY-MM-DD hh:mm:ss A"
                          )}
                          <br />
                          {translate("resources.root.ip")}:
                          {loggedInUser.lastLoginIp}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default Dashboard;
