import * as React from "react";
import "./Profile.css";
import { path, url } from "../request";
import { Form } from "react-bootstrap";
import { useNotify, useTranslate } from "react-admin";
import { HTTP } from "../axios";

export default () => {
  const translate = useTranslate();
  const notify = useNotify();

  const [firstName, setFirstName] = React.useState(
    localStorage.getItem("firstName") ?? null
  );
  const [middleName, setMiddleName] = React.useState(
    localStorage.getItem("middleName") ?? null
  );
  const [lastName, setLastName] = React.useState(
    localStorage.getItem("lastName") ?? null
  );
  const [username, setUsername] = React.useState(
    localStorage.getItem("userName")
  );

  const [oldPassword, setOldPassword] = React.useState(null);
  const [newPassword, setNewPassword] = React.useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = React.useState(null);

  const [image, setImage] = React.useState(
    localStorage.getItem("image") !== "null"
      ? `${path}/${localStorage.getItem("image")}`
      : "https://via.placeholder.com/150"
  );
  const [file, setFile] = React.useState(null);
  function handleFile(e) {
    setFile(e.target.files[0]);
  }

  async function uploadFile() {
    let formData = new FormData();
    formData.append("file", file);
    if (!file) {
      notify(translate("resources.root.pleaseChooseImage"), "error");
    } else {
      const response = await HTTP.post(
        `${url}/Accounts/UploadProfileImage`,
        formData
      ).catch((err) => {
        notify(err.response.data.message, { type: "error", undoable: true });
      });
      localStorage.setItem("image", response.data);
      setImage(`${path}/${response.data}`);
      window.location.reload();
    }
  }

  async function handleUpdateInfo() {
    if (!firstName || !middleName || !username) {
      notify(translate("resources.root.pleaseWriteFullName"), "error");
    } else if (
      firstName.length < 3 ||
      middleName.length < 3 ||
      username.length < 3
    ) {
      notify(translate("resources.root.namesMustBeMoreThan3Chars"), "error");
    } else {
      await HTTP.post(`${url}/Accounts/ChangeAccountInfo`, {
        firstName,
        middleName,
        lastName,
        username,
      })
        .then(() => {
          localStorage.setItem("firstName", firstName);
          localStorage.setItem("middleName", middleName);
          localStorage.setItem("lastName", lastName);
          localStorage.setItem("username", username);
          notify("resources.root.updated", "success");
        })
        .catch((err) => {
          notify(err.response.data.message, { type: "error", undoable: true });
        });
    }
  }

  async function handleUpdatePassword() {
    if (!oldPassword || !newPassword || !confirmNewPassword) {
      notify(translate("resources.root.pleaseWriteAllPasswords"), "error");
    } else if (newPassword !== confirmNewPassword) {
      notify(translate("resources.root.passwordsAreNotMatch"), "error");
    } else {
      await HTTP.post(`${url}/Accounts/ChangePassword`, {
        currentPassword: oldPassword,
        password: newPassword,
        confirmPassword: confirmNewPassword,
      })
        .catch((err) => {
          notify(err.response.data.message, { type: "error", undoable: true });
        })
        .finally(() => {
          notify("resources.root.updated", "success");
        });
    }
  }

  return (
    <div className="row gutters" style={{ textAlign: "start" }}>
      <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
        <div className="card h-100">
          <div className="card-body">
            <div className="account-settings">
              <div className="user-profile">
                <div className="user-avatar">
                  <img
                    src={image}
                    alt={`${firstName} ${middleName} ${lastName}`}
                  />
                </div>
                <h5 className="user-name">
                  {firstName} {middleName} {lastName}
                </h5>
              </div>
              <div className="about">
                <Form.Control
                  type={`file`}
                  id={`avatar`}
                  onChange={(e) => handleFile(e)}
                />
                <button
                  type="button"
                  id="submit"
                  name="submit"
                  className="btn btn-primary"
                  onClick={uploadFile}
                >
                  {translate("resources.root.updateAvatar")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
        <div className="card h-100">
          <div className="card-body">
            <div className="row gutters">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h6 className="mt-3 mb-2 text-primary">
                  {translate("resources.root.accountInfo")}
                </h6>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="form-group">
                  <label htmlFor="firstName">
                    {translate("resources.root.firstName")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    placeholder={translate("resources.root.firstName")}
                    defaultValue={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <div className="form-group">
                  <label htmlFor="middleName">
                    {translate("resources.root.middleName")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="middleName"
                    placeholder={translate("resources.root.middleName")}
                    defaultValue={middleName}
                    onChange={(e) => setMiddleName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <div className="form-group">
                  <label htmlFor="lastName">
                    {translate("resources.root.lastName")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    placeholder={translate("resources.root.lastName")}
                    defaultValue={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <div className="form-group">
                  <label htmlFor="username">
                    {translate("resources.root.username")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder={translate("resources.root.username")}
                    defaultValue={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="form-group">
                  <button
                    type="button"
                    id="submit"
                    name="submit"
                    className="btn btn-primary"
                    onClick={handleUpdateInfo}
                  >
                    {translate("resources.root.updateInfo")}
                  </button>
                </div>
              </div>
            </div>
            <div className="row gutters">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h6 className="mt-3 mb-2 text-primary">
                  {translate("resources.root.updatePassword")}
                </h6>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="form-group">
                  <label htmlFor="oldPassword">
                    {translate("resources.root.oldPassword")}
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="oldPassword"
                    placeholder={translate("resources.root.oldPassword")}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <div className="form-group">
                  <label htmlFor="newPassword">
                    {translate("resources.root.newPassword")}
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="newPassword"
                    placeholder={translate("resources.root.newPassword")}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <div className="form-group">
                  <label htmlFor="confirmNewPassword">
                    {translate("resources.root.confirmNewPassword")}
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="confirmNewPassword"
                    placeholder={translate("resources.root.confirmNewPassword")}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-group">
                  <button
                    type="button"
                    id="submit"
                    name="submit"
                    className="btn btn-primary"
                    onClick={handleUpdatePassword}
                  >
                    {translate("resources.root.updatePassword")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
