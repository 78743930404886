import * as React from "react";
import {
List,
Datagrid,
TextField,
Create,
SimpleForm,
TextInput,
Edit,
} from 'react-admin';
import {MyCustomPagination, SearchFilter} from "../../App";
import BulkAction from "../CustomComponents/BulkActions";

export const ProductCategoriesList = (props) => (
    <div>
        <List
            {...props}
            filters={<SearchFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            pagination={<MyCustomPagination />}
        >
            <Datagrid bulkActionButtons={<BulkAction {...props}/>} rowClick={'edit'}>
                <TextField source="name" label="resources.root.name" />
                <TextField source="shortDescription" label="resources.root.shortDescription" />
                <TextField source="longDescription" label="resources.root.longDescription" />
            </Datagrid>
        </List>
    </div>
);

export const ProductCategoriesCreate = (props) => {
    return (
        <Create {...props} redirect="list">
            <SimpleForm>
                <TextInput source="name" label="resources.root.name" />
                <TextInput source="shortDescription" label="resources.root.shortDescription" options={{ multiLine: true }} />
                <TextInput source="longDescription" label="resources.root.longDescription" options={{ multiLine: true }} />
            </SimpleForm>
        </Create>
    )
};

export const ProductCategoriesEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="name" label="resources.root.name" />
                <TextInput source="shortDescription" label="resources.root.shortDescription" options={{ multiLine: true }} />
                <TextInput source="longDescription" label="resources.root.longDescription" options={{ multiLine: true }} />
            </SimpleForm>
        </Edit>
    )
};
