import * as React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { url, path } from "../../request";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import CornerRibbon from "react-corner-ribbon";
import { Col, Form, Row, Table, Button, InputGroup } from "react-bootstrap";
import { FaMinus, FaPlus, FaTimes } from "react-icons/fa";
import swal from "sweetalert2";
import Switch from "@mui/material/Switch";
import Select from "react-select";
import { useNotify, useRedirect, useTranslate } from "react-admin";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { makeStyles } from "@mui/styles";
import Page from "./SellReceipt/Page";
import { HTTP } from "../../axios";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginTop: 72,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  invoiceNumberInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  discount: {
    width: "10% !important",
    marginLeft: 5,
    marginTop: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 20,
    maxHeight: "350px !important",
    overflow: "auto",
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  printWrapper: {
    display: "none",
    ["@media print"]: {
      display: "block",
      width: "140%",
      marginLeft: theme.direction === "rtl" ? 0 : -230,
      marginRight: theme.direction === "rtl" ? -230 : -230,
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "80%",
      marginLeft: 265,
      paddingTop: 60,
      //   marginRight: 230,
    },
  },
}));

export default () => {
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const redirect = useRedirect();

  const [products, setProducts] = React.useState([]);
  const [filteredProducts, setFilteredProducts] = React.useState([]);
  const [orders, setOrders] = React.useState([]);
  const [customers, setCustomers] = React.useState([]);
  const [customer, setCustomer] = React.useState(
    parseInt(localStorage.getItem("defaultCustomer"))
  );
  const [totalQty, setTotalQty] = React.useState(0);
  const [scTotalPrice, setScTotalPrice] = React.useState(0);
  const [mcTotalPrice, setMcTotalPrice] = React.useState(0);
  const [remainAmount, setRemainAmount] = React.useState(0);
  const [scDiscount, setScDiscount] = React.useState(0);
  const [mcDiscount, setMcDiscount] = React.useState(0);
  const [currencyType, setCurrencyType] = React.useState(false);
  const [invoice, setInvoice] = React.useState([]);
  const [isCash, setIsCash] = React.useState(false);

  React.useEffect(() => {
    let prodId = localStorage.getItem("defaultProducts")
      ? JSON.parse(localStorage.getItem("defaultProducts")).map(
          (id) => id.value
        )
      : [];
    let allIds = prodId
      .map((i) => "&id=" + i)
      .join()
      .replaceAll(",", "");
    HTTP.get(`${url}/Products?_end=5000${allIds}`)
      .then((response) => {
        setProducts(response.data);
        setFilteredProducts(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });

    HTTP.get(`${url}/Customers?_end=1000`)
      .then((response) => {
        let newArray = [];
        response.data.map((customer) => {
          newArray.push({
            value: customer.id,
            label: `${customer.firstName} ${customer.middleName} ${customer.lastName}`,
            customer: customer,
          });
        });
        setCustomers(newArray);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  React.useEffect(() => {
    calcTotals();
  }, [mcDiscount, scDiscount]);

  function addProductToOrders(product) {
    if (product.qteInStock <= 0) {
      swal.fire({
        icon: "error",
        title: "Oops!",
        text: `${translate("resources.root.theProductQuantityInStockIsZero")}`,
      });
    } else {
      const objectIndex = orders.findIndex((obj) => obj.id === product.id);
      if (objectIndex === -1) {
        product.qte = 1;
        product.theMcTotalPrice = product.qte * product.mcSellPrice;
        product.theScTotalPrice = product.qte * product.scSellPrice;
        setOrders([...orders, product].reverse());
        calcTotals([...orders, product]);
      } else {
        let check = product.qte + 1;
        if (check > product.qteInStock) {
          swal.fire({
            icon: "error",
            title: "Oops!",
            text: `${translate(
              "resources.root.theProductQuantityInStockIsOnly"
            )} ${product.qteInStock}`,
          });
        } else {
          const updatedOrders = [...orders];
          product = updatedOrders[objectIndex];
          product.qte += 1;
          product.theMcTotalPrice = product.qte * product.mcSellPrice;
          product.theScTotalPrice = product.qte * product.scSellPrice;
          updatedOrders[objectIndex] = product;
          setOrders(updatedOrders);
          calcTotals([...updatedOrders]);
        }
      }
    }
  }

  function addQty(e, order, type) {
    const objectIndex = orders.findIndex((obj) => obj.id === order.id);
    const updatedOrders = [...orders];
    if (type === "+") {
      let check = order.qte + 1;
      if (check > order.qteInStock) {
        swal.fire({
          icon: "error",
          title: "Oops!",
          text: `${translate(
            "resources.root.theProductQuantityInStockIsOnly"
          )} ${order.qteInStock}`,
        });
      } else {
        order.qte += 1;
      }
    } else if (type === "-") {
      let check = order.qte - 1;
      if (check === 0) {
        order.qte = 1;
      } else {
        order.qte -= 1;
      }
    } else {
      if (
        parseInt(e.target.value) === 0 ||
        parseInt(e.target.value) === undefined ||
        isNaN(parseInt(e.target.value))
      ) {
        order.qte = 1;
      } else {
        if (parseInt(e.target.value) > order.qteInStock) {
          swal.fire({
            icon: "error",
            title: "Oops!",
            text: `${translate(
              "resources.root.theProductQuantityInStockIsOnly"
            )} ${order.qteInStock}`,
          });
          order.qte = order.qteInStock;
        } else {
          order.qte = parseInt(e.target.value);
        }
      }
    }
    order.theMcTotalPrice = order.qte * order.mcSellPrice;
    order.theScTotalPrice = order.qte * order.scSellPrice;
    updatedOrders[objectIndex] = order;
    setOrders(updatedOrders);
    calcTotals([...updatedOrders]);
  }

  function removeOrderFromList(order) {
    const objects = orders.filter((obj) => obj.id !== order.id);
    setOrders(objects);
    calcTotals([...objects]);
  }

  function calcTotals(newOrders = null) {
    if (newOrders === null) {
      newOrders = orders;
    }
    setTotalQty(newOrders.map((el) => el.qte).reduce((a, b) => a + b, 0));
    setMcTotalPrice(
      (
        newOrders.map((el) => el.theMcTotalPrice).reduce((a, b) => a + b, 0) -
        mcDiscount
      )?.toLocaleString()
    );
    setScTotalPrice(
      (
        newOrders.map((el) => el.theScTotalPrice).reduce((a, b) => a + b, 0) -
        scDiscount
      )?.toLocaleString()
    );
  }

  function filterProducts(e) {
    let theProduct = products.filter(
      (product) =>
        product.name.includes(e.target.value) ||
        (product.barcode && product.barcode.includes(e.target.value))
    );
    setFilteredProducts(theProduct);
    if (theProduct.length > 0 && (e.which === 13 || e.keyCode === 13)) {
      addProductToOrders(theProduct[0]);
    }
  }

  function getByBarcode(e) {
    HTTP.get(`${url}/Products/GetByBarcode?barcode=${e.target.value}`)
      .then((response) => {
        addProductToOrders(response.data);
      })
      .catch((error) => {
        if (error.response.status === 404)
          swal.fire({
            toast: true,
            icon: "error",
            title: `${translate("resources.root.productNotFound")}`,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
      });
  }

  function addDiscount(e) {
    if (currencyType) {
      setMcDiscount(e.target.value);
      setScDiscount(
        e.target.value * parseInt(localStorage.getItem("dollarPrice"))
      );
    } else {
      setScDiscount(e.target.value);
      setMcDiscount(
        e.target.value / parseInt(localStorage.getItem("dollarPrice"))
      );
    }
  }

  function resetForm() {
    setFilteredProducts(products);
    setOrders([]);
    setTotalQty(0);
    setMcDiscount(0);
    setScDiscount(0);
    document.getElementById("paidAmount").value = 0;
    document.getElementById("discountInput").value = 0;
    setScTotalPrice(0);
    setMcTotalPrice(0);
    setRemainAmount(0);
    setCurrencyType(false);
  }

  function sellOrders() {
    document.getElementById("checkOutButton").disabled = true;
    document.getElementById("resetButton").disabled = true;
    let sellOrders = [];
    orders.map((order) => {
      sellOrders.push({
        productId: order.id,
        qte: order.qte,
        mcSellPrice: order.mcSellPrice,
        scSellPrice: order.scSellPrice,
        sellByMain: order.sellByMain,
      });
    });
    let theRequest = {
      customerId: customer,
      mcInvoiceDiscount: parseFloat(mcDiscount).toFixed(4),
      scInvoiceDiscount: parseInt(scDiscount),
      payByMain: currencyType,
      isCash: isCash,
      sellOrders: sellOrders,
    };

    HTTP.post(`${url}/SellInvoices`, theRequest)
      .then((response) => {
        setInvoice(response.data);
        document.getElementById("printButton").click();
        resetForm();
      })
      .catch((err) =>
        notify(err.response.data.message, { type: "error", undoable: true })
      );
    document.getElementById("checkOutButton").disabled = false;
    document.getElementById("resetButton").disabled = false;
  }

  return (
    <PrintProvider>
      <Print printOnly single name="foo">
        <div className={classes.printWrapper}>
          <Page
            invoice={invoice}
            title={translate("resources.root.sellInvoice")}
            order={invoice.sellOrders}
            human={invoice.customer}
            direction={
              localStorage.getItem("direction")
                ? localStorage.getItem("direction")
                : "ltr"
            }
          />
        </div>
      </Print>
      <NoPrint force>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Row>
              <Col>
                <Form.Control
                  size="lg"
                  type="text"
                  placeholder={translate("resources.root.nameBarcode")}
                  onChange={(e) => filterProducts(e)}
                  onKeyPress={(e) => {
                    if (e.which === 13 || e.keyCode === 13) {
                      filterProducts(e);
                    }
                  }}
                />
              </Col>
              <Col>
                <Form.Control
                  size="lg"
                  type="text"
                  placeholder={translate("resources.root.barcode")}
                  onKeyPress={(e) => {
                    if (e.which === 13 || e.keyCode === 13) {
                      getByBarcode(e);
                    }
                  }}
                />
              </Col>
            </Row>
            <div className={`products`} style={{ margin: "10px" }}>
              <Grid container spacing={1}>
                {filteredProducts &&
                  filteredProducts.map((product, index) => {
                    return (
                      <Grid item xs={3} key={index}>
                        <Card style={{ height: "350px", position: "relative" }}>
                          <CornerRibbon>
                            {currencyType
                              ? product.mcSellPrice + " USD"
                              : product.scSellPrice + " IQD"}
                          </CornerRibbon>
                          <CardActionArea
                            onClick={() => {
                              addProductToOrders(product);
                            }}
                          >
                            <CardMedia
                              component="img"
                              alt={product.name}
                              height="200"
                              image={
                                product.attachment
                                  ? `${path}${product.attachment}`
                                  : "https://via.placeholder.com/200"
                              }
                              title={product.name}
                            />
                            <CardContent style={{ textAlign: "center" }}>
                              <Typography
                                gutterBottom
                                variant="h6"
                                component="h6"
                                style={{
                                  fontSize: "18px",
                                  wordWrap: "break-word",
                                  overflow: "hidden",
                                  direction: "rtl",
                                  maxHeight: "3.6em",
                                  lineHeight: "1.8em",
                                }}
                              >
                                {product.name}
                              </Typography>
                              <Typography>
                                {product.barcode && `(${product.barcode})`}
                              </Typography>
                              <p
                                style={{
                                  fontSize: "15px",
                                  color: "green",
                                  margin: "0",
                                }}
                              >
                                {product.qteInStock}
                              </p>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </div>
          </Grid>
          <Grid item xs={5}>
            <div>
              <Row>
                <Col xs={"9"}>
                  <Select
                    isClearable
                    isSearchable
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={customers}
                    value={customers.find(
                      (oneCustomer) => oneCustomer.value === customer
                    )}
                    onChange={(e) => {
                      setCustomer(e.value);
                    }}
                  />
                </Col>
                <Col xs={"3"}>
                  <Button
                    id={"printButton"}
                    variant={"outline-info"}
                    className={classes.botton}
                    onClick={() => {
                      window.print();
                    }}
                  >
                    {translate("resources.root.lastInvoice")}
                  </Button>
                </Col>
              </Row>
              <Row className={"mt-2"}>
                <Col xs={6}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      {translate("resources.root.paidAmount")}
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        id={"paidAmount"}
                        type="number"
                        defaultValue={0}
                        onFocus={(e) => e.target.select()}
                        placeholder={translate("resources.root.receivedAmount")}
                        onChange={(e) => {
                          setRemainAmount(
                            parseInt(e.target.value) -
                              parseInt(
                                currencyType
                                  ? parseInt(mcTotalPrice.replace(/,/g, ""))
                                  : parseInt(scTotalPrice.replace(/,/g, ""))
                              )
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <h6>
                    {translate("resources.root.remain")}:{" "}
                    <b id={"remain-value"}>{remainAmount}</b>{" "}
                    {currencyType
                      ? translate("resources.root.usd")
                      : translate("resources.root.iqd")}
                  </h6>
                </Col>
              </Row>
              <Row className={"mt-2"}>
                <Col xs={6}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      {translate("resources.root.discount")}
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        id={"discountInput"}
                        type="number"
                        placeholder={translate("resources.root.discount")}
                        defaultValue={0}
                        onBlur={(e) => addDiscount(e)}
                        onFocus={(e) => e.target.select()}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <label>
                    <label>{translate("resources.root.cashIqd")}</label>
                    <Switch
                      size={"medium"}
                      defaultChecked={currencyType}
                      onChange={(e) => {
                        setCurrencyType(e.target.checked);
                      }}
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    <label>{translate("resources.root.cashUsd")}</label>
                  </label>
                </Col>
                <Col xs={6}>
                  <label>
                    <Switch
                      size={"medium"}
                      defaultChecked={isCash}
                      onChange={(e) => {
                        setIsCash(e.target.checked);
                      }}
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    <label>{translate("resources.root.isCash")}?</label>
                  </label>
                </Col>
              </Row>
              <Row className={"mt-2"}>
                <Col xs={6}>
                  {translate("resources.root.totalQty")}:{" "}
                  <b id={"total-qty"}>{totalQty}</b>
                </Col>
                <Col xs={6}>
                  {translate("resources.root.totalPrice")}:{" "}
                  <b id={"total-price"}>
                    {currencyType
                      ? mcTotalPrice?.toLocaleString()
                      : scTotalPrice?.toLocaleString()}
                  </b>{" "}
                  {currencyType ? "USD" : "IQD"}
                </Col>
                <Col className={"mt-2"} xs={8}>
                  <Button
                    id={"checkOutButton"}
                    block
                    variant={"primary"}
                    onClick={(e) => sellOrders(e)}
                  >
                    {translate("resources.root.checkout")}
                  </Button>
                </Col>
                <Col className={"mt-2"} xs={4}>
                  <Button
                    id={"resetButton"}
                    block
                    variant={"outline-secondary"}
                    onClick={(e) => resetForm(e)}
                  >
                    {translate("resources.root.reset")}
                  </Button>
                </Col>
              </Row>
            </div>
            <hr />
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{translate("resources.root.product")}</th>
                  <th>{translate("resources.root.quantity")}</th>
                  <th>{translate("resources.root.price")}</th>
                  <th>{translate("resources.root.totalPrice")}</th>
                  <th>{translate("resources.root.remove")}</th>
                </tr>
              </thead>
              <tbody>
                {orders &&
                  orders.map((order, key) => {
                    return (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>{order.name.substring(0, 15)} ...</td>
                        <td className={"text-center"}>
                          <InputGroup className="mb-3 justify-content-center">
                            <InputGroup.Text>
                              <Button
                                variant="outline-primary"
                                size={"lg"}
                                style={{ borderRadius: "5px" }}
                                onClick={(e) => addQty(e, order, "+")}
                              >
                                <FaPlus />
                              </Button>
                            </InputGroup.Text>
                            <Form.Control
                              className={"mx-2 text-center"}
                              size={"lg"}
                              value={order.qte}
                              onChange={(e) => addQty(e, order, "*")}
                              onFocus={(e) => e.target.select()}
                            />
                            <InputGroup.Text>
                              <Button
                                variant="outline-danger"
                                size={"lg"}
                                style={{ borderRadius: "5px" }}
                                onClick={(e) => addQty(e, order, "-")}
                              >
                                <FaMinus />
                              </Button>
                            </InputGroup.Text>
                          </InputGroup>
                        </td>
                        <td>
                          {currencyType
                            ? order.mcSellPrice?.toLocaleString()
                            : order.scSellPrice?.toLocaleString()}
                        </td>
                        <td>
                          {currencyType
                            ? order.theMcTotalPrice?.toLocaleString()
                            : order.theScTotalPrice?.toLocaleString()}
                        </td>
                        <td>
                          <Button
                            variant={"outline-danger"}
                            onClick={() => removeOrderFromList(order)}
                          >
                            <FaTimes />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Grid>
        </Grid>
      </NoPrint>
    </PrintProvider>
  );
};
