import * as React from "react";
import {
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  ExportButton,
} from "react-admin";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { NoPrint } from "react-easy-print";
import { BiDollar } from "react-icons/bi";
import { FaHistory, FaPrint } from "react-icons/fa";
import { GiTwoCoins } from "react-icons/gi";
import { GoListOrdered } from "react-icons/go";

export const ListActions = (props) => {
  const classes = useStyles();
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, showFilter, total } =
    useListContext();
  return (
    <NoPrint>
      <TopToolbar {...sanitizeListRestProps(rest)}>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        {/*<ButtonGroup className={classes.btnGroup} size="small" variant="contained">*/}
        <Button className={classes.primary}>
          <GoListOrdered /> {total ?? 0}
        </Button>
        {process.env.REACT_APP_SINGLE_CURRENCY === "false" && (
          <div>
            <Button
              className={classes.primary}
              style={{
                backgroundColor:
                  props.currency === "default" && "rgb(17, 82, 147)",
              }}
              onClick={() => props.setCurrency("default")}
            >
              <FaHistory />
            </Button>
            <Button
              className={classes.primary}
              style={{
                backgroundColor:
                  props.currency === "dollar" && "rgb(17, 82, 147)",
              }}
              onClick={() => props.setCurrency("dollar")}
            >
              <BiDollar />
            </Button>
            <Button
              className={classes.primary}
              style={{
                backgroundColor:
                  props.currency === "dinar" && "rgb(17, 82, 147)",
              }}
              onClick={() => props.setCurrency("dinar")}
            >
              {" "}
              <GiTwoCoins />
            </Button>
          </div>
        )}
        <PrintButton />
        <div>
          <ExportButton
            maxResults={3000}
            label={""}
            className={classes.primary}
          />
        </div>
        <CreateButton
          className={classes.primary}
          style={{ height: "50px !important" }}
        />
        {/*</ButtonGroup>*/}
      </TopToolbar>
    </NoPrint>
  );
};

export const PrintButton = () => {
  const classes = useStyles();
  return (
    <NoPrint>
      <Button
        className={classes.primary}
        style={{
          borderRight: "1px solid #bdbdbd",
          height: "100%",
          boxShadow: "none",
          borderRadius: 0,
        }}
        variant={"contained"}
        onClick={() => {
          let sidebar = document.getElementsByClassName("MuiDrawer-root");
          let appMenu = document.getElementsByClassName("MuiPaper-root");
          let appBar = document.getElementsByClassName("theRoot");
          sidebar[0].style.display = "none";
          appMenu[0].style.display = "none";
          appBar[0].style.marginTop = "0px";
          window.print();
          sidebar[0].style.display = "block";
          appMenu[0].style.display = "block";
          appBar[0].style.marginTop = "40px";
        }}
      >
        <FaPrint />
      </Button>
    </NoPrint>
  );
};

const useStyles = makeStyles({
  primary: {
    margin: "0",
    backgroundColor: "#1976d2",
    borderRadius: 0,
    borderRight: "1px solid #bdbdbd",
    color: "#fff",
    height: "50px !important",
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  btnGroup: {
    marginRight: 10,
  },
});
