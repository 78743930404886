import moment from "moment";
import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { FaCheck } from "react-icons/fa";
import { useTranslate } from "react-admin";
import logos from "../../assets/logos";

export const VoucherMockup = (props) => {
  const [logoUrl, setLogoUrl] = React.useState(
    localStorage.getItem("logoUrl") ?? logos.logo
  );
  const translate = useTranslate();
  return (
    <div id={`cachFrame`}>
      <div
        style={{
          fontSize: parseInt(localStorage.getItem("depositVoucherFont")) ?? 14,
        }}
      >
        <div
          style={{
            fontSize: 14,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div id={`cachFrame`} style={{ width: "100%" }}>
            <div style={{ padding: 0 }}>
              <Grid container spacing={0}>
                <Grid item xs={3} style={{ textAlign: "center" }}>
                  <Paper elevation={0}>
                    <div>Iraq - Erbil</div>
                    <div>
                      {localStorage.getItem("useCustomPhoneNumbers") === "true"
                        ? localStorage.getItem("phoneNumber1")
                        : process.env.REACT_APP_PHONE}
                    </div>
                    <div>
                      {localStorage.getItem("useCustomPhoneNumbers") === "true"
                        ? localStorage.getItem("phoneNumber2")
                        : process.env.REACT_APP_PHONE2}
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "center" }}>
                  {process.env.REACT_APP_SHOW_LOGO === "true" && (
                    <img alt="" src={logoUrl} width={80} height={80} />
                  )}
                </Grid>
                <Grid item xs={3} style={{ textAlign: "center" }}>
                  <p>{process.env.REACT_APP_APP_NAME}</p>
                  <p>No.: D1326547894f</p>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: 5,
            height: 30,
            borderTopStyle: "solid",
            borderTopWidth: 2,
            borderBottomStyle: "solid",
            borderBottomWidth: 2,
            marginBottom: "10px",
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <p>
                Mob.:&nbsp;
                <span>
                  {localStorage.getItem("useCustomPhoneNumbers") === "true"
                    ? localStorage.getItem("phoneNumber1")
                    : process.env.REACT_APP_PHONE}
                </span>
                &nbsp;-&nbsp;
                <span>
                  {localStorage.getItem("useCustomPhoneNumbers") === "true"
                    ? localStorage.getItem("phoneNumber2")
                    : process.env.REACT_APP_PHONE2}
                </span>
              </p>
            </Grid>
            <Grid item xs={6}>
              <div style={{ textAlign: "right" }}>
                <p>E-mail: {`${process.env.REACT_APP_APP_NAME}@gmail.com`}</p>
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <div>
              <div style={{ border: "1px solid", width: 140 }}>
                <p style={{ margin: 0, padding: "10px" }}>
                  <span
                    style={{
                      width: "50px",
                      padding: "12px",
                      borderRight: "2px solid darkgreen",
                    }}
                  >
                    دینار&nbsp;
                  </span>
                  <span style={{ marginLeft: "8px" }}>
                    {!props.byMain ? <FaCheck /> : ""}
                  </span>
                </p>
              </div>
              <div style={{ border: "1px solid", width: 140 }}>
                <p style={{ margin: 0, padding: "10px" }}>
                  <span
                    style={{
                      width: "50px",
                      padding: "12px",
                      borderRight: "2px solid",
                    }}
                  >
                    دۆلار&nbsp;
                  </span>
                  <span style={{ marginLeft: "8px" }}>
                    {props.byMain ? <FaCheck /> : ""}
                  </span>
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ textAlign: "center", lineHeight: 0.5 }}>
              {props.received && props.received ? (
                <div>
                  <p>پسولەی وەرگرتن</p>
                  <p>
                    <u>Receipt Voucher</u>
                  </p>
                </div>
              ) : (
                <div>
                  <p>پسولەی پارەدان</p>
                  <p>
                    <u>Payment Voucher</u>
                  </p>
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ textAlign: "right" }}>
              <p>{moment(props.date).format("YYYY-MM-DD")} :ڕێکەوت</p>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <div style={{ textAlign: "left" }}>
              {props.received && (
                <p>
                  Received From: <b>{props.receivedFrom}</b>
                </p>
              )}
              {!props.received && (
                <p>
                  Payed To: <b>Test Name{props.receivedFrom}</b>
                </p>
              )}
              <p>
                Amount: <b>1500 IQD</b>
                <b>
                  {props.byMain
                    ? props.priceMc &&
                      props.priceMc?.toLocaleString() +
                        ` ${translate("resources.root.usd")}`
                    : props.priceSc &&
                      props.priceSc?.toLocaleString() +
                        ` ${translate("resources.root.iqd")}`}
                </b>
                {props.currentLoan && localStorage.getItem("showCurrentLoan")
                  ? ` (${translate("resources.root.currentLoan")} ${
                      props.currentLoan
                    })`
                  : ""}
              </p>
              <p>
                Note: <b>No Notes For This Deposit{props.note}</b>
              </p>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ textAlign: "right" }}>
              {props.received && <p>:(وەرگیرا لە (استلمت من</p>}
              {!props.received && <p>:(درا بە (أعطی إلی</p>}
              <p>:(بڕی (المبلغ</p>
              <p>:(تێبینیی (الملاحظة</p>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <p style={{ textAlign: "center" }}>ژمێریار</p>
          </Grid>
          <Grid item xs={6}>
            <p style={{ textAlign: "center" }}>واژوو</p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
