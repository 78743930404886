import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  required,
  DateField,
  NumberField,
  Show,
  ReferenceField,
  DateInput,
  NumberInput,
  SimpleFormIterator,
  ArrayInput,
  useDataProvider,
  SimpleShowLayout,
  FunctionField,
  ArrayField,
  AutocompleteInput,
  useTranslate,
  useRefresh,
  BooleanField,
} from "react-admin";
import { ListActions } from "../templates/ListActions";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { useParams } from "react-router-dom";
import Page from "../CustomPages/SellReceipt/Page";
import { HTTP } from "../../axios";
import { url } from "../../request";
import CheckIcon from "@mui/icons-material/Check";
import { jssPreset, makeStyles, StylesProvider } from "@mui/styles";
import Button from "@mui/material/Button";

import Swal from "sweetalert2";
import swal from "sweetalert";

const BulkActionButtons = (props) => {
  const refresh = useRefresh();
  const translate = useTranslate();

  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.selectedIds.length === 1 && (
          <Button
            color="primary"
            style={{ marginBottom: 15 }}
            onClick={() => {
              const { value: formValues } = Swal.fire({
                title: "Confirm the transfering",
                html: '<span class="d-flex justify-content-center align-items-center"> <label class="text-dark ">Note:</label> <input  id="swal-input1"  class="swal2-input"></span>',
                focusConfirm: false,
                confirmButtonColor: "#117baf",
                confirmButtonText: translate("resources.root.submit"),
                preConfirm: () => {
                  HTTP.put(
                    `${url}/StoreTransferInvoices/${
                      props.selectedIds[0]
                    }?note=${document.getElementById("swal-input1").value}`
                  )
                    .then(() => {
                      swal.stopLoading();
                      swal.close();
                      refresh();
                    })
                    .catch((err) => {
                      if (err) {
                        console.log(err);
                        Swal.fire({
                          title: "Error",
                          html: err.response.data.message,
                        });
                      } else {
                        swal.stopLoading();
                        swal.close();
                      }
                    });
                },
              });
            }}
          >
            <CheckIcon /> &nbsp; {translate("resources.root.accept")}
          </Button>
        )}

        <BulkAction {...props} bulkDeleteButton={true} />
      </div>
    </React.Fragment>
  );
};

export const StoreTransferInvoicesList = (props) => {
  const [currency, setCurrency] = React.useState("default");

  return (
    <List
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions setCurrency={setCurrency} currency={currency} />}
    >
      <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
        <ReferenceField
          label="resources.root.store"
          source="storeId"
          reference="Stores"
        >
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField
          label="resources.root.toStore"
          source="toStoreId"
          reference="Stores"
        >
          <TextField source="title" />
        </ReferenceField>
        <BooleanField source="received" label="resources.root.received" />
        <TextField source="driver" label="resources.root.driver" />
        <TextField source="transferedBy" label="resources.root.transferredBy" />
        <TextField source="note" label="resources.root.note" />
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
      </Datagrid>
    </List>
  );
};

export const StoreTransferInvoicesShow = (props) => {
  const translate = useTranslate();
  const { id } = useParams();
  const [orders, setOrders] = React.useState([]);
  const [invoice, setInvoice] = React.useState([]);
  const classes = useStyles();
  React.useEffect(() => {
    if (id !== 0) {
      HTTP.get(`${url}/StoreTransferInvoices/${id}`).then((res) => {
        setOrders(res.data.sellOrders);
        setInvoice(res.data);
      });
    }
  }, [id]);
  return (
    <div>
      <PrintProvider>
        <Print single={true} name="foo">
          <div className={classes.printWrapper}>
            <Page
              invoice={invoice}
              order={invoice.storeTransferOrders}
              title={translate("resources.root.transferInvoice")}
              human={invoice.customer}
              showPrice={false}
              showCustomer={false}
              isTransferInvoice={true}
            />
            {/*<NewInvoice*/}
            {/*  ref={ref}*/}
            {/*  orders={orders}*/}
            {/*  invoice={invoice}*/}
            {/*  title={translate('resources.root.sellInvoice')}*/}
            {/*  human={invoice.customer}*/}
            {/*/>*/}
          </div>
        </Print>
        <NoPrint force>
          <Show>
            <SimpleShowLayout>
              <DateField
                source="date"
                label="resources.root.date"
                locales={"en-GB"}
              />
              <TextField source="driver" label="resources.root.driver" />
              <TextField
                source="transferedBy"
                label="resources.root.transferredBy"
              />
              <TextField source="note" label="resources.root.note" />
              <ArrayField source="storeTransferOrders">
                <Datagrid>
                  <FunctionField
                    label="resources.root.product"
                    render={(record) => record.product.name}
                  />
                  <FunctionField
                    label="resources.root.barcode"
                    render={(record) => record.product?.barcode}
                  />
                  <TextField source="sentQte" label="resources.root.sentQty" />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </Show>
          <Button
            variant="contained"
            onClick={() => {
              // let sidebar = document.getElementsByClassName('MuiDrawer-root');
              // let appMenu = document.getElementsByClassName('MuiPaper-root');
              // let appBar = document.getElementsByClassName('theRoot');
              // sidebar[0].style.display = 'none';
              // appMenu[0].style.display = 'none';
              // appBar[0].style.marginTop = '-100px';
              // window.print();
              // sidebar[0].style.display = 'block';
              // appMenu[0].style.display = 'block';
              // appBar[0].style.marginTop = '40px';
              let sidebar = document.getElementsByClassName("MuiDrawer-root");
              sidebar[0].style.display = "none";
              window.print();
              sidebar[0].style.display = "block";
            }}
          >
            {translate("resources.root.print")}
          </Button>
        </NoPrint>
      </PrintProvider>
    </div>
  );
};

export const StoreTransferInvoicesCreate = (props) => {
  const [stores, setStores] = React.useState([]);
  const [choices, setChoices] = React.useState([]);

  const dataProvider = useDataProvider();

  React.useEffect(() => {
    const res = dataProvider
      .getList("Products", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "id", order: "DESC" },
        filter: {},
      })
      .then((response) => setChoices(response.data));

    dataProvider
      .getList("Stores", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "id", order: "DESC" },
        filter: {},
      })
      .then((response) => setStores(response.data));
  }, []);

  const optionRenderer = (choice) => `${choice.name} ~ ${choice.qteInStock}`;
  const storeOptionRenderer = (choice) => `${choice.title}`;
  return (
    <Create {...props} redirect="list">
      <SimpleForm>
        <AutocompleteInput
          source="storeId"
          perPage={500}
          label="resources.root.store"
          choices={stores}
          optionText={storeOptionRenderer}
          optionValue="id"
          validate={[required()]}
        />
        <AutocompleteInput
          source="toStoreId"
          perPage={500}
          label="resources.root.toStore"
          choices={stores}
          optionText={storeOptionRenderer}
          optionValue="id"
          validate={[required()]}
        />
        <DateInput
          source="date"
          label="resources.root.date"
          defaultValue={new Date()}
        />
        <TextInput source="driver" label="resources.root.driver" />
        <TextInput source="transferedBy" label="resources.root.transferredBy" />
        <TextInput
          source="note"
          label="resources.root.note"
          options={{ multiLine: true }}
        />
        <ArrayInput source="storeTransferOrders">
          <SimpleFormIterator>
            <AutocompleteInput
              source="productId"
              perPage={500}
              label="resources.root.product"
              choices={choices}
              optionText={optionRenderer}
              optionValue="id"
              validate={[required()]}
            />
            <NumberInput source="sentQte" label="resources.root.sendQty" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginTop: 72,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  dateTimeInput: {
    width: "100% !important",
    margin: "0 10px -35px",
  },
  addressInput: {
    width: "70% !important",
    marginTop: 8,
  },
  invoiceNumberInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
    margin: "0 10px",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 20,
    maxHeight: "350px !important",
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  successChips: {
    backgroundColor: "#72ffa7",
    color: "#000",
  },
  printWrapper: {
    display: "none",
    width: "100%",
    height: "950",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
}));
