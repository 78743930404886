import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Create,
    SimpleForm,
    TextInput,
    Edit,
    useTranslate
} from 'react-admin';
import {MyCustomPagination, SearchFilter} from "../../App";
import BulkAction from "../CustomComponents/BulkActions";

export const DepositsTypesList = (props) => {
    const translate = useTranslate();
    return (
        <List
            filters={<SearchFilter />}
            pagination={<MyCustomPagination />}
            {...props}
            sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid bulkActionButtons={<BulkAction {...props} />} rowClick={'edit'}>
                <TextField source="label" label={translate("resources.root.label")} />
                <TextField source="note" label={translate("resources.root.note")} />
            </Datagrid>
        </List>
    )
};

export const DepositsTypesCreate = (props) => {
    const translate = useTranslate();
    return (
        <Create {...props} redirect='list'>
            <SimpleForm>
                <TextInput source="label" label={translate("resources.root.label")} />
                <TextInput source="note" label={translate("resources.root.note")} options={{ multiLine: true }} />
            </SimpleForm>
        </Create>
    )
};

export const DepositsTypesEdit = (props) => {
    const translate = useTranslate();
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="label" label={translate("resources.root.label")} margin="normal" variant="outlined" />
                <TextInput source="note" label={translate("resources.root.note")} margin="normal" variant="outlined" options={{ multiLine: true }} />
            </SimpleForm>
        </Edit>
    )
};
