import { useEffect } from "react";
import { useState } from "react";
import { HTTP } from "../../axios";
import moment from "moment";
import userImg from "../../assets/dashboardImages/userImg.png";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
import SellTable from "./SellTable";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 10px auto;
  border-color: red;
`;

const RecentLogins = () => {
    const [allLogs, setAllLogs] = useState([]);
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        HTTP.get("Dashboard/LastLogins")
            .then((result) => {
                setAllLogs(result.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setLoading(false));
    }, []);

    return (
        <div className="row">
            <SellTable />
            <div className="col-lg-2 col-sm-12">
                <div className="card shadow">
                    {
                        loading &&
                        <HashLoader color="#488EF9" loading={loading} css={override} size={55} />
                    }
                    {
                        !loading &&
                        <div>
                            <div className="card-header bgWhite pl-3 pt-3 pb-3">
                                <h3 className="mb-0">
                                    <b>Last Logins</b>
                                </h3>
                            </div>
                            <div className="card-body staWidth p-2">
                                <ul className="list-group">
                                    {allLogs &&
                                        allLogs.map((item, index) => (
                                            <li
                                                className="list-group-item border-0 d-flex justify-content-between ps-0 mb-0"
                                                key={index}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <img src={userImg} width="30" /> &nbsp;
                                                    <div className="d-flex flex-column">
                                                        <h5 className="mb-1 text-dark text-sm">
                                                            <b>
                                                                {
                                                                    item.firstName.charAt(0).toUpperCase() + item.firstName.slice(1) +
                                                                    " " +
                                                                    item.middleName.charAt(0).toUpperCase() + item.middleName.slice(1) +
                                                                    " " +
                                                                    item.lastName.charAt(0).toUpperCase() + item.lastName.slice(1)
                                                                }
                                                            </b>
                                                        </h5>
                                                        <span className="text-xs">
                                  <b>Time:</b>{" "}
                                                            {moment(item.lastLoginDate).format(
                                                                "YYYY-MM-DD HH:mm:ss A"
                                                            )}{" "}
                                </span>
                                                        <br />
                                                        <span className="text-xs"><b>IP:</b> {item.lastLoginIp}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default RecentLogins;