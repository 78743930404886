import { url } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import { PrintProvider, Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import moment from "moment";
import { HTTP } from "../../axios";
import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const OrdersReport = () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [radioValue, setRadioValue] = useState("SellInvoice");
  const [groupItems, setGroupItems] = useState(false);
  const radios = [
    { name: translate("resources.root.sellInvoice"), value: "SellInvoice" },
    {
      name: translate("resources.root.purchaseInvoice"),
      value: "PurchaseInvoice",
    },
    // { name: translate('resources.root.purchaseInvoice'), value: 'QuickSellInvoice' },
  ];
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const [isLoading, setIsLoading] = React.useState(false);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    setIsLoading(true);
    let sendDates = ``;
    if (startDate && startDate.length > 0)
      sendDates += `&startDate=${startDate}`;
    if (endDate && endDate.length > 0) sendDates += `&endDate=${endDate}`;

    await HTTP.post(
      `${url}/Utilities/GetBatchOrders?type=${radioValue}&groupProducts=${groupItems}&_start=${start}&_end=${end}&byDate=true&showPrices=true${sendDates}`,
      [1, 2]
    )
      .then((response) => {
        setData(response.data);
        setToTalItems(response.headers["x-total-count"]);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  // React.useEffect(
  //   function () {
  //     setIsLoading(true);
  //     getData();
  //   },
  //   [startDate, endDate, start, end]
  // );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );
  const handleClick = () => getData();

  return (
    <div>
      <PrintProvider>
        <Print single name={`ordersReport`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.sellOrdersReport")}
            </h1>
            <div className={`d-inline-block`} style={{ float: "inline-end" }}>
              <NoPrint>
                <Button
                  variant={"outline-primary"}
                  onClick={(e) => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}
                </Button>
                <div className={`clearfix`}> </div>
              </NoPrint>
            </div>
          </div>
          <Row>
            {isLoading && <LoadingScreen />}
            <Col xs={4}>
              <Row>
                <Col xs={8}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Gender
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Female"
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio />}
                        label="Other"
                      />
                      <FormControlLabel
                        value="disabled"
                        disabled
                        control={<Radio />}
                        label="other"
                      />
                    </RadioGroup>
                  </FormControl>
                  <ButtonGroup className="mb-2">
                    {radios.map((radio, idx) => (
                      <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant="secondary"
                        name="radio"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={(e) => setRadioValue(e.currentTarget.value)}
                      >
                        {radio.name}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                </Col>
              </Row>
            </Col>
            <Grid
              container
              className="col-12 mb-3 mb-lg-0 p-0 row justify-content-center"
            >
              {/* Switch */}
              <Grid
                className="col-12 col-lg-4 mb-4 mb-lg-0 text-center p-0 row justify-content-center"
                item
              >
                <Col className="col-6 p-0">
                  <Button
                    variant="primary"
                    disabled={isLoading}
                    onClick={!isLoading ? handleClick : null}
                  >
                    {" "}
                    {isLoading ? "Loading…" : "Search"}
                  </Button>
                </Col>
                <Col className="col-6 p-0">
                  <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center mx-md-5 mx-lg-0">
                    <FormControlLabel
                      className="m-0 text-nowrap"
                      control={
                        <Switch
                          onChange={(e) =>
                            setGroupItems(e.currentTarget.checked)
                          }
                          name="gilad"
                        />
                      }
                      // label={translate("resources.root.groupItems")}
                      label="Group Items"
                    />
                  </InputGroup>
                </Col>
              </Grid>
              {/* Date */}
              <Grid item className="col-12 col-lg-8">
                <Row>
                  <Col className="col-12 col-md-6 mb-3">
                    <Form.Group controlId="startDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2 text-nowrap">
                          {translate("resources.root.startDate")} :
                        </Form.Label>
                        <Form.Control
                          className=""
                          label="startDate"
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="col-12 col-md-6 mb-3">
                    <Form.Group controlId="endDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2  text-nowrap">
                          {translate("resources.root.endDate")} :
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Grid>
            </Grid>
          </Row>
          <Row className={`mt-2`}>
            <Col>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.name")}</th>
                    <th>{translate("resources.root.barcode")}</th>
                    {!groupItems && <th>{translate("resources.root.date")}</th>}
                    <th>{translate("resources.root.qty")}</th>
                    <th>{translate("resources.root.price")}</th>
                    <th>{translate("resources.root.totalPrice")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr>
                          <td>{one.name}</td>
                          <td>{one.barcode}</td>
                          {!groupItems && (
                            <td>{moment(one.date).format("YYYY-MM-DD")}</td>
                          )}
                          <td>{one.qty}</td>
                          {one.byMain ? (
                            <td>{`$${one.mcAveragePrice?.toLocaleString()}`}</td>
                          ) : (
                            <td>{`${one.scAveragePrice?.toLocaleString()} IQD`}</td>
                          )}
                          {one.byMain ? (
                            <td>{`$${one.mcTotalPrice?.toLocaleString()}`}</td>
                          ) : (
                            <td>{`${one.scTotalPrice?.toLocaleString()} IQD`}</td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};

export default OrdersReport;
