import sherko from "./sherko.jpg"
import techeye from "./techeye.png"
import bahez from "./bahez.jfif"
import hbm from "./hbm.png"
import pirmamStreet from "./pirmam-street.png"

let logo;
switch (process.env.REACT_APP_APP_NAME) {
    case "Techeye":
        logo = techeye
        break;
    case "Sherko":
        logo = sherko
        break;
    case "Bahez":
        logo = bahez
        break;
    case "HBM":
        logo = hbm
        break;
    case "PirmamStreet":
        logo = pirmamStreet
        break;
    default:
        logo = techeye
        break;
}
const logos = {logo}
export default logos
