import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import { HTTP } from '../../axios';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import CashierChart from './CashierChart';

import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";

Chart.register(...registerables);
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
display: block;
margin: 10px auto;
border-color: red;
`;

const ExpenseChart = () => {
    const pluck = (arr, key) => arr.map(o => o[key]);
    const [labels, setLabels] = useState([]);
    const [dataset, setDataset] = useState([]);
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        HTTP.get('Dashboard/Charts?invoiceType=expense&startDate=2020-03-13&endDate=2022-03-13&ret=false')
            .then((response) => {
                setLabels(
                    pluck(response.data.map(({date}) => {
                        return { date: moment(date).format("YYYY-MM-DD") }
                    }), "date"),
                )
                setDataset(
                    [
                        {
                            label: "Expenses",
                            data: pluck(response.data, "scPrice"),
                            borderColor: "#2DCE90",
                            backgroundColor: "#eee",
                        }
                    ]
                )
            }).catch((err) => {
            console.log(err);
        })
            .finally(() => setLoading(false));
    }, []);

    return (
        <div className='row'>
            <div className="col-lg-6 col-sm-6">
                <div className='expenseChart bgWhite radius shadow' style={{ padding: "20px" }}>
                    {
                        loading &&
                        <HashLoader color="#488EF9" loading={loading} css={override} size={55} />
                    }
                    {
                        !loading &&
                        <Line options={{ responsive: true }} data={{ labels: labels, datasets: dataset }} type={`line`} />
                    }
                </div>
            </div>
            <CashierChart />
        </div>
    );
}

export default ExpenseChart;