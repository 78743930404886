import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  useNotify,
  FormDataConsumer,
  DateInput,
  Filter,
  FunctionField,
  BooleanField,
  BooleanInput,
  Show,
  DateField,
  Edit,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  NumberInput,
  ImageField,
  ImageInput,
  EditButton,
} from "react-admin";
import Button from "@mui/material/Button";
import { path } from "../../request";

const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn />
    <DateInput label="Start Date" source="startDate" alwaysOn />
    <DateInput label="End Date" source="endDate" alwaysOn />
  </Filter>
);

export const ToDoList = (props) => {
  return (
    <List
      filters={<SearchFilter />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid rowClick="show">
        <TextField source="title" />
        <TextField source="description" />
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
        <BooleanField source="completed" />
        {/* <FunctionField
          label=""
          render={(record) =>
            !record.completed && (
              <EditButton
                label="Complete"
                onClick={() => window.open(`/${props.id}`, "_self")}
              />
            )
          }
        /> */}
        <EditButton label="Complete" />
      </Datagrid>
    </List>
  );
};

export const ToDoShow = (props) => {
  return (
    <div>
      <Show>
        <SimpleShowLayout>
          <FunctionField
            label="Price"
            render={(record) =>
              record.byMain ? record.mcPrice : record.scPrice
            }
          />
          <BooleanField source="byMain" label="Using Dollars" />
          <TextField source="accountant" />
          <TextField source="withdrawer" />
          <TextField source="note" />
          <DateField
            source="date"
            label="resources.root.date"
            locales={"en-GB"}
          />
          <FunctionField
            label="Attachment"
            render={(record) => (
              <img
                style={{
                  width: 200,
                  height: 200,
                  objectFit: "scale-down",
                  cursor: "pointer",
                }}
                src={`${path}${record.attachment}`}
                alt="Attachment"
                onClick={() =>
                  window.open(`${path}${record.attachment}`, "_blank")
                }
              />
            )}
          />
          {/* <ImageField
          label="attachment"
          source="attachment"
          //   src={(record) => `${path}${record.attachment}`}
          title="Attachment"
        /> */}
        </SimpleShowLayout>
      </Show>
      <Button
        onClick={() => {
          // var content = document.getElementById(`rowNumber${key+1}`);
          var pri = document.getElementById(`ifmcontentstoprint`).contentWindow;
          pri.document.open();
          pri.document.write(document.getElementById(`cachFrame`).innerHTML);
          pri.document.write("<hr />");
          pri.document.write(document.getElementById(`cachFrame`).innerHTML);
          // pri.document.write(content.innerHTML);
          pri.document.close();
          pri.focus();
          pri.print();
        }}
      >
        Print
      </Button>
    </div>
  );
};

export const ToDoCreate = (props) => {
  const [currency, setCurrency] = React.useState(true);
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const notify = useNotify();
  const onFailure = (error) => {
    if (error.status === 410) {
      notify("Please Check Your Settings", { type: "warning", undoable: true });
      window.open("#/Settings/create", "_self");
    } else {
      notify("ra.notification.http_error", { type: "warning", undoable: true });
    }
  };
  return (
    <Create
      onFailure={onFailure}
      {...props}
      transform={(data) => ({
        ...data,
        accountant: localStorage.getItem("name"),
      })}
      redirect="list"
    >
      <SimpleForm>
        <TextInput source="title" />
        <BooleanInput
          source="byMain"
          onChange={() => setSeparatedNumber(0)}
          defaultValue={true}
          label={currency ? "Dollar" : "Dinar"}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            setCurrency(formData.byMain);
            return formData.byMain ? (
              <NumberInput
                source="mcPrice"
                label="Price"
                onChange={(e) =>
                  setSeparatedNumber(parseFloat(e.target.value) || 0)
                }
                helperText={
                  separatedNumber > 0
                    ? `$${separatedNumber?.toLocaleString()}`
                    : ""
                }
              />
            ) : (
              <NumberInput
                source="scPrice"
                label="Price"
                onChange={(e) =>
                  setSeparatedNumber(parseFloat(e.target.value) || 0)
                }
                helperText={
                  separatedNumber > 0
                    ? `${separatedNumber?.toLocaleString()} IQD`
                    : ""
                }
              />
            );
          }}
        </FormDataConsumer>
        <ReferenceInput
          label="Withdraw Type"
          source="withdrawTypeId"
          reference="WithdrawTypes"
        >
          <SelectInput optionText="label" />
        </ReferenceInput>
        <TextInput source="withdrawer" />
        <DateInput source="date" defaultValue={new Date()} />
        <TextInput source="note" options={{ multiLine: true }} />
        <ImageInput source="attachment" label="Attachment" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export const ToDoEdit = (props) => {
  return (
    <Edit
      {...props}
      transform={(data) => ({
        ...data,
        completedAt: new Date(),
        completed: true,
      })}
      redirect="list"
    >
      <SimpleForm>
        <TextInput source="completeNote" multiline />
      </SimpleForm>
    </Edit>
  );
};
