import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  required,
  DateField,
  Show,
  DateInput,
  NumberInput,
  SimpleFormIterator,
  ArrayInput,
  useDataProvider,
  SimpleShowLayout,
  FunctionField,
  ArrayField,
  AutocompleteInput,
  useTranslate,
  ReferenceInput,
  NumberField,
} from "react-admin";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { Col, Row } from "react-bootstrap";
import { ListActions } from "../templates/ListActions";
import moment from "moment";

export const ProcessInvoicesList = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const translate = useTranslate();
  return (
    <List
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions setCurrency={setCurrency} currency={currency} />}
    >
      <Datagrid
        bulkActionButtons={
          <BulkAction {...props} hasPrintOrders={"ProcessInvoice"} />
        }
        rowClick="show"
      >
        <NumberField source="id" label="resources.root.id" />
        <FunctionField
          sortBy={"totalProcessQte"}
          label="resources.root.producedQuantity"
          render={(record) =>
            `${
              record.totalProcessQte && record.totalProcessQte?.toLocaleString()
            } + ${
              record.totalProcessSmallQte &&
              record.totalProcessSmallQte?.toLocaleString()
            }`
          }
        />
        <FunctionField
          sortBy={"totalProducedQte"}
          label="resources.root.producedQte"
          render={(record) =>
            `${
              record.totalProducedQte &&
              record.totalProducedQte?.toLocaleString()
            } + ${
              record.totalProducedSmallQte &&
              record.totalProducedSmallQte?.toLocaleString()
            }`
          }
        />
        {currency === "default" && (
          <FunctionField
            sortBy={"mcTotalProcessPrice"}
            label="resources.root.totalProcessPrice"
            render={(record) =>
              record.purchaseByMain
                ? `$${
                    record.mcTotalProcessPrice &&
                    record.mcTotalProcessPrice?.toLocaleString()
                  }`
                : `${
                    record.scTotalProcessPrice &&
                    record.scTotalProcessPrice?.toLocaleString()
                  } ${translate("resources.root.iqd")}`
            }
          />
        )}
        {currency === "default" && (
          <FunctionField
            sortBy={"mcTotalProducedPrice"}
            label="resources.root.totalProducedPrice"
            render={(record) =>
              record.purchaseByMain
                ? `$${
                    record.mcTotalProducedPrice &&
                    record.mcTotalProducedPrice?.toLocaleString()
                  }`
                : `${
                    record.scTotalProducedPrice &&
                    record.scTotalProducedPrice?.toLocaleString()
                  } ${translate("resources.root.iqd")}`
            }
          />
        )}
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
        <TextField source="note" label="resources.root.note" />
      </Datagrid>
    </List>
  );
};

export const ProcessInvoicesShow = (props) => {
  const translate = useTranslate();
  return (
    <Show>
      <SimpleShowLayout>
        <FunctionField
          label="resources.root.producedQuantity"
          render={(record) =>
            `${
              record.totalProcessQte && record.totalProcessQte?.toLocaleString()
            } + ${
              record.totalProcessSmallQte &&
              record.totalProcessSmallQte?.toLocaleString()
            }`
          }
        />
        <FunctionField
          label="resources.root.producedQte"
          render={(record) =>
            `${
              record.totalProducedQte &&
              record.totalProducedQte?.toLocaleString()
            } + ${
              record.totalProducedSmallQte &&
              record.totalProducedSmallQte?.toLocaleString()
            }`
          }
        />
        <FunctionField
          label="resources.root.totalProcessPrice"
          render={(record) =>
            `${
              record.scTotalProcessPrice &&
              record.scTotalProcessPrice?.toLocaleString()
            } ${translate("resources.root.iqd")}`
          }
        />
        <FunctionField
          label="resources.root.totalProducedPrice"
          render={(record) =>
            `${
              record.scTotalProducedPrice &&
              record.scTotalProducedPrice?.toLocaleString()
            } ${translate("resources.root.iqd")}`
          }
        />
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
        <TextField source="note" label="resources.root.note" />
        <Row>
          <Col xs={6}>
            <h4>{translate("resources.root.processOrders")}</h4>
            <ArrayField source="processOrders">
              <Datagrid>
                <FunctionField
                  label="resources.root.product"
                  render={(record) => {
                    return (
                      <a href={`#Products/${record.product.id}/show`}>
                        {record.product.name}
                      </a>
                    );
                  }}
                />
                <FunctionField
                  label="resources.root.barcode"
                  render={(record) => record.product.barcode}
                />
                <TextField
                  source="qte"
                  label="resources.root.processQuantity"
                />
              </Datagrid>
            </ArrayField>
          </Col>
          <Col xs={6}>
            <h4>{translate("resources.root.produceOrders")}</h4>
            <ArrayField source="produceOrders">
              <Datagrid>
                <FunctionField
                  label="resources.root.product"
                  render={(record) => {
                    return (
                      <a href={`#Products/${record.product.id}/show`}>
                        {record.product.name}
                      </a>
                    );
                  }}
                />
                <FunctionField
                  label="resources.root.barcode"
                  render={(record) => record.product.barcode}
                />
                <TextField
                  source="qte"
                  label="resources.root.producedQuantity"
                />
              </Datagrid>
            </ArrayField>
          </Col>
        </Row>
      </SimpleShowLayout>
    </Show>
  );
};

export const ProcessInvoicesCreate = (props) => {
  const [choices, setChoices] = React.useState([]);
  const dataProvider = useDataProvider();
  React.useEffect(() => {
    const res = dataProvider
      .getList("Products", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "id", order: "DESC" },
        filter: {},
      })
      .then((response) => setChoices(response.data));
  }, []);

  const optionRenderer = (choice) => `${choice.name} ~ ${choice.qteInStock}`;
  return (
    <Create
      {...props}
      transform={(data) => ({
        ...data,
        date: data.date + " " + moment().format("hh:mm:ss a"),
      })}
      redirect="list"
    >
      <SimpleForm>
        {/*<Datetime*/}
        {/*    initialValue={date}*/}
        {/*    defaultShow={true}*/}
        {/*    dateFormat="YYYY-MM-DD"*/}
        {/*    timeFormat="HH:mm:ss"*/}
        {/*    onChange={(e) => {*/}
        {/*      setDate(e.format('YYYY-MM-DD HH:mm:ss'));*/}
        {/*    }}*/}
        {/*/>*/}
        <DateInput
          source="date"
          label="resources.root.date"
          defaultValue={moment(new Date()).format("YYYY-MM-DD")}
        />
        <TextInput
          source="note"
          label="resources.root.note"
          options={{ multiLine: true }}
        />
        <Row style={{ width: "100%" }}>
          <Col xs={6}>
            <ArrayInput
              source="processOrders"
              label={"resources.root.processOrders"}
            >
              <SimpleFormIterator>
                <ReferenceInput
                  label="resources.root.products"
                  source="productId"
                  reference="Products"
                  perPage={20}
                >
                  <AutocompleteInput
                    optionText={optionRenderer}
                    validate={[required()]}
                    filterToQuery={(searchText) => ({ search: searchText })}
                  />
                </ReferenceInput>
                <NumberInput source="qte" label="resources.root.quantity" />
                <NumberInput
                  source="smallMeasureQte"
                  label="resources.root.smallMeasureQty"
                  defaultValue={0}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Col>
          <Col xs={6}>
            <ArrayInput
              source="produceOrders"
              label={"resources.root.produceOrders"}
            >
              <SimpleFormIterator>
                <ReferenceInput
                  label="resources.root.products"
                  source="productId"
                  reference="Products"
                  perPage={20}
                >
                  <AutocompleteInput
                    optionText={optionRenderer}
                    validate={[required()]}
                    filterToQuery={(searchText) => ({ search: searchText })}
                  />
                </ReferenceInput>
                <NumberInput source="qte" label="resources.root.quantity" />
                <NumberInput
                  source="smallMeasureQte"
                  label="resources.root.smallMeasureQty"
                  defaultValue={0}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Col>
        </Row>
      </SimpleForm>
    </Create>
  );
};
