import * as React from "react";
import { useNotify, useTranslate } from "react-admin";
import { url } from "../../request";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import { BiRefresh } from "react-icons/bi";
import { FaSave } from "react-icons/fa";
import { HTTP } from "../../axios";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Datetime from "react-datetime";

export const ProductsCreate = (props) => {
  const [productCategories, setProductCategories] = React.useState([]);
  const [productBrands, setProductBrands] = React.useState([]);
  const [stores, setStores] = React.useState([]);
  const [category, setCategory] = React.useState(
    parseInt(localStorage.getItem("defaultCategory"))
  );
  const [brand, setBrand] = React.useState(null);
  const [store, setStore] = React.useState(0);
  const [barcode, setBarcode] = React.useState(null);
  const [name, setName] = React.useState(null);
  const [preferredName, setPreferredName] = React.useState(null);
  const [shortDescription, setShortDescription] = React.useState(null);
  const [longDescription, setLongDescription] = React.useState(null);
  const [productMeasure, setProductMeasure] = React.useState(
    localStorage.getItem("productMeasure") ?? "Kg"
  );
  const [weightInKg, setWeightInKg] = React.useState(
    localStorage.getItem("productWeightInKg") ?? 1
  );
  const [oneContains, setOneContains] = React.useState(
    localStorage.getItem("productOneContains") ?? 1
  );
  const [oneMeasure, setOneMeasure] = React.useState(
    localStorage.getItem("productOneMeasure") ?? "Kg"
  );
  const [note, setNote] = React.useState(null);
  const [profitRatio, setProfitRatio] = React.useState(null);
  const [purchasePrice, setPurchasePrice] = React.useState(0);
  const [sellPrice, setSellPrice] = React.useState(0);
  const [smallMeasureSellPrice, setSmallMeasureSellPrice] = React.useState(0);
  const [alertQty, setAlertQty] = React.useState(0);
  const [initialQty, setInitialQty] = React.useState(0);
  const [productionDate, setProductionDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [expireDate, setExpireDate] = React.useState(
    moment().add(6, "months").format("YYYY-MM-DD")
  );
  const [sellByUsd, setSellByUsd] = React.useState(
    localStorage.getItem("productSellByUSD") === "true" || false
  );
  const [purchaseByUsd, setPurchaseByUsd] = React.useState(
    localStorage.getItem("productPurchaseByUSD") === "true" || false
  );
  const [canSellBelowZero, setCanSellBelowZero] = React.useState(false);
  const [barcode1, setBarcode1] = React.useState(null);
  const [barcode2, setBarcode2] = React.useState(null);
  const [barcode3, setBarcode3] = React.useState(null);
  const [barcode4, setBarcode4] = React.useState(null);
  const [barcode5, setBarcode5] = React.useState(null);
  const [barcode6, setBarcode6] = React.useState(null);
  const [barcode7, setBarcode7] = React.useState(null);
  const [barcode8, setBarcode8] = React.useState(null);
  const [barcode9, setBarcode9] = React.useState(null);
  const [dollarPrice, setDollarPrice] = React.useState(
    localStorage.getItem("dollarPrice") ?? 1485
  );

  const notify = useNotify();
  const translate = useTranslate();

  function handleCreate(redirect = false) {
    document.getElementById("disableOnClick").disabled = true;
    document.getElementById("disableOnClick2").disabled = true;
    HTTP.post(`${url}/Products`, {
      productCategoryId: category,
      brandId: brand,
      barcode: barcode,
      name: name,
      preferName: preferredName,
      shortDescription: shortDescription,
      longDescription: longDescription,
      productMeasure: productMeasure,
      weightInKg: weightInKg,
      oneContains: oneContains,
      oneMeasure: oneMeasure,
      note: note,
      barcode1: barcode1,
      barcode2: barcode2,
      barcode3: barcode3,
      barcode4: barcode4,
      barcode5: barcode5,
      barcode6: barcode6,
      barcode7: barcode7,
      barcode8: barcode8,
      barcode9: barcode9,
      storeProducts: [
        {
          storeId: store,
          mcPurchasePrice: purchasePrice,
          scPurchasePrice: purchasePrice,
          purchaseByMain: purchaseByUsd,
          mcSellPrice: sellPrice,
          scSellPrice: sellPrice,
          mcSmallMeasureSellPrice: smallMeasureSellPrice,
          scSmallMeasureSellPrice: smallMeasureSellPrice,
          sellByMain: sellByUsd,
          initialQte: initialQty,
          alertQte: alertQty,
          productionDate: productionDate,
          expireDate: expireDate,
          canSellBellowZero: canSellBelowZero,
          profitRate: profitRatio / 100,
        },
      ],
    })
      .then((response) => {
        if (redirect) {
          window.location.href = "#/Products";
        }
        window.location.reload(true);
      })
      .catch((err) => {
        document.getElementById("disableOnClick").disabled = false;
        document.getElementById("disableOnClick2").disabled = false;
        notify(err.response.data.message, { type: "error", undoable: true });
      });
  }

  React.useEffect(function () {
    HTTP.get(`${url}/ProductCategories?_end=10000`)
      .then((response) => {
        let newArray = [];
        response.data.map((category) => {
          newArray.push({
            value: category.id,
            label: category.name,
            category: category,
          });
        });
        setProductCategories(newArray);
      })
      .catch((err) =>
        notify(err.response.data.message, { type: "error", undoable: true })
      );

    HTTP.get(`${url}/Brands?_end=10000`)
      .then((response) => {
        let newArray = [];
        response.data.map((brand) => {
          newArray.push({
            value: brand.id,
            label: brand.name,
            brand: brand,
          });
        });
        setProductBrands(newArray);
      })
      .catch((err) =>
        notify(err.response.data.message, { type: "error", undoable: true })
      );

    HTTP.get(`${url}/Stores?_end=10000`)
      .then((response) => {
        let newArray = [];
        response.data.map((store) => {
          newArray.push({
            value: store.id,
            label: store.title,
            store: store,
          });
        });
        setStores(newArray);
      })
      .catch((err) =>
        notify(err.response.data.message, { type: "error", undoable: true })
      );
  }, []);

  React.useEffect(
    function () {
      if (
        profitRatio !== 0 &&
        profitRatio !== null &&
        profitRatio !== undefined &&
        profitRatio !== ""
      ) {
        let addSellPrice = 0;
        if ((purchaseByUsd && sellByUsd) || (!purchaseByUsd && !sellByUsd)) {
          addSellPrice =
            (parseFloat(profitRatio) / 100) * parseFloat(purchasePrice) +
            parseFloat(purchasePrice);
        } else if (!purchaseByUsd && sellByUsd) {
          addSellPrice =
            ((purchasePrice / dollarPrice) * profitRatio) / 100 +
            parseFloat(purchasePrice / dollarPrice);
        } else if (purchaseByUsd && !sellByUsd) {
          addSellPrice =
            parseFloat(purchasePrice * profitRatio * dollarPrice) / 100 +
            parseFloat(purchasePrice * dollarPrice);
        }
        setSellPrice(addSellPrice);
        setSmallMeasureSellPrice((addSellPrice / oneContains).toFixed(3));
      }
    },
    [profitRatio, purchasePrice, purchaseByUsd, sellByUsd]
  );

  React.useEffect(function () {
    console.log(productionDate);
  }, []);

  return (
    <div className={`p-4`}>
      <Row>
        <Col xs={4}>
          <Form.Group as={Row} controlId="barcode">
            <Form.Label column sm={3}>
              {translate("resources.root.barcode")}
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                type="text"
                onChange={(e) => setBarcode(e.target.value)}
                placeholder={translate("resources.root.barcode")}
                value={barcode}
              />
            </Col>
            <Col xs={2}>
              <Button
                variant={"outline-primary"}
                onClick={() => {
                  HTTP.get("/Products/GenerateNewBarcode")
                    .then((response) => {
                      setBarcode(response.data);
                    })
                    .catch((err) =>
                      notify(err.response.data.message, {
                        type: "error",
                        undoable: true,
                      })
                    );
                }}
              >
                <BiRefresh />{" "}
              </Button>
            </Col>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group as={Row} controlId="name">
            <Form.Label column sm={3}>
              {process.env.REACT_APP_APP_NAME === "Mediply"
                ? translate("resources.root.scientificName")
                : translate("resources.root.name")}
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                onChange={(e) => {
                  setName(e.target.value);
                  setPreferredName(e.target.value);
                }}
                placeholder={
                  process.env.REACT_APP_APP_NAME === "Mediply"
                    ? translate("resources.root.scientificName")
                    : translate("resources.root.name")
                }
                value={name}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group as={Row} controlId="preferedName">
            <Form.Label column sm={3}>
              {process.env.REACT_APP_APP_NAME === "Mediply"
                ? translate("resources.root.BusinessName")
                : translate("resources.root.preferedName")}
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                onChange={(e) => setPreferredName(e.target.value)}
                placeholder={
                  process.env.REACT_APP_APP_NAME === "Mediply"
                    ? translate("resources.root.BusinessName")
                    : translate("resources.root.preferedName")
                }
                value={preferredName}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={1}>
          <label>{translate("resources.root.category")}</label>
        </Col>
        <Col xs={3}>
          <Select
            name="colors"
            isClearable
            isSearchable
            className="basic-multi-select"
            classNamePrefix="select"
            value={productCategories.find((x) => x.value === category)}
            options={productCategories}
            onChange={(e) => {
              setCategory(e ? e.value : 0);
            }}
          />
        </Col>
        <Col xs={1}>
          <label>{translate("resources.root.brand")}</label>
        </Col>
        <Col xs={3}>
          <Select
            name="colors"
            isClearable
            isSearchable
            className="basic-multi-select"
            classNamePrefix="select"
            value={productBrands.find((x) => x.value === brand)}
            options={productBrands}
            onChange={(e) => {
              setBrand(e ? e.value : 0);
            }}
          />
        </Col>
        <Col xs={4}>
          <Form.Group as={Row} controlId="shortDescription">
            <Form.Label column sm={3}>
              {translate("resources.root.shortDescription")}
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                onChange={(e) => setShortDescription(e.target.value)}
                placeholder={translate("resources.root.shortDescription")}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group as={Row} controlId="longDescription">
            <Form.Label column sm={3}>
              {translate("resources.root.longDescription")}
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                onChange={(e) => setLongDescription(e.target.value)}
                placeholder={translate("resources.root.longDescription")}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group as={Row} controlId="productMeasure">
            <Form.Label column sm={3}>
              {translate("resources.root.productMeasure")}
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                defaultValue={productMeasure}
                onChange={(e) => setProductMeasure(e.target.value)}
                placeholder={translate("resources.root.productMeasure")}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group as={Row} controlId="weightInKg">
            <Form.Label column sm={3}>
              {translate("resources.root.weightInKg")}
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                defaultValue={weightInKg}
                onChange={(e) => setWeightInKg(e.target.value)}
                placeholder={translate("resources.root.weightInKg")}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group as={Row} controlId="oneContains">
            <Form.Label column sm={3}>
              {translate("resources.root.oneContains")}
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                defaultValue={oneContains}
                onChange={(e) => {
                  setOneContains(e.target.value);
                  setSmallMeasureSellPrice(
                    (sellPrice / e.target.value).toFixed(3)
                  );
                }}
                placeholder={translate("resources.root.oneContains")}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group as={Row} controlId="oneMeasure">
            <Form.Label column sm={3}>
              {translate("resources.root.oneMeasure")}
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                defaultValue={oneMeasure}
                onChange={(e) => setOneMeasure(e.target.value)}
                placeholder={translate("resources.root.oneMeasure")}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group as={Row} controlId="note">
            <Form.Label column sm={3}>
              {translate("resources.root.note")}
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                onChange={(e) => setNote(e.target.value)}
                placeholder={translate("resources.root.note")}
              />
            </Col>
          </Form.Group>
        </Col>

        {/* <Col xs={4}>
          <Form.Group as={Row} controlId="profitRatio">
            <Form.Label column sm={3}>
              {translate("resources.root.profitRatio")}
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="number"
                maxLength={2}
                max={100}
                min={0}
                onChange={(e) => setProfitRatio(e.target.value)}
                placeholder={translate("resources.root.profitRatio")}
              />
            </Col>
          </Form.Group>
        </Col> */}

        {(localStorage.getItem("role").toLowerCase() === "superadmin" ||
          localStorage.getItem("role").toLowerCase() === "admin" ||
          localStorage.getItem("role").toLowerCase() === "branchadmin") && (
          <Col xs={8}>
            <Row>
              <Col xs={2}>
                <label>{translate("resources.root.stores")}</label>
              </Col>
              <Col xs={10}>
                <Select
                  name="colors"
                  isClearable
                  isSearchable
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={stores}
                  onChange={(e) => {
                    setStore(e ? e.value : 0);
                  }}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <hr />
      <Row>
        {process.env.REACT_APP_APP_NAME !== "Mediply" && (
          <Col xs={4}>
            <Form.Group as={Row} controlId="purchasePrice">
              <Form.Label column sm={3}>
                {translate("resources.root.purchasePrice")}
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="number"
                  min={0}
                  onChange={(e) => setPurchasePrice(e.target.value)}
                  placeholder={translate("resources.root.purchasePrice")}
                />
              </Col>
            </Form.Group>
          </Col>
        )}
        <Col xs={4}>
          <Form.Group as={Row} controlId="sellPrice">
            <Form.Label column sm={3}>
              {translate("resources.root.sellPrice")}
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="number"
                min={0}
                onChange={(e) => {
                  setSellPrice(e.target.value);
                  setSmallMeasureSellPrice(
                    (e.target.value / oneContains).toFixed(3)
                  );
                }}
                value={sellPrice}
                placeholder={translate("resources.root.sellPrice")}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group as={Row} controlId="smallMeasureSellPrice">
            <Form.Label column sm={3}>
              {translate("resources.root.smallMeasureSellPrice")}
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="number"
                min={0}
                onChange={(e) => setSmallMeasureSellPrice(e.target.value)}
                placeholder={translate("resources.root.smallMeasureSellPrice")}
                value={smallMeasureSellPrice}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group as={Row} controlId="alertQty">
            <Form.Label column sm={3}>
              {translate("resources.root.alertQty")}
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="number"
                min={0}
                onChange={(e) => setAlertQty(e.target.value)}
                placeholder={translate("resources.root.alertQty")}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group as={Row} controlId="initialQty">
            <Form.Label column sm={3}>
              {translate("resources.root.initialQty")}
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="number"
                min={0}
                onChange={(e) => setInitialQty(e.target.value)}
                placeholder={translate("resources.root.initialQty")}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group as={Row} controlId="productionDate">
            <Form.Label column sm={3}>
              {translate("resources.root.productionDate")}
            </Form.Label>
            <Col sm={9}>
              <Datetime
                initialValue={productionDate}
                defaultShow={true}
                dateFormat="DD-MM-YYYY"
                timeFormat={false}
                placeholder={translate("resources.root.productionDate")}
                onChange={(e) => setProductionDate(e)}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group as={Row} controlId="expireDate">
            <Form.Label column sm={3}>
              {translate("resources.root.expireDate")}
            </Form.Label>
            <Col sm={9}>
              <Datetime
                initialValue={expireDate}
                defaultShow={true}
                dateFormat="DD-MM-YYYY"
                timeFormat={false}
                placeholder={translate("resources.root.expireDate")}
                onChange={(e) => setExpireDate(e)}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <div className="form-check">
            <input
              id={"purchaseByUsd"}
              type="checkbox"
              onChange={(e) => setPurchaseByUsd(e.target.checked)}
              defaultChecked={purchaseByUsd}
            />
            <label className="form-check-label mb-3" htmlFor="purchaseByUsd">
              {translate("resources.root.purchaseByUsd")}
            </label>
          </div>
        </Col>
        <Col xs={4}>
          <div className="form-check">
            <input
              id={"sellByUsd"}
              type="checkbox"
              onChange={(e) => setSellByUsd(e.target.checked)}
              defaultChecked={sellByUsd}
            />
            <label className="form-check-label mb-3" htmlFor="sellByUsd">
              {translate("resources.root.sellByUsd")}
            </label>
          </div>
        </Col>
        <Col xs={4}>
          <br />
          <div className="form-check">
            <input
              id={"canSellBelowZero"}
              type="checkbox"
              onChange={(e) => setCanSellBelowZero(e.target.checked)}
            />
            <label className="form-check-label mb-3" htmlFor="canSellBelowZero">
              {translate("resources.root.canSellBelowZero")}
            </label>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontWeight: "bold" }}>
                {translate("resources.root.barcodes")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Row>
                <Col xs={4}>
                  <Form.Group as={Row} controlId="barcode1">
                    <Form.Label column sm={3}>
                      {translate("resources.root.barcode1")}
                    </Form.Label>
                    <Col sm={7}>
                      <Form.Control
                        type="text"
                        onChange={(e) => setBarcode1(e.target.value)}
                        placeholder={translate("resources.root.barcode")}
                        value={barcode1}
                      />
                    </Col>
                    <Col xs={2}>
                      <Button
                        variant={"outline-primary"}
                        onClick={() => {
                          HTTP.get("/Products/GenerateNewBarcode")
                            .then((response) => {
                              setBarcode1(response.data);
                            })
                            .catch((err) =>
                              notify(err.response.data.message, {
                                type: "error",
                                undoable: true,
                              })
                            );
                        }}
                      >
                        <BiRefresh />{" "}
                      </Button>
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={4}>
                  <Form.Group as={Row} controlId="barcode1">
                    <Form.Label column sm={3}>
                      {translate("resources.root.barcode2")}
                    </Form.Label>
                    <Col sm={7}>
                      <Form.Control
                        type="text"
                        onChange={(e) => setBarcode2(e.target.value)}
                        placeholder={translate("resources.root.barcode")}
                        value={barcode2}
                      />
                    </Col>
                    <Col xs={2}>
                      <Button
                        variant={"outline-primary"}
                        onClick={() => {
                          HTTP.get("/Products/GenerateNewBarcode")
                            .then((response) => {
                              setBarcode2(response.data);
                            })
                            .catch((err) =>
                              notify(err.response.data.message, {
                                type: "error",
                                undoable: true,
                              })
                            );
                        }}
                      >
                        <BiRefresh />{" "}
                      </Button>
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={4}>
                  <Form.Group as={Row} controlId="barcode1">
                    <Form.Label column sm={3}>
                      {translate("resources.root.barcode3")}
                    </Form.Label>
                    <Col sm={7}>
                      <Form.Control
                        type="text"
                        onChange={(e) => setBarcode3(e.target.value)}
                        placeholder={translate("resources.root.barcode")}
                        value={barcode3}
                      />
                    </Col>
                    <Col xs={2}>
                      <Button
                        variant={"outline-primary"}
                        onClick={() => {
                          HTTP.get("/Products/GenerateNewBarcode")
                            .then((response) => {
                              setBarcode3(response.data);
                            })
                            .catch((err) =>
                              notify(err.response.data.message, {
                                type: "error",
                                undoable: true,
                              })
                            );
                        }}
                      >
                        <BiRefresh />{" "}
                      </Button>
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={4}>
                  <Form.Group as={Row} controlId="barcode1">
                    <Form.Label column sm={3}>
                      {translate("resources.root.barcode4")}
                    </Form.Label>
                    <Col sm={7}>
                      <Form.Control
                        type="text"
                        onChange={(e) => setBarcode4(e.target.value)}
                        placeholder={translate("resources.root.barcode")}
                        value={barcode4}
                      />
                    </Col>
                    <Col xs={2}>
                      <Button
                        variant={"outline-primary"}
                        onClick={() => {
                          HTTP.get("/Products/GenerateNewBarcode")
                            .then((response) => {
                              setBarcode4(response.data);
                            })
                            .catch((err) =>
                              notify(err.response.data.message, {
                                type: "error",
                                undoable: true,
                              })
                            );
                        }}
                      >
                        <BiRefresh />{" "}
                      </Button>
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={4}>
                  <Form.Group as={Row} controlId="barcode1">
                    <Form.Label column sm={3}>
                      {translate("resources.root.barcode5")}
                    </Form.Label>
                    <Col sm={7}>
                      <Form.Control
                        type="text"
                        onChange={(e) => setBarcode5(e.target.value)}
                        placeholder={translate("resources.root.barcode")}
                        value={barcode5}
                      />
                    </Col>
                    <Col xs={2}>
                      <Button
                        variant={"outline-primary"}
                        onClick={() => {
                          HTTP.get("/Products/GenerateNewBarcode")
                            .then((response) => {
                              setBarcode5(response.data);
                            })
                            .catch((err) =>
                              notify(err.response.data.message, {
                                type: "error",
                                undoable: true,
                              })
                            );
                        }}
                      >
                        <BiRefresh />{" "}
                      </Button>
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={4}>
                  <Form.Group as={Row} controlId="barcode1">
                    <Form.Label column sm={3}>
                      {translate("resources.root.barcode6")}
                    </Form.Label>
                    <Col sm={7}>
                      <Form.Control
                        type="text"
                        onChange={(e) => setBarcode6(e.target.value)}
                        placeholder={translate("resources.root.barcode")}
                        value={barcode6}
                      />
                    </Col>
                    <Col xs={2}>
                      <Button
                        variant={"outline-primary"}
                        onClick={() => {
                          HTTP.get("/Products/GenerateNewBarcode")
                            .then((response) => {
                              setBarcode6(response.data);
                            })
                            .catch((err) =>
                              notify(err.response.data.message, {
                                type: "error",
                                undoable: true,
                              })
                            );
                        }}
                      >
                        <BiRefresh />{" "}
                      </Button>
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={4}>
                  <Form.Group as={Row} controlId="barcode1">
                    <Form.Label column sm={3}>
                      {translate("resources.root.barcode7")}
                    </Form.Label>
                    <Col sm={7}>
                      <Form.Control
                        type="text"
                        onChange={(e) => setBarcode7(e.target.value)}
                        placeholder={translate("resources.root.barcode")}
                        value={barcode7}
                      />
                    </Col>
                    <Col xs={2}>
                      <Button
                        variant={"outline-primary"}
                        onClick={() => {
                          HTTP.get("/Products/GenerateNewBarcode")
                            .then((response) => {
                              setBarcode7(response.data);
                            })
                            .catch((err) =>
                              notify(err.response.data.message, {
                                type: "error",
                                undoable: true,
                              })
                            );
                        }}
                      >
                        <BiRefresh />{" "}
                      </Button>
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={4}>
                  <Form.Group as={Row} controlId="barcode1">
                    <Form.Label column sm={3}>
                      {translate("resources.root.barcode8")}
                    </Form.Label>
                    <Col sm={7}>
                      <Form.Control
                        type="text"
                        onChange={(e) => setBarcode8(e.target.value)}
                        placeholder={translate("resources.root.barcode")}
                        value={barcode8}
                      />
                    </Col>
                    <Col xs={2}>
                      <Button
                        variant={"outline-primary"}
                        onClick={() => {
                          HTTP.get("/Products/GenerateNewBarcode")
                            .then((response) => {
                              setBarcode8(response.data);
                            })
                            .catch((err) =>
                              notify(err.response.data.message, {
                                type: "error",
                                undoable: true,
                              })
                            );
                        }}
                      >
                        <BiRefresh />{" "}
                      </Button>
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={4}>
                  <Form.Group as={Row} controlId="barcode1">
                    <Form.Label column sm={3}>
                      {translate("resources.root.barcode9")}
                    </Form.Label>
                    <Col sm={7}>
                      <Form.Control
                        type="text"
                        onChange={(e) => setBarcode9(e.target.value)}
                        placeholder={translate("resources.root.barcode")}
                        value={barcode9}
                      />
                    </Col>
                    <Col xs={2}>
                      <Button
                        variant={"outline-primary"}
                        onClick={() => {
                          HTTP.get("/Products/GenerateNewBarcode")
                            .then((response) => {
                              setBarcode9(response.data);
                            })
                            .catch((err) =>
                              notify(err.response.data.message, {
                                type: "error",
                                undoable: true,
                              })
                            );
                        }}
                      >
                        <BiRefresh />{" "}
                      </Button>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </AccordionDetails>
          </Accordion>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs={3}> </Col>
        <Col xs={6}>
          <Button
            id={`disableOnClick`}
            block
            variant={"outline-primary"}
            onClick={() => handleCreate()}
            size={"lg"}
          >
            <FaSave /> {translate("resources.root.create")}
          </Button>
          <Button
            id={`disableOnClick2`}
            block
            variant={"outline-primary"}
            onClick={() => handleCreate(true)}
            size={"lg"}
          >
            <FaSave /> {translate("resources.root.createAndGoToList")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
