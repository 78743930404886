import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";

import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
import DataProvider from "../../dataProvider";
import { HTTP } from "../../axios";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 10px auto;
  border-color: red;
`;

export const getList = async (
  resource,
  perPage = 50,
  filters = {},
  sort = "id",
  order = "DESC"
) => {
  const { data } = await DataProvider.getList(resource, {
    pagination: { page: 1, perPage: perPage },
    sort: { field: sort, order: order },
    filter: filters,
  });
  return data;
};

const PurchaseTable = () => {
  let [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    HTTP.get("PurchaseInvoices")
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="col-lg-12 col-sm-12">
      {loading && (
        <HashLoader
          color="#488EF9"
          loading={loading}
          css={override}
          size={55}
        />
      )}
      {!loading && (
        <div>
          <div className="card mb-4 shadow-lg tableCard">
            <div
              className="card-header pl-3 pt-3 pb-1"
              style={{ background: "none" }}
            >
              <h3>
                <b>Purchase Table</b>
              </h3>
            </div>
            <div className="card-body px-0 pt-0 pb-2">
              <div className="table-responsive p-0">
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        ID
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Supplier Name
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Owner
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Price
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Total Quantity
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Final Quantity
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Date
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Cash
                      </th>
                      <th className="text-secondary opacity-7"></th>
                    </tr>
                  </thead>
                  <tbody style={{ borderTop: "none", textAlign: "center" }}>
                    {data &&
                      data.map((item, index) => (
                        <tr key={index}>
                          <td>{item.id}</td>
                          <td>{item.supplier.supplierName}</td>
                          <td>{item.supplier.owner}</td>
                          <td>
                            {item.payByMain
                              ? item.mcTotalPrice?.toLocaleString() + " $"
                              : item.scTotalPrice?.toLocaleString() + " IQD"}
                          </td>
                          <td>{item.totalQte}</td>
                          <td>{item.totalGiftQte}</td>
                          <td>
                            {moment(item.date).format("YYYY-MM-DD HH:mm:ss A")}
                          </td>
                          <td>
                            {item.isCash ? (
                              <span className="badge badge-sm bg-gradient-success">
                                Active
                              </span>
                            ) : (
                              <span className="badge badge-sm bg-gradient-danger">
                                Disactive
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchaseTable;
