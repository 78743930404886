import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  minValue,
  required,
  BooleanField,
  DateField,
  SimpleShowLayout,
  NumberInput,
  BooleanInput,
  DateInput,
  Show,
  useTranslate,
  number,
} from "react-admin";
import moment from "moment";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";

export const CurrencyExchangesList = (props) => {
  const translate = useTranslate();
  return (
    <List
      filters={<SearchFilter date={true} />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={<BulkAction {...props} />} rowClick="show">
        <TextField
          source="mcPrice"
          label={translate("resources.root.mcPrice")}
        />
        <TextField
          source="scPrice"
          label={translate("resources.root.scPrice")}
        />
        <DateField
          source="date"
          label={translate("resources.root.date")}
          locales={"en-GB"}
        />
        <BooleanField
          source="fromMcToSc"
          label={translate("resources.root.fromMcToSc")}
        />
      </Datagrid>
    </List>
  );
};

export const CurrencyExchangesShow = (props) => {
  const translate = useTranslate();
  return (
    <Show>
      <SimpleShowLayout
        filters={<SearchFilter />}
        {...props}
        sort={{ field: "id", order: "DESC" }}
      >
        <TextField
          source="mcPrice"
          label={translate("resources.root.mcPrice")}
        />
        <TextField
          source="scPrice"
          label={translate("resources.root.scPrice")}
        />
        <BooleanField
          source="fromMcToSc"
          label={translate("resources.root.fromMcToSc")}
        />
        <DateField
          source="date"
          label={translate("resources.root.date")}
          locales={"en-GB"}
        />
        <TextField source="note" label={translate("resources.root.note")} />
      </SimpleShowLayout>
    </Show>
  );
};

export const CurrencyExchangesCreate = (props) => {
  const [currency, setCurrency] = React.useState(true);
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const [separatedNumberDollar, setSeparatedNumberDollar] = React.useState(
    localStorage.getItem("dollarPrice")
  );
  const translate = useTranslate();
  return (
    <Create {...props}>
      <SimpleForm>
        {currency ? (
          <NumberInput
            validate={[required(), number(), minValue(0.01)]}
            defaultValue={0}
            min={0}
            source="mcPrice"
            label="resources.root.price"
            onChange={(e) =>
              setSeparatedNumber(parseFloat(e.target.value) || 0)
            }
            helperText={
              separatedNumber > 0
                ? `$${separatedNumber?.toLocaleString()} = ${(
                    separatedNumber * localStorage.getItem("dollarPrice")
                  )?.toLocaleString()} IQD`
                : ""
            }
          />
        ) : (
          <NumberInput
            validate={[required(), number(), minValue(0.01)]}
            defaultValue={0}
            min={0}
            source="scPrice"
            label="resources.root.price"
            onChange={(e) =>
              setSeparatedNumber(parseFloat(e.target.value) || 0)
            }
            helperText={
              separatedNumber > 0
                ? `${separatedNumber?.toLocaleString()} IQD = $${Math.round(
                    separatedNumber / localStorage.getItem("dollarPrice")
                  )?.toLocaleString()}`
                : ""
            }
          />
        )}
        <NumberInput
          validate={[required(), number(), minValue(0.01)]}
          min={0}
          source="mcToScPrice"
          label="resources.root.dollarPrice"
          defaultValue={localStorage.getItem("dollarPrice")}
          onChange={(e) =>
            setSeparatedNumberDollar(parseFloat(e.target.value) || 0)
          }
          helperText={
            separatedNumberDollar > 0
              ? `1$ = ${separatedNumberDollar?.toLocaleString()} IQD`
              : ""
          }
        />
        <BooleanInput
          source="fromMcToSc"
          label={
            currency
              ? translate("resources.root.usdToIqd")
              : translate("resources.root.iqdToUsd")
          }
          defaultValue={true}
          onChange={(e) => setCurrency(e.target.checked)}
        />
        <DateInput
          source="date"
          defaultValue={moment().format("YYYY/MM/DD")}
          label={translate("resources.root.date")}
        />
        <TextInput
          source="note"
          options={{ multiLine: true }}
          label={translate("resources.root.note")}
        />
      </SimpleForm>
    </Create>
  );
};
