import React from "react";
import PrintProvider, { Print } from "react-easy-print";
import Page from "./SellReceipt/Page";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { useResourceDefinition, useTranslate } from "react-admin";
import { HTTP } from "../../axios";
import { url } from "../../request";
import swal from "sweetalert";
import { Col, Row, Table, Form } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";

export const PrintInvoiceOrders = (props) => {
  const params = useParams();
  const queryString = require("query-string");
  const type = params.type;
  const { search } = useLocation();
  const translate = useTranslate();
  const classes = useStyles();
  const [products, setProducts] = React.useState([]);
  const [totalQty, setTotalQty] = React.useState(0);
  const [totalSmallQty, setTotalSmallQty] = React.useState(0);
  const [mcTotalPrice, setMcTotalPrice] = React.useState(0);
  const [scTotalPrice, setScTotalPrice] = React.useState(0);
  const [mcTotalDiscount, setMcTotalDiscount] = React.useState(0);
  const [scTotalDiscount, setScTotalDiscount] = React.useState(0);
  const [groupItems, setGroupItems] = React.useState(true);
  const [showDate, setShowDate] = React.useState(false);
  const [showTotalPrice, setShowTotalPrice] = React.useState(false);
  const [showHeaders, setShowHeaders] = React.useState(true);
  const [showInvoiceId, setShowInvoiceId] = React.useState(false);

  React.useEffect(
    function () {
      let ids = queryString
        .parse(search, { arrayFormat: "bracket" })
        ["ids"].map((x) => parseInt(x));
      HTTP.post(
        `${url}/Utilities/GetBatchOrders?_end=1000&groupProducts=${groupItems}&Type=${type}`,
        ids
      )
        .then((res) => {
          setProducts(res.data);
          setTotalQty(res.headers["totalqty"]);
          setTotalSmallQty(res.headers["totalsmallqty"]);

          setMcTotalPrice(res.headers["mctotalprice"]);
          setScTotalPrice(res.headers["sctotalprice"]);
          setMcTotalDiscount(res.headers["mcdiscount"]);
          setScTotalDiscount(res.headers["scdiscount"]);
        })
        .catch((err) => {
          if (err) {
            swal("Oh noes!", "The AJAX request failed!", "error");
          } else {
            swal.stopLoading();
            swal.close();
          }
        })
        .finally(() => {
          let sidebar = document.getElementsByClassName("MuiDrawer-root");
          let appMenu = document.getElementsByClassName("MuiPaper-root");
          let appBar = document.getElementsByClassName("theRoot");
          let sidebar2 = document.getElementsByClassName("RaSidebar-fixed");
          // let layOut2 = document.getElementsByClassName("RaLayout-appFrame-2");
          sidebar[0].style.display = "none";
          appMenu[0].style.display = "none";
          appBar[0].style.marginTop = "0px";
          sidebar2[0].style.display = "none";
          // layOut2[0].style.marginTop = "0px";
          // window.print();
          // window.close();
        });
    },
    [groupItems, showDate]
  );

  return (
    <PrintProvider>
      <Row>
        <Col xs={2}>
          <Button
            variant="contained"
            className={classes.botton}
            onClick={() => {
              let sidebar = document.getElementsByClassName("MuiDrawer-root");
              let appMenu = document.getElementsByClassName("MuiPaper-root");
              let appBar = document.getElementsByClassName("theRoot");
              let sidebar2 = document.getElementsByClassName("RaSidebar-fixed");
              // let layOut2 = document.getElementsByClassName("RaLayout-appFrame-2");
              sidebar[0].style.display = "none";
              appMenu[0].style.display = "none";
              appBar[0].style.marginTop = "0px";
              sidebar2[0].style.display = "none";
              // layOut2[0].style.marginTop = "0px";
              window.print();
            }}
          >
            {translate("resources.root.print")}
          </Button>
        </Col>
        <Col xs={2}>
          <Form>
            <Form.Check
              inline
              type="switch"
              id="group-switch"
              label="Group Items"
              defaultChecked={true}
              onChange={(e) => setGroupItems(e.currentTarget.checked)}
            />
          </Form>
        </Col>

        <Col xs={2}>
          <Form>
            <Form.Check
              inline
              type="switch"
              id="date-switch"
              label="Show Date"
              onChange={(e) => setShowDate(e.currentTarget.checked)}
            />
          </Form>
        </Col>
        <Col xs={2}>
          <Form>
            <Form.Check
              inline
              type="switch"
              id="total-switch"
              label="Show Total"
              defaultChecked={false}
              onChange={(e) => setShowTotalPrice(e.currentTarget.checked)}
            />
          </Form>
        </Col>
        <Col xs={2}>
          <Form>
            <Form.Check
              inline
              type="switch"
              id="header-switch"
              label="Show Header"
              defaultChecked={true}
              onChange={(e) => setShowHeaders(e.currentTarget.checked)}
            />
          </Form>
        </Col>
        <Col xs={2}>
          <Form>
            <Form.Check
              inline
              type="switch"
              id="invoice-switch"
              label="Show Invoice"
              defaultChecked={false}
              onChange={(e) => setShowInvoiceId(e.currentTarget.checked)}
            />
          </Form>
        </Col>
      </Row>
      <Print single={true} name="foo">
        <div className={classes.printWrapper}>
          <Row>
            <Col xs={12}>
              <p>
                {translate("resources.root.date")}:{" "}
                {new Date().toISOString().slice(0, 10)}
              </p>
            </Col>
            {showHeaders && (
              <Col xs={4} className={"text-center"}>
                <p>
                  {translate("resources.root.totalQty")}:{" "}
                  {totalQty?.toLocaleString()} {" + "}
                  {totalSmallQty?.toLocaleString()}
                </p>
              </Col>
            )}
            {showHeaders && (
              <Col xs={4} className={"text-center"}>
                <p>
                  {translate("resources.root.totalPrice")}:{" "}
                  {products && products[0] && products[0].byMain
                    ? parseFloat(mcTotalPrice)?.toLocaleString() +
                      " " +
                      translate("resources.root.usd")
                    : parseFloat(scTotalPrice)?.toLocaleString() +
                      " " +
                      translate("resources.root.iqd")}
                </p>
              </Col>
            )}
            {showHeaders && (
              <Col xs={4} className={"text-center"}>
                <p>
                  {translate("resources.root.discount")}:{" "}
                  {products && products[0] && products[0].byMain
                    ? mcTotalDiscount?.toLocaleString() +
                      " " +
                      translate("resources.root.usd")
                    : scTotalDiscount?.toLocaleString() +
                      " " +
                      translate("resources.root.iqd")}
                </p>
              </Col>
            )}
            <Col xs={12}>
              {process.env.REACT_APP_APP_NAME === "Bahez" ? (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      {showInvoiceId && (
                        <th>{translate("resources.root.id")}</th>
                      )}
                      <th>{translate("resources.root.name")}</th>
                      <th>{translate("resources.root.barcode")}</th>
                      {showDate && <th>{translate("resources.root.date")}</th>}
                      <th>{translate("resources.root.quantity")}</th>
                      {type === "StoreInventoryInvoice" && (
                        <th>{translate("resources.root.newQty")}</th>
                      )}
                      {type !== "StoreInventoryInvoice" && (
                        <th>{translate("resources.root.price")}</th>
                      )}
                      {/*{type === 'StoreInventoryInvoice' && <th>{translate('resources.root.newSmallMeasureQty')}</th>}*/}
                      {/* {type !== "StoreInventoryInvoice" && (
                        <th>{translate("resources.root.giftQuantity")}</th>
                      )} */}
                      {type !== "StoreInventoryInvoice" && (
                        <th>{translate("resources.root.driver")}</th>
                      )}
                      {showTotalPrice && (
                        <th>{translate("resources.root.total")}</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {products &&
                      products.map((one, index) => {
                        return (
                          <tr>
                            <td>{++index}</td>
                            {showInvoiceId && <td>{one.invoiceId}</td>}
                            <td>{one.name}</td>
                            <td>{one.barcode}</td>
                            {showDate && (
                              <td>{moment(one.date).format("YYYY-MM-DD")}</td>
                            )}
                            <td>
                              {one.qty} + {one.smallMeasureQty}
                            </td>
                            {type === "StoreInventoryInvoice" && (
                              <td>{one.newQty}</td>
                            )}
                            {type !== "StoreInventoryInvoice" && (
                              <td>
                                {one.byMain
                                  ? one.mcAveragePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd")
                                  : one.scAveragePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd")}
                              </td>
                            )}
                            {/*{type === 'StoreInventoryInvoice' && <td>{one.newSmallQty}</td>}*/}
                            {/* {type !== "StoreInventoryInvoice" && (
                              <td>{one.giftQty}</td>
                            )} */}
                            {type !== "StoreInventoryInvoice" && (
                              <td>{one.driver}</td>
                            )}
                            {showTotalPrice && (
                              <td>
                                {one.byMain
                                  ? one.mcTotalPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd")
                                  : one.scTotalPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd")}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              ) : (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      {showInvoiceId && (
                        <th>{translate("resources.root.id")}</th>
                      )}
                      <th>{translate("resources.root.name")}</th>
                      <th>{translate("resources.root.barcode")}</th>
                      {showDate && <th>{translate("resources.root.date")}</th>}
                      <th>{translate("resources.root.quantity")}</th>
                      {type === "StoreInventoryInvoice" && (
                        <th>{translate("resources.root.newQty")}</th>
                      )}
                      {/* 5 */}
                      {type !== "StoreInventoryInvoice" && (
                        <th>{translate("resources.root.price")}</th>
                      )}
                      {type !== "StoreInventoryInvoice" && (
                        <th>{translate("resources.root.sellPrice")}</th>
                      )}
                      {/*{type === 'StoreInventoryInvoice' && <th>{translate('resources.root.newSmallMeasureQty')}</th>}*/}

                      {type !== "StoreInventoryInvoice" && (
                        <th>{translate("resources.root.totalPrice")}</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {products &&
                      products.map((one, index) => {
                        return (
                          <tr>
                            <td>{++index}</td>
                            {showInvoiceId && <td>{one.invoiceId}</td>}
                            <td>{one.name}</td>
                            <td>{one.barcode}</td>
                            {showDate && (
                              <td>{moment(one.date).format("YYYY-MM-DD")}</td>
                            )}
                            <td>
                              {one.qty} + {one.smallMeasureQty}
                            </td>
                            {type === "StoreInventoryInvoice" && (
                              <td>{one.newQty}</td>
                            )}
                            {/* 5 */}
                            {type !== "StoreInventoryInvoice" && (
                              <td>
                                {one.byMain
                                  ? one.mcActualPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd")
                                  : one.scActualPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd")}
                              </td>
                            )}
                            {type !== "StoreInventoryInvoice" && (
                              <td>
                                {one.byMain
                                  ? one.mcAveragePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd")
                                  : one.scAveragePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd")}
                              </td>
                            )}
                            {/*{type === 'StoreInventoryInvoice' && <td>{one.newSmallQty}</td>}*/}

                            {type !== "StoreInventoryInvoice" && (
                              <td>
                                {one.byMain
                                  ? one.mcTotalPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd")
                                  : one.scTotalPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd")}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
        </div>
      </Print>
    </PrintProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  addressInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  discount: {
    width: "10% !important",
    marginLeft: 5,
    marginTop: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  tableContainer: {
    marginTop: 20,
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  printWrapper: {
    width: "100%",
    height: "950",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
}));
