import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ImageInput,
  ImageField,
  FunctionField,
  DateField,
} from "react-admin";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { path } from "../../request";

export const UploadCentersList = (props) => (
  <List
    filters={<SearchFilter />}
    pagination={<MyCustomPagination />}
    {...props}
    sort={{ field: "id", order: "DESC" }}
  >
    <Datagrid bulkActionButtons={<BulkAction {...props} />}>
      <TextField source="id" label="resources.root.id"  />
      <FunctionField
        label={`resources.root.image`}
        render={(record) => (
          <img
            style={{
              width: 75,
              height: 75,
              objectFit: "scale-down",
              cursor: "pointer",
            }}
            src={
              record.attachment !== null
                ? `${path}${record.attachment}`
                : `https://via.placeholder.com/75`
            }
            alt={record.name}
            onClick={() =>
              window.open(
                record.attachment !== null
                  ? `${path}${record.attachment}`
                  : `https://via.placeholder.com/75`,
                "_blank"
              )
            }
          />
        )}
      />
      <TextField source="title" label="resources.root.title" />
      <TextField source="type" label="resources.root.type" />
      <TextField source="note" label="resources.root.note" />
      <DateField source="createdAt" label="resources.root.date" />
    </Datagrid>
  </List>
);

export const UploadCentersCreate = (props) => (
  <Create {...props} redirect="list">
    <SimpleForm>
      <TextInput source="title" label="resources.root.title" />
      <TextInput source="type" label="resources.root.type" />
      <TextInput
        source="note"
        label="resources.root.note"
        options={{ multiLine: true }}
      />
      <ImageInput
        source="attachment"
        label="resources.root.attachment"
        accept="image/*"
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export const UploadCentersEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="label" label="resources.root.label" />
      <TextInput
        source="note"
        label="resources.root.note"
        options={{ multiLine: true }}
      />
    </SimpleForm>
  </Edit>
);
