import * as React from "react";
import { CardContent } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  useNotify,
  useTranslate,
  useResourceDefinition,
} from "react-admin";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";
import { Button, Col, Form, Row } from "react-bootstrap";
import Datetime from "react-datetime";
import moment from "moment";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import { useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { InputGroup } from "react-bootstrap";
import { ListItem } from "@mui/material/ListItem";
const _ = require("lodash");

export const UserNamesList = (props) => (
  <List
    filters={<SearchFilter />}
    pagination={<MyCustomPagination />}
    {...props}
    sort={{ field: "id", order: "DESC" }}
  >
    <Datagrid bulkActionButtons={<BulkAction {...props} />} rowClick={"show"}>
      <TextField source="userName" label="resources.root.username" />
      <TextField source="password" label="resources.root.password" />
      <FunctionField
        label="resources.root.name"
        render={(record) =>
          `${record.firstName} ${record.middleName} ${record.lastName}`
        }
      />
      <TextField source="userType" label="resources.root.usertype" />
    </Datagrid>
  </List>
);

export const UserNamesShow = () => {
  const props = useResourceDefinition();
  const { id } = useParams();
  const translate = useTranslate();
  const notify = useNotify();

  const [name, setName] = React.useState([]);
  const [permissions, setPermissions] = React.useState([]);
  const [activities, setActivities] = React.useState([]);
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  React.useEffect(function () {
    HTTP.get(`/Accounts/GetClaims?userId=${id}`)
      .then((response) => {
        setName(
          response.headers["firstname"] + " " + response.headers["middlename"]
        );
        setPermissions([
          {
            name: translate("resources.root.canDiscountByPercentage"),
            value: "DiscountByPercentage",
            setValue: response.data.DiscountByPercentage === "true",
            type: "bool",
            cat: "discount",
          },
          {
            name: translate("resources.root.maxPercentageDiscount"),
            value: "MaxPercentageDiscount",
            setValue:
              response.data.MaxPercentageDiscount !== 0
                ? response.data.MaxPercentageDiscount
                : 0,
            type: "text",
            cat: "discount",
          },
          {
            name: translate("resources.root.canDiscountByAmount"),
            value: "DiscountByAmount",
            setValue: response.data.DiscountByAmount === "true",
            type: "bool",
            cat: "discount",
          },
          {
            name: translate("resources.root.maxDiscountAmount"),
            value: "MaxDiscountAmount",
            setValue:
              response.data.MaxDiscountAmount !== 0
                ? response.data.MaxDiscountAmount
                : 0,
            type: "text",
            cat: "discount",
          },
          {
            name: translate("resources.root.viewHisExpense"),
            value: "ViewHisExpense",
            setValue: response.data.ViewHisExpense === "true",
            type: "bool",
            cat: "accountant",
          },
          {
            name: translate("resources.root.deleteHisExpense"),
            value: "DeleteHisExpense",
            setValue: response.data.DeleteHisExpense === "true",
            type: "bool",
            cat: "accountant",
          },
          {
            name: translate("resources.root.canOpenDrawer"),
            value: "OpenDrawer",
            setValue: response.data.OpenDrawer === "true",
            type: "bool",
            cat: "accountant",
          },
          {
            name: translate("resources.root.canReprintLastInvoice"),
            value: "ReprintLastInvoice",
            setValue: response.data.ReprintLastInvoice === "true",
            type: "bool",
            cat: "invoice",
          },
          {
            name: translate("resources.root.canSeeOldSellInvoices"),
            value: "SeeOldSellInvoice",
            setValue: response.data.SeeOldSellInvoice === "true",
            type: "bool",
            cat: "invoice",
          },
          {
            name: translate("resources.root.canSeeOldReturnInvoices"),
            value: "SeeOldReturnInvoice",
            setValue: response.data.SeeOldReturnInvoice === "true",
            type: "bool",
            cat: "invoice",
          },
          {
            name: translate("resources.root.canSeeCashBox"),
            value: "SeeCashBox",
            setValue: response.data.SeeCashBox === "true",
            type: "bool",
            cat: "accountant",
          },

          {
            name: translate("resources.root.canPrintInvoiceWithoutSubmit"),
            value: "PrintWithoutSubmit",
            setValue: response.data.PrintWithoutSubmit === "true",
            type: "bool",
            cat: "invoice",
          },
          {
            name: translate("resources.root.canSellByLoan"),
            value: "SellByLoan",
            setValue: response.data.SellByLoan === "true",
            type: "bool",
            cat: "sell",
          },
          {
            name: translate("resources.root.restrictOpeningAccount"),
            value: "RestrictOpeningAccount",
            setValue: response.data.RestrictOpeningAccount === "true",
            type: "bool",
            cat: "accountant",
          },
          {
            name: translate("resources.root.openingAccountTime"),
            value: "OpeningAccountTime",
            setValue:
              moment(response.data.OpeningAccountTime).format(
                "YYYY-MM-DD HH:mm:ss"
              ) ?? moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            type: "time",
            cat: "accountant",
          },
          {
            name: translate("resources.root.restrictClosingAccount"),
            value: "RestrictClosingAccount",
            setValue: response.data.RestrictClosingAccount === "true",
            type: "bool",
            cat: "accountant",
          },
          {
            name: translate("resources.root.closingAccountTime"),
            value: "ClosingAccountTime",
            setValue:
              moment(response.data.ClosingAccountTime).format(
                "YYYY-MM-DD HH:mm:ss"
              ) ?? moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            type: "time",
            cat: "accountant",
          },
          {
            name: translate("resources.root.canSeeOthersInvoices"),
            value: "SeeOthersInvoices",
            setValue: response.data.SeeOthersInvoices === "true",
            type: "bool",
            cat: "invoice",
          },
          {
            name: translate("resources.root.emptyCashBox"),
            value: "EmptyCashBox",
            setValue: response.data.EmptyCashBox === "true",
            type: "bool",
            cat: "accountant",
          },
          {
            name: translate("resources.root.canAddExpense"),
            value: "AddExpense",
            setValue: response.data.AddExpense === "true",
            type: "bool",
            cat: "accountant",
          },
          {
            name: translate("resources.root.canReturnInvoice"),
            value: "Return",
            setValue: response.data.Return === "true",
            type: "bool",
            cat: "invoice",
          },
          {
            name: translate("resources.root.canReturnWithoutInvoice"),
            value: "ReturnWithoutInvoice",
            setValue: response.data.ReturnWithoutInvoice === "true",
            type: "bool",
            cat: "invoice",
          },
          {
            name: translate("resources.root.canDiscountOrderByPercentage"),
            value: "OrderDiscountByPercentage",
            setValue: response.data.OrderDiscountByPercentage === "true",
            type: "bool",
            cat: "discount",
          },
          {
            name: translate("resources.root.maxPercentageOrderDiscount"),
            value: "OrderMaxPercentageDiscount",
            setValue:
              response.data.OrderMaxPercentageDiscount !== 0
                ? response.data.OrderMaxPercentageDiscount
                : 0,
            type: "text",
            cat: "discount",
          },
          {
            name: translate("resources.root.canDiscountOrderByAmount"),
            value: "OrderDiscountByAmount",
            setValue: response.data.OrderDiscountByAmount === "true",
            type: "bool",
            cat: "discount",
          },
          {
            name: translate("resources.root.maxDiscountOrderAmount"),
            value: "OrderMaxDiscountAmount",
            setValue:
              response.data.OrderMaxDiscountAmount !== 0
                ? response.data.OrderMaxDiscountAmount
                : 0,
            type: "text",
            cat: "discount",
          },
          {
            name: translate("resources.root.canGiveGift"),
            value: "GiveGift",
            setValue: response.data.GiveGift === "true",
            type: "bool",
            cat: "sell",
          },
          {
            name: translate("resources.root.maxGiftCount"),
            value: "MaxGiftCount",
            setValue:
              response.data.MaxGiftCount !== 0 ? response.data.MaxGiftCount : 0,
            type: "text",
            cat: "sell",
          },
          {
            name: translate("resources.root.maxGiftCountPerOrder"),
            value: "MaxGiftPerOrderCount",
            setValue:
              response.data.MaxGiftPerOrderCount !== 0
                ? response.data.MaxGiftPerOrderCount
                : 0,
            type: "text",
            cat: "sell",
          },
          {
            name: translate("resources.root.canChangeSellPrice"),
            value: "ChangeSellPrice",
            setValue: response.data.ChangeSellPrice === "true",
            type: "bool",
            cat: "sell",
          },
          {
            name: translate("resources.root.canChangePurchasePrice"),
            value: "ChangePurchasePrice",
            setValue: response.data.ChangePurchasePrice === "true",
            type: "bool",
            cat: "purchase",
          },
          {
            name: translate("resources.root.cantSellByPurchasePrice"),
            value: "CantSellByPurchasePrice",
            setValue: response.data.CantSellByPurchasePrice === "true",
            type: "bool",
            cat: "purchase",
          },
          {
            name: translate("resources.root.cantSellLessThanPurchasePrice"),
            value: "CantSellLessThanPurchasePrice",
            setValue: response.data.CantSellLessThanPurchasePrice === "true",
            type: "bool",
            cat: "purchase",
          },
          {
            name: translate("resources.root.deleteHisSellInvoice"),
            value: "DeleteHisSellInvoice",
            setValue: response.data.DeleteHisSellInvoice === "true",
            type: "bool",
            cat: "sell",
          },
          {
            name: translate("resources.root.deleteHisSellReturnInvoice"),
            value: "DeleteHisSellReturnInvoice",
            setValue: response.data.DeleteHisSellReturnInvoice === "true",
            type: "bool",
            cat: "sell",
          },
          {
            name: translate("resources.root.canWithdrawForHimSelf"),
            value: "CanWithdrawForHimSelf",
            setValue: response.data.CanWithdrawForHimSelf === "true",
            type: "bool",
            cat: "accountant",
          },
          {
            name: translate("resources.root.canReceiveDollar"),
            value: "CanReceiveMainCurrency",
            setValue: response.data.CanReceiveMainCurrency === "true",
            type: "bool",
            cat: "accountant",
          },
          {
            name: translate("resources.root.canOpenAzReport"),
            value: "CanOpenAzReport",
            setValue: response.data.CanOpenAzReport === "true",
            type: "bool",
            cat: "accountant",
          },
          {
            name: translate("resources.root.canSellOffline"),
            value: "CanSellOffline",
            setValue: response.data.CanSellOffline === "true",
            type: "bool",
            cat: "sell",
          },
          {
            name: translate("resources.root.canQuickSellRoundUp"),
            value: "CanQuickSellRoundUp",
            setValue: response.data.CanQuickSellRoundUp === "true",
            type: "bool",
            cat: "sell",
          },
          {
            name: translate("resources.root.canQuickSellRoundDown"),
            value: "CanQuickSellRoundDown",
            setValue: response.data.CanQuickSellRoundDown === "true",
            type: "bool",
            cat: "sell",
          },
          {
            name: translate("resources.root.canQuickSellRoundExact"),
            value: "CanQuickSellRoundExact",
            setValue: response.data.CanQuickSellRoundExact === "true",
            type: "bool",
            cat: "sell",
          },
          {
            name: translate("resources.root.canToggleSellByMain"),
            value: "CanToggleSellByMain",
            setValue: response.data.CanToggleSellByMain === "true",
            type: "bool",
            cat: "sell",
          },
          {
            name: translate("resources.root.disableF6Command"),
            value: "DisableF6Command",
            setValue: response.data.DisableF6Command === "true",
            type: "bool",
            cat: "sell",
          },
          {
            name: translate("resources.root.casherSellInvoiceNameIsRequired"),
            value: "CasherSellInvoiceNameIsRequired",
            setValue: response.data.CasherSellInvoiceNameIsRequired === "true",
            type: "bool",
            cat: "sell",
          },
          {
            name: translate("resources.root.maxLocalInvoiceCount"),
            value: "MaxLocalInvoiceCount",
            setValue:
              response.data.MaxLocalInvoiceCount !== 0
                ? response.data.MaxLocalInvoiceCount
                : 0,
            type: "text",
            cat: "invoice",
          },
          {
            name: translate("resources.root.canEditPosSettings"),
            value: "EditPosSetting",
            setValue: response.data.EditPosSetting === "true",
            type: "bool",
            cat: "others",
          },
        ]);
      })
      .catch((err) =>
        notify(err.response.data.message, { type: "error", undoable: true })
      );
  }, []);

  React.useEffect(
    function () {
      let sendDates = ``;

      if (startDate && startDate.length > 0) {
        sendDates += `&startDate=${startDate}`;
      } else {
        sendDates += `&startDate=${moment().format("YYYY-MM-DD")}`;
      }

      if (endDate && endDate.length > 0) {
        sendDates += `&endDate=${endDate}`;
      } else {
        sendDates += `&endDate=${moment().format("YYYY-MM-DD")}`;
      }

      HTTP.get(`/Reports/GetUserActivities?userId=${id}${sendDates}`)
        .then((response) => {
          setActivities([
            {
              label: translate("resources.root.purchases"),
              dollar: response.data.mcPurchases,
              dinar: response.data.scPurchases,
            },
            {
              label: translate("resources.root.purchaseReturns"),
              dollar: response.data.mcPurchaseReturns,
              dinar: response.data.scPurchaseReturns,
            },
            {
              label: translate("resources.root.supplierPayment"),
              dollar: response.data.mcSupplierPayment,
              dinar: response.data.scSupplierPayment,
            },
            {
              label: translate("resources.root.sells"),
              dollar: response.data.mcSells,
              dinar: response.data.scSells,
            },
            {
              label: translate("resources.root.sellReturns"),
              dollar: response.data.mcSellReturns,
              dinar: response.data.scSellReturns,
            },
            {
              label: translate("resources.root.customerPayment"),
              dollar: response.data.mcCustomerPayment,
              dinar: response.data.scCustomerPayment,
            },
            {
              label: translate("resources.root.quickSells"),
              dollar: response.data.mcQuickSells,
              dinar: response.data.scQuickSells,
            },
            {
              label: translate("resources.root.quickSellReturns"),
              dollar: response.data.mcQuickSellReturns,
              dinar: response.data.scQuickSellReturns,
            },
            {
              label: translate("resources.root.expenses"),
              dollar: response.data.mcExpenses,
              dinar: response.data.scExpenses,
            },
            {
              label: translate("resources.root.deposit"),
              dollar: response.data.mcDeposit,
              dinar: response.data.scDeposit,
            },
            {
              label: translate("resources.root.withdraw"),
              dollar: response.data.mcWithdraw,
              dinar: response.data.scWithdraw,
            },
            {
              label: translate("resources.root.damage"),
              dollar: response.data.mcDamage,
              dinar: response.data.scDamage,
            },
          ]);
        })
        .catch((err) =>
          notify(err.response.data.message, { type: "error", undoable: true })
        );
    },
    [startDate, endDate]
  );

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const permissionsCat = _.chain(permissions)
    .groupBy("cat")
    .map((value, key) => ({ cat: key, permissions: value }))
    .value();

  return (
    <div className="p-2">
      <h1 className="m-2">
        {translate("resources.root.name")}: {name}
      </h1>
      <hr />
      <h2 className="m-2">{translate("resources.root.userActivities")}</h2>

      <Accordion className="my-2" style={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ fontWeight: "bold" }}>
            {translate("resources.root.userDetails")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Row>
            <Col className="col-12 col-md-6 mb-3">
              <Form.Group controlId="startDate">
                <Col className="d-flex align-items-center m-0 p-0">
                  <Form.Label className="m-0 mr-2 text-nowrap">
                    {translate("resources.root.startDate")} :
                  </Form.Label>
                  <Form.Control
                    className=""
                    label="startDate"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col className="col-12 col-md-6 mb-3">
              <Form.Group controlId="endDate">
                <Col className="d-flex align-items-center m-0 p-0">
                  <Form.Label className="m-0 mr-2  text-nowrap">
                    {translate("resources.root.endDate")} :
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {activities &&
              activities.map((activity, index) => {
                return (
                  <Grid item className="col-12 col-md-4 col-lg-3 p-4">
                    <Card style={{ textAlign: "center" }} className="noMargin">
                      <CardContent className="noMargin">
                        <Row className="newSellReturnBox-dashboard">
                          <Col xs={12} className="pt-2 h5 ">
                            {activity.label}
                          </Col>
                          {process.env.REACT_APP_SINGLE_CURRENCY === "true" ? (
                            process.env.REACT_APP_SHOW_MC === "true" ? (
                              <Col
                                className="blured py-3"
                                style={{ fontSize: "1.2rem" }}
                                xs={12}
                              >
                                <Row className="px-4 ">
                                  <Col>
                                    <Typography className="text-nowrap h6 d-flex justify-content-around">
                                      ${activity.dollar?.toLocaleString()}
                                    </Typography>
                                  </Col>
                                </Row>
                              </Col>
                            ) : (
                              <Col
                                className="blured py-3"
                                style={{ fontSize: "1.2rem" }}
                                xs={12}
                              >
                                <Row className="px-4 ">
                                  <Col>
                                    <Typography className="text-nowrap h6">
                                      {activity.dinar?.toLocaleString()} IQD
                                    </Typography>
                                  </Col>
                                </Row>
                              </Col>
                            )
                          ) : (
                            <Col
                              className="blured py-3"
                              style={{ fontSize: "1.2rem" }}
                              xs={12}
                            >
                              <Row className="px-4 ">
                                <Col>
                                  <Typography className="text-nowrap h6 d-flex justify-content-around">
                                    ${activity.dollar?.toLocaleString()}
                                  </Typography>
                                </Col>
                                <Col>
                                  <Typography className="text-nowrap h6">
                                    {activity.dinar?.toLocaleString()} IQD
                                  </Typography>
                                </Col>
                              </Row>
                            </Col>
                          )}
                        </Row>
                      </CardContent>
                    </Card>
                  </Grid>

                  // <Col xs={3}>
                  //   <div className="card">
                  //     <div className="card-body">
                  //       <h5 className="card-title">{activity.label}</h5>
                  //       <p className="card-text">
                  //         ${activity.dollar?.toLocaleString()}
                  //       </p>
                  //       <p className="card-text">
                  //         {activity.dinar?.toLocaleString()} IQD
                  //       </p>
                  //     </div>
                  //   </div>
                  // </Col>
                );
              })}
          </Row>
        </AccordionDetails>
      </Accordion>
      <h2 className="m-2">{translate("resources.root.userPermissions")}</h2>
      <div>
        {permissionsCat.map((Percat, index) => (
          <Accordion
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className="text-uppercase bold m-1">
                {translate(`resources.root.${Percat.cat}`)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Row>
                {Percat.permissions.map((permission, index) => {
                  return permission.type === "bool" ? (
                    <Col xs={12} md={4}>
                      <InputGroup className="row justify-content-start mx-5 my-2 mx-lg-0  ">
                        <FormControlLabel
                          className="m-0 text-nowrap "
                          control={
                            <Switch
                              defaultChecked={permission.setValue}
                              id={permission.value}
                              onChange={(e) => {
                                permission.setValue = e.target.checked;
                              }}
                            />
                          }
                          label={permission.name}
                        />
                      </InputGroup>
                      {/* <div className="form-check">
                      <input
                        id={permission.value}
                        type="checkbox"
                        onChange={(e) => {
                          permission.setValue = e.target.checked;
                        }}
                        defaultChecked={permission.setValue}
                      />
                      {console.log(permission.setValue)}
                      <label
                        className="form-check-label mb-3"
                        htmlFor={permission.value}
                      >
                        {permission.name}
                      </label>
                    </div> */}
                    </Col>
                  ) : permission.type === "time" ? (
                    <Col xs={12} md={4}>
                      <Form.Group
                        controlId={permission.value}
                        className="d-flex align-items-center justify-content-start p-2"
                      >
                        <Form.Label className="text-nowrap m-0 mx-1">
                          {permission.name}:
                        </Form.Label>
                        <Datetime
                          initialValue={moment(permission.setValue).format(
                            "h:mm A"
                          )}
                          defaultShow={true}
                          dateFormat={false}
                          onChange={(e) => {
                            permission.setValue = e.format(
                              "YYYY-MM-DD HH:mm:ss"
                            );
                          }}
                          defaultChecked={permission.setValue}
                        />
                      </Form.Group>
                    </Col>
                  ) : permission.type === "text" ? (
                    <Col xs={12} md={4}>
                      <Form.Group
                        controlId={permission.value}
                        className="d-flex align-items-center justify-content-start p-2"
                      >
                        <Form.Label className="text-nowrap m-0 mx-1">
                          {permission.name}:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={permission.name}
                          onChange={(e) => {
                            permission.setValue = e.target.value;
                          }}
                          defaultValue={permission.setValue}
                          onFocus={(e) => e.target.select()}
                        />
                      </Form.Group>
                    </Col>
                  ) : (
                    ""
                  );
                })}
              </Row>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>

      {/* <div>
        <h4>{translate("resources.root.accountantPermissions")}</h4>
        <hr />
        <Row>
          {permissions.map((permission, index) => {
            return (
              <>
                {permission.cat === "accountant" &&
                  permission.type === "text" && (
                    <Col xs={12} md={4}>
                      <Form.Group
                        controlId={permission.value}
                        className="d-flex align-items-center justify-content-start"
                      >
                        <Form.Label>{permission.name}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={permission.name}
                          onChange={(e) => {
                            permissions[index].setValue = e.target.value;
                          }}
                          defaultValue={permission.setValue}
                          onFocus={(e) => e.target.select()}
                        />
                      </Form.Group>
                    </Col>
                  )}
              </>
            );
          })}
        </Row>
      </div>
      <div>
        <h4>{translate("resources.root.invoicePermissions")}</h4>
        <hr />
        <Row>
          {permissions.map((permission, index) => {
            return (
              <>
                {permission.cat === "invoice" && permission.type === "bool" && (
                  <Col xs={12} md={4}>
                    <InputGroup className="row justify-content-start mx-5 my-2 mx-lg-0  ">
                      <FormControlLabel
                        className="m-0 text-nowrap "
                        control={
                          <Switch
                            onChange={(e) => {
                              permissions[index].setValue = e.target.checked;
                            }}
                            name="gilad"
                          />
                        }
                        label={permission.name}
                      />
                    </InputGroup>
                  </Col>
                )}
              </>
            );
          })}
        </Row>
      </div>
      <div>
        <h4>{translate("resources.root.discountPermissions")}</h4>
        <hr />
        <Row>
          {permissions.map((permission, index) => {
            return (
              <>
                {permission.cat === "discount" &&
                  permission.type === "bool" && (
                    <Col xs={12} md={4}>
                      <InputGroup className="row justify-content-start mx-5 my-2 mx-lg-0  ">
                        <FormControlLabel
                          className="m-0 text-nowrap "
                          control={
                            <Switch
                              onChange={(e) => {
                                permissions[index].setValue = e.target.checked;
                              }}
                              name="gilad"
                            />
                          }
                          label={permission.name}
                        />
                      </InputGroup>
                    </Col>
                  )}
              </>
            );
          })}
        </Row>
      </div>
      <div>
        <h4>{translate("resources.root.purchasePermissions")}</h4>
        <hr />
        <Row>
          {permissions.map((permission, index) => {
            return (
              <>
                {permission.cat === "purchase" &&
                  permission.type === "bool" && (
                    <Col xs={12} md={4}>
                      <InputGroup className="row justify-content-start mx-5 my-2 mx-lg-0  ">
                        <FormControlLabel
                          className="m-0 text-nowrap "
                          control={
                            <Switch
                              onChange={(e) => {
                                permissions[index].setValue = e.target.checked;
                              }}
                              name="gilad"
                            />
                          }
                          label={permission.name}
                        />
                      </InputGroup>
                    </Col>
                  )}
              </>
            );
          })}
        </Row>
      </div> */}

      <Button
        className="my-4"
        onClick={() => {
          permissions.forEach((v) => delete v.name);
          let filtered = permissions;
          let newObj = {};
          filtered.map((x) => {
            newObj[x.value] = x.setValue + "";
          });
          HTTP.post(`/Accounts/ReAddMyClaims?userId=${id}`, newObj)
            .then(() => {
              notify("resources.root.updated", {
                type: "success",
                undoable: true,
                autoHideDuration: 15000,
              });
            })
            .catch((err) =>
              notify(err.response.data.message, {
                type: "error",
                undoable: true,
              })
            );
        }}
      >
        {translate("ra.action.save")}
      </Button>
    </div>
  );
};
