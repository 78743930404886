import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  useTranslate,
  ReferenceField,
  BooleanField,
  useNotify,
  downloadCSV,
  TopToolbar,
  useListContext,
  sanitizeListRestProps,
  ExportButton,
} from "react-admin";
import moment from "moment";
import { url } from "../../request";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import jsonExport from "jsonexport/dist";
import PrintProvider, { NoPrint, Print } from "react-easy-print";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";

export const StoreTransactionHistoriesList = (props) => {
  const [mcTotal, setMcTotal] = React.useState(0);
  const [scTotal, setScTotal] = React.useState(0);

  const translate = useTranslate();
  const notify = useNotify();

  const exporter = (data) => {
    const dataForExport = data.map((data) => {
      let {
        id,
        store,
        amount,
        mcAmount,
        scAmount,
        withdraw,
        lost,
        referenceNumber,
        date,
        time,
        reason,
      } = data;
      let store_name = data.store.title;
      amount = data.byMain
        ? "$" + data.mcAmount?.toLocaleString()
        : data.scAmount?.toLocaleString() + "IQD";
      withdraw = data.lost
        ? translate("resources.root.yes")
        : translate("resources.root.no");
      date = moment(data.date).format("YYYY-MM-DD");
      time = moment(data.date).format("hh:mm:ss A");
      return {
        id,
        store_name,
        amount,
        withdraw,
        referenceNumber,
        date,
        time,
        reason,
      };
    });
    jsonExport(
      dataForExport,
      {
        headers: [
          "id",
          "store_name",
          "amount",
          "withdraw",
          "referenceNumber",
          "date",
          "time",
          "reason",
        ],
        rename: [
          translate("resources.root.id"),
          translate("resources.root.stores"),
          translate("resources.root.amount"),
          translate("resources.root.withdraw"),
          translate("resources.root.refNo"),
          translate("resources.root.date"),
          translate("resources.root.time"),
          translate("resources.root.reason"),
        ],
      },
      (err, csv) => {
        downloadCSV(
          "\ufeff" + csv,
          "store.transactions." + moment(new Date()).format("YYMMDDHHmmssa")
        );
      }
    );
  };

  React.useEffect(() => {
    HTTP.get(`${url}/StoreTransactionHistories`)
      .then((response) => {
        setMcTotal(response.headers.mctotallost);
        setScTotal(response.headers.sctotallost);
      })
      .catch((err) =>
        notify(err.response.data.message, { type: "error", undoable: true })
      );
  }, []);

  const postRowStyle = (record) => ({
    backgroundColor: record.lost ? "tomato" : "#efe",
  });
  const DataActions = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      hasreate,
      selectedIds,
      showFilter,
      total,
    } = useListContext();
    return (
      <NoPrint>
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
          <Button
            onClick={() => {
              let sidebar = document.getElementsByClassName("MuiDrawer-root");
              sidebar[0].style.display = "none";
              window.print();
              sidebar[0].style.display = "block";
            }}
          >
            Print Report
          </Button>
          <ExportButton />
          {filters &&
            React.cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: "button",
            })}
        </TopToolbar>
      </NoPrint>
    );
  };
  return (
    <div>
      <NoPrint>
        <Row>
          <Col>
            <InputGroup className="mb-3">
              <InputGroup.Text>
                {translate("resources.root.totalPrice")}{" "}
                {translate("resources.root.usd")}
              </InputGroup.Text>
              <Form.Control
                value={parseFloat(mcTotal)?.toLocaleString()}
                readOnly
              />
            </InputGroup>
          </Col>
          <Col>
            <InputGroup className="mb-3">
              <InputGroup.Text>
                {translate("resources.root.totalPrice")}{" "}
                {translate("resources.root.iqd")}
              </InputGroup.Text>
              <Form.Control
                value={parseFloat(scTotal)?.toLocaleString()}
                readOnly
              />
            </InputGroup>
          </Col>
        </Row>
      </NoPrint>
      <PrintProvider>
        <Print name="storeTransactionsTable" single={true}>
          <List
            exporter={exporter}
            filters={<SearchFilter date={true} />}
            {...props}
            sort={{ field: "id", order: "DESC" }}
            actions={<DataActions />}
            pagination={<MyCustomPagination />}
          >
            <Datagrid
              bulkActionButtons={<BulkAction {...props} />}
              rowStyle={postRowStyle}
            >
              <TextField source="id" />
              <ReferenceField
                label={translate("ra.navigation.Stores")}
                source="storeId"
                reference="Stores"
                link={"show"}
              >
                <FunctionField
                  label="resources.root.title"
                  render={(record) => `${record.title}`}
                />
              </ReferenceField>
              <FunctionField
                label="resources.root.amount"
                sortBy={"mcAmount"}
                render={(record) => {
                  return record.byMain
                    ? `${record.mcAmount?.toLocaleString()} ${translate(
                        "resources.root.usd"
                      )}`
                    : `${record.scAmount?.toLocaleString()} ${translate(
                        "resources.root.iqd"
                      )}`;
                }}
              />
              <BooleanField label="resources.root.withdraw" source="lost" />
              <TextField
                source="referenceNumber"
                label={`resources.root.refNo`}
              />
              <FunctionField
                source="date"
                sortBy={"date"}
                label="resources.root.date"
                render={(record) => moment(record.date).format("YYYY-MM-DD")}
              />
              <FunctionField
                source="date"
                label="resources.root.time"
                render={(record) => moment(record.date).format("hh:mm:ss A")}
              />
              <TextField source="reason" label="resources.root.reason" />
            </Datagrid>
          </List>
        </Print>
      </PrintProvider>
    </div>
  );
};
