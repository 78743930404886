import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  Edit,
  FormDataConsumer,
  DateInput,
  FunctionField,
  BooleanField,
  AutocompleteInput,
  Show,
  DateField,
  SimpleShowLayout,
  useDataProvider,
  required,
  ReferenceField,
  NumberInput,
  number,
  minValue,
  useTranslate,
  ImageInput,
  ImageField,
  useRecordContext,
  EditButton,
  useResourceDefinition,
} from "react-admin";
import Button from "@mui/material/Button";
import { url } from "../../request";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { ListActions } from "../templates/ListActions";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import Page from "../CustomPages/VoucherReceipt/Page";
import moment from "moment";
import { HTTP } from "../../axios";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { path } from "../../request";
import { Col, Form } from "react-bootstrap";

import Datetime from "react-datetime";

export const SupplierPaymentsList = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const translate = useTranslate();

  return (
    <List
      filters={<SearchFilter date={true} />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions setCurrency={setCurrency} currency={currency} />}
    >
      <Datagrid bulkActionButtons={<BulkAction {...props} />} rowClick={"show"}>
        <TextField source="id" label="resources.root.id" />
        <FunctionField
          label="resources.root.name"
          render={(record) =>
            record.supplier && `${record.supplier.supplierName}`
          }
        />
        {currency === "default" && (
          <FunctionField
            sortBy={"scPrice"}
            label="resources.root.price"
            render={(record) =>
              record.byMain
                ? `$${record.mcPrice?.toLocaleString()}`
                : `${record.scPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            sortBy={"scPrice"}
            label="resources.root.price"
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcPrice?.toLocaleString()}`
                : `${record.scPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency === "default" && (
          <FunctionField
            sortBy={"scForgivePrice"}
            label="resources.root.forgivePrice"
            render={(record) =>
              record.byMain
                ? `$${record.mcForgivePrice?.toLocaleString()}`
                : `${record.scForgivePrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            sortBy={"scForgivePrice"}
            label="resources.root.forgivePrice"
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcForgivePrice?.toLocaleString()}`
                : `${record.scForgivePrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />

        <TextField source="accountant" label="resources.root.accountant" />
        <TextField
          source="invoiceNumber"
          label="resources.root.invoiceNumber"
        />
        <TextField source="reciever" label="resources.root.reciever" />
        {localStorage.getItem("showCreatedAt") === "true" && (
          <DateField
            source="createdAt"
            label="resources.root.createdAt"
            locales={"en-GB"}
          />
        )}
        {/* <EditButton /> */}
      </Datagrid>
    </List>
  );
};

export const SupplierPaymentsShow = () => {
  const translate = useTranslate();
  const [payment, setPayment] = React.useState([]);
  const { id } = useParams();
  const props = useResourceDefinition();

  const [mainColor, SetMainColor] = React.useState(
    localStorage.getItem("mainColorSupplier") !== null
      ? localStorage.getItem("mainColorSupplier")
      : "#14a44d"
  );
  localStorage.setItem("mainColorSupplier", mainColor);

  const ShowingAttachment = () => {
    const recordy = useRecordContext();
    if (recordy.attachment === null) return null;
    return (
      <img
        style={{
          width: 75,
          height: 75,
          objectFit: "scale-down",
          cursor: "pointer",
        }}
        src={
          recordy.attachment !== null
            ? `${path}${recordy.attachment}`
            : `https://via.placeholder.com/75`
        }
        alt={recordy.name}
        onClick={() =>
          window.open(
            recordy.attachment !== null
              ? `${path}${recordy.attachment}`
              : `https://via.placeholder.com/75`,
            "_blank"
          )
        }
      />
    );
  };

  React.useEffect(() => {
    HTTP.get(`${url}/SupplierPayments/${id}`).then((res) => {
      setPayment(res.data);
    });
  }, [props]);

  return (
    <div>
      <Page
        data={{
          invoiceNo: payment.invoiceNumber && payment.invoiceNumber,
          serialNo: payment.id && payment.id,
          dollar: payment.byMain && payment.byMain,
          isDeposit: false,
          date: payment.date && payment.date,
          payedTo: payment.supplier && payment.supplier.supplierName,
          amount:
            payment.byMain && payment.byMain
              ? payment.mcPrice && payment.mcPrice?.toLocaleString()
              : payment.scPrice && payment.scPrice?.toLocaleString(),
          forgivePrice:
            payment.byMain && payment.byMain
              ? payment.mcForgivePrice &&
                payment.mcForgivePrice?.toLocaleString()
              : payment.scForgivePrice &&
                payment.scForgivePrice?.toLocaleString(),
          currentLoan:
            payment.supplier &&
            (payment.supplier.payLoanByMain
              ? `$${payment.supplier.mcCurrentLoan?.toLocaleString()}`
              : `${payment.supplier.scCurrentLoan?.toLocaleString()} ${translate(
                  "resources.root.iqd"
                )}`),
          type: "SupplierPayment",
          note: payment.note && payment.note,
        }}
      />
      <Show>
        <SimpleShowLayout>
          <TextField
            source="invoiceNumber"
            label="resources.root.invoiceNumber"
          />
          <ReferenceField
            label="resources.root.supplier"
            source="supplierId"
            reference="Suppliers"
          >
            <TextField source="supplierName" />
          </ReferenceField>
          <FunctionField
            label="resources.root.price"
            render={(record) =>
              record.byMain
                ? record.mcPrice?.toLocaleString()
                : record.scPrice?.toLocaleString()
            }
          />
          <FunctionField
            label="resources.root.forgivePrice"
            render={(record) =>
              record.byMain
                ? record.mcForgivePrice?.toLocaleString()
                : record.scForgivePrice?.toLocaleString()
            }
          />
          <BooleanField source="byMain" label="resources.root.usd" />
          <TextField source="accountant" label="resources.root.accountant" />
          <TextField
            source="invoiceNumber"
            label="resources.root.invoiceNumber"
          />
          <TextField source="reciever" label="resources.root.reciever" />
          <TextField source="note" label="resources.root.note" />
          <DateField
            source="date"
            label="resources.root.date"
            locales={"en-GB"}
            showTime
          />
          <ShowingAttachment />
          <div className="d-flex align-items-center justify-content-start">
            <Button
              class={`btn btn-primary`}
              onClick={() => {
                let appLayout = document.getElementsByClassName("theRoot");
                let sidebar = document.getElementsByClassName("MuiDrawer-root");
                let appMenu = document.getElementsByClassName("MuiPaper-root");
                let appBar = document.getElementsByClassName("theRoot");
                let showPage = document.getElementsByClassName("show-page");
                let printInvoice = document.getElementById("hidePage");
                printInvoice.classList.remove("d-none");
                appLayout[0].style.marginTop = "0";
                sidebar[0].style.display = "none";
                appMenu[0].style.display = "none";
                appBar[0].style.marginTop = "0px";
                showPage[0].style.display = "none";
                window.print();
                printInvoice.classList.add("d-none");
                appLayout[0].style.marginTop = "10px";
                sidebar[0].style.display = "block";
                appMenu[0].style.display = "block";
                appBar[0].style.marginTop = "40px";
                showPage[0].style.display = "block";
              }}
            >
              {translate("resources.root.print")}
            </Button>
            {process.env.REACT_APP_APP_NAME === "Sherko" && (
              <div className="mx-4 d-flex justify-content-center align-items-center">
                <p className="m-0 p-0 ">
                  {translate("resources.root.selectColorVoucher")}:&nbsp;
                </p>
                <div className="cp_wrapper">
                  <input
                    className="input-voucher"
                    type="color"
                    value={mainColor}
                    onChange={(e) => SetMainColor(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>
        </SimpleShowLayout>
      </Show>
    </div>
  );
};

export const SupplierPaymentsCreate = () => {
  const props = useResourceDefinition();
  const [currency, setCurrency] = React.useState(true);
  const [supplierId, setSupplierId] = React.useState(0);
  const [mcCurrentLoan, setMcCurrentLoan] = React.useState(0);
  const [scCurrentLoan, setScCurrentLoan] = React.useState(0);
  const [supplierOwner, setSupplierOwner] = React.useState("");
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const [forgivePrice, setForgivePrice] = React.useState(0);
  const [supplier, setSupplier] = React.useState([]);
  const [choices, setChoices] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const dataProvider = useDataProvider();
  const classes = useStyles();
  const translate = useTranslate();
  const idemPotent = uuidv4();

  React.useEffect(() => {
    const res = dataProvider
      .getList("Suppliers", {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: "id", order: "ASC" },
        filter: {},
      })
      .then((response) => setChoices(response.data));
  }, []);

  React.useEffect(() => {
    HTTP.get(`${url}/Suppliers/${supplierId}`).then((res) => {
      setMcCurrentLoan(res.data.mcCurrentLoan);
      setScCurrentLoan(res.data.scCurrentLoan);
      setCurrency(res.data.payLoanByMain);
      setSupplierOwner(res.data.owner);
      setSupplier(res.data);
      setLoading(false);
    });
  }, [supplierId]);

  const optionRenderer = (choice) => choice.supplierName;

  const transform = (data) => ({
    ...data,
    idemPotentToken: idemPotent,
    accountant: localStorage.getItem("name"),
    byMain: supplier.payLoanByMain,
    date: selectedDate,
  });
  return (
    <Create {...props} transform={transform} redirect="list">
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <AutocompleteInput
              source="supplierId"
              perPage={1000}
              label="resources.root.supplier"
              choices={choices}
              optionText={optionRenderer}
              optionValue="id"
              validate={[required()]}
              onChange={(e) => {
                setSupplierId(e);
                setSeparatedNumber(0);
                setLoading(true);
              }}
              className={classes.input}
            />
            <NumberInput
              source="invoiceNumber"
              label="resources.root.invoiceNumber"
              className={classes.input}
            />
            {/* <DateInput
              source="date"
              label="resources.root.date"
              className={classes.input}
              defaultValue={moment(new Date()).format("YYYY-MM-DD")}
              validate={[required()]}
            /> */}
            <Col className="col-12 col-lg-6 mb-3 p-0">
              <Form.Group controlId="startDate">
                <Col className="d-flex align-items-center m-0 p-0">
                  <Form.Label className="m-0 mr-2 text-nowrap">
                    {translate("resources.root.selectDatetime")} :
                  </Form.Label>
                  <Datetime
                    initialValue={selectedDate}
                    defaultShow={true}
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm:ss"
                    onChange={(e) => {
                      setSelectedDate(e.format("YYYY-MM-DD HH:mm:ss"));
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <TextInput
              source="note"
              label="resources.root.note"
              className={classes.input}
              options={{ multiLine: true }}
            />
            <ImageInput
              source="attachment"
              label="resources.root.attachment"
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </div>
          <div className={classes.formCol}>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return currency ? (
                  <NumberInput
                    validate={[required(), number()]}
                    defaultValue={0}
                    source="mcPrice"
                    label="resources.root.price"
                    onChange={(e) =>
                      setSeparatedNumber(parseFloat(e.target.value) || 0)
                    }
                    helperText={
                      separatedNumber > 0
                        ? `$${separatedNumber?.toLocaleString()}`
                        : ""
                    }
                    className={classes.input}
                  />
                ) : (
                  <NumberInput
                    validate={[required(), number()]}
                    defaultValue={0}
                    source="scPrice"
                    label="resources.root.price"
                    onChange={(e) =>
                      setSeparatedNumber(parseFloat(e.target.value) || 0)
                    }
                    helperText={
                      separatedNumber > 0
                        ? `${separatedNumber?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`
                        : ""
                    }
                    className={classes.input}
                  />
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return currency ? (
                  <NumberInput
                    validate={[required(), number()]}
                    source="mcForgivePrice"
                    label="resources.root.forgivePrice"
                    defaultValue={0}
                    onChange={(e) =>
                      setForgivePrice(parseFloat(e.target.value) || 0)
                    }
                    helperText={
                      forgivePrice > 0
                        ? `$${forgivePrice?.toLocaleString()}`
                        : ""
                    }
                    className={classes.input}
                  />
                ) : (
                  <NumberInput
                    validate={[required(), number()]}
                    source="scForgivePrice"
                    label="resources.root.forgivePrice"
                    defaultValue={0}
                    onChange={(e) =>
                      setForgivePrice(parseFloat(e.target.value) || 0)
                    }
                    helperText={
                      forgivePrice > 0
                        ? `${forgivePrice?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`
                        : ""
                    }
                    className={classes.input}
                  />
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  supplierOwner && (
                    <TextInput
                      source="reciever"
                      label="resources.root.reciever"
                      className={classes.input}
                      defaultValue={supplierOwner.owner}
                    />
                  )
                );
              }}
            </FormDataConsumer>
            {supplier.id && (
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {translate("resources.root.currentLoan")}
                  </Typography>
                  <Typography
                    className={classes.marginBottom}
                    variant="h5"
                    component="h2"
                  >
                    {currency
                      ? `$${mcCurrentLoan?.toLocaleString()}`
                      : `${scCurrentLoan?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`}
                  </Typography>
                  <Typography
                    className={classes.title}
                    style={{
                      color: currency
                        ? mcCurrentLoan - separatedNumber < 0 && "red"
                        : scCurrentLoan - separatedNumber < 0 && "red",
                    }}
                    color="textSecondary"
                    gutterBottom
                  >
                    {translate("resources.root.remainder")}
                  </Typography>
                  <Typography
                    className={classes.marginBottom}
                    style={{
                      color: currency
                        ? mcCurrentLoan - separatedNumber < 0 && "red"
                        : scCurrentLoan - separatedNumber < 0 && "red",
                    }}
                    variant="h5"
                    component="h2"
                  >
                    {currency
                      ? `$${(
                          mcCurrentLoan -
                          (separatedNumber + forgivePrice)
                        )?.toLocaleString()}`
                      : `${(
                          scCurrentLoan -
                          (separatedNumber + forgivePrice)
                        )?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`}
                  </Typography>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {supplier.id && translate("resources.root.owner")}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {supplier.owner}
                    <br />
                    {supplier.phone}
                    <br />
                    {supplier.email}
                    <br />
                    {supplier.address}
                  </Typography>
                </CardContent>
                {loading && <LinearProgress className={classes.progressBar} />}
              </Card>
            )}
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

export const SupplierPaymentsEdit = () => {
  const { id } = useParams();
  const props = useResourceDefinition();

  const [currency, setCurrency] = React.useState(true);
  const [supplierId, setSupplierId] = React.useState(0);
  const [mcCurrentLoan, setMcCurrentLoan] = React.useState(0);
  const [scCurrentLoan, setScCurrentLoan] = React.useState(0);
  const [supplierOwner, setSupplierOwner] = React.useState("");
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const [forgivePrice, setForgivePrice] = React.useState(0);
  const [supplier, setSupplier] = React.useState([]);
  const [choices, setChoices] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const dataProvider = useDataProvider();
  const classes = useStyles();
  const translate = useTranslate();

  React.useEffect(() => {
    const res = dataProvider
      .getList("Suppliers", {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: "id", order: "ASC" },
        filter: {},
      })
      .then((response) => setChoices(response.data));
  }, []);

  React.useEffect(() => {
    HTTP.get(`${url}/SupplierPayments/${id}`).then((res) => {
      setMcCurrentLoan(res.data.supplier.mcCurrentLoan);
      setScCurrentLoan(res.data.supplier.scCurrentLoan);
      setCurrency(res.data.supplier.payLoanByMain);
      setSupplier(res.data.supplier);
      setLoading(false);
    });
  }, [id]);

  const optionRenderer = (choice) => choice.supplierName;

  const transform = (data) => ({
    ...data,
    accountant: localStorage.getItem("name"),
    byMain: supplier.payLoanByMain,
  });
  return (
    <Edit {...props} transform={transform} redirect="list">
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <AutocompleteInput
              source="supplierId"
              perPage={1000}
              label="resources.root.supplier"
              choices={choices}
              optionText={optionRenderer}
              optionValue="id"
              validate={[required()]}
              onChange={(e) => {
                setSupplierId(e);
                setSeparatedNumber(0);
                setLoading(true);
              }}
              className={classes.input}
              disabled
            />
            <TextInput
              source="invoiceNumber"
              label="resources.root.invoiceNumber"
              className={classes.input}
            />
            <DateInput
              source="date"
              label="resources.root.date"
              className={classes.input}
              defaultValue={new Date()}
              validate={[required()]}
            />
            <TextInput
              source="note"
              label="resources.root.note"
              className={classes.input}
              options={{ multiLine: true }}
            />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  supplier.id &&
                  (supplier.payLoanByMain ? (
                    <BooleanInput
                      source="byMain"
                      disabled
                      label="resources.root.usd"
                      defaultValue={true}
                    />
                  ) : (
                    <BooleanInput
                      source="byMain"
                      disabled
                      label="resources.root.iqd"
                      defaultValue={false}
                    />
                  ))
                );
              }}
            </FormDataConsumer>
          </div>
          <div className={classes.formCol}>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return currency ? (
                  <NumberInput
                    validate={[required(), number()]}
                    source="mcPrice"
                    label="resources.root.price"
                    onChange={(e) =>
                      setSeparatedNumber(parseFloat(e.target.value) || 0)
                    }
                    helperText={
                      separatedNumber > 0
                        ? `$${separatedNumber?.toLocaleString()}`
                        : ""
                    }
                    className={classes.input}
                  />
                ) : (
                  <NumberInput
                    validate={[required(), number()]}
                    source="scPrice"
                    label="resources.root.price"
                    onChange={(e) =>
                      setSeparatedNumber(parseFloat(e.target.value) || 0)
                    }
                    helperText={
                      separatedNumber > 0
                        ? `${separatedNumber?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`
                        : ""
                    }
                    className={classes.input}
                  />
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return currency ? (
                  <NumberInput
                    validate={[required(), number()]}
                    source="mcForgivePrice"
                    label="resources.root.forgivePrice"
                    defaultValue={0}
                    onChange={(e) =>
                      setForgivePrice(parseFloat(e.target.value) || 0)
                    }
                    helperText={
                      forgivePrice > 0
                        ? `$${forgivePrice?.toLocaleString()}`
                        : ""
                    }
                    className={classes.input}
                  />
                ) : (
                  <NumberInput
                    validate={[required(), number()]}
                    source="scForgivePrice"
                    label="resources.root.forgivePrice"
                    defaultValue={0}
                    onChange={(e) =>
                      setForgivePrice(parseFloat(e.target.value) || 0)
                    }
                    helperText={
                      forgivePrice > 0
                        ? `${forgivePrice?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`
                        : ""
                    }
                    className={classes.input}
                  />
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  supplierOwner && (
                    <TextInput
                      source="reciever"
                      label="resources.root.reciever"
                      className={classes.input}
                      defaultValue={supplierOwner}
                      validate={[required()]}
                    />
                  )
                );
              }}
            </FormDataConsumer>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "49%",
  },
  input: {
    width: "100%",
  },
  table: {
    minWidth: 500,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
  root: {
    minWidth: 275,
    marginTop: 6,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  marginBottom: {
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
    marginBottom: 0,
  },
  pos: {
    marginBottom: 12,
  },
  primary: {
    backgroundColor: "#1976d2",
    color: "#fff",
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  btnGroup: {
    marginRight: 10,
  },
});
