import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  EditButton,
  Show,
  Filter,
  useNotify,
  DateField,
  useRedirect,
  SimpleShowLayout,
  NumberField,
  FunctionField,
  useTranslate,
  BooleanField,
  BooleanInput,
  NumberInput,
} from "react-admin";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";

export const BankAccountsList = (props) => {
  return (
    <List
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={<BulkAction {...props} />} rowClick="show">
        <TextField source="name" label="resources.root.name" />
        <NumberField
          source="mcCurrentBalance"
          options={{ style: "currency", currency: "USD" }}
          label="resources.root.mcCurrentBalance"
        />
        <NumberField
          source="scCurrentBalance"
          options={{ style: "currency", currency: "IQD" }}
          label="resources.root.scCurrentBalance"
        />
        <TextField source="detail" label="resources.root.detail" />
        <TextField
          source="accountNumber"
          label="resources.root.accountNumber"
        />
        <BooleanField
          source="allowBellowZero"
          label="resources.root.allowBelowZero"
        />
        <DateField
          source="openingDate"
          label="resources.root.openingDate"
          locales={"en-GB"}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const BankAccountsShow = (props) => (
  <Show
    filters={<SearchFilter />}
    {...props}
    sort={{ field: "id", order: "DESC" }}
  >
    <SimpleShowLayout>
      <TextField source="name" label="resources.root.name" />
      <FunctionField
        label="resources.root.BalanceInUSD"
        render={(record) => `${record.mcCurrentBalance?.toLocaleString()}`}
      />
      <FunctionField
        label="resources.root.balanceInIQD"
        render={(record) => `${record.scCurrentBalance?.toLocaleString()}`}
      />
      <FunctionField
        label="resources.root.depositInUSD"
        render={(record) => `${record.totalMcDeposit?.toLocaleString()}`}
      />
      <FunctionField
        label="resources.root.depositInIQD"
        render={(record) => `${record.totalScDeposit?.toLocaleString()}`}
      />
      <FunctionField
        label="resources.root.withdrawInUSD"
        render={(record) => `${record.totalMcWithdraw?.toLocaleString()}`}
      />
      <FunctionField
        label="resources.root.withdrawInIQD"
        render={(record) => `${record.totalScWithdraw?.toLocaleString()}`}
      />
      <FunctionField
        label="resources.root.withdrawInIQD"
        render={(record) => `${record.totalScWithdraw?.toLocaleString()}`}
      />
      <BooleanField
        source="allowBellowZero"
        label="resources.root.allowBelowZero"
      />
      <TextField source="detail" label="resources.root.details" />
      <TextField source="accountNumber" label="resources.root.accountNumber" />
      <FunctionField
        label="resources.root.totalMcTransferFromOther"
        render={(record) =>
          `${record.totalMcTransferFromOther?.toLocaleString()}`
        }
      />
      <FunctionField
        label="resources.root.totalScTransferFromOther"
        render={(record) =>
          `${record.totalScTransferFromOther?.toLocaleString()}`
        }
      />
      <FunctionField
        label="resources.root.totalMcTransferToOther"
        render={(record) =>
          `${record.totalMcTransferToOther?.toLocaleString()}`
        }
      />
      <FunctionField
        label="resources.root.totalScTransferToOther"
        render={(record) =>
          `${record.totalScTransferToOther?.toLocaleString()}`
        }
      />
      <DateField
        source="openingDate"
        label="resources.root.openingDate"
        locales={"en-GB"}
      />
      <EditButton />
    </SimpleShowLayout>
  </Show>
);

export const BankAccountsCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onFailure = (error) => {
    if (error.status === 406) {
      notify(`resources.root.bankAccountExistsAlready`, {
        type: "warning",
        undoable: true,
      });
    } else if (error.status === 401) {
      localStorage.removeItem("token");
      redirect("/login");
    }
  };
  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm>
        <h1>You don't have permission to do this</h1>
        {/*  <TextInput source="name" label="resources.root.name"/> 
        <TextInput source="detail" label="resources.root.detail" options={{ multiLine: true }} />
        <NumberInput source="mcInitialBalance" label="resources.root.initialBalanceDollar"/>
        <TextInput source="accountNumber" label="resources.root.accountNumber"/>
        <NumberInput source="scInitialBalance" label="resources.root.initialBalanceDinar"/>
        <BooleanInput source="allowBellowZero" label="resources.root.allowBelowZero" /> */}
      </SimpleForm>
    </Create>
  );
};

export const BankAccountsEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" label="resources.root.name" />
      <TextInput
        source="detail"
        label="resources.root.detail"
        options={{ multiLine: true }}
      />
      <BooleanInput
        source="allowBellowZero"
        label="resources.root.allowBelowZero"
      />
    </SimpleForm>
  </Edit>
);
