import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  ReferenceField,
  FormDataConsumer,
  DateField,
  required,
  FunctionField,
  NumberInput,
  minValue,
  DateInput,
  AutocompleteInput,
  useTranslate,
} from "react-admin";
import { ListActions } from "../templates/ListActions";
import { url } from "../../request";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";

export const EmployeeSalaryChangesList = (props) => {
  const translate = useTranslate();
  const [currency, setCurrency] = React.useState("default");

  return (
    <List
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions setCurrency={setCurrency} currency={currency} />}
    >
      <Datagrid bulkActionButtons={<BulkAction {...props} />}>
        <ReferenceField
          label={translate("resources.root.employee")}
          source="employeeId"
          reference="Employees"
        >
          <FunctionField
            label={translate("resources.root.name")}
            render={(record) => `${record.firstName} ${record.middleName}`}
          />
        </ReferenceField>
        {currency === "default" && (
          <FunctionField
            label="resources.root.currentSalary"
            sortBy={"mcCurrentSalary"}
            render={(record) =>
              record.employee.salaryByMain
                ? `$${record.mcCurrentSalary?.toLocaleString()}`
                : `${record.scCurrentSalary?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label={translate("resources.root.currentSalary")}
            sortBy={"mcCurrentSalary"}
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcCurrentSalary?.toLocaleString()}`
                : `${record.scCurrentSalary?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency === "default" && (
          <FunctionField
            label="resources.root.salaryBefore"
            sortBy={"mcSalaryBefore"}
            render={(record) =>
              record.employee.salaryByMain
                ? `$${record.mcSalaryBefore?.toLocaleString()}`
                : `${record.scSalaryBefore?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label={translate("resources.root.salaryBefore")}
            sortBy={"mcSalaryBefore"}
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcSalaryBefore?.toLocaleString()}`
                : `${record.scSalaryBefore?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        <DateField
          source="changeDate"
          label="resources.root.changeDate"
          locales={"en-GB"}
        />
        <TextField source="note" label={translate("resources.root.note")} />
      </Datagrid>
    </List>
  );
};

export const EmployeeSalaryChangesCreate = (props) => {
  const translate = useTranslate();
  const [choices, setChoices] = React.useState([]);
  const [selectedChoices, setSelectedChoices] = React.useState([]);
  const [salary, setSalaryBefore] = React.useState([]);
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const classes = useStyles();

  React.useEffect(() => {
    HTTP.get(`${url}/Employees?_end=1000`).then((res) => {
      setChoices(res.data);
    });
  }, []);

  React.useEffect(() => {
    if (selectedChoices.id === undefined) return;
    HTTP.get(`${url}/Employees/${selectedChoices.id}`).then((res) => {
      setSalaryBefore(res.data);
      setLoading(false);
    });
  }, [selectedChoices]);
  const optionRenderer = (choice) => `${choice.firstName} ${choice.middleName}`;
  return (
    <Create
      {...props}
      transform={(data) => ({
        ...data,
        accountant: localStorage.getItem("name"),
      })}
    >
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <AutocompleteInput
              source="employeeId"
              choices={choices}
              optionText={optionRenderer}
              onChange={(val) => {
                setSelectedChoices(choices.find((el) => el.id === val));
                setSeparatedNumber(0);
                setLoading(true);
              }}
              label={translate("resources.root.chooseEmployee")}
            />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  selectedChoices.id &&
                  (selectedChoices.salaryByMain ? (
                    <NumberInput
                      validate={[required(), minValue(0)]}
                      source="mcCurrentSalary"
                      helperText={
                        separatedNumber > 0
                          ? `$${parseFloat(separatedNumber)?.toLocaleString()}`
                          : ""
                      }
                      label={translate("resources.root.newSalary")}
                      onChange={(e) =>
                        setSeparatedNumber(parseFloat(e.target.value) || 0)
                      }
                      min={0}
                    />
                  ) : (
                    <NumberInput
                      validate={[required(), minValue(0)]}
                      source="scCurrentSalary"
                      helperText={
                        separatedNumber > 0
                          ? `${parseFloat(
                              separatedNumber
                            )?.toLocaleString()} ${translate(
                              "resources.root.iqd"
                            )}`
                          : ""
                      }
                      onChange={(e) =>
                        setSeparatedNumber(parseFloat(e.target.value) || 0)
                      }
                      label={translate("resources.root.newSalary")}
                      min={0}
                    />
                  ))
                );
              }}
            </FormDataConsumer>
            <DateInput
              label={translate("resources.root.changeDate")}
              source="changeDate"
              defaultValue={moment().format("YYYY-MM-DD")}
            />
            <TextInput
              source="note"
              multiline
              label={translate("resources.root.note")}
            />
          </div>
          <div className={classes.formCol}>
            {selectedChoices.id && (
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {translate("resources.root.currentSalary")}
                  </Typography>
                  <Typography
                    className={classes.marginBottom}
                    variant="h5"
                    component="h2"
                  >
                    {selectedChoices.salaryByMain
                      ? `$${
                          salary.mcBasicSalary &&
                          salary.mcBasicSalary?.toLocaleString()
                        }`
                      : `${
                          salary.scBasicSalary &&
                          salary.scBasicSalary?.toLocaleString()
                        } ${translate("resources.root.iqd")}`}
                  </Typography>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {selectedChoices.id && "Employee"}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {selectedChoices.phone}
                    <br />
                    {selectedChoices.email}
                    <br />
                    {selectedChoices.address}
                  </Typography>
                </CardContent>
                {loading && <LinearProgress className={classes.progressBar} />}
              </Card>
            )}
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "49%",
  },
  input: {
    width: "100%",
  },
  table: {
    minWidth: 500,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
  root: {
    minWidth: 275,
    marginTop: 6,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  marginBottom: {
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
    marginBottom: 0,
  },
  pos: {
    marginBottom: 12,
  },
  primary: {
    backgroundColor: "#1976d2",
    color: "#fff",
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  btnGroup: {
    marginRight: 10,
  },
});
