import * as React from "react";
import {
List,
Datagrid,
TextField,
Create,
SimpleForm,
TextInput,
Edit
} from 'react-admin';
import {MyCustomPagination, SearchFilter} from "../../App";
import BulkAction from "../CustomComponents/BulkActions";

export const WithdrawsTypesList = (props) => (
    <List
        filters={<SearchFilter />}
        pagination={<MyCustomPagination />}
        {...props}
        sort={{ field: 'id', order: 'DESC' }}
    >
        <Datagrid bulkActionButtons={<BulkAction {...props} />} rowClick={'edit'}>
            <TextField source="label" label="resources.root.label" />
            <TextField source="note" label="resources.root.note" />
        </Datagrid>
    </List>
);

export const WithdrawsTypesCreate = (props) => (
    <Create {...props} redirect='list'>
        <SimpleForm>
            <TextInput source="label" label="resources.root.label" />
            <TextInput source="note" label="resources.root.label" options={{ multiLine: true }} />
        </SimpleForm>
    </Create>
);

export const WithdrawsTypesEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="label" label="resources.root.label" />
            <TextInput source="note" label="resources.root.note" options={{ multiLine: true }} />
        </SimpleForm>
    </Edit>
);
