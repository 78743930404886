import * as React from "react";
import { url } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import { NoPrint, Print } from "react-easy-print";
import PrintProvider from "react-easy-print/lib/es";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";

export default () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [ignoreZero, setIgnoreZero] = React.useState(false);
  const [ignoreBellowZeroProducts, setIgnoreBellowZeroProducts] =
    React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    await HTTP.get(
      `${url}/Reports/StockAlertReport?ignoreZero=${ignoreZero}&ignoreBellowZeroProducts=${ignoreBellowZeroProducts}&_start=${start}&_end=${end}`
    )
      .then((response) => {
        setData(response.data);
        setToTalItems(response.headers["x-total-count"]);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [ignoreZero, ignoreBellowZeroProducts, start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  return (
    <div>
      <PrintProvider>
        <Print name={"stockAlertReport"}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.stockAlertReport")}
            </h1>
            <div className={`d-inline-block`} style={{ float: "inline-end" }}>
              <NoPrint>
                <Button
                  variant={"outline-primary"}
                  onClick={(e) => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}
                </Button>
                <div className={`clearfix`}> </div>
              </NoPrint>
            </div>
          </div>
          <Row className={`mt-2`}>
            {isLoading && <LoadingScreen />}
            <Col xs={3}>
              <NoPrint>
                <div className="form-check">
                  <input
                    id={"ignoreZero"}
                    type="checkbox"
                    onChange={(e) => setIgnoreZero(e.target.checked)}
                    defaultChecked={false}
                  />
                  <label className="form-check-label mb-3" htmlFor="ignoreZero">
                    {translate("resources.root.ignoreZero")}
                  </label>
                </div>
              </NoPrint>
            </Col>
            <Col xs={3}>
              <NoPrint>
                <div className="form-check">
                  <input
                    id={"ignoreBellowZeroProducts"}
                    type="checkbox"
                    onChange={(e) =>
                      setIgnoreBellowZeroProducts(e.target.checked)
                    }
                    defaultChecked={true}
                  />
                  <label
                    className="form-check-label mb-3"
                    htmlFor="ignoreBellowZeroProducts"
                  >
                    {translate("resources.root.ignoreBellowZeroProducts")}
                  </label>
                </div>
              </NoPrint>
            </Col>
            <Col xs={4}>
              <Button
                id="export"
                onClick={() => {
                  toCsv(
                    document.getElementById("exportableTable"),
                    "download.csv"
                  );
                }}
              >
                {translate("resources.root.exportCsv")}
              </Button>
            </Col>
            <Col xs={12}>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.barcode")}</th>
                    <th>{translate("resources.root.name")}</th>
                    <th>{translate("resources.root.purchasePrice")}</th>
                    <th>{translate("resources.root.sellPrice")}</th>
                    <th>{translate("resources.root.qtyInStock")}</th>
                    <th>{translate("resources.root.smallMeasureQty")}</th>
                    <th>{translate("resources.root.alertQty")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr key={one.id}>
                          <td>
                            <a
                              href={`#/Products/${one.id}/show`}
                              target={"_blank"}
                            >
                              {one.barcode}
                            </a>
                          </td>
                          <td>{one.name}</td>
                          <td>
                            {one.purchaseByMain
                              ? `$${one.mcPurchasePrice?.toLocaleString()}`
                              : `${one.scPurchasePrice?.toLocaleString()} IQD`}
                          </td>
                          <td>
                            {one.sellByMain
                              ? `$${one.mcSellPrice?.toLocaleString()}`
                              : `${one.scSellPrice?.toLocaleString()} IQD`}
                          </td>
                          <td>{one.qteInStock}</td>
                          <td>{one.smallMeasureQte}</td>
                          <td>{one.alertQte}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>Per Page</InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
