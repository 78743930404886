import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  NumberInput,
  Edit,
  TabbedForm,
  required,
  FormTab,
  useNotify,
  useTranslate,
  FunctionField,
  ImageField,
  ImageInput,
  useRefresh,
  SelectInput,
  ReferenceInput,
  downloadCSV,
  Show,
  SimpleShowLayout,
  DateField,
  ShowButton,
  EditButton,
  ArrayField,
  NumberField,
  useResourceDefinition,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import MaterialTextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { url } from "../../request";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MuiAlert from "@mui/lab/Alert";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";
import SaveIcon from "@mui/icons-material/Save";
import swal from "sweetalert";
import moment from "moment";
import Switch from "@mui/material/Switch";
import { ListActions } from "../templates/ListActions";
import { path } from "../../request";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import jsonExport from "jsonexport/dist";
import { NoPrint } from "react-easy-print";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";
import { useParams } from "react-router-dom";

const BulkActionButtons = (props) => {
  const refresh = useRefresh();
  const translate = useTranslate();
  return (
    <NoPrint>
      <React.Fragment>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            color="primary"
            style={{ marginBottom: 15 }}
            onClick={() => {
              swal({
                text: `${translate("resources.root.newExpireDate")}`,
                content: {
                  element: "input",
                  attributes: {
                    type: "date",
                  },
                },
                button: {
                  text: `${translate("resources.root.update")}`,
                  closeModal: false,
                },
              }).then((expireDate) => {
                if (expireDate) {
                  HTTP.post(
                    `${url}/Products/UpdateExpireDate?newExpireDate=${expireDate}`,
                    props.selectedIds
                  )
                    .then(() => {
                      swal.stopLoading();
                      swal.close();
                      refresh();
                    })
                    .catch((err) => {
                      if (err) {
                        swal("Oh noes!", "The AJAX request failed!", "error");
                      } else {
                        swal.stopLoading();
                        swal.close();
                      }
                    });
                }
              });
            }}
          >
            <AssignmentTurnedInRoundedIcon /> &nbsp;{" "}
            {translate("resources.root.updateExpireDate")}
          </Button>
          <Button
            color="primary"
            style={{ marginBottom: 15 }}
            onClick={() => {
              swal({
                text: `${translate("resources.root.updateSellPrice")}`,
                content: {
                  element: "input",
                  attributes: {
                    type: "number",
                  },
                },
                button: {
                  text: `${translate("resources.root.update")}`,
                  closeModal: false,
                },
              }).then((ratio) => {
                if (ratio) {
                  HTTP.post(
                    `${url}/Products/UpdateSellPriceByProfitRate?ratio=${
                      ratio / 100
                    }`,
                    props.selectedIds
                  )
                    .then(() => {
                      swal.stopLoading();
                      swal.close();
                      refresh();
                    })
                    .catch((err) => {
                      if (err) {
                        swal("Oh noes!", "The AJAX request failed!", "error");
                      } else {
                        swal.stopLoading();
                        swal.close();
                      }
                    });
                }
              });
            }}
          >
            <AssignmentTurnedInRoundedIcon /> &nbsp;{" "}
            {translate("resources.root.updateSellPrice")}
          </Button>
          <BulkAction {...props} />
        </div>
      </React.Fragment>
    </NoPrint>
  );
};

export const ProductsList = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const [products, setProducts] = React.useState("default");

  const exporter = (data) => {
    const dataForExport = data.map((data) => {
      let {
        id,
        name,
        barcode,
        mcSellPrice,
        scSellPrice,
        sellByMain,
        qteInStock,
        mcPurchasePrice,
        scPurchasePrice,
        purchaseByMain,
        mcSmallMeasureSellPrice,
        scSmallMeasureSellPrice,
        expireDate,
        ratio,
      } = data;
      let sellMain = sellByMain
        ? translate("resources.root.usd")
        : translate("resources.root.iqd");
      let purchaseMain = purchaseByMain
        ? translate("resources.root.usd")
        : translate("resources.root.iqd");
      let expire =
        expireDate !== null ? moment(expireDate).format("YYYY-MM-DD") : "-";
      return {
        id,
        name,
        barcode,
        mcSellPrice,
        scSellPrice,
        sellMain,
        qteInStock,
        mcPurchasePrice,
        scPurchasePrice,
        purchaseMain,
        mcSmallMeasureSellPrice,
        scSmallMeasureSellPrice,
        expire,
      };
    });
    jsonExport(
      dataForExport,
      {
        headers: [
          "id",
          "name",
          "barcode",
          "mcSellPrice",
          "scSellPrice",
          "sellMain",
          "qteInStock",
          "mcPurchasePrice",
          "scPurchasePrice",
          "purchaseMain",
          "mcSmallMeasureSellPrice",
          "scSmallMeasureSellPrice",
          "expire",
        ],
        rename: [
          translate("resources.root.id"),
          process.env.REACT_APP_APP_NAME === "Mediply"
            ? translate("resources.root.scientificName")
            : translate("resources.root.name"),
          translate("resources.root.barcode"),
          translate("resources.root.mcSellPrice"),
          translate("resources.root.scSellPrice"),
          translate("resources.root.sellCurrency"),
          translate("resources.root.quantity"),
          translate("resources.root.mcPurchasePrice"),
          translate("resources.root.scPurchasePrice"),
          translate("resources.root.purchaseCurrency"),
          translate("resources.root.mcSmallMeasureSellPrice"),
          translate("resources.root.scSmallMeasureSellPrice"),
          translate("resources.root.expireDate"),
          translate("resources.root.updateSellPrice"),
        ],
      },
      (err, csv) => {
        downloadCSV(
          "\ufeff" + csv,
          "products." + moment(new Date()).format("YYMMDDHHmmssa")
        );
      }
    );
  };

  const translate = useTranslate();

  return (
    <List
      exporter={exporter}
      filters={<SearchFilter brand={true} category={true} loses={true} />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions setCurrency={setCurrency} currency={currency} />}
      pagination={<MyCustomPagination />}
    >
      <Datagrid
        bulkActionButtons={<BulkActionButtons setProducts={setProducts} />}
        rowStyle={(record) => ({
          backgroundColor:
            record.mcPurchasePrice > record.mcSellPrice ||
            record.mcSmallMeasureSellPrice <
              record.mcPurchasePrice / record.oneContains
              ? "tomato"
              : "transparent",
        })}
      >
        <FunctionField
          sortBy={"id"}
          label="resources.root.id"
          render={(record) => (
            <a
              href={`#/Reports/ProductStatement/${record.id}`}
              target={`_blank`}
            >{`${record.id}`}</a>
          )}
        />
        <FunctionField
          label={`resources.root.image`}
          render={(record) => (
            <img
              style={{
                width: 75,
                height: 75,
                objectFit: "scale-down",
                cursor: "pointer",
              }}
              src={
                record.attachment !== null
                  ? `${path}${record.attachment}`
                  : `https://via.placeholder.com/75`
              }
              alt={record.name}
              onClick={() =>
                window.open(
                  record.attachment !== null
                    ? `${path}${record.attachment}`
                    : `https://via.placeholder.com/75`,
                  "_blank"
                )
              }
            />
          )}
        />
        {process.env.REACT_APP_APP_NAME === "Mediply" ? (
          <TextField source="name" label="resources.root.scientificName" />
        ) : (
          <TextField source="name" label="resources.root.name" />
        )}
        {process.env.REACT_APP_APP_NAME === "Mediply" && (
          <TextField source="preferName" label="resources.root.BusinessName" />
        )}
        <TextField source="barcode" label="resources.root.barcode" />
        {currency === "default" && (
          <FunctionField
            sortBy={"mcSellPrice"}
            label="resources.root.selling"
            render={(record) =>
              record.sellByMain
                ? `$${
                    record.mcSellPrice && record.mcSellPrice?.toLocaleString()
                  }`
                : `${
                    record.scSellPrice && record.scSellPrice?.toLocaleString()
                  } ${translate("resources.root.iqd")}`
            }
          />
        )}
        {localStorage.getItem("showPurchasePriceInList") === "true" &&
          currency === "default" &&
          process.env.REACT_APP_APP_NAME !== "Mediply" && (
            <FunctionField
              sortBy={"mcPurchasePrice"}
              label="resources.root.purchase"
              render={(record) =>
                record.purchaseByMain
                  ? `$${
                      record.mcPurchasePrice &&
                      record.mcPurchasePrice?.toLocaleString()
                    }`
                  : `${
                      record.scPurchasePrice &&
                      record.scPurchasePrice?.toLocaleString()
                    } ${translate("resources.root.iqd")}`
              }
            />
          )}

        {currency !== "default" && (
          <FunctionField
            sortBy={"mcSellPrice"}
            label="resources.root.sellingPrice"
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcSellPrice && record.mcSellPrice}`
                : `${record.scSellPrice && record.scSellPrice} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        <TextField source="qteInStock" label="resources.root.quantity" />
        <TextField
          source="smallMeasureQte"
          label="resources.root.smallMeasureQty"
        />
        <TextField source="smallMeasureQte" label="resources.root.smallQty" />
        {/* Showing productsion date for all other clients except mediply which we show production date */}
        {process.env.REACT_APP_APP_NAME === "Mediply" ? (
          <FunctionField
            sortBy={"productionDate"}
            label="resources.root.productionDate"
            render={(record) =>
              record.productionDate
                ? moment(record.productionDate).format("YYYY-MM-DD")
                : "-"
            }
          />
        ) : (
          <FunctionField
            sortBy={"expireDate"}
            label="resources.root.expireDate"
            render={(record) =>
              record.expireDate
                ? moment(record.expireDate).format("YYYY-MM-DD")
                : "-"
            }
          />
        )}
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const ProductsShow = (props) => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="barcode" label="resources.root.barcode" />
        {process.env.REACT_APP_APP_NAME === "Mediply" ? (
          <TextField source="name" label="resources.root.scientificName" />
        ) : (
          <TextField source="name" label="resources.root.name" />
        )}
        {process.env.REACT_APP_APP_NAME === "Mediply" ? (
          <TextField source="preferName" label="resources.root.BusinessName" />
        ) : (
          <TextField source="preferName" label="resources.root.preferName" />
        )}
        <TextField
          source="shortDescription"
          label="resources.root.shortDescription"
        />
        <TextField
          source="longDescription"
          label="resources.root.longDescription"
        />
        <TextField
          source="productMeasure"
          label="resources.root.productMeasure"
        />
        <TextField source="weightInKg" label="resources.root.weightInKg" />
        <TextField source="oneContains" label="resources.root.oneContains" />
        <TextField source="oneMeasure" label="resources.root.oneMeasure" />
        <TextField
          source="productCategory.name"
          label="resources.root.category"
        />
        <TextField source="brand.name" label="resources.root.brand" />
        <NumberField
          source="initialQte"
          label="resources.root.initialQuantity"
        />
        {/*  Showing list of the stores */}
        <ArrayField source="storeProducts" label="resources.root.stores">
          <Datagrid>
            <TextField source="store.title" label="resources.root.store" />
            {process.env.REACT_APP_APP_NAME !== "Mediply" && (
              <FunctionField
                label="resources.root.purchasePrice"
                render={(record) =>
                  record.purchaseByMain
                    ? record.mcPurchasePrice?.toLocaleString()
                    : record.scPurchasePrice?.toLocaleString()
                }
              />
            )}
            <FunctionField
              label="resources.root.sellPrice"
              render={(record) =>
                record.sellByMain
                  ? record.mcSellPrice?.toLocaleString()
                  : record.scSellPrice?.toLocaleString()
              }
            />
            <FunctionField
              label="resources.root.smallMeasureSellPrice"
              render={(record) =>
                record.sellByMain
                  ? record.mcSmallMeasureSellPrice?.toLocaleString()
                  : record.scSmallMeasureSellPrice?.toLocaleString()
              }
            />
            <NumberField
              source="qteInStock"
              label="resources.root.qteInStock"
            />
            <NumberField
              source="initialQte"
              label="resources.root.initialQuantity"
            />
            <NumberField source="alertQte" label="resources.root.alertQty" />
            <DateField
              source="expireDate"
              label="resources.root.expireDate"
              locales={"en-GB"}
            />
          </Datagrid>
        </ArrayField>
        {/* Showin list of serials */}
        {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
          <ArrayField source="productSerials" label="resources.root.serials">
            <Datagrid>
              <TextField source="serialNumber" label="resources.root.serial" />
              {process.env.REACT_APP_APP_NAME !== "Mediply" && (
                <FunctionField
                  label="resources.root.purchasePrice"
                  render={(record) =>
                    record.purchaseByMain
                      ? record.mcPurchasePrice?.toLocaleString()
                      : record.scPurchasePrice?.toLocaleString()
                  }
                />
              )}
              <FunctionField
                label="resources.root.sellPrice"
                render={(record) =>
                  record.sellByMain
                    ? record.mcSellPrice?.toLocaleString()
                    : record.scSellPrice?.toLocaleString()
                }
              />
              <FunctionField
                label="resources.root.smallMeasureSellPrice"
                render={(record) =>
                  record.sellByMain
                    ? record.mcSmallMeasureSellPrice?.toLocaleString()
                    : record.scSmallMeasureSellPrice?.toLocaleString()
                }
              />
              <NumberField
                source="qteInStock"
                label="resources.root.qteInStock"
              />
              <NumberField
                source="initialQte"
                label="resources.root.initialQuantity"
              />
              <NumberField source="alertQte" label="resources.root.alertQty" />
              <DateField
                source="productionDate"
                label="resources.root.productionDate"
                locales={"en-GB"}
              />
              <DateField
                source="expirationDate"
                label="resources.root.expireDate"
                locales={"en-GB"}
              />
            </Datagrid>
          </ArrayField>
        )}
        <TextField source="barcode1" label="resources.root.barcode1" />
        <TextField source="barcode2" label="resources.root.barcode2" />
        <TextField source="barcode3" label="resources.root.barcode3" />
        <TextField source="barcode4" label="resources.root.barcode4" />
        <TextField source="barcode5" label="resources.root.barcode5" />
        <TextField source="barcode6" label="resources.root.barcode6" />
        <TextField source="barcode7" label="resources.root.barcode7" />
        <TextField source="barcode8" label="resources.root.barcode8" />
        <TextField source="barcode9" label="resources.root.barcode9" />
      </SimpleShowLayout>
    </Show>
  );
};

export const ProductsEdit = () => {
  const props = useResourceDefinition();
  const { id } = useParams();
  const [storeProductsDetails, setStoreProductsDetails] = React.useState([]);
  const [productSerialDetails, setProductSerialDetails] = React.useState([]);
  const [originalStoreProductsDetails, setOriginalStoreProductsDetails] =
    React.useState([]);
  const [tempStoreProductsDetails, setTempStoreProductsDetails] =
    React.useState([]);
  const [stores, setStores] = React.useState([]);
  const [tablePurchasePrice, setTablePurchasePrice] = React.useState(0);
  const [tableSellingPrice, setTableSellingPrice] = React.useState(0);
  const [tableAlertQuantity, setTableAlertQuantity] = React.useState(0);
  const [tableQuantity, setTableQuantity] = React.useState(0);
  const [tableProductionDate, setTableProductionDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [tableExpireDate, setTableExpireDate] = React.useState(
    moment().add(0, "months").format("YYYY-MM-DD")
  );
  const sc = localStorage.getItem("dollarPrice");
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const role = localStorage.getItem("role") ?? "NoUser";

  React.useEffect(() => {
    HTTP.get(`${url}/Products/${id}`).then((res) => {
      setOriginalStoreProductsDetails(res.data.storeProducts);
      setProductSerialDetails(res.data.productSerials);
    });
    HTTP.get(`${url}/Stores`).then((res) => {
      setStores([...stores, ...res.data]);
    });
  }, []);

  React.useEffect(() => {
    setStoreProductsDetails([
      ...originalStoreProductsDetails,
      ...tempStoreProductsDetails,
    ]);
  }, [originalStoreProductsDetails, tempStoreProductsDetails]);

  React.useEffect(() => {
    setStores((prev) => [
      ...prev.filter(
        (store) =>
          !originalStoreProductsDetails
            .map((el) => el.storeId)
            .includes(store.id)
      ),
    ]);
  }, [originalStoreProductsDetails]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Edit
      {...props}
      transform={(data) => ({ ...data, country: "Iraq", city: "Erbil" })}
      redirect="list"
    >
      <TabbedForm>
        <FormTab label="resources.root.product">
          <TextInput
            source="barcode"
            label="resources.root.barcode"
            className={classes.input}
          />

          {process.env.REACT_APP_APP_NAME === "Mediply" ? (
            <TextInput
              source="name"
              label="resources.root.scientificName"
              className={classes.input}
              validate={[required()]}
            />
          ) : (
            <TextInput
              source="name"
              label="resources.root.name"
              className={classes.input}
              validate={[required()]}
            />
          )}

          {process.env.REACT_APP_APP_NAME === "Mediply" ? (
            <TextInput
              source="preferName"
              label="resources.root.BusinessName"
              className={classes.input}
              validate={[required()]}
            />
          ) : (
            <TextInput
              source="preferName"
              label="resources.root.preferedName"
              className={classes.input}
              validate={[required()]}
            />
          )}

          <ReferenceInput
            source="productCategoryId"
            label="resources.root.category"
            reference="ProductCategories"
            className={classes.input}
            perPage={500}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            source="brandId"
            label="resources.root.brand"
            reference="Brands"
            className={classes.input}
            perPage={500}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput
            source="shortDescription"
            label="resources.root.shortDescription"
            className={classes.input}
          />
          <TextInput
            source="longDescription"
            label="resources.root.longDescription"
            className={classes.input}
          />
          <TextInput
            source="productMeasure"
            label="resources.root.productMeasure"
            className={classes.input}
            validate={[required()]}
          />
          <NumberInput
            source="oneContains"
            label="resources.root.oneContains"
            className={classes.input}
            validate={[required()]}
          />
          <TextInput
            source="oneMeasure"
            label="resources.root.oneMeasure"
            className={classes.input}
            validate={[required()]}
          />
          <TextInput
            source="weightInKg"
            label="resources.root.weightInKg"
            className={classes.input}
            validate={[required()]}
          />
          <TextInput
            source="note"
            label="resources.root.note"
            multiline
            className={classes.input}
          />
          <TextInput
            disabled
            source="initialQte"
            label="resources.root.initialQuantity"
            className={classes.input}
          />
          <ImageInput
            source="attachment"
            label="resources.root.attachment"
            accept="image/*"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <FunctionField
            label="resources.root.attachment"
            render={(record) =>
              record.attachment !== null && (
                <div style={{ position: "relative" }}>
                  <button
                    type="button"
                    className="btn btn-danger p-2"
                    style={{ right: "0px", position: "absolute" }}
                    onClick={() => {
                      HTTP.delete(`${url}/Products/DeleteProductImage?id=${id}`)
                        .then(() => {
                          notify("Image deleted successfully", {
                            type: "success",
                            undoable: true,
                          });
                          refresh();
                        })
                        .catch((err) =>
                          notify(err.response.data.message, {
                            type: "error",
                            undoable: true,
                          })
                        );
                    }}
                  >
                    <span>&times;</span>
                  </button>
                  <img
                    style={{
                      width: 200,
                      height: 200,
                      objectFit: "scale-down",
                      cursor: "pointer",
                    }}
                    src={`${path}${record.attachment}`}
                    alt="Attachment"
                    onClick={() =>
                      window.open(`${path}${record.attachment}`, "_blank")
                    }
                  />
                </div>
              )
            }
          />
        </FormTab>
        <FormTab label="resources.root.barcodes">
          <TextInput
            source="barcode1"
            label="resources.root.barcode1"
            className={classes.input}
          />
          <TextInput
            source="barcode2"
            label="resources.root.barcode2"
            className={classes.input}
          />
          <TextInput
            source="barcode3"
            label="resources.root.barcode3"
            className={classes.input}
          />
          <TextInput
            source="barcode4"
            label="resources.root.barcode4"
            className={classes.input}
          />
          <TextInput
            source="barcode5"
            label="resources.root.barcode5"
            className={classes.input}
          />
          <TextInput
            source="barcode6"
            label="resources.root.barcode6"
            className={classes.input}
          />
          <TextInput
            source="barcode7"
            label="resources.root.barcode7"
            className={classes.input}
          />
          <TextInput
            source="barcode8"
            label="resources.root.barcode8"
            className={classes.input}
          />
          <TextInput
            source="barcode9"
            label="resources.root.barcode9"
            className={classes.input}
          />
        </FormTab>
        <FormTab label="resources.root.stores">
          <div className={classes.productCols}>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={stores}
              disableCloseOnSelect
              getOptionLabel={(option) => option.title}
              renderOption={(props, option) => (
                <React.Fragment>
                  <div style={{ display: "block" }}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={props.selected}
                      onChange={(e) => {
                        let selectedStores = storeProductsDetails;
                        let find = selectedStores.find(
                          (el) => el.id === option.id
                        );
                        if (find) {
                          return;
                        }
                        setStoreProductsDetails([...selectedStores, option]);
                        setStores(stores.filter((el) => el.id !== option.id));
                        // setStoreProductsDetails(
                        //     stores.map((el) => {
                        //       return el;
                        //     })
                        // );
                        // setTempStoreProductsDetails([...stores.map((el) => el)]);
                      }}
                    />
                    {option.title}
                  </div>
                </React.Fragment>
              )}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <MaterialTextField
                  {...params}
                  variant="outlined"
                  label={translate("resources.root.stores")}
                  placeholder={translate("resources.root.chooseStore")}
                />
              )}
            />
          </div>
          {storeProductsDetails && (
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table stickyHeader className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{translate("resources.root.title")}</TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.purchasePrice")}
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.sellingPrice")}
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.smallSellPrice")}
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.alertQuantity")}
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.sellByUSD")}
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.purchaseByUSD")}
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.canSellBelowZero")}
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.actions")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.scrollable}>
                  {storeProductsDetails
                    .filter((el) => el.id != 0)
                    .map((store, key) => {
                      return (
                        <TableRow hover key={store.id}>
                          <TableCell component="th" scope="row">
                            {store.title || store.store.title}
                          </TableCell>
                          <TableCell align="right">
                            {process.env.REACT_APP_APP_NAME === "Mediply" ? (
                              role.toLowerCase() === "storeadmin" ||
                              role.toLowerCase() === "admin" ||
                              role.toLowerCase() === "superadmin" ? (
                                <MaterialTextField
                                  name="mcPurchasePrice"
                                  type="number"
                                  onChange={(e) => {
                                    if (parseFloat(e.target.value) >= 0) {
                                      if (
                                        storeProductsDetails[key][
                                          "purchaseByMain"
                                        ]
                                      ) {
                                        setStoreProductsDetails((prev) =>
                                          [
                                            (prev[key]["mcPurchasePrice"] =
                                              e.target.value),
                                            ...prev,
                                          ].slice(1)
                                        );
                                        setStoreProductsDetails((prev) =>
                                          [
                                            (prev[key]["scPurchasePrice"] =
                                              e.target.value * sc),
                                            ...prev,
                                          ].slice(1)
                                        );
                                      } else {
                                        setStoreProductsDetails((prev) =>
                                          [
                                            (prev[key]["mcPurchasePrice"] =
                                              e.target.value / sc),
                                            ...prev,
                                          ].slice(1)
                                        );
                                        setStoreProductsDetails((prev) =>
                                          [
                                            (prev[key]["scPurchasePrice"] =
                                              e.target.value),
                                            ...prev,
                                          ].slice(1)
                                        );
                                      }
                                    }
                                  }}
                                  value={
                                    storeProductsDetails[key] &&
                                    storeProductsDetails[key]["purchaseByMain"]
                                      ? storeProductsDetails[key][
                                          "mcPurchasePrice"
                                        ]
                                      : storeProductsDetails[key][
                                          "scPurchasePrice"
                                        ]
                                  }
                                />
                              ) : (
                                <div>No Permission</div>
                              )
                            ) : (
                              <MaterialTextField
                                name="mcPurchasePrice"
                                type="number"
                                onChange={(e) => {
                                  if (parseFloat(e.target.value) >= 0) {
                                    if (
                                      storeProductsDetails[key][
                                        "purchaseByMain"
                                      ]
                                    ) {
                                      setStoreProductsDetails((prev) =>
                                        [
                                          (prev[key]["mcPurchasePrice"] =
                                            e.target.value),
                                          ...prev,
                                        ].slice(1)
                                      );
                                      setStoreProductsDetails((prev) =>
                                        [
                                          (prev[key]["scPurchasePrice"] =
                                            e.target.value * sc),
                                          ...prev,
                                        ].slice(1)
                                      );
                                    } else {
                                      setStoreProductsDetails((prev) =>
                                        [
                                          (prev[key]["mcPurchasePrice"] =
                                            e.target.value / sc),
                                          ...prev,
                                        ].slice(1)
                                      );
                                      setStoreProductsDetails((prev) =>
                                        [
                                          (prev[key]["scPurchasePrice"] =
                                            e.target.value),
                                          ...prev,
                                        ].slice(1)
                                      );
                                    }
                                  }
                                }}
                                value={
                                  storeProductsDetails[key] &&
                                  storeProductsDetails[key]["purchaseByMain"]
                                    ? storeProductsDetails[key][
                                        "mcPurchasePrice"
                                      ]
                                    : storeProductsDetails[key][
                                        "scPurchasePrice"
                                      ]
                                }
                              />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <MaterialTextField
                              name="mcSellPrice"
                              type="number"
                              onChange={(e) => {
                                if (parseFloat(e.target.value) >= 0) {
                                  if (storeProductsDetails[key]["sellByMain"]) {
                                    setStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["mcSellPrice"] =
                                          e.target.value),
                                        ...prev,
                                      ].slice(1)
                                    );
                                    setStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["scSellPrice"] =
                                          e.target.value * sc),
                                        ...prev,
                                      ].slice(1)
                                    );
                                  } else {
                                    setStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["mcSellPrice"] =
                                          e.target.value / sc),
                                        ...prev,
                                      ].slice(1)
                                    );
                                    setStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["scSellPrice"] =
                                          e.target.value),
                                        ...prev,
                                      ].slice(1)
                                    );
                                  }
                                }
                              }}
                              value={
                                storeProductsDetails[key] &&
                                storeProductsDetails[key]["sellByMain"]
                                  ? storeProductsDetails[key]["mcSellPrice"]
                                  : storeProductsDetails[key]["scSellPrice"]
                              }
                            />
                          </TableCell>
                          <TableCell align="right">
                            <MaterialTextField
                              name="mcSmallMeasureSellPrice"
                              type="number"
                              onChange={(e) => {
                                if (parseFloat(e.target.value) >= 0) {
                                  if (storeProductsDetails[key]["sellByMain"]) {
                                    setStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["mcSmallMeasureSellPrice"] =
                                          e.target.value),
                                        ...prev,
                                      ].slice(1)
                                    );
                                    setStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["scSmallMeasureSellPrice"] =
                                          e.target.value * sc),
                                        ...prev,
                                      ].slice(1)
                                    );
                                  } else {
                                    setStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["mcSmallMeasureSellPrice"] =
                                          e.target.value / sc),
                                        ...prev,
                                      ].slice(1)
                                    );
                                    setStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["scSmallMeasureSellPrice"] =
                                          e.target.value),
                                        ...prev,
                                      ].slice(1)
                                    );
                                  }
                                }
                              }}
                              value={
                                storeProductsDetails[key] &&
                                storeProductsDetails[key]["sellByMain"]
                                  ? storeProductsDetails[key][
                                      "mcSmallMeasureSellPrice"
                                    ]
                                  : storeProductsDetails[key][
                                      "scSmallMeasureSellPrice"
                                    ]
                              }
                            />
                          </TableCell>
                          <TableCell align="right">
                            <MaterialTextField
                              name="alertQte"
                              type="number"
                              onChange={(e) => {
                                if (parseFloat(e.target.value) >= 0) {
                                  setStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["alertQte"] = e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                }
                              }}
                              value={
                                storeProductsDetails[key] &&
                                storeProductsDetails[key]["alertQte"]
                              }
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Switch
                              color="primary"
                              name="checkedB"
                              inputProps={{ "aria-label": "primary checkbox" }}
                              onChange={(e) => {
                                setStoreProductsDetails((prev) =>
                                  [
                                    (prev[key]["sellByMain"] =
                                      e.target.checked),
                                    ...prev,
                                  ].slice(1)
                                );
                              }}
                              checked={
                                storeProductsDetails[key] &&
                                storeProductsDetails[key]["sellByMain"]
                              }
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Switch
                              color="primary"
                              name="checkedB"
                              inputProps={{ "aria-label": "primary checkbox" }}
                              onChange={(e) => {
                                setStoreProductsDetails((prev) =>
                                  [
                                    (prev[key]["purchaseByMain"] =
                                      e.target.checked),
                                    ...prev,
                                  ].slice(1)
                                );
                              }}
                              checked={
                                storeProductsDetails[key] &&
                                storeProductsDetails[key]["purchaseByMain"]
                              }
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Switch
                              color="primary"
                              defaultChecked={false}
                              name="checkedB"
                              inputProps={{ "aria-label": "primary checkbox" }}
                              onChange={(e) => {
                                setStoreProductsDetails((prev) =>
                                  [
                                    (prev[key]["canSellBellowZero"] =
                                      e.target.checked),
                                    ...prev,
                                  ].slice(1)
                                );
                              }}
                              checked={
                                storeProductsDetails[key] &&
                                storeProductsDetails[key]["canSellBellowZero"]
                              }
                            />
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.formColumn}
                          >
                            <Snackbar
                              open={open}
                              autoHideDuration={4000}
                              onClose={handleClose}
                            >
                              <Alert onClose={handleClose} severity="error">
                                {errorMessage}
                              </Alert>
                            </Snackbar>
                            {!store.title && (
                              <IconButton
                                aria-label="resources.root.delete"
                                onClick={() => {
                                  swal({
                                    title: translate(
                                      "resources.sellInvoices.fields.makeSure"
                                    ),
                                    text: translate(
                                      "resources.sellInvoices.fields.confirmPerminantDelete"
                                    ),
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                  }).then((willDelete) => {
                                    if (willDelete) {
                                      HTTP.delete(
                                        `${url}/StoreProducts/${
                                          storeProductsDetails[key] &&
                                          storeProductsDetails[key]["id"]
                                        }`
                                      )
                                        .then((res) => {
                                          setTempStoreProductsDetails((prev) =>
                                            prev.filter(
                                              (el) => el.id !== store.id
                                            )
                                          );
                                        })
                                        .then((res) => {
                                          HTTP.get(
                                            `${url}/Products/${id}`
                                          ).then((res) => {
                                            setOriginalStoreProductsDetails(
                                              res.data.storeProducts
                                            );
                                          });
                                        })
                                        .catch((err) =>
                                          notify(
                                            err.response.data.message,
                                            "error"
                                          )
                                        );
                                    } else {
                                      swal(
                                        translate(
                                          "resources.sellInvoices.fields.deleteCancel"
                                        )
                                      );
                                    }
                                  });
                                }}
                              >
                                <DeleteIcon style={{ fontSize: "30px" }} />
                              </IconButton>
                            )}
                            {!store.title ? (
                              <IconButton
                                size={"medium"}
                                aria-label="resources.root.update"
                                onClick={() => {
                                  HTTP.put(
                                    `${url}/StoreProducts/${
                                      storeProductsDetails[key] &&
                                      storeProductsDetails[key]["id"]
                                    }`,
                                    {
                                      id:
                                        storeProductsDetails[key] &&
                                        storeProductsDetails[key]["id"],
                                      productId: id,
                                      storeId:
                                        storeProductsDetails[key] &&
                                        storeProductsDetails[key]["storeId"],
                                      mcPurchasePrice:
                                        storeProductsDetails[key] &&
                                        storeProductsDetails[key][
                                          "mcPurchasePrice"
                                        ],
                                      scPurchasePrice:
                                        storeProductsDetails[key] &&
                                        storeProductsDetails[key][
                                          "scPurchasePrice"
                                        ],
                                      mcSellPrice:
                                        storeProductsDetails[key] &&
                                        storeProductsDetails[key][
                                          "mcSellPrice"
                                        ],
                                      scSellPrice:
                                        storeProductsDetails[key] &&
                                        storeProductsDetails[key][
                                          "scSellPrice"
                                        ],
                                      mcSmallMeasureSellPrice:
                                        storeProductsDetails[key] &&
                                        storeProductsDetails[key][
                                          "mcSmallMeasureSellPrice"
                                        ],
                                      scSmallMeasureSellPrice:
                                        storeProductsDetails[key] &&
                                        storeProductsDetails[key][
                                          "scSmallMeasureSellPrice"
                                        ],
                                      qteInStock:
                                        storeProductsDetails[key] &&
                                        storeProductsDetails[key]["qteInStock"],
                                      productionDate:
                                        storeProductsDetails[key] &&
                                        storeProductsDetails[key][
                                          "productionDate"
                                        ],
                                      alertQte:
                                        storeProductsDetails[key] &&
                                        storeProductsDetails[key]["alertQte"],
                                      expireDate:
                                        storeProductsDetails[key] &&
                                        storeProductsDetails[key]["expireDate"],
                                      purchaseByMain:
                                        storeProductsDetails[key] &&
                                        storeProductsDetails[key][
                                          "purchaseByMain"
                                        ],
                                      sellByMain:
                                        storeProductsDetails[key] &&
                                        storeProductsDetails[key]["sellByMain"],
                                      canSellBellowZero:
                                        storeProductsDetails[key] &&
                                        storeProductsDetails[key][
                                          "canSellBellowZero"
                                        ],
                                    }
                                  )
                                    .then(() => {
                                      HTTP.get(`${url}/Products/${id}`).then(
                                        (res) => {
                                          setOriginalStoreProductsDetails(
                                            res.data.storeProducts
                                          );
                                        }
                                      );
                                      notify(
                                        translate("resources.root.updated")
                                      );
                                    })
                                    .catch((err) =>
                                      notify(err.response.data.message, {
                                        type: "error",
                                        undoable: true,
                                      })
                                    );
                                }}
                              >
                                <UpdateIcon style={{ fontSize: "30px" }} />
                              </IconButton>
                            ) : (
                              <IconButton
                                id={`saveButton`}
                                aria-label="resources.root.delete"
                                onClick={() => {
                                  document.getElementById(
                                    "saveButton"
                                  ).disabled = true;
                                  if (
                                    storeProductsDetails[key] &&
                                    !storeProductsDetails[key]["alertQte"]
                                  ) {
                                    setErrorMessage(
                                      "Please fill in the Alert Quantity."
                                    );
                                    setOpen(true);
                                    document.getElementById(
                                      "saveButton"
                                    ).disabled = false;
                                    return;
                                  } else if (
                                    storeProductsDetails[key] &&
                                    !(
                                      storeProductsDetails[key][
                                        "mcSellPrice"
                                      ] ||
                                      storeProductsDetails[key]["scSellPrice"]
                                    )
                                  ) {
                                    setErrorMessage(
                                      "Please fill in Selling Price."
                                    );
                                    setOpen(true);
                                    document.getElementById(
                                      "saveButton"
                                    ).disabled = false;
                                    return;
                                  }
                                  HTTP.post(`${url}/StoreProducts`, {
                                    productId: id,
                                    storeId:
                                      storeProductsDetails[key] &&
                                      storeProductsDetails[key]["id"],
                                    mcPurchasePrice:
                                      storeProductsDetails[key] &&
                                      storeProductsDetails[key][
                                        "mcPurchasePrice"
                                      ],
                                    scPurchasePrice:
                                      storeProductsDetails[key] &&
                                      storeProductsDetails[key][
                                        "scPurchasePrice"
                                      ],
                                    mcSellPrice:
                                      storeProductsDetails[key] &&
                                      storeProductsDetails[key]["mcSellPrice"],
                                    scSellPrice:
                                      storeProductsDetails[key] &&
                                      storeProductsDetails[key]["scSellPrice"],
                                    mcSmallMeasureSellPrice:
                                      storeProductsDetails[key] &&
                                      storeProductsDetails[key][
                                        "mcSmallMeasureSellPrice"
                                      ],
                                    scSmallMeasureSellPrice:
                                      storeProductsDetails[key] &&
                                      storeProductsDetails[key][
                                        "scSmallMeasureSellPrice"
                                      ],
                                    initialQte:
                                      storeProductsDetails[key] &&
                                      storeProductsDetails[key]["qteInStock"],
                                    productionDate:
                                      storeProductsDetails[key] &&
                                      storeProductsDetails[key][
                                        "productionDate"
                                      ],
                                    alertQte:
                                      storeProductsDetails[key] &&
                                      storeProductsDetails[key]["alertQte"],
                                    expireDate:
                                      storeProductsDetails[key] &&
                                      storeProductsDetails[key]["expireDate"],
                                    purchaseByMain:
                                      (storeProductsDetails[key] &&
                                        typeof storeProductsDetails[key][
                                          "purchaseByMain"
                                        ]) === "undefined"
                                        ? false
                                        : storeProductsDetails[key][
                                            "purchaseByMain"
                                          ],
                                    sellByMain:
                                      (storeProductsDetails[key] &&
                                        typeof storeProductsDetails[key][
                                          "sellByMain"
                                        ]) === "undefined"
                                        ? false
                                        : storeProductsDetails[key][
                                            "sellByMain"
                                          ],
                                    canSellBellowZero:
                                      (storeProductsDetails[key] &&
                                        typeof storeProductsDetails[key][
                                          "canSellBellowZero"
                                        ]) === "undefined"
                                        ? false
                                        : storeProductsDetails[key][
                                            "canSellBellowZero"
                                          ],
                                  })
                                    .then((res) => {
                                      setTempStoreProductsDetails((prev) =>
                                        prev.filter((el) => el.id !== store.id)
                                      );
                                    })
                                    .then(() => {
                                      HTTP.get(`${url}/Products/${id}`).then(
                                        (res) => {
                                          setOriginalStoreProductsDetails(
                                            res.data.storeProducts
                                          );
                                        }
                                      );
                                      document.getElementById(
                                        "saveButton"
                                      ).disabled = false;
                                    })
                                    .catch((err) =>
                                      notify(err.response.data.message, {
                                        type: "error",
                                        undoable: true,
                                      })
                                    );
                                  document.getElementById(
                                    "saveButton"
                                  ).disabled = false;
                                }}
                              >
                                <SaveIcon style={{ fontSize: "30px" }} />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </FormTab>
        {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
          <FormTab label="resources.root.serials">
            {/* <div className={classes.productCols}>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={stores}
              disableCloseOnSelect
              getOptionLabel={(option) => option.title}
              renderOption={(props, option) => (
                <React.Fragment>
                  <div style={{ display: "block" }}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={props.selected}
                      onChange={(e) => {
                        let selectedStores = storeProductsDetails;
                        let find = selectedStores.find(
                          (el) => el.id === option.id
                        );
                        if (find) {
                          return;
                        }
                        setStoreProductsDetails([...selectedStores, option]);
                        setStores(stores.filter((el) => el.id !== option.id));
                        // setStoreProductsDetails(
                        //     stores.map((el) => {
                        //       return el;
                        //     })
                        // );
                        // setTempStoreProductsDetails([...stores.map((el) => el)]);
                      }}
                    />
                    {option.title}
                  </div>
                </React.Fragment>
              )}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <MaterialTextField
                  {...params}
                  variant="outlined"
                  label={translate("resources.root.stores")}
                  placeholder={translate("resources.root.chooseStore")}
                />
              )}
            />
          </div> */}

            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table stickyHeader className={classes.table} size="small">
                <TableHead>
                  <TableRow hover>
                    <TableCell>{translate("resources.root.id")}</TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.serial")}
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.productionDate")}
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.expireDate")}
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.alertQuantity")}
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.canSellBelowZero")}
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.actions")}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody className={classes.scrollable}>
                  {productSerialDetails &&
                    productSerialDetails
                      .filter((el) => el.id !== 0)
                      .map((serial, key) => {
                        return (
                          <TableRow hover key={serial.id}>
                            <TableCell component="th" scope="row">
                              {serial.id || serial.store.id}
                            </TableCell>
                            <TableCell align="right">
                              <MaterialTextField
                                name="serialNumber"
                                disabled
                                onChange={(e) => {
                                  setProductSerialDetails((prev) =>
                                    [
                                      (prev[key]["serialNumber"] =
                                        e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                }}
                                value={
                                  productSerialDetails[key] &&
                                  productSerialDetails[key]["serialNumber"]
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <input
                                style={{
                                  marginRight: 5,
                                  padding: 6,
                                  borderRadius: 4,
                                  borderWidth: 1,
                                  borderStyle: "solid",
                                  borderColor: "gray",
                                  width: "100%",
                                }}
                                type="date"
                                label="resources.root.productionDate"
                                source="productionDate"
                                alwaysOn
                                onChange={(e) => {
                                  setProductSerialDetails((prev) =>
                                    [
                                      (prev[key]["productionDate"] =
                                        e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                }}
                                defaultValue={
                                  productSerialDetails[key] &&
                                  moment(
                                    productSerialDetails[key]["productionDate"]
                                  ).format("YYYY-MM-DD")
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <input
                                style={{
                                  marginRight: 5,
                                  padding: 6,
                                  borderRadius: 4,
                                  borderWidth: 1,
                                  borderStyle: "solid",
                                  borderColor: "gray",
                                  width: "100%",
                                }}
                                type="date"
                                label="resources.root.expirationDate"
                                source="expirationDate"
                                alwaysOn
                                onChange={(e) => {
                                  setProductSerialDetails((prev) =>
                                    [
                                      (prev[key]["expirationDate"] =
                                        e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                }}
                                defaultValue={
                                  productSerialDetails[key] &&
                                  moment(
                                    productSerialDetails[key]["expirationDate"]
                                  ).format("YYYY-MM-DD")
                                }
                              />
                            </TableCell>

                            <TableCell align="right">
                              <MaterialTextField
                                name="alertQte"
                                type="number"
                                onChange={(e) => {
                                  if (parseFloat(e.target.value) >= 0) {
                                    setStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["alertQte"] =
                                          e.target.value),
                                        ...prev,
                                      ].slice(1)
                                    );
                                  }
                                }}
                                value={
                                  productSerialDetails[key] &&
                                  productSerialDetails[key]["alertQte"]
                                }
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Switch
                                color="primary"
                                defaultChecked={false}
                                name="checkedB"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                                onChange={(e) => {
                                  setStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["canSellBellowZero"] =
                                        e.target.checked),
                                      ...prev,
                                    ].slice(1)
                                  );
                                }}
                                checked={
                                  productSerialDetails[key] &&
                                  productSerialDetails[key]["canSellBellowZero"]
                                }
                              />
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.formColumn}
                            >
                              <Snackbar
                                open={open}
                                autoHideDuration={4000}
                                onClose={handleClose}
                              >
                                <Alert onClose={handleClose} severity="error">
                                  {errorMessage}
                                </Alert>
                              </Snackbar>
                              {serial.id !== 0 && (
                                <IconButton
                                  aria-label="resources.root.delete"
                                  onClick={() => {
                                    swal({
                                      title: translate(
                                        "resources.sellInvoices.fields.makeSure"
                                      ),
                                      text: translate(
                                        "resources.sellInvoices.fields.confirmPerminantDelete"
                                      ),
                                      icon: "warning",
                                      buttons: true,
                                      dangerMode: true,
                                    }).then((willDelete) => {
                                      if (willDelete) {
                                        HTTP.delete(
                                          `${url}/ProductSerials/${
                                            productSerialDetails[key] &&
                                            productSerialDetails[key]["id"]
                                          }`
                                        )
                                          .then((res) => {
                                            setTempStoreProductsDetails(
                                              (prev) =>
                                                prev.filter(
                                                  (el) => el.id !== serial.id
                                                )
                                            );
                                          })
                                          .then((res) => {
                                            HTTP.get(
                                              `${url}/Products/${id}`
                                            ).then((res) => {
                                              setProductSerialDetails(
                                                res.data.ProductSerials
                                              );
                                            });
                                          })
                                          .catch((err) =>
                                            notify(
                                              err.response.data.message,
                                              "error"
                                            )
                                          );
                                      } else {
                                        swal(
                                          translate(
                                            "resources.sellInvoices.fields.deleteCancel"
                                          )
                                        );
                                      }
                                    });
                                  }}
                                >
                                  <DeleteIcon style={{ fontSize: "30px" }} />
                                </IconButton>
                              )}
                              {serial.id !== 0 ? (
                                <IconButton
                                  size={"medium"}
                                  aria-label="resources.root.update"
                                  onClick={() => {
                                    HTTP.put(
                                      `${url}/ProductSerials/${
                                        productSerialDetails[key] &&
                                        productSerialDetails[key]["id"]
                                      }`,
                                      {
                                        id:
                                          productSerialDetails[key] &&
                                          productSerialDetails[key]["id"],
                                        productId: id,
                                        storeId:
                                          productSerialDetails[key] &&
                                          productSerialDetails[key]["storeId"],
                                        serialNumber:
                                          productSerialDetails[key] &&
                                          productSerialDetails[key][
                                            "serialNumber"
                                          ],
                                        productionDate:
                                          productSerialDetails[key] &&
                                          productSerialDetails[key][
                                            "productionDate"
                                          ],
                                        expirationDate:
                                          productSerialDetails[key] &&
                                          productSerialDetails[key][
                                            "expirationDate"
                                          ],
                                        alertQte:
                                          productSerialDetails[key] &&
                                          productSerialDetails[key]["alertQte"],
                                        canSellBellowZero:
                                          productSerialDetails[key] &&
                                          productSerialDetails[key][
                                            "canSellBellowZero"
                                          ],
                                      }
                                    )
                                      .then(() => {
                                        HTTP.get(`${url}/Products/${id}`).then(
                                          (res) => {
                                            setProductSerialDetails(
                                              res.data.productSerials
                                            );
                                          }
                                        );
                                        notify(
                                          translate("resources.root.updated")
                                        );
                                      })
                                      .catch((err) =>
                                        notify(err.response.data.message, {
                                          type: "error",
                                          undoable: true,
                                        })
                                      );
                                  }}
                                >
                                  <UpdateIcon style={{ fontSize: "30px" }} />
                                </IconButton>
                              ) : (
                                <IconButton
                                  id={`saveButton`}
                                  aria-label="resources.root.save"
                                  onClick={() => {
                                    document.getElementById(
                                      "saveButton"
                                    ).disabled = true;
                                    if (
                                      productSerialDetails[key] &&
                                      !productSerialDetails[key]["alertQte"]
                                    ) {
                                      setErrorMessage(
                                        "Please fill in the Alert Quantity."
                                      );
                                      setOpen(true);
                                      document.getElementById(
                                        "saveButton"
                                      ).disabled = false;
                                      return;
                                    } else if (
                                      productSerialDetails[key] &&
                                      !(
                                        productSerialDetails[key][
                                          "mcSellPrice"
                                        ] ||
                                        productSerialDetails[key]["scSellPrice"]
                                      )
                                    ) {
                                      setErrorMessage(
                                        "Please fill in Selling Price."
                                      );
                                      setOpen(true);
                                      document.getElementById(
                                        "saveButton"
                                      ).disabled = false;
                                      return;
                                    }
                                    HTTP.post(`${url}/ProductSerials`, {
                                      productId: id,
                                      storeId:
                                        productSerialDetails[key] &&
                                        productSerialDetails[key]["id"],
                                      serialNumber:
                                        productSerialDetails[key] &&
                                        productSerialDetails[key][
                                          "serialNumber"
                                        ],
                                      productionDate:
                                        productSerialDetails[key] &&
                                        productSerialDetails[key][
                                          "productionDate"
                                        ],
                                      expirationDate:
                                        productSerialDetails[key] &&
                                        productSerialDetails[key][
                                          "expirationDate"
                                        ],
                                      alertQte:
                                        productSerialDetails[key] &&
                                        productSerialDetails[key]["alertQte"],
                                      canSellBellowZero:
                                        productSerialDetails[key] &&
                                        productSerialDetails[key][
                                          "canSellBellowZero"
                                        ],

                                      alertQte:
                                        productSerialDetails[key] &&
                                        productSerialDetails[key]["alertQte"],
                                      canSellBellowZero:
                                        (productSerialDetails[key] &&
                                          typeof productSerialDetails[key][
                                            "canSellBellowZero"
                                          ]) === "undefined"
                                          ? false
                                          : productSerialDetails[key][
                                              "canSellBellowZero"
                                            ],
                                    })
                                      .then((res) => {
                                        setTempStoreProductsDetails((prev) =>
                                          prev.filter(
                                            (el) => el.id !== serial.id
                                          )
                                        );
                                      })
                                      .then(() => {
                                        HTTP.get(`${url}/Products/${id}`).then(
                                          (res) => {
                                            setOriginalStoreProductsDetails(
                                              res.data.storeProducts
                                            );
                                          }
                                        );
                                        document.getElementById(
                                          "saveButton"
                                        ).disabled = false;
                                      })
                                      .catch((err) =>
                                        notify(err.response.data.message, {
                                          type: "error",
                                          undoable: true,
                                        })
                                      );
                                    document.getElementById(
                                      "saveButton"
                                    ).disabled = false;
                                  }}
                                >
                                  <SaveIcon style={{ fontSize: "30px" }} />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles((theme) => ({
  input: {
    width: "30%",
  },
  formContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  formColumn: {
    display: "flex",
    justifyContent: "space-between",
  },
  perColumnInput: {
    flex: 1,
    marginLeft: 5,
  },
  productInputsContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottomStyle: "solid",
    borderBottomColor: "#eee",
    borderBottomWidth: 1,
    paddingBottom: 10,
    marginBottom: 10,
  },
  storeProductInputsContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottomStyle: "solid",
    borderBottomColor: "#eee",
    borderBottomWidth: 1,
    marginBottom: 10,
    flexWrap: "wrap",
    "&:after": {
      content: "''",
      flex: 1,
    },
  },
  sampleStoreProductInputsContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottomStyle: "solid",
    borderBottomColor: "#eee",
    borderBottomWidth: 1,
    marginBottom: 10,
    backgroundColor: "#f2f2f2",
    flexWrap: "wrap",
    paddingTop: 10,
    "&:after": {
      content: "''",
      flex: 1,
    },
  },
  productInputsColumn: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    margin: 5,
  },
  ProductsInput: {
    marginRight: 10,
    width: "15%",
  },
  root: {
    marginTop: 72,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  productCols: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: 10,
  },
  productNumberInput: {
    width: "15% !important",
    marginRight: 10,
    marginBottom: 5,
    marginTop: 5,
  },
  productTextInput: {
    width: "20% !important",
    marginRight: 10,
    marginBottom: 5,
    marginTop: 5,
  },
  productLongTextInput: {
    width: "25% !important",
    marginLeft: 10,
  },
  tableHeader: {
    textAlign: "center",
  },
  tableContainer: {
    width: "100%",
  },
  primary: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
  },
  secondary: {
    // backgroundColor: 'rgb(220, 0, 78)',
    color: "rgb(220, 0, 78)",
  },
  switchLabel: {
    fontFamily: "monospace",
  },
}));
