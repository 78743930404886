import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  useTranslate,
  Show,
  TabbedShowLayout,
  Tab,
  DateField,
  ReferenceField,
  BooleanField,
  useResourceDefinition,
} from "react-admin";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { url } from "../../request";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import rtl from "jss-rtl";
import { ListActions } from "../templates/ListActions";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { MyCustomPagination, SearchFilter } from "../../App";
import Page from "../CustomPages/SellReceipt/Page";
import { HTTP } from "../../axios";
import BulkAction from "../CustomComponents/BulkActions";
import { useParams } from "react-router-dom";

export const RepresentativeCustomerSellInvoicesList = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const [orders, setOrders] = React.useState([]);

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div>
      <PrintProvider>
        <NoPrint force>
          <List
            filters={<SearchFilter date={true} />}
            pagination={<MyCustomPagination />}
            {...props}
            sort={{ field: "id", order: "DESC" }}
            actions={
              <ListActions setCurrency={setCurrency} currency={currency} />
            }
          >
            <Datagrid
              bulkActionButtons={
                <BulkAction
                  {...props}
                  hasPrintOrders={"RepresentativeCustomerInvoice"}
                />
              }
              rowClick={"show"}
            >
              <TextField source="id" label="resources.root.id" />
              <FunctionField
                label="ra.navigation.RepresentativeCustomers"
                render={(record) =>
                  `${record.representativeCustomer.placeName}`
                }
              />
              <FunctionField
                label="resources.root.representativeName"
                render={(record) =>
                  `${record.representativeCustomer.representative.firstName}`
                }
              />
              {currency === "default" && (
                <FunctionField
                  label="resources.root.price"
                  render={(record) =>
                    record.payByMain
                      ? `$${record.mcFinalPrice?.toLocaleString()}`
                      : `${record.scFinalPrice?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`
                  }
                />
              )}
              {currency !== "default" && (
                <FunctionField
                  label="Price"
                  render={(record) =>
                    currency === "dollar"
                      ? `$${record.mcFinalPrice?.toLocaleString()}`
                      : `${record.scFinalPrice?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`
                  }
                />
              )}
              <TextField source="totalQte" label="resources.root.totalQty" />
              <FunctionField
                label="resources.root.finalQuantity"
                render={(record) =>
                  (record.totalQte + record.totalGiftQte)?.toLocaleString()
                }
              />
              <BooleanField source="isCash" label="resources.root.isCash" />
              <DateField
                source="date"
                label="resources.root.date"
                locales={"en-GB"}
              />
            </Datagrid>
          </List>
        </NoPrint>
        <div>
          <Print single={true} name="foo">
            <div className={classes.ordersPrintWrapper}>
              <div style={{ marginBottom: 20 }}>
                {translate("resources.root.date")}:{" "}
                {moment().format("YYYY-MM-DD")}
              </div>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell align="right">
                        {translate("resources.root.name")}
                      </TableCell>
                      <TableCell align="right">
                        {translate("resources.root.barcode")}
                      </TableCell>
                      <TableCell align="right">
                        {translate("resources.root.quantity")}
                      </TableCell>
                      <TableCell align="right">
                        {translate("resources.root.giftQuantity")}
                      </TableCell>
                      <TableCell align="right">
                        {translate("resources.root.driver")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((order, key) => (
                      <TableRow key={order.name}>
                        <TableCell component="th" scope="row">
                          {key + 1}
                        </TableCell>
                        <TableCell align="right">{order.name}</TableCell>
                        <TableCell align="right">{order.barcode}</TableCell>
                        <TableCell align="right">{order.qty}</TableCell>
                        <TableCell align="right">{order.giftQty}</TableCell>
                        <TableCell align="right">{order.driver}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Print>
        </div>
      </PrintProvider>
    </div>
  );
};

export const RepresentativeCustomerSellInvoicesShow = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const { id } = useParams();
  const props = useResourceDefinition();
  const [orders, setOrders] = React.useState([]);
  const [invoice, setInvoice] = React.useState([]);
  React.useEffect(() => {
    HTTP.get(`${url}/RepresentativeCustomerSellInvoices/${id}`).then((res) => {
      setOrders(res.data.representativeCustomerSellOrders);
      setInvoice(res.data);
    });
  }, [props]);

  return (
    <div>
      <PrintProvider>
        <Print single={true} name="foo">
          <div className={classes.printWrapper}>
            <Page
              invoice={invoice}
              title={translate("resources.root.sellInvoice")}
              order={invoice.representativeCustomerSellOrders}
              human={invoice.representativeCustomer}
              name={
                invoice.representativeCustomer &&
                invoice.representativeCustomer.placeName
              }
            />
          </div>
        </Print>
        <NoPrint force>
          <Show>
            <TabbedShowLayout>
              <Tab label="resources.root.invoice">
                <FunctionField
                  label="resources.root.name"
                  render={(record) =>
                    `${record.representativeCustomer.placeName}`
                  }
                />
                <TextField
                  source="totalQte"
                  label="resources.root.totalQuantity"
                />
                <TextField
                  source="totalGiftQte"
                  label="resources.root.giftQuantity"
                />
                <FunctionField
                  label="resources.root.finalQuantity"
                  render={(record) =>
                    `${record.totalQte + record.totalGiftQte}`
                  }
                />
                <TextField source="note" />
                {invoice.payByMain ? (
                  <TextField
                    source="mcTotalPrice"
                    label="resources.root.totalPrice"
                  />
                ) : (
                  <TextField
                    source="scTotalPrice"
                    label="resources.root.totalPrice"
                  />
                )}
                {invoice.payByMain ? (
                  <TextField
                    source="mcFinalPrice"
                    label="resources.root.finalPrice"
                  />
                ) : (
                  <TextField
                    source="scFinalPrice"
                    label="resources.root.finalPrice"
                  />
                )}
                {invoice.payByMain ? (
                  <TextField
                    source="mcFinalDiscount"
                    label="resources.root.finalDiscount"
                  />
                ) : (
                  <TextField
                    source="scFinalDiscount"
                    label="resources.root.finalDiscount"
                  />
                )}
              </Tab>
              <Tab label="resources.root.orders">
                <TableContainer
                  component={Paper}
                  className={classes.tableContainer}
                >
                  <Table
                    stickyHeader
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {translate("resources.root.barcode")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.name")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.quantity")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.gifts")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.price")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.totalPrice")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.scrollable}>
                      {orders &&
                        orders.map((order) => (
                          <TableRow key={order.product.barcode}>
                            <TableCell component="th" scope="row">
                              {order.product.barcode}
                            </TableCell>
                            <TableCell align="right">
                              {order.product.name}
                            </TableCell>
                            <TableCell align="right">
                              {order.qte} + {order.smallMeasureQte}
                            </TableCell>
                            <TableCell align="right">
                              {order.giftQte} + {order.smallMeasureGiftQte}
                            </TableCell>
                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcSellPrice
                                : order.scSellPrice}
                            </TableCell>
                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcLastPrice
                                : order.scLastPrice}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Tab>
            </TabbedShowLayout>
          </Show>
          <Button
            variant="contained"
            className={classes.botton}
            onClick={() => {
              window.print();
            }}
          >
            {translate("resources.root.print")}
          </Button>
        </NoPrint>
      </PrintProvider>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginTop: 72,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  invoiceNumberInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  discount: {
    width: "10% !important",
    marginLeft: 5,
    marginTop: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 20,
    maxHeight: "350px !important",
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  printWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "140%",
      marginLeft: theme.direction === "rtl" ? 0 : -230,
      marginRight: theme.direction === "rtl" ? -230 : -230,
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      display: "block",
      width: "80%",
      marginLeft: 265,
      paddingTop: 60,
    },
  },
}));
