import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  EditButton,
  FunctionField,
  DateInput,
  BooleanInput,
  NumberInput,
  AutocompleteInput,
  required,
  TabbedForm,
  FormTab,
  FormDataConsumer,
  maxValue,
  minValue,
  number,
  useTranslate,
  useRefresh,
  downloadCSV,
  BooleanField,
  useNotify,
  Show,
  SimpleShowLayout,
  DateField,
  ShowButton,
  NumberField,
  ArrayField,
  ImageField,
  ImageInput,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import { url } from "../../request";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/lab/Autocomplete";
import MaterialTextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import MaterialButton from "@mui/material/Button";
import swal from "sweetalert";
import { ListActions } from "../templates/ListActions";
import moment from "moment";
import { MyCustomPagination, SearchFilter } from "../../App";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import { FaCalendar } from "react-icons/fa";
import BulkAction from "../CustomComponents/BulkActions";
import jsonExport from "jsonexport/dist";
import { HTTP } from "../../axios";
import { useParams } from "react-router-dom";
import { path } from "../../request";

const BulkActionButtons = (props) => {
  const refresh = useRefresh();
  const translate = useTranslate();

  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          color="primary"
          style={{ marginBottom: 15 }}
          onClick={() => {
            swal({
              text: `${translate("resources.root.days")}`,
              content: "input",
              button: {
                text: `${translate("resources.root.update")}`,
                closeModal: false,
              },
            }).then((days) => {
              if (days) {
                HTTP.post(
                  `${url}/Customers/BatchUpdateLimitDays?days=${days}`,
                  props.selectedIds
                )
                  .then(() => {
                    swal.stopLoading();
                    swal.close();
                    refresh();
                  })
                  .catch((err) => {
                    if (err) {
                      swal("Oh noes!", "The AJAX request failed!", "error");
                    } else {
                      swal.stopLoading();
                      swal.close();
                    }
                  });
              } else {
                swal(
                  `${translate("resources.root.pleaseEnterNumberOfDays")}`,
                  "",
                  "error"
                );
              }
            });
          }}
        >
          <FaCalendar /> &nbsp; {translate("resources.root.updateLimitDays")}
        </Button>
        <BulkAction {...props} />
      </div>
    </React.Fragment>
  );
};

export const CustomersList = (props) => {
  const translate = useTranslate();
  const [currency, setCurrency] = React.useState("default");
  const [customers, setCustomers] = React.useState([]);
  const exporter = (data) => {
    const dataForExport = data.map((data) => {
      let {
        id,
        firstName,
        middleName,
        lastName,
        male,
        dob,
        email,
        phone,
        phone2,
        address,
        mcCurrentLoan,
        scCurrentLoan,
        mcOpenAccountLoan,
        scOpenAccountLoan,
        payLoanByMain,
        mcRoofLoan,
        scRoofLoan,
      } = data;
      let fullName = firstName + " " + middleName + " " + lastName;
      let gender = male
        ? translate("resources.root.male")
        : translate("resources.root.female");
      let birthdate = moment(dob).format("YYYY-MM-DD");
      let phoneNumber = phone.replace("964", "0");
      let phoneNumber2 = phone2 ? phone2.replace("964", "0") : "-";
      let currentLoan = payLoanByMain ? mcCurrentLoan : scCurrentLoan;
      let initialLoan = payLoanByMain ? mcOpenAccountLoan : scOpenAccountLoan;
      let roofLoan = payLoanByMain ? mcRoofLoan : scRoofLoan;
      return {
        id,
        fullName,
        gender,
        birthdate,
        email,
        phoneNumber,
        phoneNumber2,
        address,
        currentLoan,
        initialLoan,
        roofLoan,
      };
    });
    jsonExport(
      dataForExport,
      {
        headers: [
          "id",
          "fullName",
          "gender",
          "birthdate",
          "email",
          "phoneNumber",
          "phoneNumber2",
          "address",
          "currentLoan",
          "initialLoan",
          "roofLoan",
        ],
        rename: [
          translate("resources.root.id"),
          translate("resources.root.name"),
          translate("resources.root.gender"),

          process.env.REACT_APP_APP_NAME === "Mediply"
            ? translate("resources.root.startingDate")
            : translate("resources.root.birthdate"),

          translate("resources.root.email"),
          translate("resources.root.phone"),
          translate("resources.root.phone2"),
          translate("resources.root.address"),
          translate("resources.root.currentLoan"),
          translate("resources.root.initialLoan"),
          translate("resources.root.roofLoan"),
        ],
      },
      (err, csv) => {
        downloadCSV(
          "\ufeff" + csv,
          "customers." + moment(new Date()).format("YYMMDDHHmmssa")
        );
      }
    );
  };
  return (
    <List
      {...props}
      exporter={exporter}
      pagination={<MyCustomPagination />}
      filters={<SearchFilter />}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions setCurrency={setCurrency} currency={currency} />}
    >
      <Datagrid
        bulkActionButtons={<BulkActionButtons setOrders={setCustomers} />}
      >
        <TextField source="id" label="resources.root.id" />
        <FunctionField
          sortBy={"firstName"}
          label="resources.root.customerName"
          render={(record) => (
            <a
              href={`#/Reports/CustomerStatement/${record.id}`}
              target={`_blank`}
            >{`${record.firstName} ${record.middleName} ${record.lastName}`}</a>
          )}
        />
        <TextField source="email" label="resources.root.email" />
        <TextField source="phone" label="resources.root.phone" />
        <TextField source="address" label="resources.root.address" />
        {currency === "default" && (
          <FunctionField
            sortBy={"mcCurrentLoan"}
            label="resources.root.currentLoan"
            render={(record) =>
              (record.mcCurrentLoan || record.scCurrentLoan) &&
              (record.payLoanByMain
                ? `$${record.mcCurrentLoan?.toLocaleString()}`
                : `${record.scCurrentLoan?.toLocaleString()} IQD`)
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            sortBy={"mcCurrentLoan"}
            label="resources.root.currentLoan"
            render={(record) =>
              (record.mcCurrentLoan || record.scCurrentLoan) &&
              (currency === "dollar"
                ? `$${record.mcCurrentLoan?.toLocaleString()}`
                : `${record.scCurrentLoan?.toLocaleString()} resources.root.iqd`)
            }
          />
        )}
        <BooleanField source="payLoanByMain" label="resources.root.isDollar" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const CustomersCreate = (props) => {
  const [choices, setChoices] = React.useState([]);
  const [selectedChoices, setSelectedChoices] = React.useState([]);
  const [gender, setGender] = React.useState(true);
  const [currency, setCurrency] = React.useState(true);
  const classes = useStyles();
  React.useEffect(() => {
    HTTP.get(`${url}/Stores`).then((res) => {
      setChoices(res.data);
    });
  }, []);

  const optionRenderer = (choice) => `${choice.title}`;
  return (
    <Create
      {...props}
      transform={(data) => ({
        ...data,
        storeIds: [data.storeIds],
        country: "Iraq",
        city: "Erbil",
      })}
      redirect="list"
    >
      <SimpleForm defaultValues={{ discountRate: 0 }}>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <AutocompleteInput
              source="storeIds"
              choices={choices}
              optionText={optionRenderer}
              onSelect={(val) => setSelectedChoices([...selectedChoices, val])}
              label="resources.root.storeIds"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="firstName"
              label="resources.root.firstName"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="middleName"
              label="resources.root.middleName"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="lastName"
              label="resources.root.lastName"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="email"
              label="resources.root.email"
              className={classes.input}
            />
            <TextInput
              source="address"
              label="resources.root.address"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="phone"
              label="resources.root.phone"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="phone2"
              label="resources.root.phone2"
              className={classes.input}
            />
            <BooleanInput
              source="male"
              label={gender ? "resources.root.male" : "resources.root.female"}
              defaultValue={true}
              onChange={(e) => setGender(e.target.checked)}
              className={classes.input}
              validate={[required()]}
            />
            <NumberInput
              source="loanLimitDays"
              label="resources.root.loanLimitDays"
              className={classes.input}
            />
          </div>
          <div className={classes.formCol}>
            {process.env.REACT_APP_APP_NAME === "Mediply" ? (
              <DateInput
                source="dob"
                label="resources.root.startingDate"
                defaultValue={moment().subtract(18, "years")}
                className={classes.input}
                validate={[required()]}
              />
            ) : (
              <DateInput
                source="dob"
                label="resources.root.dob"
                defaultValue={moment().subtract(18, "years")}
                className={classes.input}
                validate={[required()]}
              />
            )}
            <TextInput
              source="nationality"
              label="resources.root.nationality"
              defaultValue="Iraqi"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="currentWork"
              label="resources.root.currentWork"
              className={classes.input}
            />
            <TextInput
              source="note"
              label="resources.root.note"
              className={classes.input}
            />
            <TextInput
              source="idCardNumber"
              label="resources.root.idCardNumber"
              className={classes.input}
            />
            <TextInput
              source="idCardType"
              label="resources.root.idCardType"
              className={classes.input}
            />
            {currency ? (
              <NumberInput
                validate={[required(), minValue(0)]}
                min={0}
                source="mcRoofLoan"
                defaultValue={0}
                label="resources.root.mcRoofLoan"
                className={classes.input}
              />
            ) : (
              <NumberInput
                validate={[required(), minValue(0)]}
                min={0}
                source="scRoofLoan"
                defaultValue={0}
                label="resources.root.scRoofLoan"
                className={classes.input}
              />
            )}
            {currency ? (
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    <NumberInput
                      validate={[
                        required(),
                        maxValue(formData.mcRoofLoan ? formData.mcRoofLoan : 0),
                        minValue(0),
                      ]}
                      max={formData.mcRoofLoan ? formData.mcRoofLoan : 0}
                      min={0}
                      defaultValue={0}
                      source="mcOpenAccountLoan"
                      label="resources.root.mcOpenAccountLoan"
                      className={classes.input}
                    />
                  );
                }}
              </FormDataConsumer>
            ) : (
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    <NumberInput
                      validate={[
                        required(),
                        maxValue(formData.scRoofLoan ? formData.scRoofLoan : 0),
                        minValue(0),
                      ]}
                      max={formData.scRoofLoan ? formData.scRoofLoan : 0}
                      min={0}
                      defaultValue={0}
                      source="scOpenAccountLoan"
                      label="resources.root.scOpenAccountLoan"
                      className={classes.input}
                    />
                  );
                }}
              </FormDataConsumer>
            )}
            <BooleanInput
              source="payLoanByMain"
              className={classes.input}
              label={currency ? "resources.root.usd" : "resources.root.iqd"}
              defaultValue={true}
              onChange={(e) => setCurrency(e.target.checked)}
            />

            <NumberInput
              source="discountRate"
              min={0}
              max={100}
              step={1}
              label="resources.root.discountRate"
              className={classes.input}
            />
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

export const CustomersEdit = (props) => {
  const [stores, setStores] = React.useState([]);
  const [storeCustomers, setStoreCustomers] = React.useState([]);
  const [storeId, setStoreId] = React.useState();
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const [customer, setCustomer] = React.useState(0);
  const [currentLoan, setCurrentLoan] = React.useState(0);
  const refresh = useRefresh();
  const { id } = useParams();

  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();

  function isInArray(value, array) {
    return array.indexOf(value) > -1;
  }

  React.useEffect(() => {
    HTTP.get(`${url}/Customers/${props.id}`).then((res) => {
      setCustomer(res.data);
      res.data.storeCustomers && setStoreCustomers(res.data.storeCustomers);
    });
  }, []);

  React.useEffect(() => {
    HTTP.get(`${url}/Stores?_end=10000`).then((res) => {
      setStores(
        res.data.filter(
          (el) =>
            !isInArray(
              el.id,
              storeCustomers.map((el) => el.store.id)
            )
        )
      );
    });
  }, [storeCustomers]);

  return (
    <Edit
      {...props}
      transform={(data) => ({ ...data, country: "Iraq", city: "Erbil" })}
      redirect="list"
    >
      <TabbedForm>
        <FormTab label="resources.root.customers">
          <div className={classes.container}>
            <div className={classes.formCol}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextInput
                    source="firstName"
                    label="resources.root.firstName"
                    className={classes.input}
                    validate={[required()]}
                  />
                  <TextInput
                    source="middleName"
                    label="resources.root.middleName"
                    className={classes.input}
                    validate={[required()]}
                  />
                  <TextInput
                    source="lastName"
                    label="resources.root.lastName"
                    className={classes.input}
                    validate={[required()]}
                  />
                  <TextInput
                    source="email"
                    label="resources.root.email"
                    className={classes.input}
                  />
                  <TextInput
                    source="address"
                    label="resources.root.address"
                    className={classes.input}
                    validate={[required()]}
                  />
                  <TextInput
                    source="phone"
                    label="resources.root.phone"
                    className={classes.input}
                    validate={[required()]}
                  />
                  <TextInput
                    source="phone2"
                    label="resources.root.phone2"
                    className={classes.input}
                  />
                  <BooleanInput
                    source="male"
                    label="resources.root.male"
                    className={classes.input}
                    validate={[required()]}
                  />
                  <NumberInput
                    source="loanLimitDays"
                    label="resources.root.loanLimitDays"
                    className={classes.input}
                  />
                  <NumberInput
                    source="discountRate"
                    min={0}
                    max={100}
                    step={1}
                    label="resources.root.discountRate"
                    className={classes.input}
                  />

                  <ImageInput
                    source="attachment"
                    label="resources.root.attachment"
                    accept="image/*"
                  >
                    <ImageField source="src" title="title" />
                  </ImageInput>
                  <FunctionField
                    label="resources.root.attachment"
                    render={(record) =>
                      record.attachment !== null && (
                        <div style={{ position: "relative" }}>
                          <button
                            type="button"
                            className="btn btn-danger p-2"
                            style={{ right: "0px", position: "absolute" }}
                            onClick={() => {
                              HTTP.delete(
                                `${url}/Customers/DeleteCustomerImage?id=${id}`
                              )
                                .then(() => {
                                  notify("Image deleted successfully", {
                                    type: "success",
                                    undoable: true,
                                  });
                                  refresh();
                                })
                                .catch((err) =>
                                  notify(err.response.data.message, {
                                    type: "error",
                                    undoable: true,
                                  })
                                );
                            }}
                          >
                            <span>&times;</span>
                          </button>
                          <img
                            style={{
                              width: 200,
                              height: 200,
                              objectFit: "scale-down",
                              cursor: "pointer",
                            }}
                            src={`${path}${record.attachment}`}
                            alt="Attachment"
                            onClick={() =>
                              window.open(
                                `${path}${record.attachment}`,
                                "_blank"
                              )
                            }
                          />
                        </div>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  {process.env.REACT_APP_APP_NAME === "Mediply" ? (
                    <DateInput
                      source="dob"
                      label="resources.root.startingDate"
                      className={classes.input}
                      validate={[required()]}
                    />
                  ) : (
                    <DateInput
                      source="dob"
                      label="resources.root.dob"
                      className={classes.input}
                      validate={[required()]}
                    />
                  )}
                  <TextInput
                    source="country"
                    label="resources.root.country"
                    className={classes.input}
                  />
                  <TextInput
                    source="city"
                    label="resources.root.city"
                    className={classes.input}
                  />
                  <TextInput
                    source="nationality"
                    label="resources.root.nationality"
                    className={classes.input}
                    validate={[required()]}
                  />
                  <TextInput
                    source="currentWork"
                    label="resources.root.currentWork"
                    className={classes.input}
                  />
                  <TextInput
                    source="note"
                    label="resources.root.note"
                    className={classes.input}
                  />
                  <TextInput
                    source="idCardNumber"
                    label="resources.root.idCardNumber"
                    className={classes.input}
                  />
                  <TextInput
                    source="idCardType"
                    label="resources.root.idCardType"
                    className={classes.input}
                  />
                  <FormDataConsumer>
                    {({ formData, ...rest }) => {
                      setSeparatedNumber(
                        formData.payLoanByMain
                          ? parseFloat(formData.mcRoofLoan)
                          : parseFloat(formData.scRoofLoan)
                      );
                      return formData.payLoanByMain ? (
                        <NumberInput
                          validate={[
                            required(),
                            number(),
                            minValue(formData.mcCurrentLoan),
                          ]}
                          min={formData.mcCurrentLoan}
                          source="mcRoofLoan"
                          label="resources.root.mcRoofLoan"
                          className={classes.input}
                          onChange={(e) =>
                            setSeparatedNumber(parseFloat(e.target.value) || 0)
                          }
                          helperText={
                            separatedNumber > 0
                              ? `$${separatedNumber?.toLocaleString()}`
                              : ""
                          }
                        />
                      ) : (
                        <NumberInput
                          validate={[
                            required(),
                            number(),
                            minValue(formData.scCurrentLoan),
                          ]}
                          min={formData.scCurrentLoan}
                          source="scRoofLoan"
                          label="resources.root.scRoofLoan"
                          className={classes.input}
                          onChange={(e) =>
                            setSeparatedNumber(parseFloat(e.target.value) || 0)
                          }
                          helperText={
                            separatedNumber > 0
                              ? `${separatedNumber?.toLocaleString()} ${translate(
                                  "resources.root.iqd"
                                )}`
                              : ""
                          }
                        />
                      );
                    }}
                  </FormDataConsumer>
                </Grid>
              </Grid>
            </div>
          </div>
        </FormTab>
        <FormTab label="resources.root.store">
          <div className={classes.addStoreSection}>
            <Autocomplete
              id="combo-box-demo"
              options={stores}
              onChange={(e, val) => (val ? setStoreId(val.id) : setStoreId(0))}
              getOptionLabel={(option) => option.title}
              style={{ width: 300 }}
              renderInput={(params) => (
                <MaterialTextField
                  {...params}
                  label={translate("resources.root.store")}
                  variant="outlined"
                />
              )}
            />
            {customer.payLoanByMain && (
              <Input
                type={"number"}
                label={"resources.root.currentLoan"}
                defaultValue={currentLoan}
                onChange={(e) => setCurrentLoan(e.target.value)}
              />
            )}
            {!customer.payLoanByMain && (
              <Input
                type={"number"}
                label={"resources.root.currentLoan"}
                defaultValue={currentLoan}
                onChange={(e) => setCurrentLoan(e.target.value)}
              />
            )}
            <MaterialButton
              variant="outlined"
              disabled={!storeId}
              onClick={() => {
                HTTP.post(`${url}/StoreCustomers`, {
                  customerId: props.id,
                  storeId: storeId,
                  byMain: customer.payLoanByMain,
                  mcOpenAccountLoan: currentLoan,
                  scOpenAccountLoan: currentLoan,
                }).then((res) => {
                  HTTP.get(`${url}/Customers/${props.id}`).then((res) => {
                    res.data.storeCustomers &&
                      setStoreCustomers(res.data.storeCustomers);
                  });
                });
                setStoreId(0);
              }}
            >
              {translate("resources.root.add")}
            </MaterialButton>
          </div>
          <TableContainer component={Paper} className={classes.table}>
            <Table aria-label="simple table">
              <TableHead className={classes.TableHead}>
                <TableRow>
                  <TableCell className={classes.TableHeadCell}>
                    {translate("resources.root.title")}
                  </TableCell>
                  <TableCell align="right" className={classes.TableHeadCell}>
                    {translate("resources.root.description")}
                  </TableCell>
                  <TableCell align="right" className={classes.TableHeadCell}>
                    {translate("resources.root.openAccountLoan")}
                  </TableCell>
                  <TableCell align="right" className={classes.TableHeadCell}>
                    {translate("resources.root.currentLoan")}
                  </TableCell>
                  <TableCell align="right" className={classes.TableHeadCell}>
                    {translate("resources.root.action")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {storeCustomers &&
                  storeCustomers.map((row) => (
                    <TableRow key={row.store.id}>
                      <TableCell>{row.store.title}</TableCell>
                      <TableCell align="right">
                        {row.store.description}
                      </TableCell>
                      <TableCell align="right">
                        {customer.payLoanByMain
                          ? row.mcOpenAccountLoan?.toLocaleString()
                          : row.scOpenAccountLoan?.toLocaleString()}
                      </TableCell>
                      <TableCell align="right">
                        {customer.payLoanByMain
                          ? row.mcCurrentLoan?.toLocaleString()
                          : row.scCurrentLoan?.toLocaleString()}
                      </TableCell>
                      <TableCell align="right">
                        <DeleteIcon
                          onClick={() => {
                            swal({
                              title: translate("resources.root.swalTitle"),
                              text: translate("resources.root.swalText"),
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then((willDelete) => {
                              if (willDelete) {
                                HTTP.delete(`${url}/StoreCustomers/${row.id}`)
                                  .then((res) => {
                                    HTTP.get(
                                      `${url}/Customers/${props.id}`
                                    ).then((res) => {
                                      res.data.storeCustomers &&
                                        setStoreCustomers(
                                          res.data.storeCustomers
                                        );
                                      swal(
                                        translate("resources.root.swalDeleted"),
                                        {
                                          icon: "success",
                                        }
                                      );
                                    });
                                  })
                                  .catch((err) =>
                                    notify(err.response.data.message, {
                                      type: "error",
                                      undoable: true,
                                    })
                                  )
                                  .finally((res) => {
                                    HTTP.get(
                                      `${url}/Customers/${props.id}`
                                    ).then((res) => {
                                      res.data.storeCustomers &&
                                        setStoreCustomers(
                                          res.data.storeCustomers
                                        );
                                    });
                                  });
                              } else {
                                swal(
                                  translate(
                                    "resources.root.swalDeleteCancelled"
                                  )
                                );
                              }
                            });
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const CustomersShow = (props) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const { id } = useParams();
  const notify = useNotify();
  return (
    <Show>
      <SimpleShowLayout>
        <FunctionField
          label="resources.root.customerName"
          render={(record) =>
            `${record.firstName} ${record.middleName} ${record.lastName}`
          }
        />
        <BooleanField source="male" label="resources.root.gender" />

        {process.env.REACT_APP_APP_NAME === "Mediply" ? (
          <DateField source="dob" label="resources.root.startingDate" />
        ) : (
          <DateField source="dob" label="resources.root.dob" />
        )}

        <TextField source="email" label="resources.root.email" />
        <TextField source="phone" label="resources.root.phone" />
        <TextField source="phone2" label="resources.root.phone2" />
        <TextField source="country" label="resources.root.country" />
        <TextField source="city" label="resources.root.city" />
        <TextField source="address" label="resources.root.address" />
        <TextField source="note" label="resources.root.note" />
        <TextField source="nationality" label="resources.root.nationality" />
        <FunctionField
          label="resources.root.currentLoan"
          render={(record) =>
            record.payLoanByMain
              ? record.mcCurrentLoan?.toLocaleString() +
                " " +
                translate("resources.root.usd")
              : record.scCurrentLoan?.toLocaleString() +
                " " +
                translate("resources.root.iqd")
          }
        />
        <FunctionField
          label="resources.root.roofLoan"
          render={(record) =>
            record.payLoanByMain
              ? record.mcRoofLoan?.toLocaleString() +
                " " +
                translate("resources.root.usd")
              : record.scRoofLoan?.toLocaleString() +
                " " +
                translate("resources.root.iqd")
          }
        />
        <FunctionField
          label="resources.root.openAccountLoan"
          render={(record) =>
            record.payLoanByMain
              ? record.mcOpenAccountLoan?.toLocaleString() +
                " " +
                translate("resources.root.usd")
              : record.scOpenAccountLoan?.toLocaleString() +
                " " +
                translate("resources.root.iqd")
          }
        />

        <FunctionField
          label="resources.root.attachment"
          render={(record) =>
            record.attachment !== null && (
              <div style={{ position: "relative" }}>
                <img
                  style={{
                    width: 200,
                    height: 200,
                    objectFit: "scale-down",
                    cursor: "pointer",
                  }}
                  src={`${path}${record.attachment}`}
                  alt="Attachment"
                  onClick={() =>
                    window.open(`${path}${record.attachment}`, "_blank")
                  }
                />
              </div>
            )
          }
        />
        <ArrayField source="storeCustomers" label="resources.root.stores">
          <Datagrid>
            <TextField source="store.title" label="resources.root.store" />
            <FunctionField
              label="resources.root.currentLoan"
              render={(record) =>
                record.byMain
                  ? record.mcCurrentLoan?.toLocaleString() +
                    " " +
                    translate("resources.root.usd")
                  : record.scCurrentLoan?.toLocaleString() +
                    " " +
                    translate("resources.root.iqd")
              }
            />
            <FunctionField
              label="resources.root.openAccountLoan"
              render={(record) =>
                record.byMain
                  ? record.mcOpenAccountLoan?.toLocaleString() +
                    " " +
                    translate("resources.root.usd")
                  : record.scOpenAccountLoan?.toLocaleString() +
                    " " +
                    translate("resources.root.iqd")
              }
            />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  input: {
    width: "70%",
  },
  table: {
    minWidth: 800,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 800,
  },
});
