import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  TextField,
  useRefresh,
  useResourceDefinition,
  useTranslate,
} from "react-admin";
import * as React from "react";
import { MyCustomPagination, SearchFilter } from "../../App";
import { ListActions } from "../templates/ListActions";
import Button from "@mui/material/Button";
import PrintIcon from "@mui/icons-material/Print";
import swal from "sweetalert";
import { HTTP } from "../../axios";
import { url } from "../../request";
import { FaAddressBook, FaCar } from "react-icons/fa";
import BulkAction from "../CustomComponents/BulkActions";

const BulkActionButtons = (props) => {
  const refresh = useRefresh();
  const translate = useTranslate();

  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          color="primary"
          style={{ marginBottom: 15 }}
          onClick={() => {
            let ids = "";
            for (let i = 0; i < props.selectedIds.length; i++) {
              if (i === 0) {
                ids += "?ids[]=" + props.selectedIds[i];
              } else {
                ids += "&ids[]=" + props.selectedIds[i];
              }
            }
            window
              .open(`#/PrintInvoiceOrders/QuickSellInvoice${ids}`, "_blank")
              .focus();
          }}
        >
          <PrintIcon /> &nbsp; {translate("resources.root.printOrders")}
        </Button>
        <BulkAction {...props} bulkDeleteButton={true} />
      </div>
    </React.Fragment>
  );
};

export const QuickSellInvoicesList = () => {
  const props = useResourceDefinition();
  const translate = useTranslate();
  const [currency, setCurrency] = React.useState("default");

  return (
    <div>
      <List
        {...props}
        filters={
          <SearchFilter date={true} cashiers={true} fullDiscount={true} />
        }
        pagination={<MyCustomPagination />}
        sort={{ field: "id", order: "DESC" }}
        actions={<ListActions setCurrency={setCurrency} currency={currency} />}
      >
        <Datagrid
          bulkActionButtons={
            <BulkAction
              {...props}
              bulkDeleteButton={true}
              hasPrintOrders={"QuickSellInvoice"}
            />
          }
        >
          <FunctionField
            source="invoiceNumber"
            label="resources.root.invoiceNumber"
            render={(record) => (
              <a
                href={`#/Cashier/SellInfo/${record.id}/show`}
                target={`_blank`}
              >
                {record.invoiceNumber}
              </a>
            )}
          />
          <TextField source="name" label="resources.root.name" />
          <DateField
            source="date"
            label="resources.root.date"
            showTime={true}
          />
          {currency === "default" && (
            <FunctionField
              label="resources.root.totalPrice"
              render={(record) =>
                record.payByMain
                  ? `$${record.mcTotalPrice?.toLocaleString()}`
                  : `${record.scTotalPrice?.toLocaleString()} ${translate(
                      "resources.root.iqd"
                    )}`
              }
            />
          )}
          {currency !== "default" && (
            <FunctionField
              label="resources.root.totalPrice"
              render={(record) =>
                currency === "dollar"
                  ? `$${record.mcTotalPrice?.toLocaleString()}`
                  : `${record.scTotalPrice?.toLocaleString()} ${translate(
                      "resources.root.iqd"
                    )}`
              }
            />
          )}
          {currency === "default" && (
            <FunctionField
              label="resources.root.discount"
              render={(record) =>
                record.payByMain
                  ? `$${record.mcInvoiceDiscount?.toLocaleString()}`
                  : `${record.scInvoiceDiscount?.toLocaleString()} ${translate(
                      "resources.root.iqd"
                    )}`
              }
            />
          )}
          {currency !== "default" && (
            <FunctionField
              label="resources.root.discount"
              render={(record) =>
                currency === "dollar"
                  ? `$${record.mcInvoiceDiscount?.toLocaleString()}`
                  : `${record.scInvoiceDiscount?.toLocaleString()} ${translate(
                      "resources.root.iqd"
                    )}`
              }
            />
          )}
          <NumberField source="totalQte" label="resources.root.totalQte" />
          <NumberField
            source="totalSmallMeasureQte"
            label="resources.root.smallMeasureQty"
          />
          {currency === "default" && (
            <FunctionField
              label="resources.root.cashPrice"
              render={(record) =>
                record.payByMain
                  ? `$${record.mcCashPrice?.toLocaleString()}`
                  : `${record.scCashPrice?.toLocaleString()} ${translate(
                      "resources.root.iqd"
                    )}`
              }
            />
          )}
          {currency !== "default" && (
            <FunctionField
              label="resources.root.cashPrice"
              render={(record) =>
                currency === "dollar"
                  ? `$${record.mcCashPrice?.toLocaleString()}`
                  : `${record.scCashPrice?.toLocaleString()} ${translate(
                      "resources.root.iqd"
                    )}`
              }
            />
          )}
          {currency === "default" && (
            <FunctionField
              label="resources.root.returnPrice"
              render={(record) =>
                record.payByMain
                  ? `$${record.mcReturnPrice?.toLocaleString()}`
                  : `${record.scReturnPrice?.toLocaleString()} ${translate(
                      "resources.root.iqd"
                    )}`
              }
            />
          )}
          {currency !== "default" && (
            <FunctionField
              label="resources.root.returnPrice"
              render={(record) =>
                currency === "dollar"
                  ? `$${record.mcReturnPrice?.toLocaleString()}`
                  : `${record.scReturnPrice?.toLocaleString()} ${translate(
                      "resources.root.iqd"
                    )}`
              }
            />
          )}
        </Datagrid>
      </List>
    </div>
  );
};
