import {HTTP} from './axios'
import {url} from './request'
import axios from "axios";

const authProvider = {
  login: async ({ username, password }) => {
    const request = await new Request(`${url}/Accounts/authenticate`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then(async (response) => {
        if (response.status === 401) {
          throw new Error("Username or Password is incorrect!");
        } else if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return await response.json();
      })
      .then(({ token, firstName, middleName, lastName, image, userType, loginId, lastLogout, userName, storeId }) => {
        if((userType === 'Customer' || userType === 'Casher') && process.env.REACT_APP_APP_NAME !== 'Global Mall') {
          return Promise.resolve();
        }
        localStorage.setItem('token', token)
        axios.defaults.headers.common = { Authorization: `Bearer ${token}`, "platform": "Web" };
        axios.get(`${url}/DbPrefenses`)
        .then(res => {
          localStorage.setItem('lastEmptySafeBoxDate', res.data[0].lastEmptySafeBoxDate)
          localStorage.setItem('dollarPrice', res.data[0].mcToScPrice)
          localStorage.setItem('mcLabel', res.data[0].mcLabel)
          localStorage.setItem('scLabel', res.data[0].scLabel)
          localStorage.setItem('mcSymbol', res.data[0].mcSymbol)
          localStorage.setItem('scSymbol', res.data[0].scSymbol)
          localStorage.setItem('canSellBellowZero', res.data[0].canSellBellowZero)
        })
        .catch(err => {
          if(err.response.status === 406) {
            axios.defaults.headers.common = { Authorization: `Bearer ${token}`, "platform": "Web" };
            axios.post(`${url}/DbPrefenses`, {
              lastEmptySafeBoxDate: null,
              mcLabel: 'Dollar',
              scLabel: 'Dinar',
              mcSymbol: 'USD',
              scSymbol: ' IQD',
              mcToScPrice: 1480,
              canSellBellowZero: false
            })
            .then(res => {
              localStorage.setItem('lastEmptySafeBoxDate', null)
              localStorage.setItem('dollarPrice', "1450")
              localStorage.setItem('mcLabel', 'Dollar')
              localStorage.setItem('scLabel', 'Dinar')
              localStorage.setItem('mcSymbol', 'USD')
              localStorage.setItem('scSymbol', ' IQD')
              localStorage.setItem('canSellBellowZero', "false")
            })
          }
        }).finally(() => {
          window.location.reload()
        })
        localStorage.setItem("name", `${firstName} ${middleName}`);
        localStorage.setItem("firstName", `${firstName}`);
        localStorage.setItem("middleName", `${middleName}`);
        localStorage.setItem("lastName", `${lastName}`);
        localStorage.setItem("image", `${image}`);
        localStorage.setItem("role", userType);
        localStorage.setItem("loginId", loginId);
        localStorage.setItem("lastLogout", lastLogout);
        localStorage.setItem("storeId", storeId);
        localStorage.setItem("userName", username);
      });
  },
  logout: () => {
    HTTP.post(`${url}/Accounts/LogOut`)
        .then(resp => {})
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
  getPermissions: () => {
    const role = localStorage.getItem("role");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default authProvider;
