import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";

export const ProductDamageTypesList = (props) => (
  <div>
    <List
      pagination={<MyCustomPagination />}
      {...props}
      filters={<SearchFilter />}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={<BulkAction {...props} />} rowClick={"edit"}>
        <TextField source="title" label="resources.root.title" />
        <TextField source="description" label="resources.root.description" />
        <TextField source="note" label="resources.root.note" />
      </Datagrid>
    </List>
  </div>
);

export const ProductDamageTypesCreate = (props) => {
  const classes = useStyles();

  return (
    <Create {...props} redirect="list">
      <SimpleForm>
        <TextInput
          source="title"
          label="resources.root.title"
          className={classes.input}
        />
        <TextInput
          source="description"
          label="resources.root.description"
          options={{ multiLine: true }}
          className={classes.input}
        />
        <TextInput
          source="note"
          label="resources.root.note"
          options={{ multiLine: true }}
          className={classes.input}
        />
      </SimpleForm>
    </Create>
  );
};

export const ProductDamageTypesEdit = (props) => {
  const classes = useStyles();

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput
          source="title"
          label="resources.root.title"
          className={classes.input}
        />
        <TextInput
          source="description"
          label="resources.root.description"
          options={{ multiLine: true }}
          className={classes.input}
        />
        <TextInput
          source="note"
          label="resources.root.note"
          options={{ multiLine: true }}
          className={classes.input}
        />
      </SimpleForm>
    </Edit>
  );
};

const useStyles = makeStyles({
  input: {
    width: "30%",
  },
});
