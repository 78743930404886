import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  NumberField,
  NumberInput
} from "react-admin";
import {MyCustomPagination, SearchFilter} from "../../App";
import BulkAction from "../CustomComponents/BulkActions";

export const BrandsList = (props) => {
  return (
      <List
          pagination={<MyCustomPagination />}
          filters={<SearchFilter />}
          {...props}
          sort={{ field: "id", order: "DESC" }}
      >
        <Datagrid bulkActionButtons={<BulkAction {...props} />} rowClick={'edit'}>
          <TextField source="name" label="resources.root.name"/>
          <TextField source="description" label="resources.root.description" />
          <NumberField
              source="sort"
              label="resources.root.sort"
          />
        </Datagrid>
      </List>
  );
}

export const BrandsCreate = () => {
  return (
    <Create>
      <SimpleForm
          defaultValues={{ description: null, sort: 0 }}
      >
        <TextInput required source="name" label="resources.root.name"/>
        <TextInput source="description" label="resources.root.description" options={{ multiLine: true }} />
        <NumberInput required source="sort" label="resources.root.sort" />
      </SimpleForm>
    </Create>
  );
};

export const BrandsEdit = () => (
  <Edit>
    <SimpleForm defaultValues={{ description: null, sort: 0 }}>
      <TextInput required source="name" label="resources.root.name"/>
      <TextInput source="description" label="resources.root.description" options={{ multiLine: true }} />
      <NumberInput required source="sort" label="resources.root.sort"/>
    </SimpleForm>
  </Edit>
);
