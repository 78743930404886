import * as React from "react";
import { useRedirect, useNotify, useTranslate } from "react-admin";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { url } from "../../request";
import MaterialTextField from "@mui/material/TextField";
import Autocomplete from "@mui/lab/Autocomplete";
import Chip from "@mui/material/Chip";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import MuiAlert from "@mui/lab/Alert";
import Snackbar from "@mui/material/Snackbar";
import swal from "sweetalert";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import { Form } from "react-bootstrap";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { HTTP } from "../../axios";
import AsyncSelect from "react-select/async";
import Datetime from "react-datetime";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

export const McSellInvoiceNew = ({ invoice }) => {
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const [customers, setCustomers] = React.useState([]);
  const [previousSellPrice, setPreviousSellPrice] = React.useState(null);
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [cashPayment, setCashPayment] = React.useState(false);
  const [address, setAddress] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [orderQtt, setOrderQtt] = React.useState(1);
  const [productSerial, setProductSerial] = React.useState(null); // use this to sset the selected serial string
  const [productSerials, setProductSerials] = React.useState([]); // usisng for the list of product serialials
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [orders, setOrders] = React.useState(
    invoice === "return"
      ? localStorage.getItem("mcInvoiceReturnOrders")
        ? JSON.parse(localStorage.getItem("mcInvoiceReturnOrders"))
        : []
      : localStorage.getItem("mcInvoiceOrders")
      ? JSON.parse(localStorage.getItem("mcInvoiceOrders"))
      : []
  );
  const [discount, setDiscount] = React.useState(0);
  const [percentageDiscount, setPercentageDiscount] = React.useState(0);
  const [discountInPercent, setDiscountInPercent] = React.useState(true);
  const [modifiedPriceInIQD, setModifiedPriceInIQD] = React.useState(0);
  const [orderDiscount, setOrderDiscount] = React.useState(0);
  const [orderDiscountPercentage, setOrderDiscountPercentage] =
    React.useState(0);
  const [orderDiscountInPercent, setOrderDiscountInPercent] =
    React.useState(true);
  const [gift, setGift] = React.useState(0);
  const [note, setNote] = React.useState("");
  const [invoiceNote, setInvoiceNote] = React.useState("");
  const [driver, setDriver] = React.useState("");
  const [orderToEdit, setOrderToEdit] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [delay, setDelay] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [jss, setJss] = React.useState(
    create({ plugins: [...jssPreset().plugins, rtl()] })
  );
  const [ignoreProductPriceType, setIgnoreProductPriceType] = React.useState(
    localStorage.getItem("ignoreProductPriceType") || false
  );

  const redirect = useRedirect();
  const idemPotent = uuidv4();

  React.useEffect(() => {
    HTTP.get(`${url}/Customers?_end=10000&byMain=true`).then((res) => {
      setCustomers(res.data);
    });

    HTTP.get(`${url}/Products?_end=10000`).then((res) => {
      setProducts(res.data);
    });
  }, []);

  React.useEffect(() => {
    setProductSerials([]);
    setProductSerial(null);
    if (selectedProduct != null && selectedProduct?.id != undefined) {
      HTTP.get(`${url}/ProductSerials/${selectedProduct.id}`).then((res) => {
        setProductSerials(res.data);
      });
    }
    selectedProduct &&
      selectedCustomer &&
      HTTP.get(
        `${url}/SellInvoices/GetLastPriceOfProduct?customerId=${selectedCustomer.id}&productId=${selectedProduct.id}`
      ).then((res) => {
        setPreviousSellPrice(res.data);
      });
  }, [selectedCustomer, selectedProduct]);

  React.useEffect(() => {
    if (invoice === "return") {
      localStorage.removeItem("mcInvoiceReturnOrders");
      localStorage.setItem("mcInvoiceReturnOrders", JSON.stringify(orders));
    } else {
      localStorage.removeItem("mcInvoiceOrders");
      localStorage.setItem("mcInvoiceOrders", JSON.stringify(orders));
    }
  }, [orders]);

  React.useEffect(() => {
    selectedProduct && (selectedProduct.mcSellPrice = modifiedPriceInIQD);
  }, [modifiedPriceInIQD]);

  React.useEffect(() => {
    setSelectedProduct(products.filter((el) => el.id === orderToEdit)[0]);
  }, [orderToEdit]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function roundToTwo(num, to = 3) {
    return +(Math.round(num + "e+" + to) + "e-" + to);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 0) {
      let response = await HTTP.get(
        `${url}/Products?_end=40&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((product) => {
        newArray.push({
          value: product.id,
          label: product.name,
          product: product,
        });
      });
      callback(newArray);
    }
  };
  return (
    <StylesProvider jss={jss}>
      <Card className={classes.root}>
        <CardContent>
          <div className={classes.invoice}>
            <div className={classes.invoiceCols}>
              <Autocomplete
                id="combo-box-demo"
                options={customers}
                getOptionLabel={(option) =>
                  `${
                    option.fullName
                      ? option.fullName
                      : option.firstName + " " + option.middleName
                  } `
                }
                style={{ width: 300 }}
                onChange={(el, val) => {
                  setDelay(false);
                  setSelectedCustomer(val);
                  val && val.address && setAddress(val.address ?? "");
                }}
                renderInput={(params) => (
                  <MaterialTextField
                    {...params}
                    error={submitAttempted && !selectedCustomer}
                    label={translate("resources.root.chooseCustomer")}
                    variant="outlined"
                  />
                )}
                className={classes.autocomplete}
              />
              <Form.Group className={`m-4`}>
                <Datetime
                  initialValue={selectedDate}
                  defaultShow={true}
                  dateFormat="YYYY-MM-DD"
                  timeFormat="HH:mm:ss"
                  onChange={(e) => {
                    setSelectedDate(e.format("YYYY-MM-DD HH:mm:ss"));
                  }}
                />
              </Form.Group>
              <MaterialTextField
                label={translate("resources.root.address")}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className={classes.addressInput}
              />
            </div>
            <div className={classes.invoiceCols}>
              <Chip
                icon={<PhoneIphoneIcon />}
                label={
                  selectedCustomer
                    ? selectedCustomer.phone
                    : translate("resources.root.phone")
                }
                className={classes.bottomLeftMargin}
              />
              <Chip
                label={
                  selectedCustomer
                    ? `${translate("resources.root.currentLoan")} ${
                        selectedCustomer.payLoanByMain
                          ? selectedCustomer.mcCurrentLoan?.toLocaleString()
                          : selectedCustomer.scCurrentLoan?.toLocaleString()
                      } ${
                        selectedCustomer.payLoanByMain
                          ? translate("resources.root.usd")
                          : translate("resources.root.iqd")
                      }`
                    : translate("resources.root.currentLoan")
                }
                className={classes.bottomLeftMargin}
              />
              <Chip
                label={
                  selectedCustomer
                    ? `${translate("resources.root.maxLoan")} ${
                        selectedCustomer.payLoanByMain
                          ? selectedCustomer.mcRoofLoan?.toLocaleString()
                          : selectedCustomer.scRoofLoan?.toLocaleString()
                      } ${
                        selectedCustomer.payLoanByMain
                          ? translate("resources.root.usd")
                          : translate("resources.root.iqd")
                      }`
                    : translate("resources.root.maxLoan")
                }
                className={classes.bottomLeftMargin}
              />
              <Chip
                label={
                  selectedCustomer
                    ? `${translate("resources.root.futureLoan")} ${(
                        roundToTwo(
                          selectedCustomer.payLoanByMain
                            ? parseFloat(selectedCustomer.mcCurrentLoan)
                            : parseFloat(selectedCustomer.scCurrentLoan)
                        ) +
                        (invoice === "return" ? -1 : 1) *
                          (!cashPayment
                            ? parseFloat(
                                orders
                                  .map(
                                    (el) =>
                                      (selectedCustomer.payLoanByMain
                                        ? parseFloat(el.mcSellPrice) * el.qte
                                        : parseFloat(el.mcSellPrice)) * el.qte
                                  )
                                  .reduce((a, b) => a + b, 0) - discount
                              )
                            : 0)
                      )?.toLocaleString()} ${
                        selectedCustomer.payLoanByMain
                          ? translate("resources.root.usd")
                          : translate("resources.root.iqd")
                      }`
                    : translate("resources.root.futureLoan")
                }
                className={classes.bottomLeftMargin}
              />
            </div>
          </div>
          <div className={classes.invoice}>
            <div className={classes.toggles}>
              {!invoice && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={cashPayment}
                      onChange={(e) => {
                        setCashPayment(e.target.checked);
                        setDelay(false);
                      }}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={translate("resources.root.cash")}
                />
              )}
            </div>
          </div>
          <div className={classes.orders}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <div className={classes.orderCols}>
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.productMeasure")} - ${
                              selectedProduct.productMeasure
                            }`
                          : translate("resources.root.productMeasure")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.oneMeasure")} - ${
                              selectedProduct.oneMeasure
                            }`
                          : translate("resources.root.oneMeasure")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.oneContains")} ${
                              selectedProduct.oneContains
                            } ${translate("resources.root.items")}`
                          : translate("resources.root.oneContains")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.totalPrice")} - ${
                              modifiedPriceInIQD > 0
                                ? roundToTwo(modifiedPriceInIQD * orderQtt)
                                : roundToTwo(
                                    selectedProduct.mcSellPrice * orderQtt
                                  )
                            }  ${translate("resources.root.usd")}`
                          : translate("resources.root.totalPrice")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.currency")} - ${
                              selectedProduct.sellByMain
                                ? translate("resources.root.usd")
                                : translate("resources.root.iqd")
                            }`
                          : translate("resources.root.currency")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    {previousSellPrice && (
                      <Chip
                        label={
                          selectedProduct &&
                          `${previousSellPrice.mcSellPrice?.toLocaleString()} ${translate(
                            "resources.root.usd"
                          )}`
                        }
                        className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                        style={{
                          backgroundColor: "lightgreen",
                          color: "black",
                          marginLeft: "auto",
                        }}
                      />
                    )}
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate(
                              "resources.root.purchasePrice"
                            )}: $${selectedProduct.mcPurchasePrice?.toLocaleString()} `
                          : translate("resources.root.purchasePrice")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate(
                              "resources.root.max"
                            )}: ${selectedProduct.qteInStock?.toLocaleString()} `
                          : translate("resources.root.max")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                  </div>
                  <div className={classes.orderCols}>
                    <div style={{ width: "350px" }}>
                      {localStorage.getItem("useOldSelect") === "true" ? (
                        <Autocomplete
                          id="combo-box-demo"
                          options={products}
                          getOptionLabel={(option) =>
                            `${option.name} - ${option.barcode}`
                          }
                          style={{ width: 300 }}
                          onChange={(el, val) => {
                            setSelectedProduct(val);
                            setOrderQtt(0);
                            setModifiedPriceInIQD(0);
                          }}
                          renderInput={(params) => (
                            <MaterialTextField
                              {...params}
                              label={translate("resources.root.chooseProduct")}
                              variant="outlined"
                            />
                          )}
                        />
                      ) : (
                        <AsyncSelect
                          defaultOptions
                          menuPortalTarget={document.body}
                          loadOptions={loadOptions}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          onChange={(e) => {
                            setSelectedProduct(e.product);
                            setOrderQtt(1);
                            setModifiedPriceInIQD(
                              e.product ? e.product.mcSellPrice : 0
                            );
                          }}
                        />
                      )}
                    </div>
                    {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
                      // <MaterialTextField
                      //   label={translate("resources.root.serial")}
                      //   value={productSerial}
                      //   onChange={(e) => {
                      //     setProductSerial(e.target.value);
                      //   }}
                      //   className={classes.orderNumberInput}
                      // />
                      <Autocomplete
                        id="combo-box-demo"
                        options={productSerials}
                        getOptionLabel={(option) => `${option.serialNumber}`}
                        style={{ width: 200 }}
                        onChange={(el, val) => {
                          setProductSerial(val?.serialNumber ?? null);
                        }}
                        renderInput={(params) => (
                          <MaterialTextField
                            {...params}
                            label={translate("resources.root.chooseSerial")}
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                    <MaterialTextField
                      type="number"
                      label={translate("resources.root.quantity")}
                      disabled={!selectedProduct}
                      value={orderQtt}
                      onChange={(e) => {
                        !invoice
                          ? ((parseFloat(e.target.value) +
                              orders
                                .filter(
                                  (el) => el.productId === selectedProduct.id
                                )
                                .map(
                                  (el) =>
                                    parseFloat(el.qte) + parseFloat(el.giftQte)
                                )
                                .reduce((a, b) => a + b, 0) +
                              gift <=
                              (selectedProduct &&
                                parseFloat(selectedProduct.qteInStock)) &&
                              e.target.value >= 0) ||
                              selectedProduct.canSellBellowZero) &&
                            setOrderQtt(e.target.value)
                          : setOrderQtt(e.target.value);
                      }}
                      className={classes.orderNumberInput}
                    />
                    <MaterialTextField
                      type="number"
                      label={translate("resources.root.priceInUSD")}
                      value={modifiedPriceInIQD}
                      onChange={(e) =>
                        setModifiedPriceInIQD(parseFloat(e.target.value))
                      }
                      className={classes.orderNumberInput}
                    />
                    {/* &nbsp; */}
                    <MaterialTextField
                      type="text"
                      label={translate("resources.root.note")}
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                    <MaterialTextField
                      type="number"
                      label={translate("resources.root.gifts")}
                      disabled={!selectedProduct}
                      value={gift}
                      onChange={(e) =>
                        parseFloat(e.target.value) +
                          orders
                            .filter((el) => el.productId === selectedProduct.id)
                            .map(
                              (el) =>
                                parseFloat(el.qte) + parseFloat(el.giftQte)
                            )
                            .reduce((a, b) => a + b, 0) +
                          parseFloat(orderQtt) <=
                          (selectedProduct &&
                            parseFloat(selectedProduct.qteInStock)) &&
                        e.target.value >= 0 &&
                        setGift(parseFloat(e.target.value))
                      }
                      className={classes.orderNumberInput}
                    />
                    {process.env.REACT_APP_ORDER_DISCOUNT === "true" && (
                      <div>
                        <FormControlLabel
                          className={classes.inPercentage}
                          control={
                            <Switch
                              checked={orderDiscountInPercent}
                              onChange={(e) =>
                                setOrderDiscountInPercent(e.target.checked)
                              }
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label={translate("resources.root.inPercentage")}
                        />
                        <MaterialTextField
                          type="number"
                          label={translate("resources.root.discountPer")}
                          disabled={
                            !orderDiscountInPercent ||
                            !selectedProduct ||
                            percentageDiscount > 0
                          }
                          value={orderDiscountPercentage}
                          variant="filled"
                          onChange={(e) => {
                            e.target.value >= 0 &&
                              e.target.value <= 100 &&
                              setOrderDiscountPercentage(e.target.value);
                            setOrderDiscount(
                              (e.target.value / 100) *
                                (modifiedPriceInIQD * orderQtt)
                            );
                          }}
                          className={classes.discount2}
                        />
                        <MaterialTextField
                          type="number"
                          label={translate("resources.root.discount")}
                          disabled={
                            orderDiscountInPercent ||
                            !selectedProduct ||
                            discount > 0
                          }
                          value={orderDiscount}
                          variant="filled"
                          onChange={(e) => {
                            e.target.value >= 0 &&
                              setOrderDiscount(e.target.value);
                            setOrderDiscountPercentage(
                              (e.target.value /
                                (modifiedPriceInIQD * orderQtt)) *
                                100
                            );
                          }}
                          className={classes.discount2}
                        />
                      </div>
                    )}
                    <Button
                      variant="contained"
                      className={classes.botton}
                      disabled={
                        (parseFloat(orderQtt) <= 0 && parseFloat(gift) <= 0) ||
                        !selectedProduct
                      }
                      onClick={() => {
                        (parseFloat(orderQtt) > 0 || parseFloat(gift) > 0) &&
                          setOrders((prev) => {
                            const found = prev.find(
                              (el) =>
                                el.productId === parseInt(selectedProduct.id)
                            );
                            if (found) {
                              const newOrder = prev.map(
                                (el) =>
                                  el.productId === selectedProduct.id && {
                                    productId: selectedProduct.id,
                                    giftQte: el.giftQte + gift,
                                    payByMain: true,
                                    barcode: selectedProduct.barcode,
                                    productSerial: {
                                      serialNumber: productSerial,
                                    },
                                    name: selectedProduct.name,
                                    note: note,
                                    qte:
                                      parseFloat(orderQtt) +
                                      parseFloat(el.qte ? el.qte : 0),
                                    mcLastPrice:
                                      selectedProduct.mcSellPrice *
                                      (parseFloat(orderQtt) +
                                        parseFloat(el.qte ? el.qte : 0)),
                                    mcSellPrice: selectedProduct.mcSellPrice,
                                    mcPurchasePrice:
                                      selectedProduct.mcPurchasePrice,
                                    mcDiscount: orderDiscount,
                                    mcTotalPrice:
                                      modifiedPriceInIQD > 0
                                        ? roundToTwo(modifiedPriceInIQD) *
                                            (parseFloat(orderQtt) +
                                              parseFloat(el.qte ? el.qte : 0)) -
                                          orderDiscount
                                        : selectedProduct.mcSellPrice *
                                            (parseFloat(orderQtt) +
                                              parseFloat(el.qte ? el.qte : 0)) -
                                          orderDiscount,
                                  }
                              );
                              return [
                                ...prev.filter(
                                  (el) => el.productId !== selectedProduct.id
                                ),
                                ...newOrder.filter((el) => el !== false),
                              ];
                            } else {
                              return [
                                ...prev,
                                {
                                  productId: selectedProduct.id,
                                  giftQte: gift,
                                  mcLastPrice:
                                    selectedProduct.mcSellPrice *
                                    parseFloat(orderQtt),
                                  payByMain: true,
                                  barcode: selectedProduct.barcode,
                                  name: selectedProduct.name,
                                  note: note,
                                  productSerial: {
                                    serialNumber: productSerial,
                                  },
                                  qte: parseFloat(orderQtt),
                                  mcPurchasePrice:
                                    selectedProduct.mcPurchasePrice,
                                  mcDiscount: orderDiscount,
                                  mcSellPrice:
                                    modifiedPriceInIQD > 0
                                      ? modifiedPriceInIQD
                                      : selectedProduct.mcSellPrice,
                                  mcTotalPrice:
                                    modifiedPriceInIQD > 0
                                      ? modifiedPriceInIQD *
                                          parseFloat(orderQtt) -
                                        orderDiscount
                                      : selectedProduct.mcSellPrice *
                                          parseFloat(orderQtt) -
                                        orderDiscount,
                                },
                              ];
                            }
                          });
                        if (
                          localStorage.getItem("updateSellingPrice") === "true"
                        ) {
                          HTTP.post(
                            `${url}/Products/UpdateProductPrice?byMain=${true}&productId=${
                              selectedProduct.id
                            }&scPrice=${(
                              selectedProduct.scSellPrice *
                              localStorage.getItem("dollarPrice")
                            ).toFixed(
                              3
                            )}&mcPrice=${selectedProduct.mcSellPrice.toFixed(
                              3
                            )}`,
                            {}
                          );
                        }
                        setOrderQtt(1);
                        setOrderToEdit(-1);
                        setGift(0);
                        setNote("");
                        setDelay(false);
                      }}
                    >
                      {translate("resources.root.addOrder")}
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.botton}
                      style={{ marginLeft: 10 }}
                      disabled={true}
                    >
                      {translate("resources.root.noOfOrders")}: {orders.length}
                    </Button>
                  </div>

                  <div className={classes.orderCols}>
                    <TableContainer
                      component={Paper}
                      className={classes.tableContainer}
                    >
                      <Table
                        stickyHeader
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              {translate("resources.root.barcode")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.name")}
                            </TableCell>
                            {process.env.REACT_APP_PRODUCT_SERIAL ===
                              "true" && (
                              <TableCell align="right">
                                {translate("resources.root.serial")}
                              </TableCell>
                            )}
                            <TableCell align="right">
                              {translate("resources.root.quantity")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.gifts")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.price")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.purchasePrice")}
                            </TableCell>
                            {process.env.REACT_APP_ORDER_DISCOUNT ===
                              "true" && (
                              <TableCell align="right">
                                {translate("resources.root.discount")}
                              </TableCell>
                            )}
                            <TableCell align="right">
                              {translate("resources.root.totalPrice")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.note")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.actions")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className={classes.scrollable}>
                          {orders &&
                            orders.map((order) => (
                              <TableRow key={order.barcode}>
                                <TableCell component="th" scope="row">
                                  {order.barcode}
                                </TableCell>
                                <TableCell align="right">
                                  {order.name}
                                </TableCell>
                                {process.env.REACT_APP_PRODUCT_SERIAL ===
                                  "true" && (
                                  <TableCell align="right">
                                    {order.productSerial?.serialNumber}
                                  </TableCell>
                                )}
                                <TableCell align="right">{order.qte}</TableCell>
                                <TableCell align="right">
                                  {order.giftQte}
                                </TableCell>
                                <TableCell align="right">
                                  {roundToTwo(
                                    order.mcSellPrice
                                  )?.toLocaleString()}
                                </TableCell>
                                <TableCell align="right">
                                  {roundToTwo(
                                    order.mcPurchasePrice,
                                    3
                                  )?.toLocaleString()}
                                </TableCell>
                                {process.env.REACT_APP_ORDER_DISCOUNT ===
                                  "true" && (
                                  <TableCell align="right">
                                    {roundToTwo(
                                      order.mcDiscount
                                    )?.toLocaleString()}
                                  </TableCell>
                                )}
                                <TableCell align="right">
                                  {roundToTwo(
                                    order.mcTotalPrice
                                  )?.toLocaleString()}
                                </TableCell>
                                <TableCell align="right">
                                  {order.note}
                                </TableCell>
                                <TableCell align="right">
                                  <DeleteIcon
                                    onClick={() => {
                                      swal({
                                        title: translate(
                                          "resources.root.makeSure"
                                        ),
                                        text: translate(
                                          "resources.root.confirmPerminantDelete"
                                        ),
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                      }).then((willDelete) => {
                                        if (willDelete) {
                                          setOrders((prev) => {
                                            return prev.filter(
                                              (el) =>
                                                el.productId != order.productId
                                            );
                                          });
                                        }
                                      });
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Chip
                      label={`${translate(
                        "resources.root.totalPrice"
                      )} - ${orders
                        .map(
                          (el) =>
                            parseFloat(el.mcSellPrice) * parseFloat(el.qte) -
                            parseFloat(el.mcDiscount)
                        )
                        .reduce((a, b) => roundToTwo(a + b), 0)
                        ?.toLocaleString()} ${translate("resources.root.usd")}`}
                      className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={`${translate("resources.root.finalPrice")} - ${(
                        orders
                          .map(
                            (el) =>
                              parseFloat(el.mcSellPrice) * parseFloat(el.qte) -
                              parseFloat(el.mcDiscount)
                          )
                          .reduce((a, b) => roundToTwo(a + b), 0) - discount
                      )?.toLocaleString()} ${translate("resources.root.usd")}`}
                      className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={`${translate(
                        "resources.root.totalQuantity"
                      )} - ${orders
                        .map((el) => parseFloat(el.qte))
                        .reduce((a, b) => a + b, 0)
                        ?.toLocaleString()}`}
                      className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={`${translate(
                        "resources.root.totalGifts"
                      )} - ${orders
                        .map((el) => parseFloat(el.giftQte))
                        .reduce((a, b) => a + b, 0)
                        ?.toLocaleString()}`}
                      className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={`${translate(
                        "resources.root.finalQuantity"
                      )} - ${orders
                        .map(
                          (el) => parseFloat(el.qte) + parseFloat(el.giftQte)
                        )
                        .reduce((a, b) => a + b, 0)
                        ?.toLocaleString()}`}
                      className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                    />
                    <FormControlLabel
                      className={classes.inPercentage}
                      control={
                        <Switch
                          checked={discountInPercent}
                          onChange={(e) =>
                            setDiscountInPercent(e.target.checked)
                          }
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label={translate("resources.root.inPercentage")}
                    />
                    <MaterialTextField
                      type="number"
                      label={translate("resources.root.discountInPercentage")}
                      disabled={
                        !discountInPercent || orderDiscountPercentage > 0
                      }
                      value={
                        discountInPercent
                          ? roundToTwo(percentageDiscount)
                          : roundToTwo(
                              parseFloat(
                                roundToTwo(
                                  (discount * 100) /
                                    orders
                                      .map(
                                        (el) =>
                                          parseFloat(el.mcSellPrice) *
                                          parseFloat(el.qte)
                                      )
                                      .reduce((a, b) => roundToTwo(a + b), 0)
                                )
                              )
                            )
                      }
                      variant="filled"
                      onChange={(e) => {
                        e.target.value >= 0 &&
                          e.target.value <= 100 &&
                          setPercentageDiscount(e.target.value);
                        setDiscount(
                          roundToTwo(
                            (orders
                              .map(
                                (el) =>
                                  parseFloat(el.mcSellPrice) *
                                  parseFloat(el.qte)
                              )
                              .reduce((a, b) => a + b, 0) /
                              100) *
                              e.target.value
                          )
                        );
                      }}
                      className={classes.discount}
                    />
                    <MaterialTextField
                      type="number"
                      label={translate("resources.root.discount")}
                      disabled={discountInPercent || orderDiscount > 0}
                      value={
                        !discountInPercent
                          ? roundToTwo(discount)
                          : roundToTwo(
                              (orders
                                .map(
                                  (el) =>
                                    parseFloat(el.mcSellPrice) *
                                    parseFloat(el.qte)
                                )
                                .reduce((a, b) => a + b, 0) /
                                100) *
                                percentageDiscount
                            )
                      }
                      variant="filled"
                      onChange={(e) => {
                        e.target.value >= 0 &&
                          e.target.value <=
                            orders
                              .map(
                                (el) =>
                                  parseFloat(el.mcSellPrice) *
                                  parseFloat(el.qte)
                              )
                              .reduce((a, b) => a + b, 0) &&
                          setDiscount(e.target.value);
                        setPercentageDiscount(
                          parseFloat(
                            (discount * 100) /
                              orders
                                .map(
                                  (el) =>
                                    parseFloat(el.mcSellPrice) *
                                    parseFloat(el.qte)
                                )
                                .reduce((a, b) => roundToTwo(a + b), 0)
                          )
                        );
                      }}
                      className={classes.discount}
                    />
                    <Snackbar
                      open={open}
                      autoHideDuration={4000}
                      onClose={handleClose}
                    >
                      <Alert onClose={handleClose} severity="error">
                        {errorMessage}
                      </Alert>
                    </Snackbar>
                  </div>
                  <div className={classes.orderCols}>
                    <MaterialTextField
                      label={translate("resources.root.note")}
                      value={invoiceNote}
                      onChange={(e) => setInvoiceNote(e.target.value)}
                      className={classes.noteInput}
                    />
                    <MaterialTextField
                      label={translate("resources.root.driver")}
                      value={driver}
                      onChange={(e) => setDriver(e.target.value)}
                      className={classes.orderTextInput}
                    />
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </CardContent>
        <CardActions>
          {/* Submit the invoice to database */}
          <Button
            size="medium"
            color="secondary"
            disabled={delay}
            variant="contained"
            onClick={() => {
              setSubmitAttempted(true);
              if (!selectedCustomer) {
                setErrorMessage(translate("resources.root.selectCustomer"));
                // setOpen(true);
                return;
              }
              swal({
                title: translate("resources.root.makeSure"),
                text: translate("resources.root.confirmCreateNewInvoice"),
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                setDelay(true);
                if (willDelete) {
                  setIsLoading(true);
                  let sellOrders, returnOrders;
                  if (invoice === "return") {
                    returnOrders = orders;
                  } else {
                    sellOrders = orders;
                  }
                  HTTP.post(
                    `${url}/${
                      invoice === "return"
                        ? "SellReturnInvoices"
                        : "SellInvoices"
                    }`,
                    {
                      address,
                      customerId: selectedCustomer.id,
                      date: moment(selectedDate).add(3, "hours"),
                      mcTotalPrice: orders
                        .map(
                          (el) =>
                            parseFloat(el.mcSellPrice) * parseFloat(el.qte)
                        )
                        .reduce((a, b) => a + b, 0),

                      mcFinalPrice:
                        orders
                          .map(
                            (el) =>
                              parseFloat(el.mcSellPrice) * parseFloat(el.qte)
                          )
                          .reduce((a, b) => a + b, 0) - discount,

                      isCash: cashPayment,
                      payByMain: true,
                      totalQte: orders
                        .map((el) => el.qte)
                        .reduce((a, b) => a + b, 0),
                      totalGiftQte: orders
                        .map((el) => el.giftQte)
                        .reduce((a, b) => a + b, 0),
                      mcInvoiceDiscount: discount,
                      mcOrdersDiscount: 0,
                      mcFinalDiscount: 0,
                      note: invoiceNote,
                      driver,
                      sellOrders: sellOrders,
                      idemPotentToken: idemPotent,
                      sellReturnOrders: returnOrders,
                    }
                  )
                    .then((res) => {
                      console.log(res.data);
                      if (invoice === "return") {
                        localStorage.removeItem("mcInvoiceReturnOrders");
                      } else {
                        localStorage.removeItem("mcInvoiceOrders");
                      }
                      redirect(
                        invoice !== "return"
                          ? "/SellInvoices/" + res.data.id + "/show"
                          : "/SellReturnInvoices"
                      );
                    })
                    .catch((err) =>
                      notify(err.response.data.message, {
                        type: "error",
                        undoable: true,
                      })
                    );
                } else {
                  swal(translate("resources.root.createCancel"));
                }
              });
            }}
          >
            {translate("resources.root.create")}
          </Button>
          <Button
            onClick={() => {
              swal({
                title: translate("resources.root.makeSure"),
                text: translate("resources.root.confirmCreateNewInvoice"),
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  setOrders([]);
                } else {
                  swal(translate("resources.root.createCancel"));
                }
              });
            }}
          >
            {translate("resources.root.clearOrders")}
          </Button>
        </CardActions>
      </Card>
      {isLoading && <LoadingScreen />}
    </StylesProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginTop: 72,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  addressInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  discount2: {
    width: "40% !important",
    margin: 10,
  },
  discount: {
    width: "10% !important",
    marginLeft: 5,
    marginTop: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 20,
    maxHeight: "350px !important",
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  printWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "140%",
      marginLeft: theme.direction === "rtl" ? 0 : -230,
      marginRight: theme.direction === "rtl" ? -230 : -230,
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "80%",
      marginLeft: 265,
      paddingTop: 60,
      //   marginRight: 230,
    },
  },
}));
