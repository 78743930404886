import "./AnimationButtons.css";

const LoadingScreen = ({ text, link }) => {
  return (
    <a
      href={link}
      id={`animationButton`}
      className={`btn btn-lg btn-block my-2`}
    >
      {text}
    </a>
  );
};

export default LoadingScreen;
