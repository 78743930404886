import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    useTranslate,
    ReferenceField,
} from "react-admin";
import {path} from "../../request";
import moment from "moment";
import {MyCustomPagination, SearchFilter} from "../../App";
import BulkAction from "../CustomComponents/BulkActions";

export const RepresentativeCustomerChecksList = (props) => {
    const translate = useTranslate();
    return (
        <List
            filters={<SearchFilter date={true}/>}
            {...props}
            pagination={<MyCustomPagination />}
            sort={{ field: "id", order: "DESC" }}
        >
            <Datagrid bulkActionButtons={<BulkAction {...props} />}>
                <TextField source="id" label={'resources.root.id'} />
                <FunctionField
                    label="resources.root.image"
                    render={(record) => (
                        <img
                            style={{
                                width: 75,
                                height: 75,
                                objectFit: "scale-down",
                                cursor: "pointer",
                            }}
                            src={record.attachment !== null ? `${path}${record.attachment}` : `https://via.placeholder.com/75`}
                            alt={record.name}
                            onClick={() =>
                                window.open(record.attachment !== null ? `${path}${record.attachment}` : `https://via.placeholder.com/75`, "_blank")
                            }
                        />
                    )}
                />
                <ReferenceField
                    label={translate('ra.navigation.RepresentativeCustomers')}
                    source="representativeCustomerId"
                    reference="RepresentativeCustomers"
                    link={"show"}
                >
                    <FunctionField
                        label="Name"
                        render={(record) =>
                            `${record.placeName}`
                        }
                    />
                </ReferenceField>
                <TextField source="checkType" label="resources.root.checkType" />
                <TextField source="result" label="resources.root.result" />
                <FunctionField
                    source="date"
                    label="resources.root.date"
                    render={(record => moment(record.date).format('YYYY-MM-DD') )}
                />
                <FunctionField
                    source="date"
                    label="resources.root.time"
                    render={(record => moment(record.date).format('hh:mm:ss A'))}
                />
                <TextField source="note" label="resources.root.note" />
            </Datagrid>
        </List>
    );
};
