import React, { Fragment } from "react";
import { Form } from "react-bootstrap";

const NumberInpute = ({ lable, value, name, onChange,step=1 }) => {
  return (
    <Fragment>
      <Form.Group className="mb-3 mx-4" controlId={name}>
        <Form.Label>{lable}</Form.Label>
        <Form.Control
        step={step}
          className="py-4"
          type="number"
          placeholder={lable}
          value={value}
          name={name}
          onChange={onChange}
        />
      </Form.Group>
    </Fragment>
  );
};

export default NumberInpute;
