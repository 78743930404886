import * as React from "react";
import {
  AppBar,
  UserMenu,
  MenuItemLink,
  Layout,
  useLocale,
  useSetLocale,
  useTranslate,
  useNotify,
  useLogout,
} from "react-admin";
import SettingsIcon from "@mui/icons-material/Settings";
import MyMenu from "./Menu";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { IoLanguageOutline } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import Avatar from "@mui/material/Avatar";
import { path, url } from "../request";
import { NoPrint } from "react-easy-print";
import { HTTP } from "../axios";
import swal from "sweetalert";
import ExitIcon from "@mui/icons-material/PowerSettingsNew";

const ConfigurationMenu = React.forwardRef(({ onClick }, ref) => {
  const role = localStorage.getItem("role") ?? "NoUser";
  const logout = useLogout();
  const handleLogout = () => logout();
  return (
    <NoPrint>
      <MenuItemLink
        ref={ref}
        to={`/Profile`}
        primaryText={"resources.root.profile"}
        leftIcon={<FaUser />}
        onClick={onClick} // close the menu on click
      />
      {role.toLowerCase() !== "debtcollector" && (
        <MenuItemLink
          ref={ref}
          to={`/Settings/create`}
          primaryText={"ra.navigation.options"}
          leftIcon={<SettingsIcon />}
          onClick={onClick} // close the menu on click
        />
      )}
      <MenuItemLink
        ref={ref}
        to={`/login`}
        primaryText={"ra.auth.logout"}
        leftIcon={<ExitIcon />}
        onClick={handleLogout} // close the menu on click
      />
    </NoPrint>
  );
});

const MyUserMenu = (props) => (
  <UserMenu
    icon={
      <Avatar
        style={{ width: 30, height: 30 }}
        src={
          localStorage.getItem("image") !== "null"
            ? `${path}/${localStorage.getItem("image")}`
            : "https://via.placeholder.com/150"
        }
      />
    }
  >
    <ConfigurationMenu />
  </UserMenu>
);

const MyAppBar = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const locale = useLocale();
  const setLocale = useSetLocale();

  const [dollarPrice, setDollarPrice] = React.useState(
    localStorage.getItem("dollarPrice") ?? 0
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const role = localStorage.getItem("role") ?? "NoUser";

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function getDollarPrice() {
    HTTP.get(`${url}/DbPrefenses/1`, {
      id: 1,
      mcLabel: localStorage.getItem("mcLabel", "Dollar"),
      scLabel: localStorage.getItem("scLabel", "Dinar"),
      mcSymbol: localStorage.getItem("mcSymbol", "USD"),
      scSymbol: localStorage.getItem("scSymbol", "IQD"),
      mcToScPrice: dollarPrice,
      storeId: localStorage.getItem("storeId") ?? 1,
    })
      .then((response) => {
        setDollarPrice(response.data.mcToScPrice);
        localStorage.setItem("dollarPrice", response.data.mcToScPrice);
        notify("resources.root.dollarPriceGetSuccess", {
          type: "success",
          undoable: true,
        });
      })
      .catch((err) =>
        notify(err.response.data.message, { type: "error", undoable: true })
      );
  }

  function updateDollarPrice() {
    swal({
      title: translate("resources.root.makeSure"),
      text: translate("resources.root.areYouSureToUpdate"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        HTTP.put(`${url}/DbPrefenses/1`, {
          id: 1,
          mcLabel: localStorage.getItem("mcLabel", "Dollar"),
          scLabel: localStorage.getItem("scLabel", "Dinar"),
          mcSymbol: localStorage.getItem("mcSymbol", "USD"),
          scSymbol: localStorage.getItem("scSymbol", "IQD"),
          mcToScPrice: dollarPrice,
          storeId: localStorage.getItem("storeId") ?? 1,
        })
          .then(() => {
            notify("resources.root.dollarPriceUpdatedSuccess", {
              type: "success",
              undoable: true,
            });
          })
          .catch((err) =>
            notify(err.response.data.message, { type: "error", undoable: true })
          );
      }
    });
  }

  React.useEffect(function () {
    if (parseInt(dollarPrice) === 0) {
      getDollarPrice();
    }
  }, []);

  return (
    <AppBar
      elevation={1}
      userMenu={<MyUserMenu />}
      style={{ backgroundColor: "rgba(30, 111, 195, 1)" }}
    >
      <div style={{ flex: 1, textAlign: "initial" }}>
        <b>{process.env.REACT_APP_APP_NAME}</b>
      </div>
      <div style={{ order: 1, alignSelf: "center" }}>
        {localStorage.getItem("name")}
      </div>
      {/*<div>*/}
      <input
        type="text"
        id="search"
        placeholder={translate("resources.root.dollarPrice")}
        style={{
          width: "200px",
          backgroundColor: "rgb(45, 156, 244, 1)",
          border: "none",
          borderRadius: 3,
          color: "#fff",
          outline: "none",
          padding: 2,
          paddingLeft: 5,
          paddingRight: 5,
        }}
        value={dollarPrice}
        onChange={(e) => {
          setDollarPrice(e.target.value);
          localStorage.setItem("dollarPrice", e.target.value);
        }}
        readOnly={role.toLowerCase() === "debtcollector"}
      />
      <Button
        style={{ color: "#ffffff", textTransform: "capitalize" }}
        onClick={getDollarPrice}
      >
        {translate("resources.root.get")}
      </Button>
      {role.toLowerCase() !== "debtcollector" && (
        <Button
          style={{ color: "#ffffff", textTransform: "capitalize" }}
          onClick={updateDollarPrice}
        >
          {translate("resources.root.update")}
        </Button>
      )}
      <span style={{ padding: "10px" }}>|</span>
      <Button
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ color: "#ffffff" }}
      >
        <IoLanguageOutline />
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          disabled={locale === "kr"}
          onClick={() => {
            setLocale("kr");
            localStorage.setItem("locale", "kr");
            localStorage.setItem("direction", "rtl");
            window.location.reload();
          }}
        >
          کوردی
        </MenuItem>
        <MenuItem
          disabled={locale === "en"}
          onClick={() => {
            setLocale("en");
            localStorage.setItem("locale", "en");
            localStorage.setItem("direction", "ltr");
            window.location.reload();
          }}
        >
          English
        </MenuItem>
      </Menu>

      {role.toLowerCase() !== "debtcollector" && (
        <input
          type="text"
          id="search"
          placeholder={translate("resources.root.searchForProduct")}
          className="searchInput-appBar"
          style={{
            width: "200px",
            backgroundColor: "rgb(45, 156, 244, 1)",
            border: "none",
            borderRadius: 3,
            color: "#fff",
            outline: "none",
            padding: 2,
            paddingLeft: 5,
            paddingRight: 5,
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              HTTP.get(
                `${url}/Products/GetProductByBarcode?barcode=${e.target.value}`
              )
                .then((response) => {
                  window.open(`#/Products/${response.data.id}/2`, "_self");
                  window.location.reload();
                })
                .catch((error) => {
                  console.log(error.response.status);
                });
            }
          }}
          onFocus={(e) => e.target.select()}
        />
      )}
      {/*</div>*/}
    </AppBar>
  );
};

const MyLayout = (props) => (
  <Layout {...props} appBar={MyAppBar} menu={MyMenu} />
);

export default MyLayout;
