import { useState } from "react";
import { useEffect } from "react";
import { HTTP } from "../../axios";
import moment from "moment";
import Switch from "react-switch";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 10px auto;
  border-color: red;
`;

const SellTable = () => {
  let [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [useCashier, setUseCashier] = useState(
    localStorage.getItem("quickSell") === "true"
  );

  useEffect(() => {
    setLoading(true);
    HTTP.get(useCashier ? "QuickSellInvoices" : "SellInvoices")
      .then((result) => {
        setData(result.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [useCashier]);

  return (
    <div className="col-lg-10 col-sm-12">
      <div className="card mb-4 shadow-lg tableCard ">
        {loading && (
          <HashLoader
            color="#488EF9"
            loading={loading}
            css={override}
            size={55}
          />
        )}
        {!loading && (
          <div>
            <div
              className="card-header pl-3 pt-3 pb-1"
              style={{ background: "none" }}
            >
              <div className="d-flex justify-content-between">
                <h3>
                  <b>{useCashier ? "Cashier Sells" : "Sells"}</b>
                </h3>
                <Switch
                  onChange={(e) => {
                    setUseCashier(e);
                  }}
                  checked={useCashier}
                />
              </div>
            </div>
            <div className="card-body px-0 pt-0 pb-2">
              <div className="table-responsive">
                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        ID
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Name
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Price
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Total Quantity
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Final Quantity
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Date
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Address
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Cash
                      </th>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Driver
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ borderTop: "none", textAlign: "center" }}>
                    {data &&
                      data.map((item, index) => (
                        <tr key={index}>
                          <td>{item.id}</td>
                          <td>{item.customerName}</td>
                          <td>
                            {item.payByMain
                              ? item.mcTotalPrice?.toLocaleString() + " $"
                              : item.scTotalPrice?.toLocaleString() + " IQD"}
                          </td>
                          <td>{item.totalQte}</td>
                          <td>{item.totalGiftQte}</td>
                          <td>{moment(item.date).format("YYYY-MM-DD")}</td>
                          <td>{item.address}</td>
                          <td>{item.isCash ? "✔️" : "❌"}</td>
                          <td>{item.driver}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SellTable;
