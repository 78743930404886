import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Create,
    SimpleForm,
    TextInput,
    Edit,
    useNotify,
    useRedirect,
} from 'react-admin';
import {MyCustomPagination, SearchFilter} from "../App";
import BulkAction from "./CustomComponents/BulkActions";

export const BranchesList = (props) => (
    <div>
    <List
        filters={<SearchFilter />}
        pagination={<MyCustomPagination />}
        {...props}
        sort={{ field: 'id', order: 'DESC' }}
    >
        <Datagrid bulkActionButtons={<BulkAction {...props} />} rowClick={'edit'}>
            <TextField source="title" label="resources.root.title" />
            <TextField source="phone" label="resources.root.phone" />
            <TextField source="address" label="resources.root.address" />
            <TextField source="description" label="resources.root.description" />
        </Datagrid>
    </List>
    </div>
);

export const BranchesCreate = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const onFailure = (error) => {
        if (error.status === 406) {
            notify(`Branch Exists Aready`,  { type: 'warning', undoable: true })
        } else if (error.status === 401) {
            localStorage.removeItem('token')
            redirect('/login');
        }
    };

    return (
        <Create {...props} onFailure={onFailure}>
            <SimpleForm>
                <TextInput source="title" label="resources.root.title" margin="normal" variant="outlined" />
                <TextInput source="description" label="resources.root.description" options={{ multiLine: true }} margin="normal" variant="outlined" />
                <TextInput source="note" label="resources.root.note" options={{ multiLine: true }} margin="normal" variant="outlined" />
                <TextInput source="address" label="resources.root.address" margin="normal" variant="outlined" />
                <TextInput source="phone" label="resources.root.phone" margin="normal" variant="outlined" />
                <TextInput source="country" label="resources.root.country" margin="normal" variant="outlined" />
                <TextInput source="city" label="resources.root.city" margin="normal" variant="outlined" />
            </SimpleForm>
        </Create>
    );
}

export const BranchesEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="title" label="resources.root.title" />
            <TextInput source="note" label="resources.root.note" />
            <TextInput source="description" label="resources.root.description" options={{ multiLine: true }} />
        </SimpleForm>
    </Edit>
);
