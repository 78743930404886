import * as React from "react";
import { url } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import AsyncSelect from "react-select/async";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";

export default (ItemSupplierReport) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [product, setProduct] = React.useState(0);
  const [productObject, setProductObject] = React.useState([]);
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [showInUSD, setShowInUSD] = React.useState(false);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;

    if (startDate && startDate.length > 0) {
      sendDates += `&startDate=${startDate}`;
    } else {
      sendDates += `&startDate=${moment().format("YYYY-MM-DD")}`;
    }

    if (endDate && endDate.length > 0) {
      sendDates += `&endDate=${endDate}`;
    } else {
      sendDates += `&endDate=${moment().format("YYYY-MM-DD")}`;
    }
    await HTTP.get(
      `${url}/Reports/GetItemSupplierReport?barcode=${product}&_start=${start}&_end=${end}${sendDates}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [product, startDate, endDate, start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/Products?_end=5000&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((product) => {
        newArray.push({
          value: product.id,
          label: product.name,
          product: product,
        });
      });
      callback(newArray);
    }
  };

  return (
    <div>
      <PrintProvider>
        <Print single name={`productStatement`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.itemSupplierReport")}
              {product === 0 ? "" : " (" + productObject.name + ")"}
            </h1>
            <div className={`d-inline-block`} style={{ float: "inline-end" }}>
              <NoPrint>
                <Button
                  variant={"outline-primary"}
                  onClick={() => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}{" "}
                </Button>
                <div className={`clearfix`}> </div>
              </NoPrint>
            </div>
          </div>
          <NoPrint force>
            {isLoading && <LoadingScreen />}
            <Row style={{ fontSize: "15px" }}>
              <Col xs={1}>
                <label>{translate("resources.root.products")}</label>
              </Col>
              <Col xs={3}>
                <AsyncSelect
                  defaultOptions
                  loadOptions={loadOptions}
                  onChange={(e) => {
                    setProduct(e ? e.product.barcode : 0);
                    setProductObject(e ? e.product : 0);
                  }}
                />
              </Col>

              <Col xs={4}>
                <Form.Group as={Row} controlId="startDate">
                  <Form.Label column sm={4}>
                    {translate("resources.root.startDate")}
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group as={Row} controlId="endDate">
                  <Form.Label column sm={4}>
                    {translate("resources.root.endDate")}
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={4}>
                <div className="form-check">
                  <input
                    id={"showInUSD"}
                    type="checkbox"
                    onChange={(e) => setShowInUSD(e.target.checked)}
                    defaultChecked={showInUSD}
                  />
                  <label className="form-check-label mb-3" htmlFor="showInUSD">
                    {translate("resources.root.showInUSD")}
                  </label>
                </div>
              </Col>
              <Col xs={4}>
                <Button
                  id="export"
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>
            </Row>
          </NoPrint>
          <Row style={{ fontSize: "15px" }} className={`mt-2`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.name")}</th>
                    <th>{translate("resources.root.owner")}</th>
                    <th>{translate("resources.root.phone")}</th>
                    <th>{translate("resources.root.purchaseQty")}</th>
                    <th>{translate("resources.root.averagePurchasePrice")}</th>
                    <th>{translate("resources.root.totalPurchasePrice")}</th>
                    <th>{translate("resources.root.type")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr>
                          <td>{one.supplierId ?? "-"}</td>
                          <td>
                            {one.supplierName.substring(0, 40).concat("...") ??
                              "-"}
                          </td>
                          <td>{one.supplierOwner ?? "-"}</td>
                          <td>
                            {one.supplierPhone.replace("+964", "0") ?? "-"}
                          </td>
                          <td>{one.purchaseQty ?? "-"}</td>
                          <td>
                            {showInUSD
                              ? one.mcAveragePurchasePrice?.toLocaleString() +
                                  " " +
                                  translate("resources.root.usd") ?? "-"
                              : one.scAveragePurchasePrice?.toLocaleString() +
                                  " " +
                                  translate("resources.root.iqd") ?? "-"}
                          </td>
                          <td>
                            {showInUSD
                              ? one.mcTotalPurchasePrice?.toLocaleString() +
                                  " " +
                                  translate("resources.root.usd") ?? "-"
                              : one.scTotalPurchasePrice?.toLocaleString() +
                                  " " +
                                  translate("resources.root.iqd") ?? "-"}
                          </td>
                          <td>{one.type ?? "-"}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
