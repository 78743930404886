import * as React from "react";
import { useTranslate } from "react-admin";
import HomeIcon from "@mui/icons-material/Home";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StoreIcon from "@mui/icons-material/Store";
import CategoryIcon from "@mui/icons-material/Category";
import PeopleOutline from "@mui/icons-material/PeopleOutline";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import ExtensionIcon from "@mui/icons-material/Extension";
import ExtensionTwoToneIcon from "@mui/icons-material/ExtensionTwoTone";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import BrokenImageOutlinedIcon from "@mui/icons-material/BrokenImageOutlined";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptTwoToneIcon from "@mui/icons-material/ReceiptTwoTone";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import PaymentIcon from "@mui/icons-material/Payment";
import GroupIcon from "@mui/icons-material/Group";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import WarningIcon from "@mui/icons-material/Warning";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DescriptionIcon from "@mui/icons-material/Description";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import TransformIcon from "@mui/icons-material/Transform";
import CheckIcon from "@mui/icons-material/Check";
import LabelIcon from "@mui/icons-material/Label";

export default function NestedList() {
  const classes = useStyles();
  const role = localStorage.getItem("role") ?? "NoUser";
  const [openProducts, setOpenProducts] = React.useState(false);
  const [openInvoices, setOpenInvoices] = React.useState(false);
  const [openIndustries, setOpenIndustries] = React.useState(false);
  const [openPurchases, setOpenPurchases] = React.useState(false);
  const [openAccountant, setOpenAccountant] = React.useState(false);
  const [openCustomer, setOpenCustomer] = React.useState(false);
  const [openCashier, setOpenCashier] = React.useState(false);
  const [openSupplier, setOpenSupplier] = React.useState(false);
  const [openEmployees, setOpenEmployees] = React.useState(false);
  const [openRepresentatives, setOpenRepresentatives] = React.useState(false);
  const [openRepresentativeCustomers, setOpenRepresentativeCustomers] =
    React.useState(false);
  const [openGeneralReport, setOpenGeneralReport] = React.useState(false);
  const [openItemReports, setOpenItemReports] = React.useState(false);
  const [openHrReport, setHrOpenReport] = React.useState(false);
  const [openAdminReport, setOpenAdminReport] = React.useState(false);
  const [openSupplierReports, setOpenSupplierReports] = React.useState(false);
  const translate = useTranslate();

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={`${classes.root} sideBarList`}
    >
      <ListItem
        className={classes.nested1}
        button
        onClick={() => {
          setOpenIndustries(false);
          setOpenRepresentatives(false);
          setOpenRepresentativeCustomers(false);
          setOpenInvoices(false);
          setOpenProducts(false);
          setOpenPurchases(false);
          setOpenAccountant(false);
          setOpenEmployees(false);
          setOpenCustomer(false);
          setOpenSupplierReports(false);
          setOpenGeneralReport(false);
          setOpenItemReports(false);
          setHrOpenReport(false);
          setOpenAdminReport(false);
          window.open("#/", "_self");
        }}
      >
        <ListItemIcon className={`mainMenuIcon`}>
          <HomeIcon className="IconMenu" />
        </ListItemIcon>
        <ListItemText
          className={"mainMenuItem"}
          primary={translate("ra.navigation.dashboard")}
          style={{ color: window.location.hash === "#/" ? "white" : "white" }}
        />
      </ListItem>
      {role.toLowerCase() !== "debtcollector" && (
        <div>
          <ListItem
            className={classes.nested1}
            button
            onClick={() => {
              setOpenIndustries(false);
              setOpenRepresentatives(false);
              setOpenRepresentativeCustomers(false);
              setOpenInvoices(false);
              setOpenProducts(!openProducts);
              setOpenPurchases(false);
              setOpenAccountant(false);
              setOpenEmployees(false);
              setOpenCustomer(false);
              setOpenSupplierReports(false);
              setOpenCashier(false);
              setOpenGeneralReport(false);
              setOpenItemReports(false);
              setHrOpenReport(false);
              setOpenAdminReport(false);
            }}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <ExtensionIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("ra.navigation.Products")}
            />
            {openProducts ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openProducts} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/ProductCategories", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <CategoryIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.ProductCategories")}
                  style={{
                    color:
                      window.location.hash === "#/ProductCategories"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/Products", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <ExtensionTwoToneIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.Products")}
                  style={{
                    color:
                      window.location.hash === "#/Products" ? "white" : "white",
                  }}
                />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/Brands", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <LabelIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("resources.root.brands")}
                  style={{
                    color:
                      window.location.hash === "#/Brands" ? "white" : "white",
                  }}
                />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/ProductDamageTypes", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <BrokenImageIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.ProductDamageTypes")}
                  style={{
                    color:
                      window.location.hash === "#/ProductDamageTypes"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/DamageInvoices", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <BrokenImageOutlinedIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.DamageInvoices")}
                  style={{
                    color:
                      window.location.hash === "#/DamageInvoices"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/StoreTransferInvoices", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <TransformIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.StoreTransferInvoices")}
                  style={{
                    color:
                      window.location.hash === "#/StoreTransferInvoices"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>

              {process.env.REACT_APP_APP_NAME !== "Mediply" && ( //midplay don't need process and this will hide it for it
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => window.open("#/ProcessInvoices", "_self")}
                >
                  <ListItemIcon className={`mainMenuIcon`}>
                    <CreateNewFolderIcon className="IconMenu" />
                  </ListItemIcon>
                  <ListItemText
                    className={"mainMenuItem"}
                    primary={translate("ra.navigation.ProcessInvoices")}
                    style={{
                      color:
                        window.location.hash === "#/ProcessInvoices"
                          ? "white"
                          : "white",
                    }}
                  />
                </ListItem>
              )}

              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/StoreInventoryInvoice", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <CreateNewFolderIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("resources.root.storeInventoryInvoice")}
                  style={{
                    color:
                      window.location.hash === "#/StoreInventoryInvoice"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            className={classes.nested1}
            button
            onClick={() => {
              setOpenCustomer(!openCustomer);
              setOpenSupplierReports(false);
              setOpenCashier(false);
              setOpenRepresentatives(false);
              setOpenRepresentativeCustomers(false);
              setOpenAccountant(false);
              setOpenIndustries(false);
              setOpenSupplier(false);
              setOpenInvoices(false);
              setOpenProducts(false);
              setOpenPurchases(false);
              setOpenEmployees(false);
              setOpenGeneralReport(false);
              setOpenItemReports(false);
              setHrOpenReport(false);
              setOpenAdminReport(false);
            }}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <PersonIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("ra.navigation.Customers")}
            />
            {openCustomer ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openCustomer} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/Customers", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <PersonIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.Customers")}
                  style={{
                    color:
                      window.location.hash === "#/Customers"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/CustomerPayments", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <PaymentIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.Payments")}
                  style={{
                    color:
                      window.location.hash === "#/CustomerPayments"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            className={classes.nested1}
            button
            onClick={() => {
              setOpenIndustries(false);
              setOpenRepresentatives(false);
              setOpenRepresentativeCustomers(false);
              setOpenInvoices(!openInvoices);
              setOpenProducts(false);
              setOpenPurchases(false);
              setOpenAccountant(false);
              setOpenEmployees(false);
              setOpenCustomer(false);
              setOpenSupplierReports(false);
              setOpenCashier(false);
              setOpenGeneralReport(false);
              setOpenItemReports(false);
              setHrOpenReport(false);
              setOpenAdminReport(false);
            }}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <ReceiptIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("ra.navigation.Invoices")}
            />
            {openInvoices ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openInvoices} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/SellInvoices", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <ReceiptTwoToneIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.invoices")}
                  style={{
                    color:
                      window.location.hash === "#/SellInvoices"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/SellReturnInvoices", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <AssignmentReturnIcon
                    className="IconMenu"
                    style={{ transform: "scaleX(-1)" }}
                  />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.Return")}
                  style={{
                    color:
                      window.location.hash === "#/SellReturnInvoices"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            className={classes.nested1}
            button
            onClick={() => {
              setOpenSupplier(!openSupplier);
              setOpenRepresentatives(false);
              setOpenRepresentativeCustomers(false);
              setOpenCustomer(false);
              setOpenSupplierReports(false);
              setOpenCashier(false);
              setOpenAccountant(false);
              setOpenIndustries(false);
              setOpenInvoices(false);
              setOpenProducts(false);
              setOpenPurchases(false);
              setOpenEmployees(false);
              setOpenGeneralReport(false);
              setOpenItemReports(false);
              setHrOpenReport(false);
              setOpenAdminReport(false);
            }}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <BusinessIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("ra.navigation.Suppliers")}
            />
            {openSupplier ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSupplier} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/Suppliers", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <BusinessIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.Suppliers")}
                  style={{
                    color:
                      window.location.hash === "#/Suppliers"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/SupplierPayments", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <PaymentIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.Payments")}
                  style={{
                    color:
                      window.location.hash === "#/SupplierPayments"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            className={classes.nested1}
            button
            onClick={() => {
              setOpenIndustries(false);
              setOpenRepresentatives(false);
              setOpenRepresentativeCustomers(false);
              setOpenInvoices(false);
              setOpenProducts(false);
              setOpenSupplier(false);
              setOpenPurchases(!openPurchases);
              setOpenAccountant(false);
              setOpenEmployees(false);
              setOpenCustomer(false);
              setOpenSupplierReports(false);
              setOpenCashier(false);
              setOpenGeneralReport(false);
              setOpenItemReports(false);
              setHrOpenReport(false);
              setOpenAdminReport(false);
            }}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <ShoppingBasketIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("ra.navigation.Purchases")}
            />
            {openPurchases ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openPurchases} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/PurchaseInvoices", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <ReceiptIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.invoices")}
                  style={{
                    color:
                      window.location.hash === "#/PurchaseInvoices"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>

              {/*<ListItem button className={classes.nested} onClick={() => window.open("#/PurchasePreInvoice", "_self")}>*/}
              {/*  <ListItemIcon className={`mainMenuIcon}>*/}
              {/*    <FileCopyIcon />*/}
              {/*  </ListItemIcon>*/}
              {/*  <ListItemText
               className={'mainMenuItem'}
               primary={translate("ra.navigation.PurchasePreInvoices")} />*/}
              {/*</ListItem>*/}

              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/PurchaseReturnInvoices", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <AssignmentReturnIcon
                    className="IconMenu"
                    style={{ transform: "scaleX(-1)" }}
                  />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.PurchaseReturnInvoices")}
                  style={{
                    color:
                      window.location.hash === "#/PurchaseReturnInvoices"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>
            </List>
          </Collapse>

          {process.env.REACT_APP_REPRESENTATIVE_MODULE === "true" && (
            <div>
              <ListItem
                className={classes.nested1}
                button
                onClick={() => {
                  setOpenRepresentatives(!openRepresentatives);
                  setOpenRepresentativeCustomers(false);
                  setOpenEmployees(false);
                  setOpenSupplier(false);
                  setOpenCustomer(false);
                  setOpenSupplierReports(false);
                  setOpenCashier(false);
                  setOpenAccountant(false);
                  setOpenIndustries(false);
                  setOpenInvoices(false);
                  setOpenProducts(false);
                  setOpenPurchases(false);
                  setOpenGeneralReport(false);
                  setOpenItemReports(false);
                  setHrOpenReport(false);
                }}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <SupervisedUserCircleIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.Representatives")}
                />
                {openRepresentatives ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openRepresentatives} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() => window.open("#/Representatives", "_self")}
                  >
                    <ListItemIcon className={`mainMenuIcon`}>
                      <SupervisedUserCircleIcon className="IconMenu" />
                    </ListItemIcon>
                    <ListItemText
                      className={"mainMenuItem"}
                      primary={translate("ra.navigation.Representatives")}
                      style={{
                        color:
                          window.location.hash === "#/Representatives"
                            ? "white"
                            : "white",
                      }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() =>
                      window.open("#/RepresentativeInvoices", "_self")
                    }
                  >
                    <ListItemIcon className={`mainMenuIcon`}>
                      <ReceiptIcon className="IconMenu" />
                    </ListItemIcon>
                    <ListItemText
                      className={"mainMenuItem"}
                      primary={translate(
                        "ra.navigation.RepresentativeInvoices"
                      )}
                      style={{
                        color:
                          window.location.hash === "#/RepresentativeInvoices"
                            ? "white"
                            : "white",
                      }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() =>
                      window.open("#/RepresentativePayments", "_self")
                    }
                  >
                    <ListItemIcon className={`mainMenuIcon`}>
                      <PaymentIcon className="IconMenu" />
                    </ListItemIcon>
                    <ListItemText
                      className={"mainMenuItem"}
                      primary={translate("ra.navigation.Payments")}
                      style={{
                        color:
                          window.location.hash === "#/RepresentativePayments"
                            ? "white"
                            : "white",
                      }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() =>
                      window.open("#/RepresentativeReturnInvoices", "_self")
                    }
                  >
                    <ListItemIcon className={`mainMenuIcon`}>
                      <AssignmentReturnIcon
                        className="IconMenu"
                        style={{ transform: "scaleX(-1)" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={"mainMenuItem"}
                      primary={translate("ra.navigation.Return")}
                      style={{
                        color:
                          window.location.hash ===
                          "#/RepresentativeReturnInvoices"
                            ? "white"
                            : "white",
                      }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() =>
                      window.open("#/RepresentativePreInvoices", "_self")
                    }
                  >
                    <ListItemIcon className={`mainMenuIcon`}>
                      <FileCopyIcon className="IconMenu" />
                    </ListItemIcon>
                    <ListItemText
                      className={"mainMenuItem"}
                      primary={translate("ra.navigation.PreInvoices")}
                      style={{
                        color:
                          window.location.hash === "#/RepresentativePreInvoices"
                            ? "white"
                            : "white",
                      }}
                    />
                  </ListItem>
                </List>
              </Collapse>
              <ListItem
                className={classes.nested1}
                button
                onClick={() => {
                  setOpenRepresentativeCustomers(!openRepresentativeCustomers);
                  setOpenRepresentatives(false);
                  setOpenEmployees(false);
                  setOpenSupplier(false);
                  setOpenCustomer(false);
                  setOpenSupplierReports(false);
                  setOpenCashier(false);
                  setOpenAccountant(false);
                  setOpenIndustries(false);
                  setOpenInvoices(false);
                  setOpenProducts(false);
                  setOpenPurchases(false);
                  setOpenGeneralReport(false);
                  setOpenItemReports(false);
                  setHrOpenReport(false);
                }}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <PeopleOutline className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.RepresentativeCustomers")}
                />
                {openRepresentativeCustomers ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={openRepresentativeCustomers}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() =>
                      window.open("#/RepresentativeCustomers", "_self")
                    }
                  >
                    <ListItemIcon className={`mainMenuIcon`}>
                      <PersonIcon className="IconMenu" />
                    </ListItemIcon>
                    <ListItemText
                      className={"mainMenuItem"}
                      primary={translate(
                        "ra.navigation.RepresentativeCustomers"
                      )}
                      style={{
                        color:
                          window.location.hash === "#/RepresentativeCustomers"
                            ? "white"
                            : "white",
                      }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() =>
                      window.open("#/RepresentativeCustomerPayments", "_self")
                    }
                  >
                    <ListItemIcon className={`mainMenuIcon`}>
                      <PaymentIcon className="IconMenu" />
                    </ListItemIcon>
                    <ListItemText
                      className={"mainMenuItem"}
                      primary={translate("ra.navigation.Payments")}
                      style={{
                        color:
                          window.location.hash ===
                          "#/RepresentativeCustomerPayments"
                            ? "white"
                            : "white",
                      }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() =>
                      window.open(
                        "#/RepresentativeCustomerSellInvoices",
                        "_self"
                      )
                    }
                  >
                    <ListItemIcon className={`mainMenuIcon`}>
                      <ReceiptIcon className="IconMenu" />
                    </ListItemIcon>
                    <ListItemText
                      className={"mainMenuItem"}
                      primary={translate("ra.navigation.Invoices")}
                      style={{
                        color:
                          window.location.hash ===
                          "#/RepresentativeCustomerSellInvoices"
                            ? "white"
                            : "white",
                      }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() =>
                      window.open("#/RepresentativeCustomerChecks", "_self")
                    }
                  >
                    <ListItemIcon className={`mainMenuIcon`}>
                      <CheckIcon className="IconMenu" />
                    </ListItemIcon>
                    <ListItemText
                      className={"mainMenuItem"}
                      primary={translate("ra.navigation.Checks")}
                      style={{
                        color:
                          window.location.hash ===
                          "#/RepresentativeCustomerChecks"
                            ? "white"
                            : "white",
                      }}
                    />
                  </ListItem>
                </List>
              </Collapse>
            </div>
          )}
          {process.env.REACT_APP_CASHIER_MODULE === "true" && (
            <div>
              {role &&
                (role.toLowerCase() === "storeadmin" ||
                  role.toLowerCase() === "superadmin" ||
                  role.toLowerCase() === "admin" ||
                  role.toLowerCase() === "accountant") && (
                  <div>
                    <ListItem
                      className={classes.nested1}
                      button
                      onClick={() => {
                        setOpenEmployees(false);
                        setOpenRepresentatives(false);
                        setOpenRepresentativeCustomers(false);
                        setOpenSupplier(false);
                        setOpenCustomer(false);
                        setOpenSupplierReports(false);
                        setOpenCashier(!openCashier);
                        setOpenAccountant(false);
                        setOpenIndustries(false);
                        setOpenInvoices(false);
                        setOpenProducts(false);
                        setOpenPurchases(false);
                        setOpenGeneralReport(false);
                        setOpenItemReports(false);
                        setHrOpenReport(false);
                      }}
                    >
                      <ListItemIcon className={`mainMenuIcon`}>
                        <GroupIcon className="IconMenu" />
                      </ListItemIcon>
                      <ListItemText
                        className={"mainMenuItem"}
                        primary={translate("resources.root.cashiers")}
                      />
                      {openCashier ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openCashier} timeout="auto" unmountOnExit>
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={() =>
                          window.open("#/QuickSellInvoices", "_self")
                        }
                      >
                        <ListItemIcon className={`mainMenuIcon`}>
                          <ReceiptIcon className="IconMenu" />
                        </ListItemIcon>
                        <ListItemText
                          className={"mainMenuItem"}
                          primary={translate("resources.root.sells")}
                          style={{
                            color:
                              window.location.hash === "#/QuickSellInvoices"
                                ? "white"
                                : "white",
                          }}
                        />
                      </ListItem>
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={() =>
                          window.open("#/QuickSellReturnInvoices", "_self")
                        }
                      >
                        <ListItemIcon className={`mainMenuIcon`}>
                          <ReceiptIcon className="IconMenu" />
                        </ListItemIcon>
                        <ListItemText
                          className={"mainMenuItem"}
                          primary={translate("resources.root.returns")}
                          style={{
                            color:
                              window.location.hash ===
                              "#/QuickSellReturnInvoices"
                                ? "white"
                                : "white",
                          }}
                        />
                      </ListItem>
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={() =>
                          window.open(
                            "#/UserStoreBankAccountWithdraws",
                            "_self"
                          )
                        }
                      >
                        <ListItemIcon className={`mainMenuIcon`}>
                          <ReceiptIcon className="IconMenu" />
                        </ListItemIcon>
                        <ListItemText
                          className={"mainMenuItem"}
                          primary={translate("ra.navigation.Withdraws")}
                          style={{
                            color:
                              window.location.hash ===
                              "#/UserStoreBankAccountWithdraws"
                                ? "white"
                                : "white",
                          }}
                        />
                      </ListItem>
                    </Collapse>
                  </div>
                )}
            </div>
          )}

          {process.env.REACT_APP_HR_MODULE === "true" &&
            role.toLowerCase() !== "dataentry" && (
              <div>
                <ListItem
                  className={classes.nested1}
                  button
                  onClick={() => {
                    setOpenEmployees(!openEmployees);
                    setOpenRepresentatives(false);
                    setOpenRepresentativeCustomers(false);
                    setOpenSupplier(false);
                    setOpenCustomer(false);
                    setOpenSupplierReports(false);
                    setOpenCashier(false);
                    setOpenAccountant(false);
                    setOpenIndustries(false);
                    setOpenInvoices(false);
                    setOpenProducts(false);
                    setOpenPurchases(false);
                    setOpenGeneralReport(false);
                    setOpenItemReports(false);
                    setHrOpenReport(false);
                  }}
                >
                  <ListItemIcon className={`mainMenuIcon`}>
                    <GroupIcon className="IconMenu" />
                  </ListItemIcon>
                  <ListItemText
                    className={"mainMenuItem"}
                    primary={translate("ra.navigation.hr")}
                  />
                  {openEmployees ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openEmployees} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => window.open("#/Employees", "_self")}
                    >
                      <ListItemIcon className={`mainMenuIcon`}>
                        <GroupIcon className="IconMenu" />
                      </ListItemIcon>
                      <ListItemText
                        className={"mainMenuItem"}
                        primary={translate("ra.navigation.Employees")}
                        style={{
                          color:
                            window.location.hash === "#/Employees"
                              ? "white"
                              : "white",
                        }}
                      />
                    </ListItem>

                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => window.open("#/EmployeeLoans", "_self")}
                    >
                      <ListItemIcon className={`mainMenuIcon`}>
                        <AccountBalanceWalletIcon className="IconMenu" />
                      </ListItemIcon>
                      <ListItemText
                        className={"mainMenuItem"}
                        primary={translate("ra.navigation.EmployeeLoans")}
                        style={{
                          color:
                            window.location.hash === "#/EmployeeLoans"
                              ? "white"
                              : "white",
                        }}
                      />
                    </ListItem>

                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() =>
                        window.open("#/EmployeeReturnLoans", "_self")
                      }
                    >
                      <ListItemIcon className={`mainMenuIcon`}>
                        <PaymentIcon className="IconMenu" />
                      </ListItemIcon>
                      <ListItemText
                        className={"mainMenuItem"}
                        primary={translate("ra.navigation.EmployeeReturnLoans")}
                        style={{
                          color:
                            window.location.hash === "#/EmployeeReturnLoans"
                              ? "white"
                              : "white",
                        }}
                      />
                    </ListItem>

                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => window.open("#/EmployeeAbsents", "_self")}
                    >
                      <ListItemIcon className={`mainMenuIcon`}>
                        <EventBusyIcon className="IconMenu" />
                      </ListItemIcon>
                      <ListItemText
                        className={"mainMenuItem"}
                        primary={translate("ra.navigation.EmployeeAbsents")}
                        style={{
                          color:
                            window.location.hash === "#/EmployeeAbsents"
                              ? "white"
                              : "white",
                        }}
                      />
                    </ListItem>

                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => window.open("#/EmployeeRewards", "_self")}
                    >
                      <ListItemIcon className={`mainMenuIcon`}>
                        <CardGiftcardIcon className="IconMenu" />
                      </ListItemIcon>
                      <ListItemText
                        className={"mainMenuItem"}
                        primary={translate("ra.navigation.EmployeeRewards")}
                        style={{
                          color:
                            window.location.hash === "#/EmployeeRewards"
                              ? "white"
                              : "white",
                        }}
                      />
                    </ListItem>

                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() =>
                        window.open("#/EmployeePunishments", "_self")
                      }
                    >
                      <ListItemIcon className={`mainMenuIcon`}>
                        <WarningIcon className="IconMenu" />
                      </ListItemIcon>
                      <ListItemText
                        className={"mainMenuItem"}
                        primary={translate("ra.navigation.EmployeePunishments")}
                        style={{
                          color:
                            window.location.hash === "#/EmployeePunishments"
                              ? "white"
                              : "white",
                        }}
                      />
                    </ListItem>

                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => window.open("#/EmployeeAllows", "_self")}
                    >
                      <ListItemIcon className={`mainMenuIcon`}>
                        <EventAvailableIcon className="IconMenu" />
                      </ListItemIcon>
                      <ListItemText
                        className={"mainMenuItem"}
                        primary={translate("ra.navigation.EmployeePermissions")}
                        style={{
                          color:
                            window.location.hash === "#/EmployeeAllows"
                              ? "white"
                              : "white",
                        }}
                      />
                    </ListItem>

                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() =>
                        window.open("#/EmployeeSalaryPays", "_self")
                      }
                    >
                      <ListItemIcon className={`mainMenuIcon`}>
                        <ListAltIcon className="IconMenu" />
                      </ListItemIcon>
                      <ListItemText
                        className={"mainMenuItem"}
                        primary={translate("ra.navigation.Salary")}
                        style={{
                          color:
                            window.location.hash === "#/EmployeeSalaryPays"
                              ? "white"
                              : "white",
                        }}
                      />
                    </ListItem>

                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() =>
                        window.open("#/EmployeeSalaryChanges", "_self")
                      }
                    >
                      <ListItemIcon className={`mainMenuIcon`}>
                        <PlaylistAddCheckIcon className="IconMenu" />
                      </ListItemIcon>
                      <ListItemText
                        className={"mainMenuItem"}
                        primary={translate("ra.navigation.salaryChange")}
                        style={{
                          color:
                            window.location.hash === "#/EmployeeSalaryChanges"
                              ? "white"
                              : "white",
                        }}
                      />
                    </ListItem>

                    {/*<ListItem button className={classes.nested}*/}
                    {/*          onClick={() => window.open("#/EmployeeSalarySummary", "_self")}>*/}
                    {/*    <ListItemIcon className={`mainMenuIcon} }>*/}
                    {/*        <PlaylistAddCheckIcon/>*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText
                   className={'mainMenuItem'}
                   primary={translate("ra.navigation.SalarySummary")} />*/}
                    {/*</ListItem>*/}
                  </List>
                </Collapse>
              </div>
            )}
          {role &&
            (role.toLowerCase() === "storeadmin" ||
              role.toLowerCase() === "superadmin" ||
              role.toLowerCase() === "admin" ||
              role.toLowerCase() === "accountant" ||
              role.toLowerCase() === "dataentry") && (
              <div>
                <ListItem
                  className={classes.nested1}
                  button
                  onClick={() => {
                    setOpenAccountant(!openAccountant);
                    setOpenRepresentatives(false);
                    setOpenRepresentativeCustomers(false);
                    setOpenCustomer(false);
                    setOpenSupplierReports(false);
                    setOpenCashier(false);
                    setOpenIndustries(false);
                    setOpenInvoices(false);
                    setOpenProducts(false);
                    setOpenPurchases(false);
                    setOpenEmployees(false);
                    setOpenGeneralReport(false);
                    setOpenItemReports(false);
                    setHrOpenReport(false);
                    setOpenAdminReport(false);
                  }}
                >
                  <ListItemIcon className={`mainMenuIcon`}>
                    <AccountBalanceIcon className="IconMenu" />
                  </ListItemIcon>
                  <ListItemText
                    className={"mainMenuItem"}
                    primary={translate("ra.navigation.Accountant")}
                  />
                  {openAccountant ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openAccountant} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => window.open("#/BankAccounts", "_self")}
                    >
                      <ListItemIcon className={`mainMenuIcon`}>
                        <AccountBalanceIcon className="IconMenu" />
                      </ListItemIcon>
                      <ListItemText
                        className={"mainMenuItem"}
                        primary={translate("ra.navigation.BankAccounts")}
                        style={{
                          color:
                            window.location.hash === "#/BankAccounts"
                              ? "white"
                              : "white",
                        }}
                      />
                    </ListItem>

                    {(role.toLowerCase() === "storeadmin" ||
                      role.toLowerCase() === "superadmin" ||
                      role.toLowerCase() === "admin") && (
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={() =>
                          window.open("#/StoreTransactionHistories", "_self")
                        }
                      >
                        <ListItemIcon className={`mainMenuIcon`}>
                          <AccountBalanceIcon className="IconMenu" />
                        </ListItemIcon>
                        <ListItemText
                          className={"mainMenuItem"}
                          primary={translate(
                            "ra.navigation.storeTransactionHistories"
                          )}
                          style={{
                            color:
                              window.location.hash ===
                              "#/StoreTransactionHistories"
                                ? "white"
                                : "white",
                          }}
                        />
                      </ListItem>
                    )}

                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() =>
                        window.open("#/BankAccountTransfers", "_self")
                      }
                    >
                      <ListItemIcon className={`mainMenuIcon`}>
                        <AccountBalanceIcon className="IconMenu" />
                      </ListItemIcon>
                      <ListItemText
                        className={"mainMenuItem"}
                        primary={translate(
                          "ra.navigation.BankAccountTransfers"
                        )}
                        style={{
                          color:
                            window.location.hash === "#/BankAccountTransfers"
                              ? "white"
                              : "white",
                        }}
                      />
                    </ListItem>

                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => window.open("#/BalanceSheet", "_self")}
                    >
                      <ListItemIcon className={`mainMenuIcon`}>
                        <AccountBalanceIcon className="IconMenu" />
                      </ListItemIcon>
                      <ListItemText
                        className={"mainMenuItem"}
                        primary={translate("resources.root.balanceSheet")}
                        style={{
                          color:
                            window.location.hash === "#/BalanceSheet"
                              ? "white"
                              : "white",
                        }}
                      />
                    </ListItem>

                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => window.open("#/Deposits", "_self")}
                    >
                      <ListItemIcon className={`mainMenuIcon`}>
                        <ExitToAppIcon className="IconMenu" />
                      </ListItemIcon>
                      <ListItemText
                        className={"mainMenuItem"}
                        primary={translate("ra.navigation.Deposits")}
                        style={{
                          color:
                            window.location.hash === "#/Deposits"
                              ? "white"
                              : "white",
                        }}
                      />
                    </ListItem>

                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => window.open("#/Withdraws", "_self")}
                    >
                      <ListItemIcon className={`mainMenuIcon`}>
                        <ExitToAppIcon
                          className="IconMenu"
                          style={{ transform: "rotate(-180deg)" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        className={"mainMenuItem"}
                        primary={translate("ra.navigation.Withdraws")}
                        style={{
                          color:
                            window.location.hash === "#/Withdraws"
                              ? "white"
                              : "white",
                        }}
                      />
                    </ListItem>

                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => window.open("#/Expenses", "_self")}
                    >
                      <ListItemIcon className={`mainMenuIcon`}>
                        <DescriptionIcon className="IconMenu" />
                      </ListItemIcon>
                      <ListItemText
                        className={"mainMenuItem"}
                        primary={translate("ra.navigation.Expenses")}
                        style={{
                          color:
                            window.location.hash === "#/Expenses"
                              ? "white"
                              : "white",
                        }}
                      />
                    </ListItem>

                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() =>
                        window.open("#/CurrencyExchanges", "_self")
                      }
                    >
                      <ListItemIcon className={`mainMenuIcon`}>
                        <LocalAtmIcon className="IconMenu" />
                      </ListItemIcon>
                      <ListItemText
                        className={"mainMenuItem"}
                        primary={translate("ra.navigation.CurrencyExchanges")}
                        style={{
                          color:
                            window.location.hash === "#/CurrencyExchanges"
                              ? "white"
                              : "white",
                        }}
                      />
                    </ListItem>
                  </List>
                </Collapse>
              </div>
            )}

          <ListItem
            className={classes.nested1}
            button
            onClick={() => {
              setOpenIndustries(!openIndustries);
              setOpenRepresentatives(false);
              setOpenRepresentativeCustomers(false);
              setOpenInvoices(false);
              setOpenCustomer(false);
              setOpenSupplierReports(false);
              setOpenCashier(false);
              setOpenProducts(false);
              setOpenPurchases(false);
              setOpenAccountant(false);
              setOpenEmployees(false);
              setOpenGeneralReport(false);
              setOpenItemReports(false);
              setHrOpenReport(false);
              setOpenAdminReport(false);
            }}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <SettingsApplicationsIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("ra.navigation.Settings")}
            />
            {openIndustries ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openIndustries} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/Stores", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <StoreIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.Stores")}
                  style={{
                    color:
                      window.location.hash === "#/Stores" ? "white" : "white",
                  }}
                />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/Branches", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <MergeTypeIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.Branches")}
                  style={{
                    color:
                      window.location.hash === "#/Branches" ? "white" : "white",
                  }}
                />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/DepositTypes", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <ExitToAppIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.DepositTypes")}
                  style={{
                    color:
                      window.location.hash === "#/DepositTypes"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/WithdrawTypes", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <ExitToAppIcon
                    className="IconMenu"
                    style={{ transform: "rotate(-180deg)" }}
                  />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.WithdrawTypes")}
                  style={{
                    color:
                      window.location.hash === "#/WithdrawTypes"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/ExpenseTypes", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <DescriptionIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.ExpenseTypes")}
                  style={{
                    color:
                      window.location.hash === "#/ExpenseTypes"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/UploadCenters", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <ExitToAppIcon
                    className="IconMenu"
                    style={{ transform: "rotate(-180deg)" }}
                  />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("ra.navigation.uploadCenter")}
                  style={{
                    color:
                      window.location.hash === "#/UploadCenters"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>

              {(role.toLowerCase() === "storeadmin" ||
                role.toLowerCase() === "superadmin" ||
                role.toLowerCase() === "admin") && (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => window.open("#/Users", "_self")}
                >
                  <ListItemIcon className={`mainMenuIcon`}>
                    <AssignmentIndIcon className="IconMenu" />
                  </ListItemIcon>
                  <ListItemText
                    className={"mainMenuItem"}
                    primary={translate("ra.navigation.UserNames")}
                    style={{
                      color:
                        window.location.hash === "#/Users" ? "white" : "white",
                    }}
                  />
                </ListItem>
              )}
            </List>
          </Collapse>
        </div>
      )}
      {/* General Reports */}
      <ListItem
        className={classes.nested1}
        button
        onClick={() => {
          setOpenAccountant(false);
          setOpenRepresentatives(false);
          setOpenRepresentativeCustomers(false);
          setOpenCustomer(false);
          setOpenSupplierReports(false);
          setOpenCashier(false);
          setOpenIndustries(false);
          setOpenInvoices(false);
          setOpenProducts(false);
          setOpenPurchases(false);
          setOpenEmployees(false);
          setOpenGeneralReport(!openGeneralReport);
          setOpenItemReports(false);
          setHrOpenReport(false);
          setOpenAdminReport(false);
        }}
      >
        <ListItemIcon className={`mainMenuIcon`}>
          <AccountBalanceIcon className="IconMenu" />
        </ListItemIcon>
        <ListItemText
          className={"mainMenuItem"}
          primary={translate("resources.root.generalReports")}
        />
        {openGeneralReport ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openGeneralReport} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={() => window.open("#/Reports/OrdersReport", "_self")}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.ordersReport")}
              style={{
                color:
                  window.location.hash === "#/Reports/OrdersReport"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => window.open("#/Reports/CustomerStatement", "_self")}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.customerStatement")}
              style={{
                color:
                  window.location.hash === "#/Reports/CustomerStatement"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => window.open("#/Reports/CashierSellsReport", "_self")}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.cashierSellsReport")}
              style={{
                color:
                  window.location.hash === "#/Reports/CashierSellsReport"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          {process.env.REACT_APP_REPRESENTATIVE_MODULE && (
            <ListItem
              button
              className={classes.nested}
              onClick={() =>
                window.open("#/Reports/RepresentativeStatement", "_self")
              }
            >
              <ListItemIcon className={`mainMenuIcon`}>
                <AccountBalanceIcon className="IconMenu" />
              </ListItemIcon>
              <ListItemText
                className={"mainMenuItem"}
                primary={translate("resources.root.representativeStatement")}
                style={{
                  color:
                    window.location.hash === "#/Reports/RepresentativeStatement"
                      ? "white"
                      : "white",
                }}
              />
            </ListItem>
          )}
          <ListItem
            button
            className={classes.nested}
            onClick={() => window.open("#/Reports/ExpireReport", "_self")}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.expireReport")}
              style={{
                color:
                  window.location.hash === "#/Reports/ExpireReport"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => window.open("#/Reports/SellInvoiceReport", "_self")}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.sellInvoice")}
              style={{
                color:
                  window.location.hash === "#/Reports/SellInvoiceReport"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => window.open("#/Reports/PurchaseReport", "_self")}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.purchaseReport")}
              style={{
                color:
                  window.location.hash === "#/Reports/PurchaseReport"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => window.open("#/Reports/ExpenseReport", "_self")}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.expenseReport")}
              style={{
                color:
                  window.location.hash === "#/Reports/ExpenseReport"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => window.open("#/Reports/CustomerReport", "_self")}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.customerReport")}
              style={{
                color:
                  window.location.hash === "#/Reports/CustomerReport"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() =>
              window.open("#/Reports/CustomerPaymentReport", "_self")
            }
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.customerPaymentReport")}
              style={{
                color:
                  window.location.hash === "#/Reports/CustomerPaymentReport"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
        </List>
      </Collapse>
      {/* Items Report */}
      <ListItem
        className={classes.nested1}
        button
        onClick={() => {
          setOpenAccountant(false);
          setOpenRepresentatives(false);
          setOpenRepresentativeCustomers(false);
          setOpenCustomer(false);
          setOpenSupplierReports(false);
          setOpenCashier(false);
          setOpenIndustries(false);
          setOpenInvoices(false);
          setOpenProducts(false);
          setOpenPurchases(false);
          setOpenEmployees(false);
          setOpenGeneralReport(false);
          setOpenItemReports(!openItemReports);
          setHrOpenReport(false);
        }}
      >
        <ListItemIcon className={`mainMenuIcon`}>
          <AccountBalanceIcon className="IconMenu" />
        </ListItemIcon>
        <ListItemText
          className={"mainMenuItem"}
          primary={translate("resources.root.itemReports")}
        />
        {openItemReports ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openItemReports} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={() => window.open("#/Reports/ProductStatement", "_self")}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.productStatement")}
              style={{
                color:
                  window.location.hash === "#/Reports/ProductStatement"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => window.open("#/Reports/ReportStock", "_self")}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.reportStock")}
              style={{
                color:
                  window.location.hash === "#/Reports/ReportStock"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => window.open("#/Reports/NotSaleReport", "_self")}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.notSaleReport")}
              style={{
                color:
                  window.location.hash === "#/Reports/NotSaleReport"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => window.open("#/Reports/StockReport", "_self")}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.stockReport")}
              style={{
                color:
                  window.location.hash === "#/Reports/StockReport"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => window.open("#/Reports/BrandItemReport", "_self")}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.brandItemReport")}
              style={{
                color:
                  window.location.hash === "#/Reports/BrandItemReport"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() =>
              window.open("#/Reports/ProductSellsInvoice", "_self")
            }
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.productSellsInvoice")}
              style={{
                color:
                  window.location.hash === "#/Reports/ProductSellsInvoice"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
        </List>
      </Collapse>
      {/* Suppliers Report */}
      <ListItem
        className={classes.nested1}
        button
        onClick={() => {
          setOpenAccountant(false);
          setOpenRepresentatives(false);
          setOpenRepresentativeCustomers(false);
          setOpenCustomer(false);
          setOpenCashier(false);
          setOpenIndustries(false);
          setOpenInvoices(false);
          setOpenProducts(false);
          setOpenPurchases(false);
          setOpenEmployees(false);
          setOpenGeneralReport(false);
          setOpenItemReports(false);
          setHrOpenReport(false);
          setOpenAdminReport(false);
          setOpenSupplierReports(!openSupplierReports);
        }}
      >
        <ListItemIcon className={`mainMenuIcon`}>
          <AccountBalanceIcon className="IconMenu" />
        </ListItemIcon>
        <ListItemText
          className={"mainMenuItem"}
          primary={translate("resources.root.supplierReports")}
        />
        {openSupplierReports ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openSupplierReports} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={() => window.open("#/Reports/SupplierStatement", "_self")}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.supplierStatement")}
              style={{
                color:
                  window.location.hash === "#/Reports/SupplierStatement"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => window.open("#/Reports/SupplierReport", "_self")}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.supplierReport")}
              style={{
                color:
                  window.location.hash === "#/Reports/SupplierReport"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() =>
              window.open("#/Reports/SupplierPaymentReport", "_self")
            }
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.supplierPaymentReport")}
              style={{
                color:
                  window.location.hash === "#/Reports/SupplierPaymentReport"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() =>
              window.open("#/Reports/SupplierStockRemain", "_self")
            }
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.supplierStockRemain")}
              style={{
                color:
                  window.location.hash === "#/Reports/SupplierStockRemain"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => window.open("#/Reports/SupplierItemReport", "_self")}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.supplierItemReport")}
              style={{
                color:
                  window.location.hash === "#/Reports/SupplierItemReport"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => window.open("#/Reports/ItemSupplierReport", "_self")}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.itemSupplierReport")}
              style={{
                color:
                  window.location.hash === "#/Reports/ItemSupplierReport"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() => window.open("#/Reports/SupplierSellReport", "_self")}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.sells")}
              style={{
                color:
                  window.location.hash === "#/Reports/SupplierSellReport"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            onClick={() =>
              window.open("#/Reports/SupplierStockFullReport", "_self")
            }
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.supplierStockReport")}
              style={{
                color:
                  window.location.hash === "#/Reports/SupplierStockFullReport"
                    ? "white"
                    : "white",
              }}
            />
          </ListItem>
        </List>
      </Collapse>
      {/* Admin Reoprt Menu */}
      {(role.toLowerCase() === "storeadmin" ||
        role.toLowerCase() === "superadmin" ||
        role.toLowerCase() === "admin") && (
        <div>
          <ListItem
            className={classes.nested1}
            button
            onClick={() => {
              setOpenAccountant(false);
              setOpenRepresentatives(false);
              setOpenRepresentativeCustomers(false);
              setOpenCustomer(false);
              setOpenSupplierReports(false);
              setOpenCashier(false);
              setOpenIndustries(false);
              setOpenInvoices(false);
              setOpenProducts(false);
              setOpenPurchases(false);
              setOpenEmployees(false);
              setOpenGeneralReport(false);
              setOpenItemReports(false);
              setHrOpenReport(false);
              setOpenAdminReport(!openAdminReport);
            }}
          >
            <ListItemIcon className={`mainMenuIcon`}>
              <AccountBalanceIcon className="IconMenu" />
            </ListItemIcon>
            <ListItemText
              className={"mainMenuItem"}
              primary={translate("resources.root.adminReport")}
            />
            {openAdminReport ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openAdminReport} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                onClick={() =>
                  window.open("#/AdminReports/ProfitLose", "_self")
                }
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <AccountBalanceIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("resources.root.profitLose")}
                  style={{
                    color:
                      window.location.hash === "#/AdminReports/ProfitLose"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                onClick={() =>
                  window.open("#/AdminReports/InvoiceProfitLose", "_self")
                }
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <AccountBalanceIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("resources.root.invoiceProfitLose")}
                  style={{
                    color:
                      window.location.hash ===
                      "#/AdminReports/InvoiceProfitLose"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>
              <ListItem
                button
                className={classes.nested}
                onClick={() =>
                  window.open("#/AdminReports/CustomerProfitLose", "_self")
                }
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <AccountBalanceIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("resources.root.customerProfitLose")}
                  style={{
                    color:
                      window.location.hash ===
                      "#/AdminReports/CustomerProfitLose"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                onClick={() => window.open("#/Utilities/GetLogs", "_self")}
              >
                <ListItemIcon className={`mainMenuIcon`}>
                  <AccountBalanceIcon className="IconMenu" />
                </ListItemIcon>
                <ListItemText
                  className={"mainMenuItem"}
                  primary={translate("resources.root.logs")}
                  style={{
                    color:
                      window.location.hash === "#/Utilities/GetLogs"
                        ? "white"
                        : "white",
                  }}
                />
              </ListItem>
            </List>
          </Collapse>
        </div>
      )}

      {process.env.REACT_APP_HR_MODULE === "true" &&
        role.toLowerCase() !== "dataentry" &&
        role.toLowerCase() !== "debtcollector" && (
          <div>
            <ListItem
              className={classes.nested1}
              button
              onClick={() => {
                setOpenAccountant(false);
                setOpenRepresentatives(false);
                setOpenRepresentativeCustomers(false);
                setOpenCustomer(false);
                setOpenSupplierReports(false);
                setOpenCashier(false);
                setOpenIndustries(false);
                setOpenInvoices(false);
                setOpenProducts(false);
                setOpenPurchases(false);
                setOpenEmployees(false);
                setOpenGeneralReport(false);
                setOpenItemReports(false);
                setHrOpenReport(!openHrReport);
              }}
            >
              <ListItemIcon className={`mainMenuIcon`}>
                <AccountBalanceIcon className="IconMenu" />
              </ListItemIcon>
              <ListItemText
                className={"mainMenuItem"}
                primary={translate("resources.root.hrReport")}
              />
              {openHrReport ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openHrReport} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() =>
                    window.open("#/HrReports/EmployeeAccountStatement", "_self")
                  }
                >
                  <ListItemIcon className={`mainMenuIcon`}>
                    <AccountBalanceIcon className="IconMenu" />
                  </ListItemIcon>
                  <ListItemText
                    className={"mainMenuItem"}
                    primary={translate("resources.root.employeeStatement")}
                    style={{
                      color:
                        window.location.hash ===
                        "#/HrReports/EmployeeAccountStatement"
                          ? "white"
                          : "white",
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() =>
                    window.open("#/HrReports/AbsentReport", "_self")
                  }
                >
                  <ListItemIcon className={`mainMenuIcon`}>
                    <AccountBalanceIcon className="IconMenu" />
                  </ListItemIcon>
                  <ListItemText
                    className={"mainMenuItem"}
                    primary={translate("resources.root.absentReport")}
                    style={{
                      color:
                        window.location.hash === "#/HrReports/AbsentReport"
                          ? "white"
                          : "white",
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => window.open("#/HrReports/LoanReport", "_self")}
                >
                  <ListItemIcon className={`mainMenuIcon`}>
                    <AccountBalanceIcon className="IconMenu" />
                  </ListItemIcon>
                  <ListItemText
                    className={"mainMenuItem"}
                    primary={translate("resources.root.loanReport")}
                    style={{
                      color:
                        window.location.hash === "#/HrReports/LoanReport"
                          ? "white"
                          : "white",
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() =>
                    window.open("#/HrReports/LoanReturnReport", "_self")
                  }
                >
                  <ListItemIcon className={`mainMenuIcon`}>
                    <AccountBalanceIcon className="IconMenu" />
                  </ListItemIcon>
                  <ListItemText
                    className={"mainMenuItem"}
                    primary={translate("resources.root.returnLoanReport")}
                    style={{
                      color:
                        window.location.hash === "#/HrReports/LoanReturnReport"
                          ? "white"
                          : "white",
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() =>
                    window.open("#/HrReports/PermissionReport", "_self")
                  }
                >
                  <ListItemIcon className={`mainMenuIcon`}>
                    <AccountBalanceIcon className="IconMenu" />
                  </ListItemIcon>
                  <ListItemText
                    className={"mainMenuItem"}
                    primary={translate("resources.root.permissionReport")}
                    style={{
                      color:
                        window.location.hash === "#/HrReports/PermissionReport"
                          ? "white"
                          : "white",
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() =>
                    window.open("#/HrReports/PunishmentReport", "_self")
                  }
                >
                  <ListItemIcon className={`mainMenuIcon`}>
                    <AccountBalanceIcon className="IconMenu" />
                  </ListItemIcon>
                  <ListItemText
                    className={"mainMenuItem"}
                    primary={translate("resources.root.punishmentReport")}
                    style={{
                      color:
                        window.location.hash === "#/HrReports/PunishmentReport"
                          ? "white"
                          : "white",
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() =>
                    window.open("#/HrReports/RewardReport", "_self")
                  }
                >
                  <ListItemIcon className={`mainMenuIcon`}>
                    <AccountBalanceIcon className="IconMenu" />
                  </ListItemIcon>
                  <ListItemText
                    className={"mainMenuItem"}
                    primary={translate("resources.root.rewardReport")}
                    style={{
                      color:
                        window.location.hash === "#/HrReports/RewardReport"
                          ? "white"
                          : "white",
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() =>
                    window.open("#/HrReports/SalaryPayReport", "_self")
                  }
                >
                  <ListItemIcon className={`mainMenuIcon`}>
                    <AccountBalanceIcon className="IconMenu" />
                  </ListItemIcon>
                  <ListItemText
                    className={"mainMenuItem"}
                    primary={translate("resources.root.salaryPayReport")}
                    style={{
                      color:
                        window.location.hash === "#/HrReports/SalaryPayReport"
                          ? "white"
                          : "white",
                    }}
                  />
                </ListItem>
              </List>
            </Collapse>
          </div>
        )}
    </List>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    //   backgroundColor: theme.palette.background.paper,
  },
  nested1: {
    textAlign: "start",
    borderBottom: "1px solid #cccccc",
  },
  nested: {
    paddingInlineStart: theme.spacing(4),
    textAlign: "start",
  },
}));
