export const kurdishMessages = {
  ra: {
    action: {
      edit: "دەستکاری",
      create: "نوێ",
      add_filter: "فلتەرکردن",
      save: "پاشەکەوتکردن",
      export: "داونلۆدکردن",
      delete: "سڕینەوە",
      show: "نیشاندان",
      sort: "ڕیزکردن",
      undo: "پاشگەزبوونەوە",
      bulk_actions: "کردارەکان",
      add: "زیادکردن",
      remove: "سڕینەوە",
      back: "گەڕانەوە",
      refresh: "نوێکردنەوە",
      confirm: "بەڵێ",
      cancel: "پاشگەزبوونەوە",
    },
    auth: {
      logout: "دەرچوون",
      user_menu: "لیست",
      username: "ناو",
      password: "پاسوۆرد",
      sign_in: "چوونەژوورەوە",
      auth_check_error: "بڕواپێدانە نادروستە",
    },
    validation: {
      required: "داواکراوە",
      minValue: "زۆرکەمە",
      maxValue: "زۆرە",
    },
    notification: {
      updated: "نوێکراوەتەوە",
      created: "دروستکراوە",
      deleted: "سڕایەوە",
      logged_out: "چوونەدەرەوە",
    },
    input: {
      image: {
        upload_single: "وێنەیەک ئەپڵۆد بکە",
      },
    },
    boolean: {
      true: "بەڵێ",
      false: "نەخێر",
      null: "بەتاڵ",
    },
    navigation: {
      page_rows_per_page: "زانیارییەکان بۆ هەر پەڕەیەک",
      page_range_info: "زانیاری پەڕە",
      next: "دواتر",
      previous: "پێشووتر",
      no_results: "هیچ ئەنجامێک نەدۆزرایەوە",
      Branches: "لقەکان",
      Stores: "کۆگاکان",
      ProductCategories: "جۆری بەرهەمەکان",
      Products: "بەرهەمەکان",
      ProductDamageTypes: "جۆرەکانی زیان",
      ProductDamages: "زیانەکان",
      Invoices: "فرۆشتنەکان",
      Return: "گەڕاوەکان",
      Customers: "کڕیارەکان",
      CustomerPayments: "پارەدانەکانی کڕیار",
      BankAccounts: "هەژمارەکان",
      Industries: "کارگەکان",
      Suppliers: "دابینکەرەکان",
      SupplierPayments: "پارەدانەکانی دابینکەر",
      Purchases: "کڕینەکان",
      PurchaseInvoices: "پسوولەکان",
      PurchasePreInvoices: "داواکاریەکان",
      PurchaseReturnInvoices: "گەڕاوەکان",
      Deposits: "پارەدانان",
      Withdraws: "پارە ڕاکێشان",
      WithdrawTypes: "جۆرەکانی پارە ڕاکێشان",
      uploadCenter: "ئەپڵۆدی فایل",
      DepositTypes: "جۆرەکانی پارە دانان",
      Expenses: "خەرجییەکان",
      ExpenseTypes: "جۆری خەرجییەکان",
      Accountant: "ژمێریاری",
      Employees: "کارمەندەکان",
      EmployeeLoans: "قەرزی کارمەندەکان",
      EmployeeReturnLoans: "هێنانەوەی قەرز",
      EmployeeAbsents: "ئامادە نەبوو",
      EmployeeRewards: "خەڵاتەکان",
      EmployeePunishments: "سزاکان",
      EmployeePermissions: "مۆڵەتەکان",
      Representatives: "نوێنەرەکان",
      RepresentativeCustomers: "کڕیارانی نوێنەر",
      Settings: "ڕێکخستنەکان",
      Salary: "مووچە",
      SalarySummary: "پووختەی مووچە",
      PreInvoices: "داواکاریەکان",
      UserNames: "ناوی بەکارهێنەر",
      CurrencyExchanges: "گۆڕینی دراو",
      hr: "سەرچاوە مرۆییەکان",
      ProcessInvoices: "پڕۆسەکان",
      DamageInvoices: "زیانەکان",
      BankAccountTransfers: "گواستنەوەی هەژمار",
      StoreTransferInvoices: "پسوولەی گواستنەوە",
      RepresentativeInvoices: "پسوولەکان",
      RepresentativePayments: "پارەدانەکان",
      RepresentativeCustomerPayments: "پارەدانەکان",
      Payments: "پارەدانەکان",
      Dashboard: "داشبۆرد",
      Pos: "فرۆشتن",
      Checks: "پشکنینەکان",
      storeTransactionHistories: "مێژووی ئەژمار",
      invoices: "پسوولەکان",
      options: "هەڵبژاردنەکان",
      cashierSells: "کاشێرەکان",
      skip_nav: "Skip Nav",
      salaryChange: "گۆڕینی مووچە",
      dashboard: "داشبۆرد",
      Selling: "فرۆشتن",
    },
    page: {
      dashboard: "داشبۆرد",
      list: "لیست",
      show: "پیشاندان",
      edit: "دەستکاری",
      empty: "ببورە هیچ شتێک نیە بۆ نیشاندان",
      invite: "داوەتکردن",
      not_found: "404 | نەدۆزراییەوە",
    },
    message: {
      not_found: "ئەو پەڕەی بۆی دەگەڕێیت بەردەست نییە..",
      invalid_form: "هەڵەیەک هەیە لە ناردن",
    },
  },
  resources: {
    root: {
      UserStoreBankAccountWithdraws: "ڕاکێشان",
      email: "ئیمەیڵ",
      storeIds: "کۆگا هەڵبژێرە",
      firstName: "ناو",
      middleName: "ناوی باوک",
      lastName: "ناوی باپیر",
      phone2: "ژمارە تەلەفۆنی ٢",
      male: "نێر",
      female: "مێ",
      dob: "بەرواری لەدایک بوون",
      nationality: "نەتەوە",
      currentWork: "کاری ئێستا",
      idCardNumber: "ژ. کارتی نیشتیمانی",
      idCardType: "جۆری کارت",
      mcRoofLoan: "زۆرترین قەرزی دۆلار",
      scRoofLoan: "زۆرترین قەرزی دینار",
      openAccountLoan: "قەرزی سەرەتا",
      mcOpenAccountLoan: "قەرزی ئێستا بە دۆلار",
      scOpenAccountLoan: "قەرزی ئێستا بە دینار",
      available: "بەردەستە",
      usd: "دۆلار",
      iqd: "دینار",
      store: "کۆگا",
      stores: "کۆگاکان",
      add: "زیادکردن",
      title: "ناونیشان",
      action: "کردار",
      swalTitle: "ئایا دڵنییات؟",
      swalText: "لە دوای سڕینەوە ناتوانی پاشگەزبیتەوە!",
      swalDeleted: "بە سەرکەوتوویی سڕایەوە!",
      swalDeleteCancelled: "کۆگاکەت سەلامەتە!",
      date: "بەروار",
      payedBy: "پارە دراوە لەلایەن",
      description: "وەسف",
      shortDescription: "کورتە باس",
      longDescription: "باسکردن",
      address: "ناونیشان",
      zipCode: "زیپ کۆد",
      webSite: "وێبسایت",
      customerNumber: "ژمارەی کڕیار.",
      billTo: "بۆ",
      companyName: "ناوی کۆمپانیا",
      totalNumber: "بڕی گشتی",
      previousPrice: "قەرزی پێشوو",
      purePrice: "نرخی كۆتایی",
      currentLoan: "قەرزی ئێستا",
      gift: "دیاری",
      accountant: "ژمێریار",
      customer: "كڕیار",
      chooseCustomer: "كڕیار هەڵبژێرە",
      chooseSupplier: "فرۆشیار هەڵبژێرە",
      invoiceNumber: "ژ.پسوولە",
      phone: "ژمارە تەلەفۆن",
      maxLoan: "كۆتا قەرز",
      futureLoan: "قەرزی داهاتوو",
      productMeasure: "پێوانەی بەرهەم",
      oneMeasure: "پوووانەی یەك",
      oneContains: "تێیدایە",
      totalPrice: "نرخی گشتی",
      finalPrice: "نرخی كۆتایی",
      totalQuantity: "بڕی گشتی",
      totalGifts: "كۆی دیاری",
      finalQuantity: "بڕی كۆتایی",
      discountInPercentage: "داشكان بە رێژە",
      inPercentage: "بە %",
      discount: "داشكان",
      confirmCreateNewInvoice: "دەتەوێت پسوولە دروست بكەیت؟",
      confirmPerminantDelete: "داواكاری سڕاوە ناتوانرێت بگەڕێنرێتەوە!",
      createCancel: "دروستكردن پووچەڵكرایەوە",
      deleteCancel: "سڕینەوە پووچەڵكرایەوە",
      makeSure: "دڵنییات؟",
      chooseProduct: "بەرهەم هەڵبژێرە",
      quantity: "بڕ",
      gifts: "دیاریەكان",
      max: "زۆرترین",
      price: "نرخ",
      cash: "كاش",
      dollar: "دۆلار",
      dinar: "دینار",
      addOrder: "زیادكردنی داواكاری",
      barcode: "باركۆد",
      name: "ناو",
      scientificName: "ناوی زانستی",
      BusinessName: "ناوی بازرگانی",
      items: "بەرهەمەکان",
      priceInUSD: "نرخ بە دۆلار",
      priceInIQD: "نرخ بە دینار",
      currency: "دراو",
      driver: "شوفێر",
      note: "تێبینی",
      create: "دروستكردن",
      selectCustomer: "تكایە كڕیار هەڵبژێرە!",
      emptyListOfOrders: "داواكاری بە بەتاڵی رێپێنەدراوە!",
      maximumLoanExceed: "زۆرترین قەرز تێپەڕیوە!",
      clearOrders: "سڕینەوەی داواكاریەكان",
      storeNotExist: "ئەم کۆگایە بوونی نیە!",
      customerCantBuy: "ئەم كڕیارە ناتوانێت لەم لقە شت بكڕێت",
      startDate: "بەرواری سەرەتا",
      endDate: "بەرواری كۆتا",
      search: "گەڕان",
      refNo: "ژ.پسوولە",
      print: "چاپكردن",
      customers: "كڕیارەكان",
      country: "وڵات",
      city: "شار",
      supplierName: "ناوی فرۆشیار",
      chooseStore: "کۆگا هەڵبژێرە",
      owner: "خاوەن",
      supplier: "فرۆشیار",
      reciever: "وەرگر",
      remainder: "ماوە",
      salaryType: "جۆری مووچە",
      salary: "مووچە",
      mcBasicSalary: "مووچە",
      scBasicSalary: "مووچە",
      salaryUsd: "دراوی مووچە: دۆلار",
      salaryIqd: "دراوی مووچە: دینار",
      loanUsd: "دراوی قەرز: دۆلار",
      loanIqd: "دراوی قەرز: دینار",
      loans: "قەرزەكان",
      returnedLoan: "قەرزە گەڕاوەكان",
      absents: "نەهاتووەكان",
      rewards: "پاداشتەكان",
      punishments: "سزاكان",
      permissions: "مۆڵەتەکان",
      totalLoan: "كۆی قەرز",
      TotalReturnedLoan: "كۆی قەرزە گەڕاوەكان",
      totalRewards: "كۆی بەخشینەكان",
      totalPunishments: "كۆی سزاكان",
      totalSubtractedSalary: "كۆی مووچەی بڕاو",
      rewardedBy: "پاداشتكرا لەلایەن",
      reason: "هۆكار",
      type: "جۆر",
      punishedBy: "سزادرا لە لایەن",
      subtructedSalary: "مووچەی بڕاو",
      hours: "كاتژمێرەكان",
      days: "رۆژەكان",
      employee: "كارمەند",
      chooseEmployee: "كارمەند هەڵبژێرە",
      remains: "ماوەكان",
      updated: "بەسەركەوتوویی نوێكرایەوە",
      forgivePrice: "نرخی لێخۆشبوو",
      loanMaxLoan: "بەرزترین قەرز",
      dollarSell: "فرۆشتنی دۆلار",
      dinnarSell: "فرۆشتنی دینار",
      dollarPurchase: "كڕینی دۆلار",
      dinnarPurchase: "كڕینی دینار",
      preferedName: "ناوی خوازراو",
      weightInKg: "كێش بە كـغم",
      chooseCategory: "جۆر هەڵبژێرە",
      productionDate: "بەرواری دروستكردن",
      expireDate: "بەرواری بەسەرچوون",
      alertQuantity: "بڕی ئاگاداركردنەوە",
      purchasePrice: "نرخی كڕین",
      sellingPrice: "نرخی فرۆشتن",
      purchase: "کڕین",
      selling: "فرۆشتن",
      sellByUSD: "فرۆشتن بە دۆلار",
      sellByIQD: "فرۆشتن بە دینار",
      purchaseByUSD: "كڕین بە دۆلار",
      purchaseByIQD: "كڕین بە دینار",
      cannotSellBelowZero: "نەفرۆشتن لە خوار سفر",
      canSellBelowZero: "فرۆشتن لە خوار سفر",
      initialQuantity: "بڕی سەرەتا",
      actions: "كردارەكان",
      measure: "پێوانە",
      product: "بەرهەم",
      chooseRepresentative: "هەڵبژاردنی نوێنەر",
      selectRepresentative: "تکایە نوێنەرێک هەڵبژێرە",
      dinnarReturn: "پسوولەی دینار",
      dollarReturn: "پسوولەی دۆلار",
      Representative: "نوێنەر",
      ignoreProductPriceType: "پشتگوێ خستنی جۆری نرخی بەرهەم",
      acceptedByAccountant: "پەسەند کراوە",
      canTakeLoan: "دەتوانێ قەرز ببا",
      gpsLock: "GPS لۆک",
      saturday: "شەممە",
      sunday: "یەکشەممە",
      monday: "دووشەممە",
      tuesday: "سێشەممە",
      wednesday: "چوارشەممە",
      thursday: "پێنجشەممە",
      friday: "هەینی",
      useCustomPhoneNumbers: "گۆڕینی ژمارە تەلەفۆن",
      supplierNumber: "ژمارەی دابینکەر",
      maxPossibleLoanInUSD: "زۆرترین قەرز بە دۆلار",
      maxPossibleLoanInIQD: "زۆرترین قەرز بە دینار",
      addressConfirmed: "ناونیشانەکە پشتڕاستکرایەوە",
      addressConfirmedBy: "ناونیشانەکە پشتڕاستکرایەوە لەلایەن",
      attachment: "هاوپێچ",
      placeName: "ناوی شوێن",
      monthly: "مانگانە",
      weekly: "هەفتانە",
      datetime: "بەروار و کات",
      selectDatetime: "کات و بەروار دیاری بکە",

      useMainCurrency: "بە دۆلار",
      loan: "قەرز",
      pleaseSelectSupplier: "تکایە دابینکەر هەڵبژێرە!",
      cashPaid: "پارەی نەقدی درا",
      reward: "پاداشت",
      punishment: "سزا",
      stock: "لە کۆگا",
      sellPrice: "نرخی فرۆشتن",
      qteInStock: "بڕ لە کۆگادا",
      sellByMain: "فرۆشتن بە دۆلار",
      unlockAll: "کردنەوی هەموو",
      lockAll: "داخستنی هەموو",
      toggle: "گۆڕین",
      totalQte: "کۆی بڕ",
      id: "ناسنامە",
      giftQuantity: "بڕی دیاری",
      purchaseInvoice: "پسوولەی کڕین",
      finalDiscount: "داشکانی کۆتایی",
      invoice: "پسوولە",
      orders: "داواکاریەکان",
      default: "سەرەکی",
      no: "ژمارە",
      sellInvoice: "پسوولەی فرۆشتن",
      countInvoice: "پسووڵەکان",
      total: "کۆ",
      sellReturnInvoice: "گەڕاوەی فرۆشراوەکان",
      purchaseReturnInvoice: "گەڕاوەی کڕاوەکان",
      representativeInvoice: "پسوولەی نوێنەرەکان",
      representativeReturnInvoice: "گەڕاوەی نوێنەرەکان",
      representativeCustomerInvoice: "پسوولەی کڕیاری نوێنەرەکان",
      representativeCustomerReturnInvoice: "گەڕاوەی کڕیاری نوێنەرەکان",
      expenses: "خەرجیەکان",
      supplierPayments: "پارەدانەکانی دابینکەرەکان",
      customerPayments: "پارەدانەکانی کڕیارەکان",
      boxes: "هەژمار",
      lastLogins: "چوونەژوورەوەکان",
      time: "کات",
      ip: "ئای پی",
      sells: "فرۆشتنەکان",
      reports: "ڕاپۆرتەکان",
      productStatement: "ڕاپۆرتی بەرهەم",
      customerStatement: "ڕاپۆرتی کڕیار",
      supplierStatement: "ڕاپۆرتی دابینکەر",
      stockReport: "ئاگاداریەکانی کۆگا",
      expireReport: "ڕاپۆرتی بەسەرچوون",
      productStatementReport: "ڕاپۆرتی بەرهەم",
      currentQuantity: "بڕی ئێستا",
      previousQuantity: "بڕی پێشوو",
      startQuantity: "بڕی سەرەتا",
      income: "هاتوو",
      outcome: "ڕۆیشتوو",
      balance: "هەژمار",
      perPage: "لە پەڕەیەک",
      dollarWithdraw: "ڕۆیشتوو بە دۆلار",
      dinarWithdraw: "ڕۆیشتوو بە دینار",
      dollarDeposit: "هاتوو بە دۆلار",
      dinarDeposit: "هاتوو بە دینار",
      previousBalance: "نرخی پێشوو",
      initialBalance: "نرخی سەرەتا",
      currentBalance: "نرخی ئێستا",
      stockAlertReport: "ئاگاداریەکانی کۆگا",
      ignoreZero: "فەرامۆشکردنی سفر",
      ignoreBellowZeroProducts: "فەرامۆشکردنی خوار سفر",
      qtyInStock: "بڕ لە کۆگا",
      alertQty: "بڕی ئاگاداری",
      willExpireInDays: "بەسەردەچێت لە (ڕۆ‌ژ)",
      previous: "پێشوو",
      next: "دواتر",
      suppliers: "دابینکەر",
      dollarPrice: "نرخی دینار",
      selectDefaultCustomer: "کڕیاری سەرەتا",
      defaultProductsInPos: "بەرهەمە سەرەتاکان لە فرۆشتن",
      updateSellingPriceOnSale: "گۆڕینی نرخی بەرهەم لەکاتی فرۆشتن",
      usdToIqd: "دۆلار بۆ دینار",
      iqdToUsd: "دینار بۆ دۆلار",
      createProduct: "زیادکردنی بەرهەم",
      createCustomer: "زیادکردنی فرۆشیار",
      createSupplier: "زیادکردنی دابینکەر",
      createSellInvoice: "زیادکرنی فرۆشتن",
      createPurchase: "زیادکردنی کڕین",
      createRepresentatives: "زیادکردنی نوێنەر",
      createRepresentativeCustomers: "زیادکردنی کڕیارانی نوێنەر",
      cantDeleteNonZeroProducts: "بەرهەمی سەرو سفر ناسرێتەوە",
      areYouSure: "ئایا دڵنیای؟",
      yes: "بەڵێ",
      No: "نەخێر",
      mcPrice: "نرخ بە دۆلار",
      scPrice: "نرخ بە دینار",
      fromMcToSc: "لە دۆلار بۆ دینار",
      depositor: "پارە دانەر",
      byMain: "بە دۆلار",
      depositType: "جۆری پارەدانان",
      label: "ناونیشان",
      currentPunishments: "سزای ئێستا",
      mainCurrencyBalance: "بەڵانسی دۆلار",
      detail: "زانیاری",
      accountNumber: "ژ. هەژمار",
      fromBankAccount: "لە هەژماری بانك",
      toBankAccount: "بۆ هەژماری بانك",
      mainCurrencyAmount: "بڕی دۆلار",
      withdrawer: "راكێشەر",
      withdrawType: "جۆری راكێشان",
      uploadCenter: "ئەپڵۆدی فایل",
      ExpenseTypes: "جۆركانی خەرجی",
      expenseType: "جۆری خەرجی",
      pleaseFillInTheRequiredDetails: "تکایە زانیاریە داواکراوەکان پڕبکەوە",
      totalQty: "بڕی گشتی",
      isCash: "کاش",
      products: "بەرهەمەكان",
      createProducts: "دروستكردنی بەرهەم",
      image: "وێنە",
      damageBy: "زیان لەلایەن",
      damageType: "جۆرەكانی زیان",
      producedProduct: "بەهەمی بەرهەمهاتوو",
      producedQuantity: "بڕی بەرهەمهاتوو",
      usedQuantity: "بڕی بەكارهاتوو",
      processQuantity: "بڕی بەكارهاتوو",
      toStore: "بۆ كۆگا",
      transferredBy: "گوازراوە لەلایەن",
      invoiceNo: "ژ. پسوولە",
      toAddNewOrder: "لە زیادكردنی داواكاری",
      preOrderDate: "بەرواری داواكاری",
      delivered: "گەیشتووە",
      representativeName: "ناوی نوێنەر",
      checkType: "جۆری پشكنینەكان",
      result: "ئەنجام",
      BalanceInUSD: "بەڵانس بە دۆلار",
      balanceInIQD: "بەڵانس بە دینار",
      depositInUSD: "دانان بە دۆلار",
      depositInIQD: "دانان بە دینار",
      withdrawInUSD: "راكێشان بە دۆلار",
      withdrawInIQD: "راكێشان بە دینار",
      details: "زانیاریەكان",
      totalMcTransferFromOther: "كۆی دۆلاری گواستراوە لەوانی تر",
      totalScTransferFromOther: "كۆی دیناری گواستراوە لەوانی تر",
      totalMcTransferToOther: "كۆی دۆلاری گواستراوە بۆ ئەوانی تر",
      totalScTransferToOther: "كۆی دیناری گواستراوە بۆ ئەوانی تر",
      openingDate: "بەرواری كردنەوە",
      bankAccount: "هەژماری بانكی",
      branch: "لق",
      username: "ناوی بەكارهێنەر",
      password: "ووشەی تێپەڕ",
      usertype: "جۆری بەكارهێنەر",
      otherInstructions: "زانیاری و رێنمایی تر",
      instructionOne: "كۆی پارەدانەكان لەماوەی 30 رۆژدا",
      instructionTwo: "تكابە لە پشكنینەكەت ژمارەی پسوولە داخڵ بكە",
      createDamages: "زیادکردنی زیان",
      createTransfers: "زیادکردنی گواستنەوە",
      createProcess: "زیادکردنی پڕۆسە",
      createCustomerPayment: "زیادکردنی پارەدانی کڕیارەان",
      sellInvoices: "پسوولەی فرۆشتنەکان",
      createSellInvoices: "زیادکردنی پسوولەی فرۆشتن",
      createSellReturnInvoice: "زیادکردنی پسوولەی گەڕاوە",
      createSupplierPayment: "زیادکردنی  پارەدانی دابینکەر",
      purchaseInvoices: "پسوولەی کڕینەکان",
      createPurchaseInvoices: "زیادکردنی پسوولەی کڕین",
      createPurchaseReturnInvoice: "زیادکردنی پسوولەی گەڕاوەی کڕین",
      createWithdraws: "زیادکردنی دەرهێنان",
      createDeposits: "زیادکردنی دانان",
      createExpenses: "زیادکردنی خەرجی",
      quickCommands: "فەرمانە خێراکان",
      phoneNumber: "ژمارە مۆبایل",
      phoneNumber2: "ژمارە مۆبایل ٢",
      vouchersFontSize: "قەبارەی فۆنتی پسوولە",
      productWeightInKg: "کێشی بەرهەم بە کگم",
      productOneMeasure: "یەکەی پێوانەی بەرهەم",
      productOneContains: "بەرهەمێک پێکدێت لە",
      representativeStatement: "ڕاپۆرتی نوێنەر",
      representatives: "نوێنەرەکان",
      showCurrentLoan: "پیشاندانی قەرزی کۆتایی",
      updateDriver: "گۆڕینی شۆفێر",
      printOrders: "چاپکردنی داواکاریەکان",
      profile: "پڕۆفایل",
      updateAvatar: "گۆڕینی وێنەی کەسی",
      accountInfo: "زانیاری کەسی",
      updateInfo: "گۆڕین زانیاری کەسی",
      passwordInfo: "وشەی نهێنی",
      oldPassword: "ووشەی نهێنی کۆن",
      newPassword: "ووشەی نهێنی نوێ",
      confirmNewPassword: "دووپاتکردنەوی ووشەی نهێنی نوێ",
      updatePassword: "گۆڕینی وشەی نهێنی",
      expenseStatementReport: "ڕاپۆرتی خەرجیەکان",
      expenseCategory: "جۆری خەرجی",
      all: "هەموو",
      purchaseReport: "کڕینەکان",
      expenseReport: "خەرجیەکان",
      hrReport: "ڕاپۆرتی HR",
      loanReport: "قەرز",
      returnLoanReport: "گەڕانەوەی قەرز",
      absentReport: "نەهاتوو",
      rewardReport: "پاداشت",
      punishmentReport: "سزا",
      permissionReport: "مۆڵەتەکان",
      salaryPayReport: "مووچە",
      employeeStatement: "کارمەند",
      employees: "کارمەندەکان",
      qty: "بڕ",
      customerReport: "جوڵەکانی کڕیار",
      supplierReport: "جوڵەکانی دابینکەر",
      getLastPriceForSelling: "هێنانەوەی کۆتا نرخی فرۆشتن",
      category: "جۆر",
      initialQty: "بڕی سەرەتا",
      sellByUsd: "فرۆشتن بە دۆلار",
      purchaseByUsd: "کڕین بە دۆلار",
      nameBarcode: "ناو، باڕکۆد",
      lastInvoice: "کۆتا پسوولە",
      paidAmount: "پارەی دراو",
      receivedAmount: "پارەی وەرگیراو",
      cashIqd: "کاش بە  دینار",
      cashUsd: "کاش بە دۆلار",
      checkout: "دروستکردن",
      reset: "ڕیسێت",
      remove: "سڕینەوە",
      theProductQuantityInStockIsZero: "ىڕی بەرهەم لە کۆگا سفرە",
      theProductQuantityInStockIsOnly: "بڕی بەرهەم لە کۆگا بریتیە لە",
      productNotFound: "بەرهەم نەدۆزرایەوە",
      remain: "ماوە",
      systemFontSize: "قەبارەی فۆنتی سیستەم",
      selectDefaultCategory: "هەڵبژاردنی جۆری بنەڕەتی",
      general: "گشتی",
      dashboard: "داشبۆرد",
      newDriver: "شۆفێری نوێ",
      update: "گۆڕین",
      emptyDriverNameIsNotAccepted: "نابێ ناوی شۆفێر بەتاڵ بێت",
      updateExpireDate: "گۆڕینی بەرواری بەسەرچوون",
      newExpireDate: "بەرواری بەسەرچوونی نوێ",
      showByMain: "پیشاندان بە دۆلار",
      allowBelowZero: "فرۆشتن لە خوار سفر",
      allowedBy: "ڕێگای پێدراوە لەلایەن",

      loanLimitDays: "زۆرترین ڕۆژی ڕێپێدراو بۆ قەرز",
      dinarPurchase: "کڕینی دینار",
      profitRatio: "ڕێژەی قازانج",
      addressInKurdish: "ناونیشان بە کوردی",
      addressInArabic: "ناونیشان بە عەرەبی",
      addressInEnglish: "ناونیشان بە ئینگلیزی",
      discountRate: "ڕێژەی داشکاندن",
      pleaseSelectOneInvoiceToUpdateAddress:
        "تکایە تەنها یەک پسوولە هەڵبژێرە بۆ گۆڕینی ناوسیشان",
      newAddress: "ناونیشانی نوێ",
      updateAddress: "گۆڕینی ناونیشان",
      pleaseEnterNumberOfDays: "تکایە ژماری ڕۆژەکان بنووسە",
      updateLimitDays: "گۆڕینی ڕۆژەکان",
      ignoreZeroDashboard: "پشتگوێ خستنی سفر لە ڕاپۆرتی داشبۆرد",
      ignoreBelowZeroProductsDashboard:
        "پشیگوێ خستنی بەرهەماکانی ژێر سفر لە ڕاپۆرتی داشبۆرد",
      expireDaysDashboard: "ڕۆژەکانی بەسەردەچوون لە ڕاپۆرتی داشبۆرد",
      balanceSheet: "بەڵانس شیت",
      receivable: "وەرگیراو",
      payable: "دراو",
      paidExpenses: "تێچووە دراوەکان",
      sellOrdersReport: "ڕاپۆرتی بەرهەمی فرۆشراو",
      showSmallMeasure: "پیشاندانی نرخی تاک",
      smallMeasure: "نرخی تاک",
      smallSellPrice: "نرخی تاک",
      smallMeasureSellPrice: "نرخی فرۆشتنی تاک",
      monthlyAllowDays: "ڕۆژانی ڕێپێدراو هەفتانە / مانگانە",
      workingHoursPerDay: "کاتژمێری کارکردنی ڕۆژانە",
      subtractedSalary: "مووچەی بڕاو",
      addedPunishment: "سزای زیادکراو",
      futureSubtractions: "بڕینی کۆتایی",
      returnDate: "بەرواری گەڕاوە",
      currentRewards: "پاداشتەکانی ئێستا",
      addedReward: "پاداشتی زیادکراو",
      accept: "ڕازیبون",
      colorizeTable: "ڕەنگکردنی خشتە",
      noOfOrders: "ژمارەی بابەتەکان",
      purchaseMainCurrency: "دراوی سەرەکی کڕین",
      sellMainCurrency: "دراوی سەرەکی فرۆشتن",
      tookBy: "بردراوە لە لایەن",
      currentSalary: "مووچەی ئێستا",
      basicSalary: "مووچەی سەرەکی",
      finalSalary: "مووچەی کۆتایی",
      updatePurchasePriceOnPurchase: "گۆڕینی نرخی کڕین",
      reportStock: "ڕاپۆرت کۆگا",
      logo: "لۆگۆ",
      marginTop: "پەراوێزی سەرەوە",
      marginXAxis: "پەراوێز تەوەر x",
      marginBottom: "پەراوێزی خوارەوە",
      width: "پانی",
      height: "بەرزی",
      radius: "بازنەیی",
      info: "زانیاری",
      invoiceDescription: "وەسفی پسوولە",
      infoNeedRefresh: "زانیاری (پێویستی بە بوژانەوە هەیە)",
      invoiceDescriptionInKurdish: "وەسفی پسوولە بە کوردی",
      invoiceDescriptionInArabic: "وەسفی پسوولە بە عەرەبی",
      invoiceDescriptionInEnglish: "وەسفی پسوولە بە ئینگلیزی",
      invoiceMockup: "ڕێکخستنەکانی پسوولە",
      invoiceHeaderHeight: "بەرزی بەشی سەرەوەی پسوولە",
      logoUrl: "لینکی وێنە",
      uploadAndDownloadMockup: "بارکردن و دابەزاندنی ڕێکخستن",
      upload: "بارکردن",
      download: "دابەزاندن",
      uploadedSuccessfully: "بەسەرکەوتوویی بارکرا",
      downloadedSuccessfully: "بەسەرکەوتوویی دابەزێندرا",
      someErrorOccurred: "هەڵەیەک ڕوویدا",
      fontSize: "قەبارەی فۆنت",
      showGift: "پیشاندانی دیاری",
      selectSupplier: "تکایە دابینکەر هەڵبژێرە",
      sellQty: "بڕی فرۆشتن",
      purchaseQty: "بڕی کڕین",
      averagePurchasePrice: "تێکڕای کڕین",
      averageSellPrice: "تێکڕای فرۆشتن",
      totalPurchasePrice: "بڕی کڕینی گشتی",
      showInUSD: "پیشاندان بە دۆلار",
      supplierItemReport: "بەرهەمەکانی دابینکەر",
      userType: "جۆری بەکارهێنەر",
      cashvan: "کاشڤان",
      labelHeight: "بەرزی",
      labelWidth: "پانی",
      nameColor: "ڕەنگی ناو",
      priceColor: "ڕەنگی نرخ",
      barcodeColor: "ڕەنگی باڕکۆد",
      labelPrinter: "چاپکردنی لەیبڵ",
      numberOfLabels: "ژمارەی لەیبڵەکان",
      newPrice: "نرخی نوێ",
      offerText: "نووسینی ئۆفەر",
      offerFontSize: "قەبارەی فۆنت",
      nameFontSize: "قەبارەی فۆنتی ناو",
      priceFontSize: "قەبارەی فۆنتی نرخ",
      offerColor: "رەنگی ئۆفەر",
      heightSize: "بەرزی وێنە",
      widthSize: "پانی وێنە",
      offerPrint: "چاپکردنی ئۆفەر",
      createAndGoToList: "دروستکردن و گەڕانەوە بۆ لیست",
      showSmallPrice: "پیشاندانی نرخی تاک",
      searchForProduct: "گەڕان بەدوای بەرهەم",
      printStick: "چاپکردنی لەزگە",
      barcodeWidth: "پانی باڕکۆد",
      barcodeHeight: "بەرزی باڕکۆد",
      boldName: "تۆخکردنی ناو",
      boldPrice: "تۆخکردنی نرخ",
      start: "سەرەتا",
      end: "کۆتا",
      nameSize: "قەبارەی ناو",
      priceSize: "قەبارەی نرخ",
      itemSupplierReport: "دابینکەری بارهەمەکان",
      generalReports: "ڕاپۆرتی گشتی",
      itemReports: "ڕاپۆرتی بەرهەم",
      cashiers: "کاشێرەکان",
      cashierSells: "فرۆشتنی کاشێرەکان",
      smallMeasureQty: "بڕی تاک",
      productName: "ناوی بەرهەم",
      cashPrice: "بڕی کاش",
      returnPrice: "بڕی گەڕاوە",
      cashierSellReturn: "گەڕاوەکان",
      returns: "گەڕاوەکان",
      purchases: "فرۆشتنەکان",
      brands: "مارکەکان",
      brand: "مارکە",
      sort: "ڕیزبەندی",
      smallMeasurePurchaseQty: "بڕی کڕینی تاک",
      smallMeasureSellQty: "بڕی فرۆشتنی تاک",
      initialBalanceDollar: "نرخی سەرەتا دۆلار",
      initialBalanceDinar: "نرخی سەرەتا دینار",
      mcCurrentBalance: "باڵانسی ئێستا دۆلار",
      scCurrentBalance: "بەڵانسی ئێستا دینار",
      currentCash: "نرخی ئێستا",
      amount: "نرخ",
      withdraw: "ڕاکێشان",
      showCashierReturns: "پیشاندانی گەڕاوەکان",
      supplierForgivePriceReport: "ڕاپۆرتی لێ خۆشبوونی دابینکەر",
      forgiveTotalPrice: "نرخی گشتی لێ خۆشبوون",
      customerForgivePriceReport: "ڕاپۆرتی لێ خۆشبوونی کڕیار",
      groupProducts: "گروپکردنی بەرهەمەکان",
      averagePurchaseOnePrice: "نرخی کڕینی تاک (تێکڕا)",
      averageSellOnePrice: "نرخی فرۆشتنی تاک (تێکڕا)",
      startingDate: "بەرواری دەستپێک",
      totalAbsents: "کۆی نەهاتووەکان",
      returnInvoice: "کۆی گشتی گەڕاوە",
      supplierInvoice: "کۆی گشتی کڕین",
      supplierPayment: "کۆی گشتی پارەدانەکان",
      totalSellPerPurchasePrice: "ک. گ. فرۆشتن بۆ هەر کڕینێک",
      totalSellProfit: "کۆی قازانجی فرۆشتن",
      smallPrice: "نرخی تاک",
      showDiscountInvoice: "پیشاندانی داشکان لە پسوولە",
      totalRecords: "کۆی تۆمارەکان",
      exportCsv: "داونلۆدکردن",
      customerName: "ناوی کڕیار",
      supplierPaymentReport: "ڕاپۆررتی پارەدانی دابینکەر",
      customerPaymentReport: "ڕاپۆرتی پارەدانی کڕیار",
      notSaleReport: "ڕاپۆرتی نەفرۆشراوەکان",
      brandName: "ناوی ماڕکە",
      categoryName: "جۆر",
      supplierStockRemain: "بەرهەمی ماوەی دابینکەر",
      totalSmallQte: "کۆی یەکەی بچووک",
      mcTotalDamage: "کۆی زیانەکان",
      mcTotalPurchase: "کۆی کڕینەکان",
      mcTotalPurchaseReturn: "کۆی گەڕاوەی کڕینەکان",
      mcTotalQuickSell: "کۆی فرۆشتنی کاشێر",
      mcTotalQuickSellReturn: "کۆی گەڕاوەی کاشێر",
      mcTotalSell: "کۆی فرۆشتن",
      mcTotalSellReturn: "کۆی گەڕاوەی فرۆشتن",
      scTotalDamage: "کۆی زیانەکان",
      scTotalPurchase: "کۆی کڕینەکان",
      scTotalPurchaseReturn: "کۆی گەڕاوەی کڕینەکان",
      scTotalQuickSell: "کۆی فرۆشتنی کاشێر",
      scTotalQuickSellReturn: "کۆی گەڕاوەی کاشێر",
      scTotalSell: "کۆی فرۆشتن",
      scTotalSellReturn: "کۆی گەڕاوەی فرۆشتن",
      mcSellPrice: "نرخی فرۆشتن دۆلار",
      scSellPrice: "نرخی فرۆشتن دینار",
      sellCurrency: "دراوی فرۆشتن",
      mcPurchasePrice: "نرخی کڕین دۆلار",
      scPurchasePrice: "نرخی کڕین دینار",
      purchaseCurrency: "دراوی کڕین",
      mcSmallMeasureSellPrice: "نرخی فرۆشتنی تاک دۆلار",
      scSmallMeasureSellPrice: "نرخی فرۆشتنی تاک دینار",
      mcTotalExtraInventory: "کۆگای زیادە",
      scTotalExtraInventory: "کۆگای زیادە",
      gender: "ڕەگەز",
      birthdate: "لەدایکبوون",
      initialLoan: "قەرزی سەرەتا",
      roofLoan: "زۆرترین قەرز",
      adminReport: "ڕاپۆرت ئەدمین",
      profitLose: "قازانج و زەرەر",
      profitLoseReport: "ڕاپۆرتی قازانج و زەرەر",
      brandItemReport: "ڕاپۆرتی ماڕکە",
      showBarcode: "پیشاندانی باڕکۆد",
      showDescription: "پیشاندانی باس",
      showImage: "پیشاندانی وێنە",
      showSerial: "پیشاندانی زنجیرە",
      showDiscount: "پیشاندانی داشکان",
      yAxis: "تەوەرەی ستوونی",
      xAxis: "تەوەری ئاسۆیی",
      addressPhone: "ناونیشان، ژمارە مۆبایل",
      size: "قەبارە",
      invoiceDescriptionKurdish: "وەسفی پسوولە بە کوردی",
      invoiceDescriptionArabic: "وەسفی پسوولە بە عەرەبی",
      invoiceDescriptionEnglish: "وەسفی پسوولە بە ئینگلیزی",
      style: "شێواز",
      newSalary: "مووچەی نوێ",
      changeDate: "بەرواری گۆڕین",
      salaryBefore: "مووچەی پێشوو",
      voucherMockup: "ڕێکخستنی پسوولەی پارەدان",
      totalInvoicePrice: "کۆی گشتی پسوولە",
      totalPurchaseGiftQty: "کۆی دیاریەکانی کڕین",
      paidSalaries: "مووچەی وەرگیراو",
      producedQte: "بڕی بەرهەمهاتوو",
      totalProcessPrice: "کۆی نرخی بەکارهاتوو",
      totalProducedPrice: "کۆی نرخی بەرهامهاتوو",
      totalDiscountPrice: "کۆی گشتی داشکان",
      totalReturnPrice: "کۆی گشتی گەڕاوە",
      totalSellPrice: "بڕی فرۆشتنی گشتی",
      totalProfit: "کۆی قازانج",
      totalSellQty: "بڕی گشتی فرۆشتن",
      totalSellGiftQty: "کۆی دیاریەکانی فرۆشتن",
      totalReturnQty: "بڕی گشتی گەڕاوە",
      onlyLosses: "زەرەر",
      processOrders: "بەکارهاتووەکان",
      produceOrders: "بەرهەمهاتووەکان",
      barcodes: "باڕکۆدەکان",
      barcode1: "باڕکۆد 1",
      barcode2: "باڕکۆد 2",
      barcode3: "باڕکۆد 3",
      barcode4: "باڕکۆد 4",
      barcode5: "باڕکۆد 5",
      barcode6: "باڕکۆد 6",
      barcode7: "باڕکۆد 7",
      barcode8: "باڕکۆد 8",
      barcode9: "باڕکۆد 9",
      inventoryType: "جۆری جەرد",
      extra: "زیادە",
      increaseDecrease: "زیادکردن و کەمکردن",
      futureQty: "بڕی داهاتوو",
      periodic: "ماوەیی",
      continuous: "بەردەوام",
      sudden: "لە ناکاو",
      currentInBox: "ئێستا لە صندوق",
      storeInventoryInvoice: "جەردی کۆگا",
      showSmallMeasureQte: "پیشامدانی بچووک",
      smallMeasureGift: "بڕی دیاری بچووک",
      smallMeasurePrice: "نرخی دیاری بچووک",
      smallQty: "بڕی بچووک",
      showReturn: "پیشاندانی گەڕاوە",
      userPermissions: "رێگەپێدانەكان",
      canDiscountByPercentage: "دەتوانێت بە ڕێژەی سەدی داشکاندن بکات",
      maxPercentageDiscount: "زۆرترین ڕێژەی داشکاندن",
      canDiscountByAmount: "دەتوانێ بە بڕ داشکاندن بکا",
      maxDiscountAmount: "زۆرترین بڕی داشکاندن",
      canOpenDrawer: "دەتوانێ درووکێش بکاتەوە",
      canReprintLastInvoice: "دەتوانێت دوایین وەصڵ چاپ بکاتەوە",
      canSeeOldSellInvoices: "دەتوانێ وەصڵی فرۆشتنی کۆن ببینیت",
      canSeeOldReturnInvoices: "دەتوانێت وەصڵەکانی گەرانەوە کۆنەکان ببینێت",
      canSeeCashBox: "دەتوانی سندوقی پارە ببینیت",
      canEditPosSettings: "دەتوانێت دەستکاری رێکخستنەکانی POS بکات",
      canPrintInvoiceWithoutSubmit:
        "ئەتوانێت بەبی ئەوەی وەصڵ خەزن بکا چاپی بکا",
      canSellByLoan: "دەتوانێت بە قەرز بفرۆشێت",
      restrictOpeningAccount: "سنووردارکردنی ئەژمێری کردنەوە",
      openingAccountTime: "کردنەوەی کاتی ئەژمێر",
      restrictClosingAccount: "سنووردارکردنی ئەژمێری داخستنی",
      closingAccountTime: "داخستنی کاتی ئەژمێر",
      canSeeOthersInvoices: "دەتوانێت وەصڵی ئەوانی تر ببینێت",
      emptyCashBox: "سندوقی پارەی بەتاڵبکاتەوە",
      canAddExpense: "دەتوانێت خەرجی زیاد بکات",
      canReturnInvoice: "دەتوانێت وەصڵ بگەڕێنێتەوە",
      canReturnWithoutInvoice: "دەتوانێت بەبێ وەصڵ بگەڕێتەوە",
      canDiscountOrderByPercentage:
        "دەتوانێ بە ڕێژەی سەدی داواکاری داشکاندن بکات",
      maxPercentageOrderDiscount: "داشکاندنی داواکاری ڕێژەی زیادە",
      canDiscountOrderByAmount: "دەتوانێ فرمانی داشکاندن بە بڕی",
      maxDiscountOrderAmount: "ڕێژەی داواکاری داشکاندنی ماکس",
      canGiveGift: "دەتوانێ دیاری بدەیت",
      maxGiftCount: "ژمارەی دیاریی ماکس",
      maxGiftCountPerOrder: "ژمارەی دیاریی ماکس بۆ هەر فەرمانێک",
      canChangeSellPrice: "دەتوانێت نرخی فرۆشتن بگۆڕێت",
      canChangePurchasePrice: "دەتوانێت نرخی کڕین بگۆڕێت",
      cantSellByPurchasePrice: "ناتوانێت بە نرخی کڕین بفرۆشێت",
      cantSellLessThanPurchasePrice: "ناتوانێت کەمتر لە نرخی کڕین بفرۆشێت",
      newQty: "بڕی نوێ",
      newSmallMeasureQty: "بڕی نوێی یەکەی بچووک",
      pleaseChooseImage: "تکایە وێنە هەڵبژێرە",
      pleaseWriteFullName: "تکایە هەموو ناوەکان بنووسە",
      pleaseWriteAllPasswords: "تکایە هەموو پاسوۆردەکان بنووسە",
      passwordsAreNotMatch: "پاسوۆردی نوێ وەک یەک نین",
      namesMustBeMoreThan3Chars: "ناوەکان دەبی لە 3 پیت زیاتر بن",
      isDollar: "بە دۆلارە؟",
      realStock: "کۆگای ئێستا",
      showProductMeasure: "پیشاندانی یەکە",
      cashierName: "ناوی کاشێر",
      purchaseReturnQty: "بڕی گەڕاوەی کڕین",
      sellReturnQty: "بڕی گەڕاوەی فرۆشتن",
      quickSellQty: "بڕی فرۆشی کاشێر",
      quickSellReturnQty: "بڕی گەڕاوەی فرۆشی کاشێر",
      supplierStock: "کۆگای دابینکەر",
      totalSellMc: "کۆی فرۆش (دۆلار)",
      totalSellSc: "کۆی فرۆش (دینار)",
      totalPurchaseMc: "کۆی کڕین (دۆلار)",
      totalPurchaseSc: "کۆی کڕین (دینار)",
      supplierSellReport: "ڕاپۆرتی فرۆشی دابینکەر",
      totalSellProfitMc: "کۆی قازانجی فرۆش (دۆلار)",
      totalSellProfitSc: "کۆی قازانجی فرۆش (دینار)",
      supplierReports: "ڕاپۆرتەکانی دابینکەر",
      supplierStockReport: "ڕاپۆرتی گۆگای دابینکەر",
      viewHisExpense: "بینینی خەرجیەکانی خۆی",
      deleteHisExpense: "سڕینەوەی خەرجیەکانی خۆی",
      invoiceProfitLose: "قازانجی پسوولە",
      allStores: "هەموو لقەکان",
      deleteHisSellInvoice: "سڕینەوەی پسوولەی فرۆشراوەکانی خۆی",
      deleteHisSellReturnInvoice: "سڕێنەوەی پسوولەی گەڕاوەی فرۆشراوەکانی خۆی",
      canWithdrawForHimSelf: "ڕاکێشانی پارەی خۆی",
      canReceiveDollar: "دەتوانێت دۆلار وەربگرێت",
      canOpenAzReport: "دەتوانێ ڕاپۆرتی قازانج و زەرەر ببینێت",
      canSellOffline: "دەتوانێت بە ئۆفڵاین بفرۆشێت",
      totalDamage: "کۆی زیانەکان",
      damageQty: "بڕی زیان",
      canQuickSellRoundUp: "دەتوانێت نزیک بکاتەوە بۆ ژمارەی گەورە",
      canQuickSellRoundDown: "دەتوانێت نزیک بکاتەوە بۆ ژمارەی بچووک",
      canQuickSellRoundExact: "دەتوانێت نزیک بکاتەوە بۆ ژمارەی نزیکەیی",
      canToggleSellByMain: "دەتوانێت فرۆشتن بە دۆلار بکات",
      useOldSelect: "بەکارهێنانی هەڵبژاردنی کۆن",
      showCreatedAt: "نیشاندانی بەرواری دروستکردن",
      createdAt: "دروستکردن",
      get: "وەرگرتن",
      dollarPriceUpdatedSuccess: "نرخی دۆلار بە سەرکەوتووی گۆڕدرا",
      dollarPriceGetSuccess: "نرخی دۆلار بە سەرکەوتووی وەرگیرا",
      startFromZero: "دەستپێکردن لە سفرەوە",
      logs: "تۆمارەکان",
      areYouSureToUpdate: "دڵنیای لە گۆڕین؟",
      showPreviousLoan: "پیشاندانی قەرزی پێشوو",
      mcToScPrice: "نرخی دۆلار",
      selectDamageType: "تکایە جۆری زیان هەڵبژێرە",
      totalProductQty: "کۆی بڕی بەرهەمەکان",
      top: "سەرەوە",
      imageUrl: "لینکی وێنە",
      imageBottomGap: "بۆشایی لەگەڵ خوارەوە",
      userDetails: "زانیاری کەس",
      detailsBottomGap: "بۆشایی لەگەڵ خوارەوە",
      ordersBottomGap: "بۆشایی لەگەڵ خوارەوە",
      footer: "ژێرپەڕە",
      imageWidth: "پانی وێنە",
      imageHeight: "بەرزی وێنە",
      showWeight: "کێش نیشان بدە",
      imageTopGap: "بۆشایی سەرەوەی وێنە",
      failToGetInvoiceSettings:
        "سەرکەوتوونەبوو لە بەدەستهێنانی ڕێکخستنەکانی پسوڵە",
      onlineSettings: "ڕێکخستنەکانی ئۆنلاین",
      settingsUpdatedSuccessFully: "ڕێکخستنەکان بە سەرکەوتوویی نوێکرایەوە",
      settingsDownloadedSuccessFully: "ڕێکخستنەکان بە سەرکەوتوویی دابەزێنراوە",
      failToUpdateSettings: "سەرکەوتوونەبوو لە نوێکردنەوەی ڕێکخستنەکان",
      sl: "زنجیرە",
      weight: "کێش",
      showProductImage: "وێنەی بەرهەم نیشان بدە",
      invoiceDetails: "وردەکاریەکانی پسووڵە",
      discountPer: "داشکان %",
      userActivities: "چالاکیەکانی بەکارهێنەر",
      purchaseReturns: "گەرانەوەی کڕین",
      sellReturns: "گەڕاندنەوەی فرۆشتن",
      customerPayment: "پارەدانی کڕیار",
      quickSells: "فرۆشەکانی کاشێر",
      quickSellReturns: "گەڕاوەی فرۆشتنی خێرا",
      deposit: "دانان",
      damage: "زیان",
      cashierSellsReport: "ڕاپۆرتی فرۆشی کاشێر",
      casherSellInvoiceNameIsRequired: "لە فرۆشی کاشێر ناو پێویستە",
      maxLocalInvoiceCount: "زۆرترین ژمارەی پسوولەی ڕێگەپێدراو لە لۆکاڵ",
      representativeCustomerStatement: "ڕاپۆرتی کڕیارانی نوێنەر",
      cashierSellReport: "ڕاپۆرتی فرۆشی کاشێر",
      customerProfitLose: "قازانج و زەرەر کڕیار",
      productSellsInvoice: "ڕاپۆرتی فرۆشی بەرهەم",
      lastPrice: "نرخی کۆتایی",
      controller: "خشتە",
      fullName: "ناو",
      userName: "بەکارهێنەر",
      fullDiscount: "١٠٠٪ داشکان",
      createdAt: "دروستکراوە لە",
      updateSellPrice: "گۆڕینی نرخی فرۆشتن",
      damageInvoice: "پسولەی زیان",
      sentQty: "بڕی نێردراو",
      sendQty: "بڕی نێردراو",
      receiveQte: "بڕی وەرگیراو",
      transferInvoice: "پسولەی گواستنەوە",
      FirstIdentifier: "B.No.",
      giftQty: "دیاری",
      copy: "لەبەرگرتنەوە",
      serial: "سیریال",
      expirationDate: "بەسەرچون",
      serials: "سیریالەکان",
      chooseSerial: "سریال هەڵبژێرە",
      pay: "پارەبدە",
      Dinar: "دینار",
      Dollar: "دۆلار",
      ordersReport: "ڕاپۆرتی داواکاری",
      submit: "ناردن",
      paid: "دراو",
      count: "بژمێرە",
      disableF6Command: "F6 بوەستێنە",
      received: "گەیشتووە",
      distribute: "دابەشکردن",
      restore: "گێڕانەوە",
      restoreDiscountToOriginal:
        "ئەم بەشە داشکاندن دەگێڕێتەوە بۆ ڕێژە بنەڕەتیەکەی",
      selectColorVoucher: "ڕەنگی پسووڵە هەڵبژێرە",
      ChangeUrl: "گۆڕانکاری لە (URL)",
      month: "مانگ",
      invoiceHasBeenCopied: "پسووڵەکە لەبەرگیرایەوە",
      copyAnyway: "لەبەری بگرەوە",
      dontCopy: "لەبەری مەگرەوە",
      ConfirmDeleteCurrentInvoice:
        "لە ئێستادا کار لەسەر پسووڵەیەکی تر دەکەیت و ( لەبەرگرتنەوە) دەبێتە هۆیی سڕینەوەی ، ئایا دەتەوێت ئەم پسووڵەیە لەبەربگریتەوە؟",
      accountantPermissions: "Accountant Permissions",
      invoicePermissions: "Invoice Permissions",
      discountPermissions: "Discount Permissions",
      purchasePermissions: "Purchase Permissions",
      sellPermissions: "Sell Permissions",
      sell: "فرۆشتنەکان",
      others: "ئەوانی دیکە",
    },
  },
};
