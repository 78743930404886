import { HTTP } from "../../axios";
import { useState } from "react";
import { useEffect } from "react";
import { Carousel } from "react-bootstrap";
import usa from "../../assets/dashboardImages/usa.png";
import iraq from "../../assets/dashboardImages/iraq.png";
import { Event, QueryBuilder } from "@mui/icons-material";
import moment from "moment";
import Clock from "react-live-clock";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 25% auto;
  border-color: red;
`;

const Card = () => {
  const [allCashier, setAllCashiers] = useState([]);
  const [allBoxes, setBoxes] = useState([]);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    HTTP.get("/UserStoreBankAccounts")
      .then((result) => {
        setAllCashiers(result.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));

    HTTP.get("BankAccounts")
      .then((result) => {
        setBoxes(result.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="cardss">
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <div className="card radius bgWhite noBorder p-5 pb-1 pr-1 pt-3 welcomeBackgroundImg welcomeTextBlack">
            <h1 style={{ color: "black" }}>
              <b>{localStorage.getItem("firstName")}</b>
            </h1>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam
              doloremque totam assumenda delectus placeat nostrum labore libero?
            </p>
            <p>
              <Event /> {moment().format("YYYY-MM-DD")}
            </p>{" "}
            <br />
            <p>
              <QueryBuilder />{" "}
              {<Clock format="HH:mm:ss A" interval={1000} ticking={true} />}
            </p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-12 d-flex justify-content-center">
          {loading && (
            <HashLoader
              color="#488EF9"
              loading={loading}
              css={override}
              size={55}
            />
          )}
          {!loading && (
            <Carousel>
              {allCashier &&
                allCashier.map((item, CashierIndex) => (
                  <Carousel.Item key={CashierIndex}>
                    <div
                      className={`card mb-2 dashboardCard${CashierIndex + 5}`}
                      style={{
                        border: "none",
                        borderRadius: "25px",
                        width: "24rem",
                        textAlign: "center",
                      }}
                    >
                      <div className="card-body">
                        <br />
                        <h3 style={{ color: "white" }}>
                          <b>
                            {item.firstName.charAt(0).toUpperCase() +
                              item.firstName.slice(1) +
                              " " +
                              item.middleName.charAt(0).toUpperCase() +
                              item.middleName.slice(1)}
                          </b>
                          <center>
                            <hr
                              width="75px"
                              style={{
                                color: "white",
                                height: "2px",
                                borderRadius: "20px",
                              }}
                            />
                          </center>
                        </h3>
                        <img src={usa} width="20" />{" "}
                        <p
                          className="card-text textWhite ml-1"
                          style={{ display: "inline-block" }}
                        >
                          {item.mcCurrentCash?.toLocaleString()} $
                        </p>
                        <br />
                        <img src={iraq} width="20" />{" "}
                        <p
                          className="card-text textWhite ml-1"
                          style={{ display: "inline-block" }}
                        >
                          {item.scCurrentCash?.toLocaleString()} IQD
                        </p>
                        <br />
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
            </Carousel>
          )}
        </div>
        <div className="col-lg-3 col-sm-12 d-flex justify-content-center">
          {loading && (
            <HashLoader
              color="#488EF9"
              loading={loading}
              css={override}
              size={55}
            />
          )}
          {!loading && (
            <Carousel>
              {allBoxes &&
                allBoxes.map((boxItem, boxIndex) => (
                  <Carousel.Item key={boxIndex}>
                    <div
                      className={`card mb-2 dashboardCard${boxIndex + 1}`}
                      style={{
                        border: "none",
                        borderRadius: "25px",
                        width: "24rem",
                        textAlign: "center",
                      }}
                    >
                      <div className="card-body mt-0">
                        <br />
                        <h3>
                          <b className="textWhite">{boxItem.name}</b>
                          <center>
                            <hr
                              width="75px"
                              style={{
                                color: "white",
                                height: "2px",
                                borderRadius: "20px",
                              }}
                            />
                          </center>
                        </h3>
                        <img src={usa} width="20" />
                        &nbsp;
                        <p
                          className="card-text textWhite ml-1"
                          style={{ display: "inline-block" }}
                        >
                          {boxItem.mcCurrentBalance?.toLocaleString() + " $"}
                        </p>
                        <br />
                        <img src={iraq} width="20" />
                        &nbsp;
                        <p
                          className="card-text textWhite ml-1"
                          style={{ display: "inline-block" }}
                        >
                          {boxItem.scCurrentBalance?.toLocaleString() + " IQD"}
                        </p>
                        <br />
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
            </Carousel>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
