import * as React from "react";
import { useRedirect, useTranslate } from "react-admin";
import { url } from "../../request";
import { Card, Col, Row } from "react-bootstrap";
import { HTTP } from "../../axios";

const BalanceSheet = () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [balanceSheet, setBalanceSheet] = React.useState([]);

  React.useEffect(() => {
    HTTP.get(`${url}/Dashboard/BalanceSheet`)
      .then((response) => {
        setBalanceSheet(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
  }, []);

  return (
    <div>
      <h1 className={`text-center`}>
        {translate("resources.root.balanceSheet")}
      </h1>
      <Row>
        <Col xs={3}>
          <Card className={`text-center`}>
            <Card.Header>
              {translate("resources.root.receivable")}{" "}
              <a href="#/Customers" className={`float-right`}>
                Customers
              </a>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <ul className={`list-unstyled`}>
                  <li>{`$${
                    balanceSheet.mcReceivable &&
                    balanceSheet.mcReceivable?.toLocaleString()
                  }`}</li>
                  <li>{`${
                    balanceSheet.scReceivable &&
                    balanceSheet.scReceivable?.toLocaleString()
                  } ${translate("resources.root.iqd")}`}</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={3}>
          <Card className={`text-center`}>
            <Card.Header>
              {translate("resources.root.payable")}{" "}
              <a href="#/Suppliers" className={`float-right`}>
                Suppliers
              </a>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <ul className={`list-unstyled`}>
                  <li>{`$${
                    balanceSheet.mcPayable &&
                    balanceSheet.mcPayable?.toLocaleString()
                  }`}</li>
                  <li>{`${
                    balanceSheet.scPayable &&
                    balanceSheet.scPayable?.toLocaleString()
                  } ${translate("resources.root.iqd")}`}</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={3}>
          <Card className={`text-center`}>
            <Card.Header>
              {translate("resources.root.stock")}{" "}
              <a href="#/Products" className={`float-right`}>
                Stock
              </a>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <ul className={`list-unstyled`}>
                  <li>{`$${
                    balanceSheet.mcProducts &&
                    balanceSheet.mcProducts?.toLocaleString()
                  }`}</li>
                  <li>{`${
                    balanceSheet.scProducts &&
                    balanceSheet.scProducts?.toLocaleString()
                  } ${translate("resources.root.iqd")}`}</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={3}>
          <Card className={`text-center`}>
            <Card.Header>
              {translate("resources.root.totalProductQty")}{" "}
              <a href="#/Products" className={`float-right`}>
                Qty
              </a>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <ul className={`list-unstyled`}>
                  <li>{`${
                    balanceSheet.productsTotalQte &&
                    balanceSheet.productsTotalQte?.toLocaleString()
                  }`}</li>
                  <li>{`${
                    balanceSheet.productsTotalSmallQte &&
                    balanceSheet.productsTotalSmallQte?.toLocaleString()
                  }`}</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={3}>
          <Card className={`text-center`}>
            <Card.Header>
              {translate("resources.root.paidExpenses")}{" "}
              <a href="#/Expenses" className={`float-right`}>
                Expenses
              </a>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <ul className={`list-unstyled`}>
                  <li>{`$${
                    balanceSheet.mcPaidExpenses &&
                    balanceSheet.mcPaidExpenses?.toLocaleString()
                  }`}</li>
                  <li>{`${
                    balanceSheet.scPaidExpenses &&
                    balanceSheet.scPaidExpenses?.toLocaleString()
                  } ${translate("resources.root.iqd")}`}</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={3}>
          <Card className={`text-center`}>
            <Card.Header>
              {translate("resources.root.paidSalaries")}{" "}
              <a href="#/EmployeeSalaryPays" className={`float-right`}>
                Paid Salaries
              </a>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <ul className={`list-unstyled`}>
                  <li>{`$${
                    balanceSheet.mcPaidSlaries &&
                    balanceSheet.mcPaidSlaries?.toLocaleString()
                  }`}</li>
                  <li>{`${
                    balanceSheet.scPaidSlaries &&
                    balanceSheet.scPaidSlaries?.toLocaleString()
                  } ${translate("resources.root.iqd")}`}</li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default BalanceSheet;
