import { useState } from 'react';
import { useEffect } from 'react';
import { HTTP } from '../../axios';
import { Doughnut } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
Chart.register(...registerables);

const override = css`
  display: block;
  margin: 10px auto;
  border-color: red;
`;

const CashierChart = () => {
    const pluck = (arr, key) => arr.map(o => o[key]);
    const [labels, setLabels] = useState([]);
    const [dataset, setDataset] = useState([]);
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        HTTP.get('UserStoreBankAccounts')
            .then((response) => {
                setLabels(
                    pluck(response.data.map(({firstName, middleName}) => {
                        return { cashierName: firstName.charAt(0).toUpperCase() + firstName.slice(1) + " " + middleName.charAt(0).toUpperCase() + middleName.slice(1) }
                    }), "cashierName")
                )
                setDataset(
                    [
                        {
                            label: "Cashier Box",
                            data: pluck(response.data, "scCurrentCash"),
                            borderColor: [
                                "#2C0E5A",
                                "#0066FF"
                            ],
                            backgroundColor: [
                                "#40118A",
                                "#008AFF",
                            ],
                        }
                    ]
                )
            }).catch((err) => {
                console.log(err);
            })
            .finally(() => setLoading(false));
    }, []);

    return (
        <div className="col-lg-6 col-sm-6 bgWhite radius shadow">
            <div style={{ width: "52.5%", padding: "20px", bordeRadius: "20px", margin: "0 auto" }}>
                {
                    loading &&
                    <HashLoader color="#488EF9" loading={loading} css={override} size={55} />
                }
                {
                    !loading &&
                    <Doughnut options={{ responsive: true }} data={{ labels: labels, datasets: dataset }} />
                }
            </div>
        </div>
    );
}

export default CashierChart;