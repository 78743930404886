import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  FunctionField,
  BooleanInput,
  NumberInput,
  required,
  FormDataConsumer,
  maxValue,
  minValue,
  ReferenceField,
  AutocompleteInput,
  useTranslate,
  ImageInput,
  ImageField,
  EditButton,
} from "react-admin";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { path, url } from "../../request";
import { ListActions } from "../templates/ListActions";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";

export const RepresentativeCustomersList = (props) => {
  const translate = useTranslate();
  const [currency, setCurrency] = React.useState("default");

  return (
    <List
      {...props}
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions setCurrency={setCurrency} currency={currency} />}
    >
      <Datagrid bulkActionButtons={<BulkAction {...props} />}>
        <FunctionField
          sortBy={"placeName"}
          label="resources.root.placeName"
          render={(record) => (
            <a
              href={`#/Reports/RepresentativeCustomerStatement/${record.id}`}
              target={`_blank`}
            >{`${record.placeName}`}</a>
          )}
        />
        <TextField source="owner" label={translate("resources.root.owner")} />
        <ReferenceField
          label={translate("resources.root.Representative")}
          source="representativeId"
          reference="Representatives"
          link="show"
        >
          <FunctionField
            label={translate("resources.root.name")}
            render={(record) => `${record.firstName} ${record.middleName}`}
          />
        </ReferenceField>
        <TextField source="phone" label={translate("resources.root.phone")} />
        <TextField
          source="address"
          label={translate("resources.root.address")}
        />
        {currency === "default" && (
          <FunctionField
            sortBy={"mcCurrentLoan"}
            label={translate("resources.root.currentLoan")}
            render={(record) =>
              record.loanByMain
                ? `$${record.mcCurrentLoan}`
                : `${record.scCurrentLoan} IQD`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            sortBy={"mcCurrentLoan"}
            label={translate("resources.root.price")}
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcCurrentLoan}`
                : `${record.scCurrentLoan} IQD`
            }
          />
        )}
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const RepresentativeCustomersCreate = (props) => {
  const translate = useTranslate();
  const [stores, setStores] = React.useState([]);
  const [selectedStores, setSelectedStores] = React.useState([]);
  const [representatives, setRepresentatives] = React.useState([]);
  const [selectedRepresentatives, setSelectedRepresentatives] = React.useState(
    []
  );
  const [gender, setGender] = React.useState(true);
  const [currency, setCurrency] = React.useState(true);
  const classes = useStyles();
  React.useEffect(() => {
    HTTP.get(`${url}/Stores`).then((res) => {
      setStores(res.data);
    });
    HTTP.get(`${url}/Representatives`).then((res) => {
      setRepresentatives(res.data);
    });
  }, []);

  return (
    <Create
      {...props}
      transform={(data) => ({ ...data, country: "Iraq", city: "Erbil" })}
      redirect="list"
    >
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <AutocompleteInput
              source="representativeId"
              choices={representatives}
              optionText={(choice) =>
                `${choice.firstName} ${choice.middleName}`
              }
              onSelect={(val) => setSelectedRepresentatives(val)}
              label={translate("resources.root.chooseRepresentative")}
              className={classes.input}
            />
            <TextInput
              source="owner"
              label={translate("resources.root.owner")}
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="placeName"
              label={translate("resources.root.placeName")}
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="email"
              label={translate("resources.root.email")}
              className={classes.input}
            />
            <TextInput
              source="phone"
              label={translate("resources.root.phone")}
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="phone2"
              label={translate("resources.root.phone2")}
              className={classes.input}
            />
            <Grid container>
              <Grid item xs={4}>
                <BooleanInput
                  source="saturday"
                  className={classes.input}
                  label={translate("resources.root.saturday")}
                  defaultValue={true}
                />
                <BooleanInput
                  source="sunday"
                  label={translate("resources.root.sunday")}
                  defaultValue={true}
                  className={classes.input}
                />
                <BooleanInput
                  source="monday"
                  label={translate("resources.root.monday")}
                  defaultValue={true}
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={4}>
                <BooleanInput
                  source="tuesday"
                  className={classes.input}
                  label={translate("resources.root.tuesday")}
                  defaultValue={true}
                />
                <BooleanInput
                  source="wednesday"
                  label={translate("resources.root.wednesday")}
                  defaultValue={true}
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={4}>
                <BooleanInput
                  source="thursday"
                  className={classes.input}
                  label={translate("resources.root.thursday")}
                  defaultValue={true}
                />
                <BooleanInput
                  source="friday"
                  label={translate("resources.root.friday")}
                  defaultValue={true}
                  className={classes.input}
                />
              </Grid>
            </Grid>
          </div>
          <div className={classes.formCol}>
            <TextInput
              source="country"
              label={translate("resources.root.country")}
              defaultValue={`Iraq`}
              className={classes.input}
            />
            <TextInput
              source="city"
              label={translate("resources.root.city")}
              defaultValue={`Erbil`}
              className={classes.input}
            />
            <TextInput
              source="note"
              label={translate("resources.root.note")}
              className={classes.input}
            />
            <TextInput
              source="address"
              label={translate("resources.root.address")}
              className={classes.input}
              validate={[required()]}
            />
            {currency ? (
              <NumberInput
                validate={[required(), minValue(0)]}
                min={0}
                source="mcRoofLoan"
                label={translate("resources.root.maxPossibleLoanInUSD")}
                className={classes.input}
              />
            ) : (
              <NumberInput
                validate={[required(), minValue(0)]}
                min={0}
                source="scRoofLoan"
                label={translate("resources.root.maxPossibleLoanInIQD")}
                className={classes.input}
              />
            )}
            {currency ? (
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    <NumberInput
                      validate={[
                        required(),
                        maxValue(formData.mcRoofLoan ? formData.mcRoofLoan : 0),
                        minValue(0),
                      ]}
                      max={formData.mcRoofLoan ? formData.mcRoofLoan : 0}
                      min={0}
                      source="mcCurrentLoan"
                      label={translate("resources.root.currentLoan")}
                      className={classes.input}
                    />
                  );
                }}
              </FormDataConsumer>
            ) : (
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    <NumberInput
                      validate={[
                        required(),
                        maxValue(formData.scRoofLoan ? formData.scRoofLoan : 0),
                        minValue(0),
                      ]}
                      max={formData.scRoofLoan ? formData.scRoofLoan : 0}
                      min={0}
                      source="scCurrentLoan"
                      label={translate("resources.root.currentLoan")}
                      className={classes.input}
                    />
                  );
                }}
              </FormDataConsumer>
            )}
            <Grid container>
              <Grid item xs={6}>
                <div style={{ display: "none" }}>
                  <BooleanInput
                    source="addressConfirmed"
                    label={translate("resources.root.addressConfirmed")}
                    className={classes.input}
                    defaultValue={true}
                  />
                  <TextInput
                    source="addressConfirmedBy"
                    label={translate("resources.root.addressConfirmedBy")}
                    defaultValue={localStorage.getItem("name")}
                  />
                </div>
                <BooleanInput
                  source="loanByMain"
                  className={classes.input}
                  label={
                    currency
                      ? translate("resources.root.usd")
                      : translate("resources.root.iqd")
                  }
                  defaultValue={true}
                  onChange={(e) => setCurrency(e.target.checked)}
                />
                <BooleanInput
                  source="male"
                  label={
                    gender
                      ? translate("resources.root.male")
                      : translate("resources.root.female")
                  }
                  defaultValue={true}
                  onChange={(e) => setGender(e.target.checked)}
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={6}>
                <BooleanInput
                  source="canTakeLoan"
                  label={translate("resources.root.canTakeLoan")}
                  defaultValue={true}
                  className={classes.input}
                />
                <BooleanInput
                  source="gpsLock"
                  label={translate("resources.root.gpsLock")}
                  className={classes.input}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <ImageInput
          source="attachment"
          label={translate("resources.root.attachment")}
          accept="image/*"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export const RepresentativeCustomersEdit = (props) => {
  const translate = useTranslate();
  const [stores, setStores] = React.useState([]);
  const [selectedStores, setSelectedStores] = React.useState([]);
  const [representatives, setRepresentatives] = React.useState([]);
  const [selectedRepresentatives, setSelectedRepresentatives] = React.useState(
    []
  );
  const [gender, setGender] = React.useState(true);
  const [currency, setCurrency] = React.useState(true);

  const dp = localStorage.getItem("dollarPrice");
  const classes = useStyles();
  React.useEffect(() => {
    HTTP.get(`${url}/Stores`).then((res) => {
      setStores(res.data);
    });
    HTTP.get(`${url}/Representatives`).then((res) => {
      setRepresentatives(res.data);
    });
  }, []);

  return (
    <Edit
      {...props}
      transform={(data) => ({ ...data, country: "Iraq", city: "Erbil" })}
    >
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <AutocompleteInput
              source="representativeId"
              choices={representatives}
              optionText={(choice) =>
                `${choice.firstName} ${choice.middleName}`
              }
              onSelect={(val) => setSelectedRepresentatives(val)}
              label={translate("resources.root.chooseRepresentative")}
              className={classes.input}
            />
            <TextInput
              source="owner"
              label={translate("resources.root.owner")}
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="placeName"
              label={translate("resources.root.placeName")}
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="email"
              label={translate("resources.root.email")}
              className={classes.input}
            />
            <TextInput
              source="phone"
              label={translate("resources.root.phone")}
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="phone2"
              label={translate("resources.root.phone2")}
              className={classes.input}
            />
            <Grid container>
              <Grid item xs={4}>
                <BooleanInput
                  source="saturday"
                  className={classes.input}
                  label={translate("resources.root.saturday")}
                  defaultValue={true}
                />
                <BooleanInput
                  source="sunday"
                  label={translate("resources.root.sunday")}
                  defaultValue={true}
                  className={classes.input}
                />
                <BooleanInput
                  source="monday"
                  label={translate("resources.root.monday")}
                  defaultValue={true}
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={4}>
                <BooleanInput
                  source="tuesday"
                  className={classes.input}
                  label={translate("resources.root.tuesday")}
                  defaultValue={true}
                />
                <BooleanInput
                  source="wednesday"
                  label={translate("resources.root.wednesday")}
                  defaultValue={true}
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={4}>
                <BooleanInput
                  source="thursday"
                  className={classes.input}
                  label={translate("resources.root.thursday")}
                  defaultValue={true}
                />
                <BooleanInput
                  source="friday"
                  label={translate("resources.root.friday")}
                  defaultValue={true}
                  className={classes.input}
                />
              </Grid>
            </Grid>
          </div>
          <div className={classes.formCol}>
            <TextInput
              source="country"
              label={translate("resources.root.country")}
              defaultValue={`Iraq`}
              className={classes.input}
            />
            <TextInput
              source="city"
              label={translate("resources.root.city")}
              defaultValue={`Erbil`}
              className={classes.input}
            />
            <TextInput
              source="note"
              label={translate("resources.root.note")}
              className={classes.input}
            />
            <TextInput
              source="address"
              label={translate("resources.root.address")}
              className={classes.input}
              validate={[required()]}
            />
            {currency ? (
              <NumberInput
                validate={[required(), minValue(0)]}
                min={0}
                source="mcRoofLoan"
                label={translate("resources.root.maxPossibleLoanInUSD")}
                className={classes.input}
              />
            ) : (
              <NumberInput
                validate={[required(), minValue(0)]}
                min={0}
                source="scRoofLoan"
                label={translate("resources.root.maxPossibleLoanInIQD")}
                className={classes.input}
              />
            )}
            <Grid container>
              <Grid item xs={6}>
                <div style={{ display: "none" }}>
                  <BooleanInput
                    source="addressConfirmed"
                    label={translate("resources.root.addressConfirmed")}
                    className={classes.input}
                    defaultValue={true}
                  />
                  <TextInput
                    source="addressConfirmedBy"
                    label={translate("resources.root.addressConfirmedBy")}
                    defaultValue={localStorage.getItem("name")}
                  />
                </div>
                <BooleanInput
                  source="loanByMain"
                  className={classes.input}
                  label={
                    currency
                      ? translate("resources.root.usd")
                      : translate("resources.root.iqd")
                  }
                  defaultValue={true}
                  onChange={(e) => setCurrency(e.target.checked)}
                />
                <BooleanInput
                  source="male"
                  label={
                    gender
                      ? translate("resources.root.male")
                      : translate("resources.root.female")
                  }
                  defaultValue={true}
                  onChange={(e) => setGender(e.target.checked)}
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={6}>
                <BooleanInput
                  source="canTakeLoan"
                  label={translate("resources.root.canTakeLoan")}
                  defaultValue={true}
                  className={classes.input}
                />
                <BooleanInput
                  source="gpsLock"
                  label={translate("resources.root.gpsLock")}
                  className={classes.input}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <ImageInput
          source="attachment"
          label={translate("resources.root.attachment")}
          accept="image/*"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <FunctionField
          label={translate("resources.root.attachment")}
          render={(record) => (
            <img
              style={{
                width: 200,
                height: 200,
                objectFit: "scale-down",
                cursor: "pointer",
              }}
              src={`${path}${record.attachment}`}
              alt="Attachment"
              onClick={() =>
                window.open(`${path}${record.attachment}`, "_blank")
              }
            />
          )}
        />
      </SimpleForm>
    </Edit>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  input: {
    width: "70%",
  },
  table: {
    minWidth: 500,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
});
