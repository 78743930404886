import { useEffect } from "react";
import { useState } from "react";
import { HTTP } from "../../axios";
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
import usa from "../../assets/dashboardImages/usa.png";
import iraq from "../../assets/dashboardImages/iraq.png";
import custImg from "../../assets/dashboardImages/custImg.png";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 10px auto;
  border-color: red;
`;

const Customer = () => {
  const [allLoan, setAllLoan] = useState([]);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    HTTP.get("Customers?_end=10&_order=DESC&_sort=id&_start=0")
      .then((result) => {
        setAllLoan(result.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="allCustomers">
      <h1>Customers</h1>
      <br />
      <div
        className="suppliers overflowX"
        style={{
          scrollbarColor: "rgb(106, 150, 255) rgb(224, 224, 224)",
          scrollbarWidth: "thin",
          scrollbarThumb: { borderRadius: "100px" },
        }}
      >
        {loading && (
          <HashLoader
            color="#488EF9"
            loading={loading}
            css={override}
            size={55}
          />
        )}
        {!loading && (
          <div className="row flexWrapRow" style={{ marginBottom: "10px" }}>
            {allLoan &&
              allLoan.map((item, index) => (
                <div className="col-lg-2" key={index}>
                  <div
                    className="card p-3 radius customHeight"
                    style={{
                      backgroundImage: "url(" + custImg + ")",
                      backgroundSize: "cover",
                    }}
                  >
                    <h4>
                      {item.firstName.charAt(0).toUpperCase() +
                        item.firstName.slice(1) +
                        " " +
                        item.middleName.charAt(0).toUpperCase() +
                        " " +
                        item.middleName.slice(1) +
                        item.lastName.charAt(0).toUpperCase() +
                        item.lastName.slice(1)}
                    </h4>
                    {item.payLoanByMain ? (
                      <span>
                        <img src={usa} width="20" />{" "}
                        <p
                          className="card-text  ml-1"
                          style={{ display: "inline-block" }}
                        >
                          {item.mcCurrentLoan?.toLocaleString()} <b> $</b>
                        </p>
                      </span>
                    ) : (
                      <span>
                        <img src={iraq} width="20" />{" "}
                        <p
                          className="card-text  ml-1"
                          style={{ display: "inline-block" }}
                        >
                          {item.scCurrentLoan?.toLocaleString()}
                          <b> IQD</b>
                        </p>
                      </span>
                    )}
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Customer;
