import * as React from "react";
import { url } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useNotify, useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import Select from "react-select";
import { PrintProvider, Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { HTTP } from "../../axios";

export default () => {
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [employees, setEmployees] = React.useState([]);
  const [employee, setEmployee] = React.useState(0);
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).subtract(1, "months").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [isLoading, setIsLoading] = React.useState(false);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;
    let employeeId = "";
    if (startDate && startDate.length > 0)
      sendDates += `&startDate=${startDate}`;
    if (endDate && endDate.length > 0) sendDates += `&endDate=${endDate}`;
    if (employee !== 0)
      employeeId = `&enableSubLevel=true&subLevelId=${employee}`;

    await HTTP.get(
      `${url}/EmployeeAllows?_start=${start}&_end=${end}${sendDates}${employeeId}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(function () {
    setIsLoading(true);
    getEmployees("");
    getData();
  }, []);

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [employee, startDate, endDate, start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  function getEmployees(value) {
    HTTP.get(`${url}/Employees?_end=10000&search=${value}`)
      .then((response) => {
        let newArray = [];
        newArray.push({
          value: 0,
          label: translate("resources.root.all"),
          employee: {},
        });
        response.data.map((employee) => {
          newArray.push({
            value: employee.id,
            label: `${employee.firstName} ${employee.middleName} ${employee.lastName}`,
            employee: employee,
          });
        });
        setEmployees(newArray);
      })
      .catch((err) =>
        notify(err.response.data.message, { type: "error", undoable: true })
      );
  }

  return (
    <div>
      <PrintProvider>
        <Print single name={`expireReport`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.permissionReport")}
            </h1>
            <div className={`d-inline-block`} style={{ float: "inline-end" }}>
              <NoPrint>
                <Button
                  variant={"outline-primary"}
                  onClick={(e) => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}
                </Button>
                <div className={`clearfix`}> </div>
              </NoPrint>
            </div>
          </div>
          <Row>
            {isLoading && <LoadingScreen />}
            <Col xs={1}>
              <label>{translate("resources.root.employee")}</label>
            </Col>
            <Col xs={3}>
              <Select
                name="colors"
                isClearable
                isSearchable
                className="basic-multi-select"
                classNamePrefix="select"
                options={employees}
                onChange={(e) => setEmployee(e ? e.value : 0)}
              />
            </Col>

            <Col xs={4}>
              <Form.Group as={Row} controlId="startDate">
                <Form.Label column sm={4}>
                  {translate("resources.root.startDate")}
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group as={Row} controlId="endDate">
                <Form.Label column sm={4}>
                  {translate("resources.root.endDate")}
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row className={`mt-2`}>
            <Col>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.employee")}</th>
                    <th>{translate("resources.root.invoiceNumber")}</th>
                    <th>{translate("resources.root.reason")}</th>
                    <th>{translate("resources.root.price")}</th>
                    <th>{translate("resources.root.days")}</th>
                    <th>{translate("resources.root.hours")}</th>
                    <th>{translate("resources.root.note")}</th>
                    <th>{translate("resources.root.date")}</th>
                    <th>{translate("resources.root.allowedBy")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr>
                          <td>
                            <a href={`#/Employees/${one.id}/show`}>
                              {" "}
                              {`${one.employee.firstName} ${one.employee.middleName} ${one.employee.lastName}`}
                            </a>
                          </td>
                          <td>{one.invoiceNumber ?? "-"}</td>
                          <td>{one.reason ?? "-"}</td>
                          <td>
                            {one.byMain
                              ? one.mcSubtractedSalary
                              : one.scSubtractedSalary}
                          </td>
                          <td>{one.days ?? "-"}</td>
                          <td>{one.hours ?? "-"}</td>
                          <td>{one.note ?? "-"}</td>
                          <td>{moment(one.date).format("YYYY-MM-DD")}</td>
                          <td>{one.allowedBy ?? "-"}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
