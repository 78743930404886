import * as React from "react";
import { url } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import { PrintProvider, Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";
import lodash from "lodash";
import { HTTP } from "../../axios";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { path } from "../../request";

export default () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [products, setProducts] = React.useState("");
  const [categories, setCategories] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [showInUSD, setShowInUSD] = React.useState(false);

  const [showDamageQty, setShowDamageQty] = React.useState(true);
  const [showSmallMeasurePurchaseQty, setShowSmallMeasurePurchaseQty] =
    React.useState(true);
  const [showSellQty, setShowSellQty] = React.useState(true);
  const [showAttachment, setShowAttachment] = React.useState(true);
  const [showSmallMeasureSellQty, setShowSmallMeasureSellQty] =
    React.useState(true);
  const [showReturnSellQty, setShowReturnSellQty] = React.useState(true);
  const [showAverageSellPrice, setShowAverageSellPrice] = React.useState(true);
  const [showAverageSellOnePrice, setShowAverageSellOnePrice] =
    React.useState(true);
  const [showTotalSellPrice, setShowTotalSellPrice] = React.useState(true);
  const [showTotalSellProfit, setShowTotalSellProfit] = React.useState(true);

  const [totalDiscountPrice, setTotalDiscountPrice] = React.useState([]);
  const [totalReturnPrice, setTotalReturnPrice] = React.useState([]);
  const [totalSellPrice, setTotalSellPrice] = React.useState([]);
  const [totalProfit, setTotalProfit] = React.useState([]);
  const [totalDamagePrice, setTotalDamagePrice] = React.useState([]);
  const [totalSellQty, setTotalSellQty] = React.useState(0);
  const [totalSellSmallQty, setTotalSellSmallQty] = React.useState(0);
  const [totalSellGiftQty, setTotalSellGiftQty] = React.useState(0);
  const [totalSellSmallGiftQty, setTotalSellSmallGiftQty] = React.useState(0);
  const [totalReturnQty, setTotalReturnQty] = React.useState(0);
  const [totalReturnSmallQty, setTotalReturnSmallQty] = React.useState(0);

  // TotalDiscountPrice, TotalReturnPrice, TotalSellPrice, TotalProfit,
  // TotalSellQty, TotalSellSmallQty, TotalSellGiftQty, TotalSellSmallGiftQty,
  // TotalReturnQty, TotalReturnSmallQty

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;
    if (startDate && startDate.length > 0) {
      sendDates += `&startDate=${startDate}`;
    }
    if (endDate && endDate.length > 0) {
      sendDates += `&endDate=${endDate}`;
    }

    let product = ``;
    if (products !== 0) {
      product = `${products}`;
    } else {
      product = "";
    }
    let category = ``;
    if (categories.length !== 0) {
      category = `&byCategory=true${categories}`;
    } else {
      category = "";
    }

    await HTTP.get(
      `${url}/AdminReports/GetItemProfitLoseReport?_start=${start}&_end=${end}${sendDates}${product}${category}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        setTotalDiscountPrice([
          response.headers["mctotaldiscountprice"],
          response.headers["sctotaldiscountprice"],
        ]);
        setTotalReturnPrice([
          response.headers["mctotalreturnprice"],
          response.headers["sctotalreturnprice"],
        ]);
        setTotalSellPrice([
          response.headers["mctotalsellprice"],
          response.headers["sctotalsellprice"],
        ]);
        setTotalProfit([
          response.headers["mctotalprofit"],
          response.headers["sctotalprofit"],
        ]);
        setTotalDamagePrice([
          response.headers["mctotaldamageprice"],
          response.headers["sctotaldamageprice"],
        ]);
        setTotalSellQty(response.headers["totalsellqty"]);
        setTotalSellSmallQty(response.headers["totalsellsmallqty"]);
        setTotalSellGiftQty(response.headers["totalsellgiftqty"]);
        setTotalSellSmallGiftQty(response.headers["totalsellsmallgiftqty"]);
        setTotalReturnQty(response.headers["totalreturnqty"]);
        setTotalReturnSmallQty(response.headers["totalreturnsmallqty"]);
        setData(
          lodash
            .chain(response.data)
            .groupBy("productId")
            .map((value, key) => {
              let mcAveragePurchaseOnePriceList = value;
              let mcAveragePurchasePriceList = value;
              let mcAverageSellOnePriceList = value;
              let mcAverageSellPriceList = value;
              let scAveragePurchaseOnePriceList = value;
              let scAveragePurchasePriceList = value;
              let scAverageSellOnePriceList = value;
              let scAverageSellPriceList = value;

              mcAveragePurchaseOnePriceList =
                mcAveragePurchaseOnePriceList.filter((o) => {
                  return o.type === "Purchase";
                });
              mcAveragePurchasePriceList = mcAveragePurchasePriceList.filter(
                (o) => {
                  return o.type === "Purchase";
                }
              );
              mcAverageSellOnePriceList = mcAverageSellOnePriceList.filter(
                (o) => {
                  return o.type === "Quick Sell" || o.type === "Sell";
                }
              );
              mcAverageSellPriceList = mcAverageSellPriceList.filter((o) => {
                return o.type === "Quick Sell" || o.type === "Sell";
              });
              scAveragePurchaseOnePriceList =
                scAveragePurchaseOnePriceList.filter((o) => {
                  return o.type === "Purchase";
                });
              scAveragePurchasePriceList = scAveragePurchasePriceList.filter(
                (o) => {
                  return o.type === "Purchase";
                }
              );
              scAverageSellOnePriceList = scAverageSellOnePriceList.filter(
                (o) => {
                  return o.type === "Quick Sell" || o.type === "Sell";
                }
              );
              scAverageSellPriceList = scAverageSellPriceList.filter((o) => {
                return o.type === "Quick Sell" || o.type === "Sell";
              });
              return {
                productId: key,
                productName: value[0].productName,
                productBarcode: value[0].productBarcode,
                smallMeasurePurchaseQty: lodash.sumBy(
                  value,
                  "smallMeasurePurchaseQty"
                ),
                purchaseQty: lodash.sumBy(value, "purchaseQty"),
                sellQty: lodash.sumBy(value, "sellQty"),
                damageQty: lodash.sumBy(value, "damageQty"),
                smallMeasureSellQty: lodash.sumBy(value, "smallMeasureSellQty"),
                smallMeasurePurchaseReturnQty: lodash.sumBy(
                  value,
                  "smallMeasurePurchaseReturnQty"
                ),
                purchaseReturnQty: lodash.sumBy(value, "purchaseReturnQty"),
                sellReturnQty: lodash.sumBy(value, "sellReturnQty"),
                smallMeasureSellReturnQty: lodash.sumBy(
                  value,
                  "smallMeasureSellReturnQty"
                ),

                ///// type === purchase
                mcAveragePurchaseOnePrice: lodash.meanBy(
                  scAveragePurchaseOnePriceList,
                  "mcAveragePurchaseOnePrice"
                ),
                scAveragePurchaseOnePrice: lodash.meanBy(
                  mcAveragePurchaseOnePriceList,
                  "scAveragePurchaseOnePrice"
                ),

                mcAveragePurchasePrice: lodash.meanBy(
                  mcAveragePurchasePriceList,
                  "mcAveragePurchasePrice"
                ),
                scAveragePurchasePrice: lodash.meanBy(
                  scAveragePurchasePriceList,
                  "scAveragePurchasePrice"
                ),

                ///// type === quick sell or sell
                mcAverageSellOnePrice: lodash.meanBy(
                  mcAverageSellOnePriceList,
                  "mcAverageSellOnePrice"
                ),
                scAverageSellOnePrice: lodash.meanBy(
                  scAverageSellOnePriceList,
                  "scAverageSellOnePrice"
                ),

                mcAverageSellPrice: lodash.meanBy(
                  mcAverageSellPriceList,
                  "mcAverageSellPrice"
                ),
                scAverageSellPrice: lodash.meanBy(
                  scAverageSellPriceList,
                  "scAverageSellPrice"
                ),

                mcTotalSellPrice: lodash.sumBy(value, "mcTotalSellPrice"),
                scTotalSellPrice: lodash.sumBy(value, "scTotalSellPrice"),
                mcTotalPurchasePrice: lodash.sumBy(
                  value,
                  "mcTotalPurchasePrice"
                ),
                scTotalPurchasePrice: lodash.sumBy(
                  value,
                  "scTotalPurchasePrice"
                ),
                mcTotalSellProfit: lodash.sumBy(value, "mcTotalSellProfit"),
                scTotalSellProfit: lodash.sumBy(value, "scTotalSellProfit"),
                type: "-",
              };
            })
            .value()
        );
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  // React.useEffect(function () {
  //   setIsLoading(true);
  // }, []);

  function regetData() {
    setIsLoading(true);
    getData();
  }
  // React.useEffect(
  //   function () {
  //     setIsLoading(true);
  //     getData();
  //   },
  //   [products, categories, startDate, endDate, start, end]
  // );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/Products?_end=10&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((product) => {
        newArray.push({
          value: product.id,
          label: product.name,
          product: product,
        });
      });
      callback(newArray);
    }
  };

  const loadCategoriesOption = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/ProductCategories?_end=10&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((category) => {
        newArray.push({
          value: category.id,
          label: category.name,
          category: category,
        });
      });
      callback(newArray);
    }
  };

  return (
    <div>
      <PrintProvider>
        <Print single name={`expireReport`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.profitLoseReport")}
            </h1>
            <div className={`d-inline-block`} style={{ float: "inline-end" }}>
              <NoPrint>
                <Button
                  variant={"outline-primary"}
                  onClick={() => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "15px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}
                </Button>
                <div className={`clearfix`}> </div>
              </NoPrint>
            </div>
          </div>
          <NoPrint force>
            <Row>
              {isLoading && <LoadingScreen />}
              <Col xs={4}>
                <Form.Group as={Row} controlId="startDate">
                  <Form.Label column sm={4}>
                    {translate("resources.root.startDate")}
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group as={Row} controlId="endDate">
                  <Form.Label column sm={4}>
                    {translate("resources.root.endDate")}
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col xs={4}>
                <Button
                  id="export"
                  onClick={() => {
                    regetData();
                  }}
                >
                  Search
                </Button>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={4}>
                <AsyncSelect
                  defaultOptions
                  loadOptions={loadOptions}
                  isMulti
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  onChange={(e) => {
                    let product = "";
                    if (e.length !== 0) {
                      e.map((x) => (product += "&id=" + x.value));
                      setProducts(product);
                    } else {
                      setProducts(``);
                    }
                  }}
                />
              </Col>
              <Col xs={4}>
                <AsyncSelect
                  defaultOptions
                  loadOptions={loadCategoriesOption}
                  isMulti
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  onChange={(e) => {
                    let category = "";
                    if (e.length !== 0) {
                      e.map((x) => (category += "&categoryIds=" + x.value));
                      setCategories(category);
                    } else {
                      setCategories(``);
                    }
                  }}
                />
              </Col>
              <Col xs={2} className={`m-1`}>
                <Button
                  id="export"
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  Export CSV
                </Button>
              </Col>
            </Row>
            <Row className="my-3">
              <Col xs={3}>
                <h5>
                  {translate("resources.root.totalDiscountPrice")}:&nbsp;
                  {showInUSD &&
                    totalDiscountPrice[0] &&
                    parseFloat(totalDiscountPrice[0])?.toLocaleString() +
                      " " +
                      translate("resources.root.usd")}
                  {!showInUSD &&
                    totalDiscountPrice[1] &&
                    parseFloat(totalDiscountPrice[1])?.toLocaleString() +
                      " " +
                      translate("resources.root.iqd")}
                </h5>
              </Col>
              <Col xs={3}>
                <h5>
                  {translate("resources.root.totalReturnPrice")}:&nbsp;
                  {showInUSD &&
                    totalReturnPrice[0] &&
                    parseFloat(totalReturnPrice[0])?.toLocaleString() +
                      " " +
                      translate("resources.root.usd")}
                  {!showInUSD &&
                    totalReturnPrice[1] &&
                    parseFloat(totalReturnPrice[1])?.toLocaleString() +
                      " " +
                      translate("resources.root.iqd")}
                </h5>
              </Col>
              <Col xs={3}>
                <h5>
                  {translate("resources.root.totalSellPrice")}:&nbsp;
                  {showInUSD &&
                    totalSellPrice[0] &&
                    parseFloat(totalSellPrice[0])?.toLocaleString() +
                      " " +
                      translate("resources.root.usd")}
                  {!showInUSD &&
                    totalSellPrice[1] &&
                    parseFloat(totalSellPrice[1])?.toLocaleString() +
                      " " +
                      translate("resources.root.iqd")}
                </h5>
              </Col>
              <Col xs={3}>
                <h5>
                  {translate("resources.root.totalProfit")}:&nbsp;
                  {showInUSD &&
                    totalProfit[0] &&
                    parseFloat(totalProfit[0])?.toLocaleString() +
                      " " +
                      translate("resources.root.usd")}
                  {!showInUSD &&
                    totalProfit[1] &&
                    parseFloat(totalProfit[1])?.toLocaleString() +
                      " " +
                      translate("resources.root.iqd")}
                </h5>
              </Col>
              <Col xs={3}>
                <h5>
                  {translate("resources.root.totalSellQty")}:&nbsp;
                  {totalSellQty && parseFloat(totalSellQty)?.toLocaleString()}
                  &nbsp;+&nbsp;
                  {totalSellSmallQty &&
                    parseFloat(totalSellSmallQty)?.toLocaleString()}
                </h5>
              </Col>
              <Col xs={3}>
                <h5>
                  {translate("resources.root.totalSellGiftQty")}:&nbsp;
                  {totalSellGiftQty &&
                    parseFloat(totalSellGiftQty)?.toLocaleString()}
                  &nbsp;+&nbsp;
                  {totalSellSmallGiftQty &&
                    parseFloat(totalSellSmallGiftQty)?.toLocaleString()}
                </h5>
              </Col>
              <Col xs={3}>
                <h5>
                  {translate("resources.root.totalReturnQty")}:&nbsp;
                  {totalReturnQty &&
                    parseFloat(totalReturnQty)?.toLocaleString()}
                  &nbsp;+&nbsp;
                  {totalReturnSmallQty &&
                    parseFloat(totalReturnSmallQty)?.toLocaleString()}
                </h5>
              </Col>
              <Col xs={3}>
                <h5>
                  {translate("resources.root.totalDamage")}:&nbsp;
                  {showInUSD &&
                    totalDamagePrice[0] &&
                    parseFloat(totalDamagePrice[0])?.toLocaleString() +
                      " " +
                      translate("resources.root.usd")}
                  {!showInUSD &&
                    totalDamagePrice[1] &&
                    parseFloat(totalDamagePrice[1])?.toLocaleString() +
                      " " +
                      translate("resources.root.iqd")}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={4} lg={3}>
                <Col>
                  <InputGroup>
                    <FormControlLabel
                      className="m-0"
                      defaultChecked={showInUSD}
                      control={
                        <Switch
                          defaultChecked
                          id={"showInUSD"}
                          onChange={(e) => setShowInUSD(e.target.checked)}
                        />
                      }
                      label={translate("resources.root.showInUSD")}
                    />
                  </InputGroup>
                </Col>
              </Col>
              <Col xs={6} md={4} lg={3}>
                <Col>
                  <InputGroup>
                    <FormControlLabel
                      id={"damageQty"}
                      className="m-0"
                      defaultChecked={showDamageQty}
                      control={
                        <Switch
                          defaultChecked
                          onChange={(e) => {
                            setShowDamageQty(e.target.checked);
                          }}
                          name="gilad"
                        />
                      }
                      label={translate("resources.root.damageQty")}
                    />
                  </InputGroup>
                </Col>
              </Col>
              {/* <Col xs={6} md={4} lg={3}>
                <Col>
                  <InputGroup>
                    <FormControlLabel
                      id={"smallMeasurePurchaseQty"}
                      className="m-0"
                      defaultChecked={showSmallMeasurePurchaseQty}
                      control={
                        <Switch
                          defaultChecked
                          onChange={(e) => {
                            setShowSmallMeasurePurchaseQty(e.target.checked);
                          }}
                          name="gilad"
                        />
                      }
                      label={translate(
                        "resources.root.smallMeasurePurchaseQty"
                      )}
                    />
                  </InputGroup>
                </Col>
              </Col> */}
              <Col xs={6} md={4} lg={3}>
                <Col>
                  <InputGroup>
                    <FormControlLabel
                      id={"sellQty"}
                      className="m-0"
                      defaultChecked={showSellQty}
                      control={
                        <Switch
                          defaultChecked
                          onChange={(e) => {
                            setShowSellQty(e.target.checked);
                          }}
                          name="gilad"
                        />
                      }
                      label={translate("resources.root.sellQty")}
                    />
                  </InputGroup>
                </Col>
              </Col>
              <Col xs={6} md={4} lg={3}>
                <Col>
                  <InputGroup>
                    <FormControlLabel
                      id={"smallMeasureSellQty"}
                      className="m-0"
                      defaultChecked={showSmallMeasureSellQty}
                      control={
                        <Switch
                          defaultChecked
                          onChange={(e) => {
                            setShowSmallMeasureSellQty(e.target.checked);
                          }}
                          name="gilad"
                        />
                      }
                      label={translate("resources.root.smallMeasureSellQty")}
                    />
                  </InputGroup>
                </Col>
              </Col>
              <Col xs={6} md={4} lg={3}>
                <Col>
                  <InputGroup>
                    <FormControlLabel
                      id={"returns"}
                      className="m-0"
                      defaultChecked={showReturnSellQty}
                      control={
                        <Switch
                          defaultChecked
                          onChange={(e) => {
                            setShowReturnSellQty(e.target.checked);
                          }}
                          name="gilad"
                        />
                      }
                      label={translate("resources.root.returns")}
                    />
                  </InputGroup>
                </Col>
              </Col>
              <Col xs={6} md={4} lg={3}>
                <Col>
                  <InputGroup>
                    <FormControlLabel
                      id={"averageSellPrice"}
                      className="m-0"
                      defaultChecked={showAverageSellPrice}
                      control={
                        <Switch
                          defaultChecked
                          onChange={(e) => {
                            setShowAverageSellPrice(e.target.checked);
                          }}
                          name="gilad"
                        />
                      }
                      label={translate("resources.root.averageSellPrice")}
                    />
                  </InputGroup>
                </Col>
              </Col>
              <Col xs={6} md={4} lg={3}>
                <Col>
                  <InputGroup>
                    <FormControlLabel
                      id={"averageSellOnePrice"}
                      className="m-0"
                      defaultChecked={showAverageSellOnePrice}
                      control={
                        <Switch
                          defaultChecked
                          onChange={(e) => {
                            setShowAverageSellOnePrice(e.target.checked);
                          }}
                          name="gilad"
                        />
                      }
                      label={translate("resources.root.averageSellOnePrice")}
                    />
                  </InputGroup>
                </Col>
              </Col>
              <Col xs={6} md={4} lg={3}>
                <Col>
                  <InputGroup>
                    <FormControlLabel
                      id={"totalSellPrice"}
                      className="m-0"
                      defaultChecked={showTotalSellPrice}
                      control={
                        <Switch
                          defaultChecked
                          onChange={(e) => {
                            setShowTotalSellPrice(e.target.checked);
                          }}
                          name="gilad"
                        />
                      }
                      label={translate("resources.root.totalSellPrice")}
                    />
                  </InputGroup>
                </Col>
              </Col>
              <Col xs={6} md={4} lg={3}>
                <Col>
                  <InputGroup>
                    <FormControlLabel
                      id={"totalSellProfit"}
                      className="m-0"
                      defaultChecked={showTotalSellProfit}
                      control={
                        <Switch
                          defaultChecked
                          onChange={(e) => {
                            setShowTotalSellProfit(e.target.checked);
                          }}
                          name="gilad"
                        />
                      }
                      label={translate("resources.root.totalSellProfit")}
                    />
                  </InputGroup>
                </Col>
              </Col>

              <Col xs={6} md={4} lg={3}>
                <Col>
                  <InputGroup>
                    <FormControlLabel
                      id={"attachment"}
                      className="m-0"
                      defaultChecked={showAttachment}
                      control={
                        <Switch
                          defaultChecked
                          onChange={(e) => {
                            setShowAttachment(e.target.checked);
                          }}
                          name="gilad"
                        />
                      }
                      label={translate("resources.root.attachment")}
                    />
                  </InputGroup>
                </Col>
              </Col>
            </Row>
          </NoPrint>
          <Row className={`mt-2`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    {showAttachment && (
                      <th>{translate("resources.root.attachment")}</th>
                    )}
                    <th>{translate("resources.root.name")}</th>
                    <th>{translate("resources.root.barcode")}</th>
                    {showSellQty && (
                      <th>{translate("resources.root.sellQty")}</th>
                    )}
                    {showSmallMeasureSellQty && (
                      <th>{translate("resources.root.gift")}</th>
                    )}
                    {showDamageQty && (
                      <th>{translate("resources.root.damageQty")}</th>
                    )}
                    {showReturnSellQty && (
                      <th>{translate("resources.root.returns")}</th>
                    )}
                    {showAverageSellPrice && (
                      <th>{translate("resources.root.averageSellPrice")}</th>
                    )}
                    {showAverageSellOnePrice && (
                      <th>{translate("resources.root.averageSellOnePrice")}</th>
                    )}
                    {showTotalSellPrice && (
                      <th>{translate("resources.root.totalSellPrice")}</th>
                    )}
                    {showTotalSellProfit && (
                      <th>{translate("resources.root.totalSellProfit")}</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr
                          style={{
                            backgroundColor:
                              one.mcAveragePurchaseOnePrice >
                                one.mcAverageSellOnePrice &&
                              one.type !== "Purchase"
                                ? "tomato"
                                : "transparent",
                          }}
                        >
                          <td>{one.productId ?? "-"}</td>
                          {showAttachment && (
                            <td>
                              <img src={`${path}${one.attachment}`} />
                            </td>
                          )}
                          <td>
                            {one.productName.length > 50
                              ? one.productName.substring(0, 50).concat("...")
                              : one.productName}
                          </td>
                          <td>{one.productBarcode ?? "-"}</td>
                          {showSellQty && (
                            <td>
                              {one.sellQty ?? "0"} +{" "}
                              {one.smallMeasureSellQty ?? "0"}
                            </td>
                          )}
                          {showSmallMeasureSellQty && (
                            <td>
                              {one.sellGiftQty ?? "0"} +{" "}
                              {one.smallMeasureSellGiftQty ?? "0"}
                            </td>
                          )}
                          {showDamageQty && <td>{one.damageQty ?? "0"}</td>}
                          {showReturnSellQty && (
                            <td>
                              {one.sellReturnQty ?? "0"} +{" "}
                              {one.smallMeasureSellReturnQty ?? "0"}
                            </td>
                          )}
                          {showAverageSellPrice && (
                            <td>
                              {showInUSD
                                ? one.mcAverageSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scAverageSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {showAverageSellOnePrice && (
                            <td>
                              {showInUSD
                                ? one.mcAverageSellOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scAverageSellOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {showTotalSellPrice && (
                            <td>
                              {showInUSD
                                ? one.mcTotalSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scTotalSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {showTotalSellProfit && (
                            <td>
                              {showInUSD
                                ? one.mcTotalSellProfit?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scTotalSellProfit?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
