import * as React from "react";
import { url } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import lodash from "lodash";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";

export default (SupplierItemReport) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [supplier, setSupplier] = React.useState(0);
  const [supplierObject, setSupplierObject] = React.useState([]);
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [mcTotalSell, setMcTotalSell] = React.useState(0);
  const [mcTotalPurchase, setMcTotalPurchase] = React.useState(0);
  const [scTotalSell, setScTotalSell] = React.useState(0);
  const [scTotalPurchase, setScTotalPurchase] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showInUSD, setShowInUSD] = React.useState(false);

  const [dataByType, setDataByType] = React.useState([]);

  const [product, setProduct] = React.useState("All");
  const [products, setProducts] = React.useState([]);

  const [showPurchaseQty, setShowPurchaseQty] = React.useState(true);
  const [showSmallMeasurePurchaseQty, setShowSmallMeasurePurchaseQty] =
    React.useState(true);
  const [showSellQty, setShowSellQty] = React.useState(true);
  const [showSmallMeasureSellQty, setShowSmallMeasureSellQty] =
    React.useState(true);
  const [showAveragePurchasePrice, setShowAveragePurchasePrice] =
    React.useState(true);
  const [showAveragePurchaseOnePrice, setShowAveragePurchaseOnePrice] =
    React.useState(true);
  const [showAverageSellPrice, setShowAverageSellPrice] = React.useState(true);
  const [showAverageSellOnePrice, setShowAverageSellOnePrice] =
    React.useState(true);
  const [showTotalPurchasePrice, setShowTotalPurchasePrice] =
    React.useState(true);
  const [showTotalSellPrice, setShowTotalSellPrice] = React.useState(true);
  const [showTotalSellProfit, setShowTotalSellProfit] = React.useState(true);

  const [mcTotalSellPerPurchasePrice, setMcTotalSellPerPurchasePrice] =
    React.useState(0);
  const [scTotalSellPerPurchasePrice, setScTotalSellPerPurchasePrice] =
    React.useState(0);

  const [totalPurchaseGiftQty, setTotalPurchaseGiftQty] = React.useState(0);
  const [totalPurchaseSmallGiftQty, setTotalPurchaseSmallGiftQty] =
    React.useState(0);
  const [totalSellGiftQty, setTotalSellGiftQty] = React.useState(0);
  const [totalSellSmallGiftQty, setTotalSellSmallGiftQty] = React.useState(0);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;

    if (startDate && startDate.length > 0) {
      sendDates += `&startDate=${startDate}`;
    } else {
      sendDates += `&startDate=${moment().format("YYYY-MM-DD")}`;
    }

    if (endDate && endDate.length > 0) {
      sendDates += `&endDate=${endDate}`;
    } else {
      sendDates += `&endDate=${moment().format("YYYY-MM-DD")}`;
    }

    await HTTP.get(
      `${url}/Reports/GetSupplierItemReport?id=${supplier}&_start=${start}&_end=${end}${sendDates}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        setScTotalPurchase(response.headers.sctotalpurchaseprice);
        setScTotalSell(response.headers.sctotalsellprice);
        setMcTotalPurchase(response.headers.mctotalpurchaseprice);
        setMcTotalSell(response.headers.mctotalsellprice);

        setMcTotalSellPerPurchasePrice(
          response.headers.mctotalsellperpurchaseprice
        );
        setScTotalSellPerPurchasePrice(
          response.headers.sctotalsellperpurchaseprice
        );

        setTotalPurchaseGiftQty(response.headers["totalpurchasegiftqty"]);
        setTotalPurchaseSmallGiftQty(
          response.headers["totalpurchasesmallgiftqty"]
        );
        setTotalSellGiftQty(response.headers["totalsellgiftqty"]);
        setTotalSellSmallGiftQty(response.headers["totalsellsmallgiftqty"]);

        setData(response.data);
        setDataByType(response.data);

        let prod = [{ value: "All", label: "All" }];
        lodash
          .uniqBy(response.data, "productName")
          .map((e) => e.productName)
          .map((e) => prod.push({ value: e, label: e }));
        setProducts(prod);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [supplier, startDate, endDate, start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `${url}/Suppliers?_end=5000&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((supplier) => {
        newArray.push({
          value: supplier.id,
          label: `${supplier.supplierName} (${supplier.phone.replace(
            "+964",
            "0"
          )})`,
          supplier: supplier,
        });
      });
      callback(newArray);
    }
  };

  return (
    <div>
      <PrintProvider>
        <Print single name={`supplierStatement`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.supplierItemReport")}
              {supplier === 0 ? "" : " (" + supplierObject.supplierName + ")"}
            </h1>
            <div className={`d-inline-block`} style={{ float: "inline-end" }}>
              <NoPrint>
                <Button
                  variant={"outline-primary"}
                  onClick={() => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}{" "}
                </Button>
                <div className={`clearfix`}> </div>
              </NoPrint>
            </div>
          </div>
          <NoPrint force>
            {isLoading && <LoadingScreen />}
            <Row style={{ fontSize: "15px" }}>
              <Col xs={1}>
                <label>{translate("resources.root.suppliers")}</label>
              </Col>
              <Col xs={3}>
                <AsyncSelect
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  defaultOptions
                  loadOptions={loadOptions}
                  onChange={(e) => {
                    setSupplier(e ? e.value : 0);
                    setSupplierObject(e ? e.supplier : 0);
                  }}
                />
              </Col>
              <Col xs={4}>
                <Form.Group as={Row} controlId="startDate">
                  <Form.Label column sm={4}>
                    {translate("resources.root.startDate")}
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group as={Row} controlId="endDate">
                  <Form.Label column sm={4}>
                    {translate("resources.root.endDate")}
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={1}>
                <label>{translate("resources.root.products")}</label>
              </Col>
              <Col xs={3}>
                <Select
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={products[0]}
                  name="color"
                  options={products}
                  onChange={(e) => {
                    setData(
                      e.value !== "All"
                        ? e.value !== product
                          ? dataByType.filter((x) => x.productName === e.value)
                          : data
                        : dataByType
                    );
                    setProduct(e.value);
                  }}
                />
              </Col>
              <Col xs={1}> </Col>
              <Col xs={3}>
                <div className="form-check">
                  <input
                    id={"groupProducts"}
                    type="checkbox"
                    onChange={(e) => {
                      e.target.checked &&
                        setData(
                          lodash
                            .chain(data)
                            .groupBy("productId")
                            .map((value, key) => {
                              let mcAveragePurchaseOnePriceList = value;
                              let mcAveragePurchasePriceList = value;
                              let mcAverageSellOnePriceList = value;
                              let mcAverageSellPriceList = value;
                              let scAveragePurchaseOnePriceList = value;
                              let scAveragePurchasePriceList = value;
                              let scAverageSellOnePriceList = value;
                              let scAverageSellPriceList = value;

                              mcAveragePurchaseOnePriceList =
                                mcAveragePurchaseOnePriceList.filter((o) => {
                                  return o.type === "Purchase";
                                });
                              mcAveragePurchasePriceList =
                                mcAveragePurchasePriceList.filter((o) => {
                                  return o.type === "Purchase";
                                });
                              mcAverageSellOnePriceList =
                                mcAverageSellOnePriceList.filter((o) => {
                                  return (
                                    o.type === "Quick Sell" || o.type === "Sell"
                                  );
                                });
                              mcAverageSellPriceList =
                                mcAverageSellPriceList.filter((o) => {
                                  return (
                                    o.type === "Quick Sell" || o.type === "Sell"
                                  );
                                });
                              scAveragePurchaseOnePriceList =
                                scAveragePurchaseOnePriceList.filter((o) => {
                                  return o.type === "Purchase";
                                });
                              scAveragePurchasePriceList =
                                scAveragePurchasePriceList.filter((o) => {
                                  return o.type === "Purchase";
                                });
                              scAverageSellOnePriceList =
                                scAverageSellOnePriceList.filter((o) => {
                                  return (
                                    o.type === "Quick Sell" || o.type === "Sell"
                                  );
                                });
                              scAverageSellPriceList =
                                scAverageSellPriceList.filter((o) => {
                                  return (
                                    o.type === "Quick Sell" || o.type === "Sell"
                                  );
                                });
                              return {
                                productId: key,
                                productName: value[0].productName,
                                productBarcode: value[0].productBarcode,
                                smallMeasurePurchaseQty: lodash.sumBy(
                                  value,
                                  "smallMeasurePurchaseQty"
                                ),
                                purchaseQty: lodash.sumBy(value, "purchaseQty"),
                                sellQty: lodash.sumBy(value, "sellQty"),
                                smallMeasureSellQty: lodash.sumBy(
                                  value,
                                  "smallMeasureSellQty"
                                ),
                                smallMeasurePurchaseReturnQty: lodash.sumBy(
                                  value,
                                  "smallMeasurePurchaseReturnQty"
                                ),
                                purchaseReturnQty: lodash.sumBy(
                                  value,
                                  "purchaseReturnQty"
                                ),
                                sellReturnQty: lodash.sumBy(
                                  value,
                                  "sellReturnQty"
                                ),
                                smallMeasureSellReturnQty: lodash.sumBy(
                                  value,
                                  "smallMeasureSellReturnQty"
                                ),

                                ///// type === purchase
                                mcAveragePurchaseOnePrice: lodash.meanBy(
                                  scAveragePurchaseOnePriceList,
                                  "mcAveragePurchaseOnePrice"
                                ),
                                scAveragePurchaseOnePrice: lodash.meanBy(
                                  mcAveragePurchaseOnePriceList,
                                  "scAveragePurchaseOnePrice"
                                ),

                                mcAveragePurchasePrice: lodash.meanBy(
                                  mcAveragePurchasePriceList,
                                  "mcAveragePurchasePrice"
                                ),
                                scAveragePurchasePrice: lodash.meanBy(
                                  scAveragePurchasePriceList,
                                  "scAveragePurchasePrice"
                                ),

                                ///// type === quick sell or sell
                                mcAverageSellOnePrice: lodash.meanBy(
                                  mcAverageSellOnePriceList,
                                  "mcAverageSellOnePrice"
                                ),
                                scAverageSellOnePrice: lodash.meanBy(
                                  scAverageSellOnePriceList,
                                  "scAverageSellOnePrice"
                                ),

                                mcAverageSellPrice: lodash.meanBy(
                                  mcAverageSellPriceList,
                                  "mcAverageSellPrice"
                                ),
                                scAverageSellPrice: lodash.meanBy(
                                  scAverageSellPriceList,
                                  "scAverageSellPrice"
                                ),

                                mcTotalSellPrice: lodash.sumBy(
                                  value,
                                  "mcTotalSellPrice"
                                ),
                                scTotalSellPrice: lodash.sumBy(
                                  value,
                                  "scTotalSellPrice"
                                ),
                                mcTotalPurchasePrice: lodash.sumBy(
                                  value,
                                  "mcTotalPurchasePrice"
                                ),
                                scTotalPurchasePrice: lodash.sumBy(
                                  value,
                                  "scTotalPurchasePrice"
                                ),
                                mcTotalSellProfit: lodash.sumBy(
                                  value,
                                  "mcTotalSellProfit"
                                ),
                                scTotalSellProfit: lodash.sumBy(
                                  value,
                                  "scTotalSellProfit"
                                ),
                                type: "-",
                              };
                            })
                            .value()
                        );
                    }}
                    defaultChecked={false}
                  />
                  <label
                    className="form-check-label mb-3"
                    htmlFor="groupProducts"
                  >
                    {translate("resources.root.groupProducts")}
                  </label>
                </div>
              </Col>
              <Col xs={3}>
                <div className="form-check">
                  <input
                    id={"showInUSD"}
                    type="checkbox"
                    onChange={(e) => setShowInUSD(e.target.checked)}
                    defaultChecked={showInUSD}
                  />
                  <label className="form-check-label mb-3" htmlFor="showInUSD">
                    {translate("resources.root.showInUSD")}
                  </label>
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12}>
                <Row>
                  <Col xs={3}>
                    {translate("resources.root.totalSellPrice")}:{" "}
                    <b>
                      {parseFloat(mcTotalSell)?.toLocaleString() +
                        " " +
                        translate("resources.root.usd")}
                    </b>
                  </Col>
                  <Col xs={3}>
                    {translate("resources.root.totalPurchasePrice")}:{" "}
                    <b>
                      {parseFloat(mcTotalPurchase)?.toLocaleString() +
                        " " +
                        translate("resources.root.usd")}
                    </b>
                  </Col>
                  <Col xs={3}>
                    {translate("resources.root.totalSellPrice")}:{" "}
                    <b>
                      {parseFloat(scTotalSell)?.toLocaleString() +
                        " " +
                        translate("resources.root.iqd")}
                    </b>
                  </Col>
                  <Col xs={3}>
                    {translate("resources.root.totalPurchasePrice")}:{" "}
                    <b>
                      {parseFloat(scTotalPurchase)?.toLocaleString() +
                        " " +
                        translate("resources.root.iqd")}
                    </b>
                  </Col>
                  <Col xs={3}>
                    {translate("resources.root.totalSellPerPurchasePrice")}:{" "}
                    <b>
                      {parseFloat(
                        mcTotalSellPerPurchasePrice
                      )?.toLocaleString() +
                        " " +
                        translate("resources.root.usd")}
                    </b>
                  </Col>
                  <Col xs={3}>
                    {translate("resources.root.totalSellPerPurchasePrice")}:{" "}
                    <b>
                      {parseFloat(
                        scTotalSellPerPurchasePrice
                      )?.toLocaleString() +
                        " " +
                        translate("resources.root.iqd")}
                    </b>
                  </Col>
                  <Col xs={3}>
                    {translate("resources.root.totalPurchaseGiftQty")}:{" "}
                    {totalPurchaseGiftQty &&
                      parseFloat(totalPurchaseGiftQty)?.toLocaleString()}
                    &nbsp;+&nbsp;
                    {totalPurchaseSmallGiftQty &&
                      parseFloat(totalPurchaseSmallGiftQty)?.toLocaleString()}
                  </Col>
                  <Col xs={3}>
                    {translate("resources.root.totalSellGiftQty")}:{" "}
                    {totalSellGiftQty &&
                      parseFloat(totalSellGiftQty)?.toLocaleString()}
                    &nbsp;+&nbsp;
                    {totalSellSmallGiftQty &&
                      parseFloat(totalSellSmallGiftQty)?.toLocaleString()}
                  </Col>
                </Row>
                <br />
              </Col>
              <Col xs={12}>
                <Row>
                  <Col xs={1}>
                    <div className="form-check">
                      <input
                        id={"purchaseQty"}
                        type="checkbox"
                        onChange={(e) => setShowPurchaseQty(e.target.checked)}
                        defaultChecked={showPurchaseQty}
                      />
                      <label
                        className="form-check-label mb-3"
                        htmlFor="purchaseQty"
                      >
                        {translate("resources.root.purchaseQty")}
                      </label>
                    </div>
                  </Col>
                  <Col xs={1}>
                    <div className="form-check">
                      <input
                        id={"smallMeasurePurchaseQty"}
                        type="checkbox"
                        onChange={(e) =>
                          setShowSmallMeasurePurchaseQty(e.target.checked)
                        }
                        defaultChecked={showSmallMeasurePurchaseQty}
                      />
                      <label
                        className="form-check-label mb-3"
                        htmlFor="smallMeasurePurchaseQty"
                      >
                        {translate("resources.root.smallMeasurePurchaseQty")}
                      </label>
                    </div>
                  </Col>
                  <Col xs={1}>
                    <div className="form-check">
                      <input
                        id={"sellQty"}
                        type="checkbox"
                        onChange={(e) => setShowSellQty(e.target.checked)}
                        defaultChecked={showSellQty}
                      />
                      <label
                        className="form-check-label mb-3"
                        htmlFor="sellQty"
                      >
                        {translate("resources.root.sellQty")}
                      </label>
                    </div>
                  </Col>
                  <Col xs={1}>
                    <div className="form-check">
                      <input
                        id={"smallMeasureSellQty"}
                        type="checkbox"
                        onChange={(e) =>
                          setShowSmallMeasureSellQty(e.target.checked)
                        }
                        defaultChecked={showSmallMeasureSellQty}
                      />
                      <label
                        className="form-check-label mb-3"
                        htmlFor="smallMeasureSellQty"
                      >
                        {translate("resources.root.smallMeasureSellQty")}
                      </label>
                    </div>
                  </Col>
                  <Col xs={1}>
                    <div className="form-check">
                      <input
                        id={"averagePurchasePrice"}
                        type="checkbox"
                        onChange={(e) =>
                          setShowAveragePurchasePrice(e.target.checked)
                        }
                        defaultChecked={showAveragePurchasePrice}
                      />
                      <label
                        className="form-check-label mb-3"
                        htmlFor="averagePurchasePrice"
                      >
                        {translate("resources.root.averagePurchasePrice")}
                      </label>
                    </div>
                  </Col>
                  <Col xs={1}>
                    <div className="form-check">
                      <input
                        id={"averagePurchaseOnePrice"}
                        type="checkbox"
                        onChange={(e) =>
                          setShowAveragePurchaseOnePrice(e.target.checked)
                        }
                        defaultChecked={showAveragePurchaseOnePrice}
                      />
                      <label
                        className="form-check-label mb-3"
                        htmlFor="averagePurchaseOnePrice"
                      >
                        {translate("resources.root.averagePurchaseOnePrice")}
                      </label>
                    </div>
                  </Col>
                  <Col xs={1}>
                    <div className="form-check">
                      <input
                        id={"averageSellPrice"}
                        type="checkbox"
                        onChange={(e) =>
                          setShowAverageSellPrice(e.target.checked)
                        }
                        defaultChecked={showAverageSellPrice}
                      />
                      <label
                        className="form-check-label mb-3"
                        htmlFor="averageSellPrice"
                      >
                        {translate("resources.root.averageSellPrice")}
                      </label>
                    </div>
                  </Col>
                  <Col xs={1}>
                    <div className="form-check">
                      <input
                        id={"averageSellOnePrice"}
                        type="checkbox"
                        onChange={(e) =>
                          setShowAverageSellOnePrice(e.target.checked)
                        }
                        defaultChecked={showAverageSellOnePrice}
                      />
                      <label
                        className="form-check-label mb-3"
                        htmlFor="averageSellOnePrice"
                      >
                        {translate("resources.root.averageSellOnePrice")}
                      </label>
                    </div>
                  </Col>
                  <Col xs={1}>
                    <div className="form-check">
                      <input
                        id={"totalPurchasePrice"}
                        type="checkbox"
                        onChange={(e) =>
                          setShowTotalPurchasePrice(e.target.checked)
                        }
                        defaultChecked={showTotalPurchasePrice}
                      />
                      <label
                        className="form-check-label mb-3"
                        htmlFor="totalPurchasePrice"
                      >
                        {translate("resources.root.totalPurchasePrice")}
                      </label>
                    </div>
                  </Col>
                  <Col xs={1}>
                    <div className="form-check">
                      <input
                        id={"totalSellPrice"}
                        type="checkbox"
                        onChange={(e) =>
                          setShowTotalSellPrice(e.target.checked)
                        }
                        defaultChecked={showTotalSellPrice}
                      />
                      <label
                        className="form-check-label mb-3"
                        htmlFor="totalSellPrice"
                      >
                        {translate("resources.root.totalSellPrice")}
                      </label>
                    </div>
                  </Col>
                  <Col xs={1}>
                    <div className="form-check">
                      <input
                        id={"totalSellProfit"}
                        type="checkbox"
                        onChange={(e) =>
                          setShowTotalSellProfit(e.target.checked)
                        }
                        defaultChecked={showTotalSellProfit}
                      />
                      <label
                        className="form-check-label mb-3"
                        htmlFor="totalSellProfit"
                      >
                        {translate("resources.root.totalSellProfit")}
                      </label>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={4}>
                <Button
                  id="export"
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>
            </Row>
          </NoPrint>
          <Row style={{ fontSize: "15px" }} className={`mt-2`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.name")}</th>
                    <th>{translate("resources.root.barcode")}</th>
                    {showPurchaseQty && (
                      <th>{translate("resources.root.purchaseQty")}</th>
                    )}
                    {showSmallMeasurePurchaseQty && (
                      <th>
                        {translate("resources.root.smallMeasurePurchaseQty")}
                      </th>
                    )}
                    {showSellQty && (
                      <th>{translate("resources.root.sellQty")}</th>
                    )}
                    {showSmallMeasureSellQty && (
                      <th>{translate("resources.root.smallMeasureSellQty")}</th>
                    )}
                    {showAveragePurchasePrice && (
                      <th>
                        {translate("resources.root.averagePurchasePrice")}
                      </th>
                    )}
                    {showAveragePurchaseOnePrice && (
                      <th>
                        {translate("resources.root.averagePurchaseOnePrice")}
                      </th>
                    )}
                    {showAverageSellPrice && (
                      <th>{translate("resources.root.averageSellPrice")}</th>
                    )}
                    {showAverageSellOnePrice && (
                      <th>{translate("resources.root.averageSellOnePrice")}</th>
                    )}
                    {showTotalPurchasePrice && (
                      <th>{translate("resources.root.totalPurchasePrice")}</th>
                    )}
                    {showTotalSellPrice && (
                      <th>{translate("resources.root.totalSellPrice")}</th>
                    )}
                    {showTotalSellProfit && (
                      <th>{translate("resources.root.totalSellProfit")}</th>
                    )}
                    <th>{translate("resources.root.type")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr
                          style={{
                            backgroundColor:
                              one.mcAveragePurchaseOnePrice >
                                one.mcAverageSellOnePrice &&
                              one.type !== "Purchase"
                                ? "tomato"
                                : "transparent",
                          }}
                        >
                          <td>{one.productId ?? "-"}</td>
                          <td>
                            {one.productName.length > 50
                              ? one.productName.substring(0, 50).concat("...")
                              : one.productName}
                          </td>
                          <td>
                            <a
                              href={`#/Products/${one.id}/show`}
                              target={"_blank"}
                            >
                              {one.productBarcode ?? "-"}
                            </a>
                          </td>
                          {showPurchaseQty && <td>{one.purchaseQty ?? "0"}</td>}
                          {showSmallMeasurePurchaseQty && (
                            <td>{one.smallMeasurePurchaseQty ?? "0"}</td>
                          )}
                          {showSellQty && <td>{one.sellQty ?? "0"}</td>}
                          {showSmallMeasureSellQty && (
                            <td>{one.smallMeasureSellQty ?? "0"}</td>
                          )}
                          {showAveragePurchasePrice && (
                            <td>
                              {showInUSD
                                ? one.mcAveragePurchasePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scAveragePurchasePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {showAveragePurchaseOnePrice && (
                            <td>
                              {showInUSD
                                ? one.mcAveragePurchaseOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scAveragePurchaseOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {showAverageSellPrice && (
                            <td>
                              {showInUSD
                                ? one.mcAverageSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scAverageSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {showAverageSellOnePrice && (
                            <td>
                              {showInUSD
                                ? one.mcAverageSellOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scAverageSellOnePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {showTotalPurchasePrice && (
                            <td>
                              {showInUSD
                                ? one.mcTotalPurchasePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scTotalPurchasePrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {showTotalSellPrice && (
                            <td>
                              {showInUSD
                                ? one.mcTotalSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scTotalSellPrice?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          {showTotalSellProfit && (
                            <td>
                              {showInUSD
                                ? one.mcTotalSellProfit?.toLocaleString() +
                                    " " +
                                    translate("resources.root.usd") ?? "-"
                                : one.scTotalSellProfit?.toLocaleString() +
                                    " " +
                                    translate("resources.root.iqd") ?? "-"}
                            </td>
                          )}
                          <td>{one.type ?? "-"}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
