import * as React from "react";
import {
  CustomRoutes,
  fetchUtils,
  Admin,
  Resource,
  defaultTheme,
  Pagination,
  useTranslate,
  TextInput,
  DateInput,
  Filter,
  SelectInput,
  BooleanInput,
  NullableBooleanInput,
} from "react-admin";
import authProvider from "./authProvider";
import jsonServerProvider from "ra-data-json-server";
import { url } from "./request";
import "./index.css";
import "./App.css";
import merge from "lodash/merge";
import {
  BranchesList,
  BranchesCreate,
  BranchesEdit,
} from "./components/Branches";
import { StoresList, StoresCreate, StoresEdit } from "./components/Store";
import {
  WithdrawsTypesList,
  WithdrawsTypesCreate,
  WithdrawsTypesEdit,
} from "./components/accountant/WithdrawsTypes";
import {
  UploadCentersList,
  UploadCentersCreate,
  UploadCentersEdit,
} from "./components/accountant/UploadCenters";
import {
  DepositsTypesList,
  DepositsTypesCreate,
  DepositsTypesEdit,
} from "./components/accountant/DepositsType";
import {
  ExpenseTypesList,
  ExpenseTypesCreate,
  ExpenseTypesEdit,
} from "./components/accountant/ExpenseTypes";
import {
  ExpensesList,
  ExpensesCreate,
  ExpensesShow,
  ExpensesEdit,
} from "./components/accountant/Expenses";
import {
  SellInvoicesList,
  SellInvoicesEdit,
  SellInvoicesShow,
  SellInvoicesCreate,
} from "./components/invoices/SellInvoice";
import {
  SellReturnInvoicesList,
  SellReturnInvoicesCreate,
  SellReturnInvoicesShow,
} from "./components/invoices/SellReturnInvoice";
import {
  PurchasesList,
  PurchasesCreate,
  PurchasesShow,
  PurchasesEdit,
} from "./components/purchases/Purchases";
import {
  PurchaseReturnsList,
  PurchaseReturnsCreate,
  PurchaseReturnsShow,
} from "./components/purchases/PurchaseReturns";
import {
  CustomersList,
  CustomersCreate,
  CustomersEdit,
  CustomersShow,
} from "./components/customers/Customers";
import {
  EmployeesList,
  EmployeesCreate,
  EmployeesEdit,
  EmployeesShow,
} from "./components/employees/Employees";
import {
  EmployeeLoansList,
  EmployeeLoansCreate,
  EmployeeLoansShow,
} from "./components/employees/EmployeeLoans";
import {
  EmployeeReturnLoansList,
  EmployeeReturnLoansCreate,
  EmployeeReturnLoansShow,
} from "./components/employees/EmployeeReturnLoans";
import {
  EmployeeAbsentsList,
  EmployeeAbsentsCreate,
} from "./components/employees/EmployeeAbsents";
import {
  EmployeeRewardsList,
  EmployeeRewardsCreate,
} from "./components/employees/EmployeeRewards";
import {
  EmployeePunishmentsList,
  EmployeePunishmentsCreate,
} from "./components/employees/EmployeePunishments";
import {
  EmployeePermissionsList,
  EmployeePermissionsCreate,
} from "./components/employees/EmployeePermissions";
import {
  EmployeeSalaryPaysList,
  EmployeeSalaryPaysCreate,
} from "./components/employees/EmployeeSalaryPays";
import { SalarySummaryList } from "./components/employees/SalarySummary";
import { UserNamesList, UserNamesShow } from "./components/settings/userNames";
import {
  CurrencyExchangesList,
  CurrencyExchangesCreate,
  CurrencyExchangesShow,
} from "./components/accountant/CurrencyExchanges";
import {
  CustomerPaymentsList,
  CustomerPaymentsCreate,
  CustomerPaymentsShow,
  CustomerPaymentsEdit,
} from "./components/customers/CustomerPayments";
import {
  RepresentativePaymentsList,
  RepresentativePaymentsShow,
} from "./components/representatives/RepresentativePayment";
import {
  RepresentativeCustomerPaymentsList,
  RepresentativeCustomerPaymentsShow,
} from "./components/representatives/RepresentativeCustomerPayments";
import {
  SupplierList,
  SupplierCreate,
  SupplierEdit,
  SupplierShow,
} from "./components/suppliers/Supplier";
import {
  SupplierPaymentsList,
  SupplierPaymentsCreate,
  SupplierPaymentsShow,
  SupplierPaymentsEdit,
} from "./components/suppliers/SupplierPayments";
import {
  BankAccountsList,
  BankAccountsCreate,
  BankAccountsEdit,
  BankAccountsShow,
} from "./components/bankAccounts/BankAccounts";
import {
  BankAccountTransfersList,
  BankAccountTransfersCreate,
  BankAccountTransfersShow,
} from "./components/bankAccounts/BankAccountTransfers";
import {
  DepositsList,
  DepositsCreate,
  DepositsShow,
} from "./components/accountant/Deposits";
import {
  ToDoList,
  ToDoCreate,
  ToDoShow,
  ToDoEdit,
} from "./components/toDoList/ToDoList";
import {
  RepresentativesList,
  RepresentativesCreate,
  RepresentativesEdit,
  RepresentativesShow,
} from "./components/representatives/Representatives";
import {
  RepresentativeInvoicesList,
  RepresentativeInvoicesCreate,
  RepresentativeInvoicesShow,
} from "./components/representatives/RepresentativeInvoices";
import {
  RepresentativeReturnInvoicesList,
  RepresentativeReturnInvoicesCreate,
  RepresentativeReturnInvoicesShow,
} from "./components/representatives/RepresentativeReturnInvoices";
import {
  RepresentativePreInvoicesList,
  RepresentativePreInvoicesShow,
} from "./components/representatives/RepresentativePreInvoice";
import {
  RepresentativeCustomersList,
  RepresentativeCustomersCreate,
  RepresentativeCustomersEdit,
} from "./components/representatives/RepresentativeCustomers";
import {
  WithdrawsList,
  WithdrawsCreate,
  WithdrawsShow,
} from "./components/accountant/Withdraws";
import {
  ProductCategoriesList,
  ProductCategoriesCreate,
  ProductCategoriesEdit,
} from "./components/products/ProductCategories";
import {
  ProductsList,
  ProductsEdit,
  ProductsShow,
} from "./components/products/Products";
import {
  ProductDamageTypesList,
  ProductDamageTypesCreate,
  ProductDamageTypesEdit,
} from "./components/products/ProductDamageTypes";
import {
  ProcessInvoicesList,
  ProcessInvoicesCreate,
  ProcessInvoicesShow,
} from "./components/products/ProcessInvoices";
import {
  ProductDamagesList,
  ProductDamagesCreate,
  ProductDamagesShow,
} from "./components/products/ProductDamages";
import {
  StoreTransferInvoicesList,
  StoreTransferInvoicesCreate,
  StoreTransferInvoicesShow,
} from "./components/products/StoreTransferInvoices";
import polyglotI18nProvider from "ra-i18n-polyglot";
import MyLayout from "./components/AppBar";
import Settings from "./components/Settings";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { kurdishMessages } from "./i18n/ku";
import { englishMessages } from "./i18n/en";
import Dashboard from "./Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toggle/style.css";
import {
  RepresentativeCustomerSellInvoicesList,
  RepresentativeCustomerSellInvoicesShow,
} from "./components/representatives/RepresentativeCustomerSellInvoices";
import { RepresentativeCustomerChecksList } from "./components/representatives/RepresentativeCustomerChecks";
import { StoreTransactionHistoriesList } from "./components/accountant/StoreTransactionHistories";
import { ProductsCreate } from "./components/products/ProductCreate";
import {
  BrandsCreate,
  BrandsEdit,
  BrandsList,
} from "./components/brands/Brands";
import { NoPrint } from "react-easy-print";
import {
  CashierWithdrawsCreate,
  CashierWithdrawsList,
} from "./components/cashiers/CashierWithdraws";
import { HTTP } from "./axios";
import {
  EmployeeSalaryChangesCreate,
  EmployeeSalaryChangesList,
} from "./components/employees/EmployeeSalaryChange";
import {
  StoreInventoryInvoiceCreate,
  StoreInventoryInvoiceEdit,
  StoreInventoryInvoiceList,
  StoreInventoryInvoiceShow,
} from "./components/storeInventoryInvoice/StoreInventoryInvoice";
import { QuickSellInvoicesList } from "./components/QuickSellInvoices/QuickSellInvoicesList";
import { QuickSellReturnInvoicesList } from "./components/QuickSellInvoices/QuickSellReturnInvoicesList";
import { GetLogs } from "./components/CustomPages/GetLogs";
import { Route } from "react-router-dom";

// Custom Routes
import Pos from "./components/CustomPages/Pos";
import Profile from "./components/Profile";
import ProductStatement from "./components/reports/ProductStatement";
import CustomerStatement from "./components/reports/CustomerStatement";
import SupplierStatement from "./components/reports/SupplierStatement";
import RepresentativeStatement from "./components/reports/RepresentativeStatement";
import StockReport from "./components/reports/StockReport";
import ExpireReport from "./components/reports/ExpireReport";
import SellInvoiceReport from "./components/reports/SellInvoiceReport";
import PurchaseReport from "./components/reports/PurchaseReport";
import ExpenseReport from "./components/reports/ExpenseReport";
import CustomerReport from "./components/reports/CustomerReport";
import SupplierReport from "./components/reports/SupplierReport";
import OrdersReport from "./components/reports/OrdersReport";
import SupplierItemReport from "./components/reports/SupplierItemReport";
import ItemSupplierReport from "./components/reports/ItemSupplierReport";
import SupplierPaymentReport from "./components/reports/SupplierPaymentReport";
import CustomerPaymentReport from "./components/reports/CustomerPaymentReport";
import EmployeeAccountStatement from "./components/HrReports/EmployeeAccountStatement";
import LoanReport from "./components/HrReports/LoanReport";
import LoanReturnReport from "./components/HrReports/LoanReturnReport";
import AbsentReport from "./components/HrReports/AbsentReport";
import RewardReport from "./components/HrReports/RewardReport";
import PunishmentReport from "./components/HrReports/PunishmentReport";
import PermissionReport from "./components/HrReports/PermissionReport";
import SalaryPayReport from "./components/HrReports/SalaryPayReport";
import BalanceSheet from "./components/accountant/BalanceSheet";
import ReportStock from "./components/reports/ReportStock";
import NotSaleReport from "./components/reports/NotSaleReport";
import SupplierStockRemain from "./components/reports/SupplierStockRemain";
import SupplierStockFullReport from "./components/reports/SupplierStockFullReport";
import SupplierSellReport from "./components/reports/SupplierSellReport";
import BrandItemReport from "./components/reports/BrandItemReport";
import { CashierSellsReport } from "./components/reports/CashierSellsReport";
import InvoiceMockup from "./components/CustomPages/InvoiceMockup";
import { VoucherMockup } from "./components/CustomPages/VoucherMockup";
import { ProductLabel } from "./components/CustomPages/ProductLabel";
import { ProductOffers } from "./components/CustomPages/ProductOffers";
import { ProductStick } from "./components/CustomPages/ProductStick";
import CashierSells from "./components/cashiers/CashierSells";
import SellInfo from "./components/cashiers/SellInfo";
import SellReceipt from "./components/CustomPages/SellReceipt/SellReceipt";
import VoucherReceipt from "./components/CustomPages/VoucherReceipt/VoucherReceipt";
import ProfitLose from "./components/adminReports/ProfitLose";
import InvoiceProfitLose from "./components/adminReports/InvoiceProfitLose";
import SupplierStatementById from "./components/reports/SupplierStatementById";
import CustomerStatementById from "./components/reports/CustomerStatementById";
import ProductStatementById from "./components/reports/ProductStatementById";
import RepresentativeCustomerStatementById from "./components/reports/RepresentativeCustomerStatementById";
import { NewInvoice } from "./components/CustomPages/NewInvoice";
import { PrintInvoice } from "./components/CustomPages/PrintInvoice";
import { PrintInvoiceOrders } from "./components/CustomPages/PrintInvoiceOrders";
import { PrintQuickSellInvoice } from "./components/CustomPages/PrintQuckSellInvoice";
import NewDashboard from "./components/NewDashboard";
import CustomerProfitLose from "./components/adminReports/CustomerProfitLose";
import ProductSellsInvoice from "./components/reports/ProductSellsInvoice";
import { css } from "@emotion/react";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
    options.headers.set("Ratio", localStorage.getItem("dollarPrice"));
    options.headers.set(
      "language",
      localStorage.getItem("locale") === "kr" ? "Kurdish" : "English"
    );
    options.headers.set("storeid", localStorage.getItem("storeId") ?? 1);
    localStorage.getItem("startDate") &&
      options.headers.set("startDate", localStorage.getItem("startDate"));
    localStorage.getItem("endDate") &&
      options.headers.set("endDate", localStorage.getItem("endDate"));
  }
  const token = localStorage.getItem("token");

  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(`${url}`, httpClient);

export const MyCustomPagination = () => (
  <NoPrint>
    <Pagination
      variant={"outlined"}
      rowsPerPageOptions={[10, 15, 50, 100, 150]}
      defaultValue={10}
    />
  </NoPrint>
);

export const SearchFilter = (props) => {
  const translate = useTranslate();
  const [brands, setBrands] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [cashiers, setCashiers] = React.useState([]);
  const [names, setNames] = React.useState([]);

  React.useEffect(function () {
    if (props.brand) {
      HTTP.get(`${url}/Brands?_end=5000`).then((res) => {
        let brandList = [];
        res.data.map((x) => {
          brandList.push({ id: x.id, name: x.name });
        });
        setBrands(brandList);
      });
    }
    if (props.category) {
      HTTP.get(`${url}/ProductCategories?_end=5000`).then((res) => {
        let categoryList = [];
        res.data.map((x) => {
          categoryList.push({ id: x.id, name: x.name });
        });
        setCategories(categoryList);
      });
    }
    if (props.cashiers) {
      HTTP.get(`${url}/UserStoreBankAccounts?_end=5000`).then((response) => {
        let cashierList = [];
        response.data.map((x) => {
          cashierList.push({
            id: x.id,
            name: x.firstName + " " + x.middleName,
          });
        });
        setCashiers(cashierList);
      });
    }
    if (props.names) {
      HTTP.get(`${url}/QuickSellInvoices/GetDistinctNames?_end=5000`).then(
        (response) => {
          let nameList = [];
          response.data.map((x) => {
            nameList.push({ id: x, name: x });
          });
          setNames(nameList);
        }
      );
    }
  }, []);

  return (
    <NoPrint>
      <Filter {...props}>
        {props.cashiers && (
          <SelectInput
            source="subLevelStringId"
            label="resources.root.cashiers"
            choices={cashiers}
            alwaysOn
          />
        )}
        {props.names && (
          <SelectInput
            source="name"
            label="resources.root.name"
            choices={names}
            alwaysOn
          />
        )}
        <TextInput label="resources.root.search" source="search" alwaysOn />
        {props.driver && (
          <TextInput label="resources.root.driver" source="driver" alwaysOn />
        )}
        {props.date && (
          <DateInput
            label="resources.root.startDate"
            source="startDate"
            alwaysOn
          />
        )}
        {props.date && (
          <DateInput label="resources.root.endDate" source="endDate" alwaysOn />
        )}

        {props.salary && (
          <SelectInput
            source="salaryType"
            label="resources.root.salaryType"
            alwaysOn
            choices={[
              { id: "monthly", name: translate("resources.root.monthly") },
              {
                id: "weekly",
                name: translate("resources.root.weekly"),
              },
            ]}
          />
        )}

        {props.category && (
          <SelectInput
            source="subLevelId"
            label="resources.root.category"
            alwaysOn
            choices={categories}
          />
        )}

        {props.brand && (
          <SelectInput
            source="brandId"
            label="resources.root.brand"
            alwaysOn
            choices={brands}
          />
        )}

        {props.loses && (
          <BooleanInput
            source="loses"
            label="resources.root.onlyLosses"
            alwaysOn
          />
        )}

        {props.fullDiscount && (
          <NullableBooleanInput
            source="fullDiscount"
            label="resources.root.fullDiscount"
          />
        )}
      </Filter>
    </NoPrint>
  );
};

const myDataProfider = {
  ...dataProvider,
  create: (resource, params) => {
    if (!params.data.attachment) {
      return dataProvider.create(resource, params);
    }
    return toBase64(params.data.attachment.rawFile).then((res) => {
      return httpClient(`${url}/${resource}`, {
        method: "POST",
        body: JSON.stringify({
          ...params.data,
          attachment: res,
        }),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    });
  },
  update: (resource, params) => {
    if (params.data.attachment && params.data.attachment.title) {
      return toBase64(params.data.attachment.rawFile).then((res) => {
        return httpClient(`${url}/${resource}/${params.id}`, {
          method: "PUT",
          body: JSON.stringify({
            ...params.data,
            attachment: res,
          }),
        }).then(({ json }) => ({
          data: { ...params.data, id: json.id },
        }));
      });
    } else {
      return dataProvider.update(resource, params);
    }
  },
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const messages = {
  kr: kurdishMessages,
  en: englishMessages,
};

const locale = localStorage.getItem("locale");
const i18nProvider = polyglotI18nProvider(
  (locale) => messages[locale],
  locale ? locale : "en"
);

const App = () => {
  const [direction, setDirection] = React.useState(
    localStorage.getItem("direction")
      ? localStorage.getItem("direction")
      : "ltr"
  );
  const [mode, setMode] = React.useState("dark");

  const alterDir = (dir) => {
    setDirection(dir);
    localStorage.setItem("direction", dir);
    setMode("dark");
    window.location.reload();
  };

  const myTheme = merge({}, defaultTheme, {
    typography: {
      fontFamily: "Nunito, Speda, NRT-Reg",
      textAlign: "start",
      fontSize: parseFloat(localStorage.getItem("systemFontSize")),
      fontWeight: 900,
      fontStyle: "bold",
    },
    direction,
    palette: {
      type: mode, // Switching the dark mode on is a single property value change.
    },
  });

  const role = localStorage.getItem("role") ?? "NoUser";

  if (role.toLowerCase() === "debtcollector") {
    return (
      <div dir={direction} className={"theRoot"}>
        <Admin
          dashboard={Dashboard}
          // dashboard={NewDashboard}
          authProvider={authProvider}
          dataProvider={myDataProfider}
          i18nProvider={i18nProvider}
          layout={MyLayout}
          theme={myTheme}
        >
          <Resource
            name="Stores"
            list={StoresList}
            create={StoresCreate}
            edit={StoresEdit}
            options={{ alterDir, direction }}
          />
        </Admin>
      </div>
    );
  } else {
    return (
      <div dir={direction} className={"theRoot"}>
        <Admin
          dashboard={Dashboard}
          // dashboard={NewDashboard}
          authProvider={authProvider}
          dataProvider={myDataProfider}
          i18nProvider={i18nProvider}
          layout={MyLayout}
          theme={myTheme}
        >
          <Resource
            name="Branches"
            list={BranchesList}
            create={BranchesCreate}
            edit={BranchesEdit}
            options={{ alterDir, direction }}
          />
          <Resource
            name="Stores"
            list={StoresList}
            create={StoresCreate}
            edit={StoresEdit}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
          <Resource
            name="Deposits"
            list={DepositsList}
            create={DepositsCreate}
            show={DepositsShow}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
          <Resource
            name="Withdraws"
            list={WithdrawsList}
            create={WithdrawsCreate}
            show={WithdrawsShow}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
          <Resource
            name="Employees"
            list={EmployeesList}
            create={EmployeesCreate}
            edit={EmployeesEdit}
            show={EmployeesShow}
            options={{ alterDir, direction }}
          />
          <Resource
            name="Employeeloans"
            list={EmployeeLoansList}
            create={EmployeeLoansCreate}
            show={EmployeeLoansShow}
            options={{ alterDir, direction }}
          />
          <Resource
            name="EmployeeReturnLoans"
            list={EmployeeReturnLoansList}
            create={EmployeeReturnLoansCreate}
            show={EmployeeReturnLoansShow}
            options={{ alterDir, direction }}
          />
          <Resource
            name="EmployeeAbsents"
            list={EmployeeAbsentsList}
            create={EmployeeAbsentsCreate}
            options={{ alterDir, direction }}
          />
          <Resource
            name="EmployeeRewards"
            list={EmployeeRewardsList}
            create={EmployeeRewardsCreate}
            options={{ alterDir, direction }}
          />
          <Resource
            name="EmployeePunishments"
            list={EmployeePunishmentsList}
            create={EmployeePunishmentsCreate}
            options={{ alterDir, direction }}
          />
          <Resource
            name="EmployeeAllows"
            list={EmployeePermissionsList}
            create={EmployeePermissionsCreate}
            options={{ alterDir, direction }}
          />
          <Resource
            name="EmployeeSalarySummary"
            list={SalarySummaryList}
            options={{ alterDir, direction }}
          />
          <Resource
            name="EmployeeSalaryPays"
            list={EmployeeSalaryPaysList}
            create={EmployeeSalaryPaysCreate}
            options={{ alterDir, direction }}
          />
          <Resource
            name="Users"
            list={UserNamesList}
            show={UserNamesShow}
            options={{ alterDir, direction }}
          />
          <Resource
            name="Customers"
            list={CustomersList}
            create={CustomersCreate}
            edit={CustomersEdit}
            show={CustomersShow}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
          <Resource
            name="CurrencyExchanges"
            list={CurrencyExchangesList}
            create={CurrencyExchangesCreate}
            show={CurrencyExchangesShow}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
          <Resource
            name="CustomerPayments"
            list={CustomerPaymentsList}
            create={CustomerPaymentsCreate}
            show={CustomerPaymentsShow}
            edit={CustomerPaymentsEdit}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
          <Resource
            name="QuickSellInvoices"
            list={QuickSellInvoicesList}
            hascreate={false}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
          <Resource
            name="QuickSellReturnInvoices"
            list={QuickSellReturnInvoicesList}
            options={{ alterDir, direction }}
          />
          <Resource
            name="RepresentativePayments"
            list={RepresentativePaymentsList}
            // create={RepresentativePaymentsCreate}
            show={RepresentativePaymentsShow}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
          <Resource
            name="RepresentativeCustomerPayments"
            list={RepresentativeCustomerPaymentsList}
            show={RepresentativeCustomerPaymentsShow}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
          <Resource
            name="Suppliers"
            list={SupplierList}
            create={SupplierCreate}
            edit={SupplierEdit}
            show={SupplierShow}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
          <Resource
            name="SupplierPayments"
            list={SupplierPaymentsList}
            create={SupplierPaymentsCreate}
            show={SupplierPaymentsShow}
            edit={SupplierPaymentsEdit}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
          <Resource
            name="ProductCategories"
            list={ProductCategoriesList}
            create={ProductCategoriesCreate}
            edit={ProductCategoriesEdit}
            // icon={CategoryIcon}
            options={{ alterDir, direction }}
          />
          <Resource
            name="Products"
            list={ProductsList}
            create={ProductsCreate}
            edit={ProductsEdit}
            show={ProductsShow}
            options={{ alterDir, direction }}
          />
          <Resource
            name="ProductDamageTypes"
            list={ProductDamageTypesList}
            create={ProductDamageTypesCreate}
            edit={ProductDamageTypesEdit}
            // icon={BrokenImageOutlinedIcon}
            options={{ alterDir, direction }}
          />
          <Resource
            name="ProcessInvoices"
            list={ProcessInvoicesList}
            create={ProcessInvoicesCreate}
            show={ProcessInvoicesShow}
            options={{ alterDir, direction }}
          />
          <Resource
            name="DamageInvoices"
            list={ProductDamagesList}
            create={ProductDamagesCreate}
            show={ProductDamagesShow}
            // icon={BrokenImageIcon}
            options={{ alterDir, direction }}
          />
          <Resource
            name="StoreTransferInvoices"
            list={StoreTransferInvoicesList}
            create={StoreTransferInvoicesCreate}
            show={StoreTransferInvoicesShow}
            options={{ alterDir, direction }}
          />
          <Resource
            name="ToDoLists"
            list={ToDoList}
            create={ToDoCreate}
            show={ToDoShow}
            edit={ToDoEdit}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
          <Resource
            name="SellInvoices"
            list={SellInvoicesList}
            create={SellInvoicesCreate}
            edit={SellInvoicesEdit}
            show={SellInvoicesShow}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
          <Resource
            name="SellReturnInvoices"
            list={SellReturnInvoicesList}
            create={SellReturnInvoicesCreate}
            show={SellReturnInvoicesShow}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
          <Resource
            name="PurchaseInvoices"
            list={PurchasesList}
            create={PurchasesCreate}
            show={PurchasesShow}
            edit={PurchasesEdit}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
          <Resource
            name="StoreInventoryInvoice"
            list={StoreInventoryInvoiceList}
            create={StoreInventoryInvoiceCreate}
            show={StoreInventoryInvoiceShow}
            edit={StoreInventoryInvoiceEdit}
            options={{ alterDir, direction }}
          />
          <Resource
            name="PurchaseReturnInvoices"
            list={PurchaseReturnsList}
            create={PurchaseReturnsCreate}
            show={PurchaseReturnsShow}
            // icon={StoreIcon}
            options={{ alterDir, direction }}
          />
          <Resource
            name="Settings"
            create={Settings}
            // list={role !== 'Seller' && Reports}
            icon={AssessmentIcon}
            options={{ alterDir, direction }}
          />
          {/* <Resource
        name="DepositTypes"
        list={PrePurchasesList}
        create={PrePurchasesCreate}
        edit={PrePurchasesEdit}
      /> */}
          <Resource
            name="WithdrawTypes"
            list={WithdrawsTypesList}
            create={WithdrawsTypesCreate}
            edit={WithdrawsTypesEdit}
          />
          <Resource
            name="DepositTypes"
            list={DepositsTypesList}
            create={DepositsTypesCreate}
            edit={DepositsTypesEdit}
          />
          <Resource
            name="ExpenseTypes"
            list={ExpenseTypesList}
            create={ExpenseTypesCreate}
            edit={ExpenseTypesEdit}
          />
          <Resource
            name="UploadCenters"
            list={UploadCentersList}
            create={UploadCentersCreate}
            edit={UploadCentersEdit}
          />
          <Resource
            name="Representatives"
            list={RepresentativesList}
            create={RepresentativesCreate}
            edit={RepresentativesEdit}
            show={RepresentativesShow}
          />
          <Resource
            name="RepresentativeInvoices"
            list={RepresentativeInvoicesList}
            create={RepresentativeInvoicesCreate}
            // edit={RepresentativeInvoicesEdit}
            show={RepresentativeInvoicesShow}
            options={{ alterDir, direction }}
          />
          <Resource
            name="RepresentativeReturnInvoices"
            list={RepresentativeReturnInvoicesList}
            create={RepresentativeReturnInvoicesCreate}
            // edit={RepresentativeReturnInvoicesEdit}
            show={RepresentativeReturnInvoicesShow}
            options={{ alterDir, direction }}
          />
          <Resource
            name="RepresentativePreInvoices"
            list={RepresentativePreInvoicesList}
            show={RepresentativePreInvoicesShow}
            options={{ alterDir, direction }}
          />
          <Resource
            name="RepresentativeCustomers"
            list={RepresentativeCustomersList}
            create={RepresentativeCustomersCreate}
            edit={RepresentativeCustomersEdit}
          />
          <Resource
            name="RepresentativeCustomerSellInvoices"
            list={RepresentativeCustomerSellInvoicesList}
            show={RepresentativeCustomerSellInvoicesShow}
            options={{ alterDir, direction }}
          />
          <Resource
            name="RepresentativeCustomerChecks"
            list={RepresentativeCustomerChecksList}
          />
          <Resource
            name="Expenses"
            list={ExpensesList}
            create={ExpensesCreate}
            edit={ExpensesEdit}
            show={ExpensesShow}
          />
          <Resource
            name="BankAccounts"
            list={BankAccountsList}
            create={BankAccountsCreate}
            edit={BankAccountsEdit}
            show={BankAccountsShow}
            options={{ alterDir, direction }}
          />
          <Resource
            name="BankAccountTransfers"
            list={BankAccountTransfersList}
            create={BankAccountTransfersCreate}
            show={BankAccountTransfersShow}
            options={{ alterDir, direction }}
          />
          <Resource
            name="StoreTransactionHistories"
            list={StoreTransactionHistoriesList}
            options={{ alterDir, direction }}
          />

          <Resource
            name="Brands"
            list={BrandsList}
            create={BrandsCreate}
            edit={BrandsEdit}
            options={{ alterDir, direction }}
          />
          <Resource
            name="UserStoreBankAccountWithdraws"
            list={CashierWithdrawsList}
            create={CashierWithdrawsCreate}
            // show={CashierWithdrawsShow}
            options={{ alterDir, direction }}
          />
          <Resource
            name="EmployeeSalaryChanges"
            list={EmployeeSalaryChangesList}
            create={EmployeeSalaryChangesCreate}
            options={{ alterDir, direction }}
          />
          <Resource
            name="Utilities/GetLogs"
            list={GetLogs}
            options={{ alterDir, direction }}
          />

          <CustomRoutes>
            <Route path="/Pos" element={<Pos />} />
            <Route path="/Profile" element={<Profile />} />
            <Route
              path="/Reports/ProductStatement"
              element={<ProductStatement />}
            />

            <Route
              path="/Reports/CustomerStatement"
              element={<CustomerStatement />}
            />

            <Route
              path="/Reports/SupplierStatement"
              element={<SupplierStatement />}
            />

            <Route
              path="/Reports/RepresentativeStatement"
              element={<RepresentativeStatement />}
            />

            <Route path="/Reports/StockReport" element={<StockReport />} />

            <Route path="/Reports/ExpireReport" element={<ExpireReport />} />

            <Route
              path="/Reports/SellInvoiceReport"
              element={<SellInvoiceReport />}
            />

            <Route
              path="/Reports/PurchaseReport"
              element={<PurchaseReport />}
            />

            <Route path="/Reports/ExpenseReport" element={<ExpenseReport />} />

            <Route
              path="/Reports/CustomerReport"
              element={<CustomerReport />}
            />

            <Route
              path="/Reports/SupplierReport"
              element={<SupplierReport />}
            />

            <Route path="/Reports/OrdersReport" element={<OrdersReport />} />

            <Route
              path="/Reports/SupplierItemReport"
              element={<SupplierItemReport />}
            />

            <Route
              path="/Reports/ItemSupplierReport"
              element={<ItemSupplierReport />}
            />

            <Route
              path="/Reports/SupplierPaymentReport"
              element={<SupplierPaymentReport />}
            />

            <Route
              path="/Reports/CustomerPaymentReport"
              element={<CustomerPaymentReport />}
            />

            <Route
              path="/HrReports/EmployeeAccountStatement"
              element={<EmployeeAccountStatement />}
            />

            <Route path="/HrReports/LoanReport" element={<LoanReport />} />

            <Route
              path="/HrReports/LoanReturnReport"
              element={<LoanReturnReport />}
            />

            <Route path="/HrReports/AbsentReport" element={<AbsentReport />} />

            <Route path="/HrReports/RewardReport" element={<RewardReport />} />

            <Route
              path="/HrReports/PunishmentReport"
              element={<PunishmentReport />}
            />

            <Route
              path="/HrReports/PermissionReport"
              element={<PermissionReport />}
            />

            <Route
              path="/HrReports/SalaryPayReport"
              element={<SalaryPayReport />}
            />

            <Route path="/BalanceSheet" element={<BalanceSheet />} />
            <Route path="/Reports/ReportStock" element={<ReportStock />} />

            <Route path="/Reports/NotSaleReport" element={<NotSaleReport />} />

            <Route
              path="/Reports/SupplierStockRemain"
              element={<SupplierStockRemain />}
            />

            <Route
              path="/Reports/SupplierStockFullReport"
              element={<SupplierStockFullReport />}
            />

            <Route
              path="/Reports/SupplierSellReport"
              element={<SupplierSellReport />}
            />

            <Route
              path="/Reports/BrandItemReport"
              element={<BrandItemReport />}
            />

            <Route
              path="/Reports/CashierSellsReport"
              element={<CashierSellsReport />}
            />

            <Route path="/InvoiceMockup" element={<InvoiceMockup />} />
            <Route path="/VoucherMockup" element={<VoucherMockup />} />
            <Route path="/ProductLabel" element={<ProductLabel />} />
            <Route path="/ProductOffers" element={<ProductOffers />} />
            <Route path="/ProductStick" element={<ProductStick />} />
            <Route path="/Cashier/CashierSells" element={<CashierSells />} />

            <Route path="/Cashier/SellInfo/:id/show" element={<SellInfo />} />

            <Route
              path="/Cashier/SellInfo/:id/show/:return"
              element={<SellInfo />}
            />

            <Route path="/SellReceipt" element={<SellReceipt />} />
            <Route path="/VoucherReceipt" element={<VoucherReceipt />} />
            <Route path="/AdminReports/ProfitLose" element={<ProfitLose />} />
            <Route
              path="/Reports/ProductSellsInvoice"
              element={<ProductSellsInvoice />}
            />

            <Route
              path="/AdminReports/InvoiceProfitLose"
              element={<InvoiceProfitLose />}
            />
            <Route
              path="/AdminReports/CustomerProfitLose"
              element={<CustomerProfitLose />}
            />

            <Route
              path="/Reports/SupplierStatement/:id"
              element={<SupplierStatementById />}
            />

            <Route
              path="/Reports/CustomerStatement/:id"
              element={<CustomerStatementById />}
            />

            <Route
              path="/Reports/ProductStatement/:id"
              element={<ProductStatementById />}
            />

            <Route
              path="/Reports/RepresentativeCustomerStatement/:id"
              element={<RepresentativeCustomerStatementById />}
            />

            <Route path="/NewInvoice" element={<NewInvoice />} />
            <Route
              path="/PrintInvoice/:type/:json"
              element={<PrintInvoice />}
            />

            <Route
              path="/PrintInvoiceOrders/:type"
              element={<PrintInvoiceOrders />}
            />

            <Route
              path="/PrintInvoice/quicksellinvoice/:type/:json"
              element={<PrintQuickSellInvoice />}
            />
          </CustomRoutes>
        </Admin>
      </div>
    );
  }
};
export default App;
