import React, { Fragment } from "react";
import { Form } from "react-bootstrap";

const TextInpute = ({ lable, name, onChange }) => {
  return (
    <Fragment>
      <Form.Group className="mb-3 mx-4" controlId={name}>
        <Form.Label>{lable}</Form.Label>
        <Form.Control
          className="py-4"
          type="text"
          placeholder={lable}
          name={name}
          onChange={onChange}
        />
      </Form.Group>
    </Fragment>
  );
};

export default TextInpute;
